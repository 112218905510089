export default {
	FORM: {
		CONNECT: 'connect',
		DISCONNECT: 'disconnect',
		RESIZE: 'resize',
		CHANGE: 'change',
		BUILD: 'build',
		BUILT: 'built',
		SUBMIT: 'submit',
		SUBMITTED: 'submitted',
		ERROR: 'error',
		LOADING: 'loading',
		LOADED: 'loaded',

		HAS_ADVANCED_PARAMETERS: 'hasAdvancedParameters',
	},
	INPUT: {
		MODE_CHANGE: 'modeChange',
		MODE_CHANGED: 'modeChanged',
		NESTED_CHANGED: 'nestedChanged',
		HAS_DYNAMIC_NESTED: 'hasDynamicNested',
	},
	FIELDSET: {
		BUILT: 'built',
	},
	PICKER: {
		CHANGE: 'change',
		CHANGED: 'changed',
		BEFORE_SELECT_OPTION: 'beforeSelect',
		LOAD_DONE: 'loadDone',
		LOAD_ERROR: 'loadError',
	},
	PANEL: {
		OPEN: 'open',
		CLOSE: 'close',
		OPEN_MENU: 'openPanelMenu',
	},
	DATASTORE: {
		BROWSE: 'browse',
	},
};
