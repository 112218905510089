import { SelectInput } from './select.mjs';
import OAuth from '../controls/oauth.mjs';
import { Picker } from '../controls/picker.mjs';
import configs from '../configs/config.mjs';
import EVENTS from '../events.mjs';
import { composedInputBuilder, create, registerCustomElement, resolveDefaultInputValue } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';
import ConnectionManager from '../controls/connectionManager.mjs';

const config = configs.fields.account;

export class AccountInput extends SelectInput {
	constructor() {
		super();

		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return ['scoped', 'connection'];
	}

	extendInstructions(instructions, value, metadata) {
		instructions.options = instructions.options || {};
		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || 'Choose an account';
		instructions.options.default = instructions.required ? 'scoped' : null;
		instructions.options.team = this.getTeamId(instructions);
		if (!instructions.options.team) instructions.grouped = true;
		instructions.connectionTypes = (this.getAttribute('type') || '').split(',').map((type) => {
			let scope = instructions.options.scope || this.form.meta.module.config?.scope;

			if (scope && !Array.isArray(scope)) scope = scope[type];

			return {
				type,
				scope: scope || [],
			};
		});

		if (this.form.meta.module.package && !instructions.help) {
			instructions.help = I18n.l('hints.account', {
				service: this.form.meta.module.package.label,
				name: this.form.meta.module.package.name.replace(/^app#/, ''),
			});
		}

		const storeQuery = new URLSearchParams();

		storeQuery.append('teamId', instructions.options.team);
		storeQuery.append('pg[limit]', config.api.list.limit);
		instructions.connectionTypes.forEach((acc) => {
			storeQuery.append('type', acc.type);
			acc.scope.forEach((scope) => storeQuery.append(acc.type, scope));
		});

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;
		instructions.theme = instructions.theme || this.form.meta.module?.package?.theme || DEFAULT_THEME;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker
		const picker = create('imt-picker');

		if (typeof value === 'undefined') {
			value = resolveDefaultInputValue(this, instructions.type);
		}

		picker.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item) => {
			if (item.metadata?.value) {
				option.textContent += ` (${item.metadata.value})`;
			}
			option.setAttribute('data-scoped', item.scoped);
			option.setAttribute('data-connection', item.accountName);
		});

		// Extend of the connection
		picker.addEventListener(EVENTS.PICKER.CHANGE, this._extendHandler(instructions));

		// Do not select default option if the connection doesn't have sufficient scope
		picker.addEventListener(EVENTS.PICKER.BEFORE_SELECT_OPTION, (event) => {
			const option = event.detail.option;

			if (option && option.getAttribute('data-scoped') === 'false') {
				event.preventDefault();
			}
		});

		// Build the component
		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = (await ConnectionManager.getCreateConnectionPanel(instructions, event.target)).handler;

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				try {
					const connection = event.detail.data;

					await this.addOption({
						id: connection[0],
						name: connection[1],
						connection: connection[2],
						metadata: connection[3],
					});
				} catch (ex) {
					throw new Error(I18n.l('accounts.failedAdd'));
				}
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			this.panel.open();
		};
	}

	// TODO move logic to the connection manager
	_extendHandler(instructions) {
		return (event) => {
			if (this.panel) return;

			if (!event.detail?.element) return;

			const option = event.detail.element;

			if (option.getAttribute('data-scoped') === 'true') return;

			const connectionId = parseInt(option.value);

			// Skip placeholders
			if (Number.isNaN(connectionId)) return;

			this.panel = create('imt-panel');
			this.panel.width = 350;
			this.panel.position = ['right', 'left'];
			this.panel.relative = event.target;

			const title = create('h1');

			title.textContent = I18n.l('panels.account.extend');
			this.panel.header.append(title);
			this.panel.header.addCloseButton();

			const content = create('p');

			content.textContent = I18n.l('panels.account.extendhelp');
			this.panel.body.append(content);

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;

				event.preventDefault();
			});

			const continueButton = create('button.btn.btn-primary');

			continueButton.textContent = I18n.l('common.continue');
			continueButton.addEventListener('click', async (event) => {
				this.panel.setLoading(I18n.l('panels.accounts.extending'));

				const scope =
					instructions.connectionTypes.filter(
						(connection) => connection.type === option.getAttribute('data-connection'),
					)[0]?.scope || [];

				try {
					await OAuth.extend.call(this.panel, connectionId, scope);

					this.panel.close();
					this.panel = null;

					option.setAttribute('data-scoped', 'true');
					this.value = connectionId;
				} catch (ex) {
					this.panel.warn(ex);
				}
			});

			this.panel.footer.append(continueButton);
			this.panel.open();

			event.preventDefault();
		};
	}
}

registerCustomElement('imt-input-account', AccountInput);
