// partially ported from https://github.com/integromat/imt-web-zone/blob/master/libs/zone/inspector/feature-coffee/src/imt.base/color.coffee
export const DEFAULT_THEME = 'var(--imt-primary, #6d00cc)';

export class Color {
	constructor(color, ...params) {
		this.color = { r: 0, g: 0, b: 0, a: 1 };

		if (typeof color === 'string') {
			if (color.charAt(0) === '#') {
				color = color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/, (m, r, g, b) => r + r + g + g + b + b);

				const res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

				if (res) {
					this.color = {
						r: parseInt(res[1], 16),
						g: parseInt(res[2], 16),
						b: parseInt(res[3], 16),
						a: 1,
					};
				}
			} else if (/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i.exec(color)) {
				this.color = {
					r: parseInt(RegExp.$1),
					g: parseInt(RegExp.$2),
					b: parseInt(RegExp.$3),
					a: 1,
				};
			} else if (/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+\.?\d*)\s*\)$/i.exec(color)) {
				this.color = {
					r: parseInt(RegExp.$1),
					g: parseInt(RegExp.$2),
					b: parseInt(RegExp.$3),
					a: parseFloat(RegExp.$4),
				};
			}
		} else if (typeof color === 'number') {
			this.color = {
				r: color,
				g: params[0],
				b: params[1],
				a: params[2],
			};
		} else if (color instanceof Color) {
			this.color = { ...color.color };
		}
	}

	get alpha() {
		return this.color.a;
	}

	set alpha(value) {
		this.color.a = parseInt(value);
	}

	get red() {
		return this.color.r;
	}

	set red(value) {
		this.color.r = parseInt(value);
	}

	get green() {
		return this.color.g;
	}

	set green(value) {
		this.color.g = parseInt(value);
	}

	get blue() {
		return this.color.b;
	}

	set blue(value) {
		this.color.b = parseInt(value);
	}

	get luminance() {
		return (0.3 * this.color.r + 0.59 * this.color.g + 0.11 * this.color.b) / 255;
	}

	determineForegroundColor() {
		if (this.alpha < 0.5) {
			return new Color();
		}

		if (this.luminance <= 0.82) {
			return new Color(255, 255, 255);
		}

		return new Color();
	}

	toHex() {
		const pad = (n) => (n.length < 2 ? `0${n}` : n);

		return `${pad(this.color.r.toString(16))}${pad(this.color.g.toString(16))}${pad(this.color.b.toString(16))}`;
	}
}
