import { registerCustomElement, create, uuid, dispatchResizeDebounced } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { Color } from '../helpers/color.mjs';
import { Input } from '../inputs/input.mjs';

const SEMANTIC_TYPES = {
	file: {
		label: 'File',
		icon: 'files-o',
		fields: ['name', 'data'],
	},
};

const selectors = {
	item: 'div.custom-control.custom-radio',
	input: 'input.custom-control-input',
	label: 'label.custom-control-label',
};

export class SemanticGroup extends HTMLElement {
	constructor() {
		super();

		this._built = false;

		this._id = uuid();

		const observer = new MutationObserver((mutations) => {
			if (mutations.some((mutation) => mutation.target === this)) return;

			const invalid = this.querySelector('.form-group.invalid');

			invalid ? this.classList.add('invalid') : this.classList.remove('invalid');
		});

		observer.observe(this, {
			attributes: true,
			subtree: true,
		});
	}

	get form() {
		if (!this._form) {
			this._form = this.closest('imt-forman');
		}

		return this._form;
	}

	get type() {
		return this.getAttribute('type');
	}

	set type(value) {
		this.setAttribute('type', value);
	}

	get mode() {
		return this.getAttribute('mode');
	}

	set mode(value) {
		this.setAttribute('mode', value);
	}

	get collapsed() {
		return this.classList.contains('collapsed');
	}

	set collapsed(value) {
		if (this.collapsed === value) return;

		if (value) {
			this.classList.add('collapsed');
			this._labelPreview.textContent = this.getPreview();
		} else {
			this.classList.remove('collapsed');
		}

		dispatchResizeDebounced(this);
	}

	connectedCallback() {}

	build() {
		if (this._built) return;

		this._built = true;

		this.className = 'form-group';

		let matchFound = false;
		const inputs = Array.from(this.childNodes);
		const values = this.form.meta?.pills?.semantic?.filter((item) => item.type === this.type);
		const filled = inputs.some((child) => child.value !== '');
		const required = inputs.some((child) => child.required);

		if (inputs.every((child) => child.advanced)) {
			this.setAttribute('advanced', '');

			if (!this.form.advancedParametersVisible) {
				this.classList.add('d-none');
			}
		}

		this.control = create('div.control');
		this.wrapper = create('div.wrapper');

		inputs.forEach((input) => this.wrapper.append(input));

		function map(map) {
			inputs.forEach((input) => {
				const type = input._instructions.semantic?.split(':')[1];

				if (map[type]) {
					input.value = `{{${map[type]}}}`;
				} else {
					input.value = null;
				}
			});
		}

		const name = `semantic-group-${this.type}-${this._id}`;

		if (values) {
			values.forEach((value) => {
				const item = create(selectors.item);
				const id = `${name}-${value.node.id}`;
				const input = item.appendChild(create(`${selectors.input}[type="radio"]`));

				input.id = id;
				input.setAttribute('name', name);

				const label = item.appendChild(create(selectors.label));

				label.setAttribute('for', id);

				const badge = label.appendChild(create('span.badge'));

				badge.style.color = '#' + new Color(value.theme).determineForegroundColor().toHex();
				badge.style.backgroundColor = value.theme;
				badge.textContent = `${value.node.package.label} - ${value.node.config.label}`;

				if (filled) {
					// values was filled before
					let matches = true;

					for (const input of Array.from(inputs)) {
						if (input.value !== `{{${value.map[input._instructions.semantic?.split(':')[1]]}}}`) {
							matches = false;
							break;
						}
					}

					if (matches) {
						matchFound = true;
						input.checked = true;
						this.mode = 'pick';
					}
				} else if (required && !matchFound) {
					// values was not filled but the field is required so we pre-select first option
					matchFound = true;
					input.checked = true;
					this.mode = 'pick';

					map(value.map);
				}

				item.addEventListener('click', () => {
					input.checked = true;
					this.mode = 'pick';
					map(value.map);

					dispatchResizeDebounced(this);
				});

				item.addEventListener('mouseenter', () => {
					if (value.node) value.node.enlarge = true;
				});

				item.addEventListener('mouseleave', () => {
					if (value.node) value.node.enlarge = false;
				});

				this.control.append(item);
			});

			// Manual mode

			const item = create(selectors.item);
			const id = `${name}-map`;
			const input = item.appendChild(create(`${selectors.input}#[type="radio"]`));

			input.id = id;
			input.setAttribute('name', name);

			const label = item.appendChild(create(selectors.label));

			label.setAttribute('for', id);
			label.textContent = I18n.l('buttons.map');

			item.addEventListener('click', () => {
				input.checked = true;
				this.mode = 'map';

				dispatchResizeDebounced(this);
			});

			this.control.append(item);

			if (!matchFound && filled) {
				input.checked = true;
			}
		}

		// Expander & label

		const expander = create('div.form-expander');

		expander.appendChild(create('span')); // To make animations possible without rotating whole square
		this.appendChild(expander);
		expander.addEventListener('click', (event) => {
			this.collapsed = !this.collapsed;
			event.stopImmediatePropagation();
		});

		this._label = create('label');
		// this._label.setAttribute('for', this._id);
		this.appendChild(this._label);

		const labelContent = create('span');

		labelContent.innerHTML = SEMANTIC_TYPES[this.type].label;
		this._label.appendChild(labelContent);
		this._labelPreview = this._label.appendChild(create('div.label-preview'));

		this.append(this.control);
		this.append(this.wrapper);
	}

	getPreview() {
		const fields = Array.from(this.wrapper.querySelectorAll('.form-group'));

		const value = fields
			.slice(0, 15) // take only a small slice to avoid computing unnecessarily long strings
			.filter((f) => f instanceof Input)
			.map((f) => `${f._instructions.label || f._instructions.name}: ${f.getPreview()}`)
			.filter((l) => !!l)
			.join(', ');

		if (value === null || value === undefined || value === '') {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		}

		return value;
	}
}

registerCustomElement('imt-semantic-group', SemanticGroup);
