/**
 * This file is used by both font-end and back-end.
 */

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	if (value == null) return null;

	if (typeof value === 'string') {
		if (value === 'false') {
			return false;
		} else {
			return true;
		}
	} else if (typeof value !== 'boolean') {
		return value != null;
	}

	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null || value === '') return null;

	if (typeof value === 'string') {
		if (value === 'false') {
			value = false;
		} else {
			value = true;
		}
	} else if (typeof value !== 'boolean') {
		value = value != null;
	}

	if (value && instructions.nested && more) {
		more(instructions.nested.store || instructions.nested);
	}

	return value;
}
