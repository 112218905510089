import { Input } from './input.mjs';
import { create, registerCustomElement } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class UploadInput extends Input {
	get hasModeSwitch() {
		return false;
	}

	get value() {
		return this._field.files;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this._field = create('input[type="file"].form-control.h-auto');

		if (instructions.multiple) {
			this._field.setAttribute('multiple', '');
		}

		if (instructions.accept) {
			this._field.setAttribute('accept', instructions.accept);
		}

		this.append(this._field);
	}

	_setDisabled(disabled) {
		if (disabled) {
			this._field.setAttribute('disabled', '');
		} else {
			this._field.removeAttribute('disabled');
		}
	}

	getPreview(value) {
		value = this.value;

		if (value === null || value === undefined || value === '') {
			return `<${I18n.l('file.noFile')}>`;
		}

		if (value instanceof FileList) {
			if (value.length > 0) {
				const fileName = value.item(0).name;

				return `${fileName} ${value.length > 1 ? I18n.l('file.more', { n: value.length - 1 }) : ''}`;
			} else {
				return `<${I18n.l('file.noFile')}>`;
			}
		}

		return `<${I18n.l('common.empty')}>`;
	}
}

registerCustomElement('imt-input-upload', UploadInput);
