import { Input } from './input.mjs';
import { create, debounce, registerCustomElement, showNestedFieldsets } from '../utils.mjs';
import EVENTS from '../events.mjs';

const STATE_LABEL = ['Yes', 'No', 'Empty'];
const STATE_VALUE = [true, false, undefined];

export class BooleanInput extends Input {
	get value() {
		if (this.mode === 'map') return this._coder.value;

		for (let i = 0; i < (this._controls || []).length; i++) {
			if (this._controls[i].checked) return STATE_VALUE[i];
		}

		return undefined;
	}

	set value(value) {
		if (this.mode === 'map') {
			this._coder.value = value;
			return;
		}

		if (value === undefined) {
			value = null;
		}

		if (this.value === value) return;

		if (value === true || value === 'true') {
			this._controls[0].checked = true;
		} else if (value === false || value === 'false' || this._instructions.required) {
			this._controls[1].checked = true;
		} else {
			this._controls[2].checked = true;
		}

		this._wrapper.dispatchEvent(new CustomEvent('input', { bubbles: true }));
	}

	get hasModeSwitch() {
		return this.mappable;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this._wrapper = create('div.wrapper');
		this._controls = [];

		for (let i = 0; i < (instructions.required ? 2 : 3); i++) {
			const element = create('div.custom-control.custom-radio.custom-control-inline');
			const control = create('input.custom-control-input[type="radio"]');

			control.name = `${instructions.name}_${this._id}`;
			control.id = `${this._id}_${i + 1}`;
			control.disabled = instructions.disabled || instructions.readonly;

			const innerLabel = create('label.custom-control-label');

			innerLabel.setAttribute('for', `${this._id}_${i + 1}`);
			innerLabel.textContent = STATE_LABEL[i];

			element.appendChild(control);
			element.appendChild(innerLabel);
			this._wrapper.appendChild(element);

			this._controls.push(control);
		}

		this.appendChild(this._wrapper);
		this.value = value;

		const showNestedFieldsetsDebounced = debounce(() => showNestedFieldsets(this));

		showNestedFieldsets(this, metadata?.fieldset?.value, metadata?.fieldset?.metadata).then(() => {
			this._wrapper.addEventListener('input', (e) => showNestedFieldsetsDebounced());
			this.addEventListener(
				EVENTS.INPUT.MODE_CHANGED,
				(event) => event.target === this && showNestedFieldsetsDebounced(),
			);
		});
	}

	_toCoderValue(value) {
		if (value === true) return '{{true}}';
		else if (value === false) return '{{false}}';
		else return value;
	}

	_fromCoderValue(value) {
		if (value === '{{true}}') return true;
		else if (value === '{{false}}') return false;
		else return value;
	}

	_setDisabled(disabled) {
		this._controls.forEach((c) => (c.disabled = disabled));
	}
}

registerCustomElement('imt-input-boolean', BooleanInput);
