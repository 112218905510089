/* eslint-disable max-len */

export default {
	common: {
		add: 'Add',
		close: 'Close',
		save: 'Save',
		popup: 'The pop-up window has been blocked. Please enable pop-up windows and try again.',
		error: 'Error',
		continue: 'Continue',
		edit: 'Edit',
		clone: 'Clone',
		showAdvanced: 'Show advanced settings',
		note: 'Note',
		name: 'Name',
		empty: 'Empty',
		whitespace: 'Whitespace',
		value: 'Value: ',
	},
	buttons: {
		edititem: 'Edit item',
		extract: 'Extract',
		newitem: 'New item',
		addor: 'Add OR rule',
		addand: 'Add AND rule',
		browse: 'Browse',
		map: 'Map',
		refresh: 'Refresh',
		expandAll: 'Expand all',
		collapseAll: 'Collapse all',
	},
	panels: {
		keychain: {
			add: 'Add new keychain',
		},
		udt: {
			add: 'Add data structure',
		},
		datastores: {
			create: 'Create a data store',
		},
		device: {
			add: 'Add new device',
		},
		account: {
			create: 'Create a connection',
			extend: 'Extend permissions',
			waiting: 'Waiting for authorization...',
			creating: 'Creating a connection...',
			authorize: 'Click here to start the authorization process.',
			extending: 'Extending permissions...',
			verifying: 'Verifying connection...',
			extendhelp: 'You need to extend the permissions given to the selected connection so that we can use it.',
			extendfailed: 'Failed to extend permissions',
			verifyfailed: 'Failed to verify the connection.',
		},
		hook: {
			create: 'Create a webhook',
		},
		rpc: {
			ok: 'OK',
		},
		datetimepicker: {
			dateTitle: 'Select date',
			timeTitle: 'Select time',
		},
	},
	datastore: {
		chooseDatastore: 'Choose a data store',
	},
	udtspec: {
		generate: 'Generate',
		specification: {
			label: 'Type',
		},
		label: {
			label: 'Label',
			help: 'Display name for better readability.',
		},
		name: {
			label: 'Name',
			help: 'Name of the property.',
		},
	},
	udt: {
		placeholder: 'Choose a data structure',
	},
	tokens: {
		token: 'Token',
		safariDenied: 'Permission was denied, please check your Safari settings.',
		safariDisabled: 'Asking for push notifications permissions is disabled in Safari preferences.',
		chromeExtNotInstalled: 'Chrome extension is not installed.',
		chromeActivateExtension: 'Please, activate the extension first',
	},
	hooks: {
		copy: 'Copy address to clipboard',
		gone: 'Webhook was inactive for too long and therefore was removed.',
		show: 'Show address',
		copied: 'Address was copied to clipboard.',
		create: 'Add a hook',
		attached: 'We read your mind, we attached this webhook automatically for you.',
		listening:
			'{{integromat}} is now listening for the data and will determine the data structure from the incoming data automatically. To initiate this, please send your data sample to the webhook address displayed above.',
		determined: 'Successfuly determined.',
		redetermine: 'Redetermine data structure',
	},
	accounts: {
		failedAdd: 'Failed to add a new connection.',
	},
	hints: {
		enum: 'Valid values: {{value}}.',
		format: 'Format: {{value}}',
		filetypes: 'Valid file types: {{value}}.',
		selectmost: 'Choose at most {{value}} item(s).',
		containmost: 'Can contain at most {{value}} item(s).',
		incomparray:
			'The source array, which you have mapped into the destination array, is not compatible with the target data structure. By clicking here you can add other modules to your scenario, which will enable you to remap the data structure of the source array.',
		selectleast: 'Choose at least {{value}} item(s).',
		containleast: 'Must contain at least {{value}} item(s).',
		exactlength: 'Must be exactly {{value}} characters long.',
		lengthbetween: 'Must be between {{a}} and {{b}} characters long.',
		lengthmost: 'Must be at most {{value}} characters long.',
		lengthleast: 'Must be at least {{value}} characters long.',
		pattern: 'Must match pattern /{{pattern}}/.',
		striptags: 'All HTML tags will be automatically removed.',
		escapetags: 'All HTML tags will be automatically escaped.',
		numberequal: 'Must be equal to {{value}}.',
		numberbetween: 'Must be a number between {{min}} and {{max}}.',
		numberlower: 'Must be lower than or equal to {{value}}.',
		numberhigher: 'Must be higher than or equal to {{value}}.',
		port: 'Must be between 1 and 65535.',
		timezone: 'Time zone: {{value}}',
		formats: 'For more information about supported date formats, see the [online Help](kb://mapping/type-coercion).',
		account:
			'For more information on how to create a connection to {{service}}, see the [online Help](kb://app/{{name}}).',
		webhook:
			'For more information on how to create a webhook in {{service}}, see the [online Help](kb://app/{{name}}).',
	},
	errors: {
		invalidResponse: 'Invalid response.',
		noMatches: 'No matches found.',
	},
	picker: {
		failedLoad: 'Failed to load data!',
		listEmpty: 'No items available.',
	},
	file: {
		noFile: 'No files selected',
		more: '+{{n}} files',
	},
	filter: {
		groups: {
			date: 'Datetime operators',
			text: 'Text operators',
			time: 'Time operators',
			array: 'Array operators',
			basic: 'Basic operators',
			number: 'Numeric operators',
			boolean: 'Boolean operators',
		},
		operators: {
			exist: {
				label: 'Exists',
			},
			notexist: {
				label: 'Does not exist',
			},
			'date:less': {
				label: 'Earlier than',
			},
			'time:less': {
				label: 'Less than',
			},
			'array:less': {
				label: 'Array length less than',
			},
			'date:equal': {
				label: 'Equal to',
			},
			'text:equal': {
				label: 'Equal to',
			},
			'time:equal': {
				label: 'Equal to',
			},
			'array:equal': {
				label: 'Array length equal to',
			},
			'number:less': {
				label: 'Less than',
			},
			'date:greater': {
				label: 'Later than',
			},
			'number:equal': {
				label: 'Equal to',
			},
			'text:contain': {
				label: 'Contains',
			},
			'text:endwith': {
				label: 'Ends with',
			},
			'text:pattern': {
				label: 'Matches pattern',
			},
			'time:greater': {
				label: 'Greater than',
			},
			'array:contain': {
				label: 'Contains',
			},
			'array:greater': {
				label: 'Array length greater than',
			},
			'boolean:equal': {
				label: 'Equal to',
			},
			'date:notequal': {
				label: 'Not equal to',
			},
			'text:equal:ci': {
				label: 'Equal to (case insensitive)',
			},
			'text:notequal': {
				label: 'Not equal to',
			},
			'time:notequal': {
				label: 'Not equal to',
			},
			'array:notequal': {
				label: 'Array length not equal to',
			},
			'number:greater': {
				label: 'Greater than',
			},
			'text:startwith': {
				label: 'Starts with',
			},
			'number:notequal': {
				label: 'Not equal to',
			},
			'text:contain:ci': {
				label: 'Contains (case insensitive)',
			},
			'text:endwith:ci': {
				label: 'Ends with (case insensitive)',
			},
			'text:notcontain': {
				label: 'Does not contain',
			},
			'text:notendwith': {
				label: 'Does not end with',
			},
			'text:notpattern': {
				label: 'Does not match pattern',
			},
			'text:pattern:ci': {
				label: 'Matches pattern (case insensitive)',
			},
			'array:contain:ci': {
				label: 'Contains (case insensitive)',
			},
			'array:notcontain': {
				label: 'Does not contain',
			},
			'boolean:notequal': {
				label: 'Not equal to',
			},
			'date:lessorequal': {
				label: 'Earlier than or equal to',
			},
			'text:notequal:ci': {
				label: 'Not equal to (case insensitive)',
			},
			'time:lessorequal': {
				label: 'Less than or equal to',
			},
			'array:lessorequal': {
				label: 'Array length less than or equal to',
			},
			'text:notstartwith': {
				label: 'Does not start with',
			},
			'text:startwith:ci': {
				label: 'Starts with (case insensitive)',
			},
			'number:lessorequal': {
				label: 'Less than or equal to',
			},
			'text:notcontain:ci': {
				label: 'Does not contain (case insensitive)',
			},
			'text:notendwith:ci': {
				label: 'Does not end with (case insensitive)',
			},
			'text:notpattern:ci': {
				label: 'Does not match pattern (case insensitive)',
			},
			'array:notcontain:ci': {
				label: 'Does not contain (case insensitive)',
			},
			'date:greaterorequal': {
				label: 'Later than or equal to',
			},
			'time:greaterorequal': {
				label: 'Greater than or equal to',
			},
			'array:greaterorequal': {
				label: 'Array length greater than or equal to',
			},
			'text:notstartwith:ci': {
				label: 'Does not start with (case insensitive)',
			},
			'number:greaterorequal': {
				label: 'Greater than or equal to',
			},
		},
	},

	iml: {
		date: {
			label: 'Date and time',
			functions: {
				setDay: {
					help: 'Returns a new date with the day specified in parameters. It can be used to set the day of the week, with Sunday as 1 and Saturday as 7. If the given value is from 1 to 7, the resulting date will be within the current (Sunday-to-Saturday) week. If a number is given outside of the range, it will return the day from the previous or subsequent week(s), accordingly.',
					syntax: 'setDay(date; number/name of the day in english)',
					example:
						'setDay("2018-06-27T11:36:39.138Z", "monday") {2018-06-25T11:36:39.138Z}\nsetDay("2018-06-27T11:36:39.138Z", 1) {2018-06-24T11:36:39.138Z}\nsetDay("2018-06-27T11:36:39.138Z", 7) {2018-06-30T11:36:39.138Z}',
				},
				addDays: {
					help: 'Returns a new date as a result of adding a given number of days to a date. To subtract days, enter a negative number.',
					syntax: 'addDays(date; number)',
					example:
						'addDays("2016-12-08T15:55:57.536Z"; 2) {2016-12-10T15:55:57.536Z}\naddDays("2016-12-08T15:55:57.536Z"; -2) {2016-12-6T15:55:57.536Z}',
				},
				setDate: {
					help: 'Returns a new date with the day of the month specified in parameters. Accepts numbers from 1 to 31. If a number is given outside of the range, it will return the day from the previous or subsequent month(s), accordingly.',
					syntax: 'setDate(date; number)',
					example:
						'setDate("2015-08-07T11:36:39.138Z", 5) {2015-08-05T11:36:39.138Z}\nsetDate("2015-08-07T11:36:39.138Z", 32) {2015-09-01T11:36:39.138Z}',
				},
				setHour: {
					help: 'Returns a new date with the hour specified in parameters. Accepts numbers from 0 to 23. If a number is given outside of the range, it will return the date with the hour from the previous or subsequent day(s), accordingly.',
					syntax: 'setHour(date; number)',
					example:
						'setHour("2015-08-07T11:36:39.138Z", 6) {2015-08-07T06:36:39.138Z}\nsetHour("2015-08-07T11:36:39.138Z", -6) {2015-08-06T18:36:39.138Z}',
				},
				setYear: {
					help: 'Returns a new date with the year specified in parameters.',
					syntax: 'setYear(date; number)',
					example: 'setYear("2015-08-07T11:36:39.138Z", 2017) {2017-08-07T11:36:39.138Z}',
				},
				addHours: {
					help: 'Returns a new date as a result of adding a given number of hours to a date. To subtract hours, enter a negative number.',
					syntax: 'addHours(date; number)',
					example:
						'addHours("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T17:55:57.536Z}\naddHours("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T13:55:57.536Z}',
				},
				addYears: {
					help: 'Returns a new date as a result of adding a given number of years to a date. To subtract years, enter a negative number.',
					syntax: 'addYears(datum;years)',
					example:
						'addYears("2016-08-08T15:55:57.536Z"; 2) {2018-08-08T15:55:57.536Z}\naddYears("2016-08-08T15:55:57.536Z"; -2) {2014-08-08T15:55:57.536Z}',
				},
				setMonth: {
					help: 'Returns a new date with the month specified in parameters. Accepts numbers from 1 to 12. If a number is given outside of this range, it will return the month in the previous or subsequent year(s), accordingly.',
					syntax: 'setMonth(date; number/name of the month in english)',
					example:
						'setMonth("2015-08-07T11:36:39.138Z", 5) {2015-05-07T11:36:39.138Z}\nsetMonth("2015-08-07T11:36:39.138Z", 17) {2016-05-07T11:36:39.138Z}\nsetMonth("2015-08-07T11:36:39.138Z", "january") {2015-01-07T12:36:39.138Z}',
				},
				addMonths: {
					help: 'Returns a new date as a result of adding a given number of months to a date. To subtract months, enter a negative number.',
					syntax: 'addMonths(date; number)',
					example:
						'addMonths("2016-08-08T15:55:57.536Z"; 2) {2016-10-08T15:55:57.536Z}\naddMonths("2016-08-08T15:55:57.536Z"; -2) {2016-06-08T15:55:57.536Z}',
				},
				parseDate: {
					help: 'Parses a string with a date and returns the date.',
					syntax: 'parseDate(date; format; [timezone])',
					example:
						'parseDate("2016-12-28"; "YYYY-MM-DD") {2016-12-28T00:00:00.000Z}\nparseDate("2016-12-28 16:03"; "YYYY-MM-DD HH:mm") {2016-12-28T16:03:00.000Z}\nparseDate("2016-12-28 04:03 pm"; "YYYY-MM-DD hh:mm a") {2016-12-28T16:03:06.000Z}\nparseDate(1482940986; "X") {2016-12-28T16:03:06.000Z}',
				},
				setMinute: {
					help: 'Returns a new date with the minutes specified in parameters. Accepts numbers from 0 to 59. If a number is given outside of the range, it will return the date with the minutes from the previous or subsequent hour(s), accordingly. ',
					syntax: 'setMinute(date; number)',
					example:
						'setMinute("2015-10-07T11:36:39.138Z", 10) {2015-10-07T11:10:39.138Z}\nsetMinute("2015-10-07T11:36:39.138Z", 61) {2015-10-07T12:01:39.138Z}',
				},
				setSecond: {
					help: 'Returns a new date with the seconds specified in parameters. Accepts numbers from 0 to 59. If a number is given outside of this range, it will return the date with the seconds from the previous or subsequent minute(s), accordingly.',
					syntax: 'setSecond(date; number)',
					example:
						'setSecond("2015-10-07T11:36:39.138Z", 10) {2015-10-07T11:36:10.138Z}\nsetSecond("2015-10-07T11:36:39.138Z", 61) {2015-10-07T11:37:01.138Z}',
				},
				addMinutes: {
					help: 'Returns a new date as a result of adding a given number of minutes to a date. To subtract minutes, enter a negative number.',
					syntax: 'addMinutes(date; number)',
					example:
						'addMinutes("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T15:57:57.536Z}\naddMinutes("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T15:53:57.536Z}',
				},
				addSeconds: {
					help: 'Returns a new date as a result of adding a given number of seconds to a date. To subtract seconds, enter a negative number.',
					syntax: 'addSeconds(date; number)',
					example:
						'addSeconds("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T15:55:59.536Z}\naddSeconds("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T15:55:55.536Z}',
				},
				formatDate: {
					help: 'Returns a date in the requested format and optionally, in a specified timezone. For example, format DD.MM.YYYY HH:mm',
					syntax: 'formatDate(date; format; [timezone])',
					example:
						'formatDate("2016-12-28T16:03:06.372Z"; "MM/DD/YYYY")  {12/28/2016}\nformatDate("2016-12-28T16:03:06.372Z"; "YYYY-MM-DD hh:mm A")  {2016-12-28 4:03 PM}\nformatDate("2016-12-28T16:03:06.372Z"; "DD.MM.YYYY HH:mm"; "Europe/Prague")  {28.12.2016 17:03}',
				},
			},
			variables: {
				now: {
					help: 'Current date and time.',
				},
				timestamp: {
					help: 'Unix timestamp. Number of seconds since midnight January 1, 1970 GMT.',
				},
			},
		},
		math: {
			label: 'Math functions',
			functions: {
				max: {
					help: 'Returns the largest number in a specified array, or the largest number among numbers entered individually.',
					syntax: 'max([array of values]), max(value1;value2; ...)',
				},
				min: {
					help: 'Returns the smallest number in a specified array, or the smallest number among numbers entered individually.',
					syntax: 'min([array of values]), min(value1; value2; ...)',
				},
				sum: {
					help: 'Returns the sum of the values in a specified array, or the sum of numbers entered individually.',
					syntax: 'sum([array of values]), sum(value1; value2; ...)',
				},
				ceil: {
					help: 'Returns the smallest integer greater than or equal to a specified number.',
					syntax: 'ceil(number)',
					example: 'ceil(1.2) {2}\nceil(4) {4}',
				},
				floor: {
					help: 'Returns the largest integer less than or equal to a specified number.',
					syntax: 'floor(number)',
					example: 'floor(1.2) {1}\nfloor(1.9) {1}\nfloor(4) {4}',
				},
				round: {
					help: 'Rounds a numeric value to the nearest integer.',
					syntax: 'round(number)',
					example: 'round(1.2) {1}\nround(1.5) {2}\nround(1.7) {2}\nround(2) {2}',
				},
				average: {
					help: 'Returns the average value of the numeric values in a specific array, or the average value of numerical values entered individually.',
					syntax: 'average([array of values])\naverage(value1; [value2], ...)',
				},
				parseNumber: {
					help: 'Parses a string with a number and returns the number. Example:  parseNumber(1 756,456;,)',
					syntax: 'parseNumber(number; decimal separator)',
				},
				formatNumber: {
					help: 'Returns a number in the requested format. Decimal point is `,` by default, Thousands separator is `.` by default.',
					syntax: 'formatNumber(number, decimalPoints, [decimalSeparator], [thousandsSeparator])',
				},
			},
			operators: {
				'%': {
					help: 'Modulus.',
				},
				'*': {
					help: 'Multiplication.',
				},
				'+': {
					help: 'Addition.',
				},
				'-': {
					help: 'Subtraction.',
				},
				'/': {
					help: 'Division.',
				},
				'<': {
					help: 'Lower than.',
				},
				'>': {
					help: 'Greater than.',
				},
				'<=': {
					help: 'Lower than or equal to.',
				},
				'>=': {
					help: 'Greater than or equal to.',
				},
				'++': {
					help: 'Sums up decimal numbers',
				},
			},
			variables: {
				pi: {
					help: 'Constant π.',
				},
				random: {
					help: 'A floating-point, random number between 0 (inclusive) and 1 (exclusive).',
				},
			},
		},
		array: {
			label: 'Functions for working with arrays',
			keywords: {
				emptyarray: {
					help: 'Inserts an empty array.',
					label: 'Empty array',
				},
			},
			functions: {
				add: {
					help: 'Adds values specified in parameters to an array and returns that array.',
					syntax: 'add(array; value1; value2; ...)',
				},
				map: {
					help: 'Returns a primitive array containing values of a complex array. Allows to filter values. Use raw variable names for keys.',
					syntax: 'map(complex array; key;[key for filtering];[possible values for filtering])',
					example:
						'map(Emails[]; "email") {Returns a primitive array with emails.}\nmap(Emails[]; "email"; "label"; "work,home") {Returns a primitive array with emails having a label equal to work or home.}',
				},
				join: {
					help: 'Concatenates all the items of an array into a string, using the specified separator between each item.',
					syntax: 'join(array; separator)',
				},
				keys: {
					help: "Returns an array of a given object's or array's properties.",
					syntax: 'keys(object)',
				},
				sort: {
					help: 'Sorts values of an array. The valid values of the `order` parameter are: `asc` (ascending order), `desc` (descending order), `asc ci` (case insensitive ascending order) and `desc ci` (case insensitive descending order). Use `key` argument to access properties inside complex objects. Use raw variable names for keys. To access nested properties, use dot notation. First item in an array is index 1.',
					syntax: 'sort(array; [order]; [key])',
					example:
						'sort(Contacts[]; "name") {Sorts an array of contacts by the `name` property.}\nsort(Emails[]; "sender.name") {Sorts an array of emails by the `sender.name` property.}',
				},
				merge: {
					help: 'Merges two or more arrays into one array.',
					syntax: 'merge(array1; array2; ...)',
				},
				slice: {
					help: 'Returns a new array containing only selected items.',
					syntax: 'slice(array; start; [end])',
				},
				length: {
					help: 'Returns the number of items in an array.',
					syntax: 'length(array)',
				},
				remove: {
					help: 'Removes values specified in the parameters of an array. Effective only on primitive arrays of text or numbers.',
					syntax: 'remove(array; value1; value2; ...)',
				},
				contains: {
					help: 'Verifies if an array contains the value.',
					syntax: 'contains(array; value)',
				},
				distinct: {
					help: 'Removes duplicates inside an array. Use `key` argument to access properties inside complex objects. To access nested properties, use dot notation. First item in an array is index 1.',
					syntax: 'distinct(array; [key])',
					example:
						'distinct(Contacts[]; "name") {Removes duplicates inside an array of contacts by comparing the `name` property.}',
				},
				toCollection: {
					help: 'Converts an array containing objects with key-value pairs to a collection.',
					syntax: 'toCollection(array; key; value)',
					example:
						"toCollection(array; \"name\", \"value\") {Converts array like this `[{name: 'firstName', value: 'John'}, {name: 'lastName', value: 'Mars'}] to a collection `{firstName: 'John', lastName: 'Mars'}`}",
				},
				deduplicate: {
					help: 'Removes duplicates from an array.',
					syntax: 'deduplicate(array)',
				},
				flatten: {
					help: 'Returns a new array with all sub-array elements concatenated into it recursively up to the specified depth. The default depth is `1`.',
					syntax: 'flatten(array, [depth])',
				},
				reverse: {
					help: 'Changes the sequence of elements of the given array and returns the reverse sequence.',
					syntax: 'reverse(array)',
				},
				shuffle: {
					help: 'Shuffles (randomly reorders) elements of an array.',
					syntax: 'shuffle(array)',
				},
				toArray: {
					help: ' Converts a collection into an array of key-value collections.',
					syntax: 'toArray(collection)',
				},
				first: {
					help: 'Returns the first element of an array.',
					syntax: 'first(array)',
				},
				last: {
					help: 'Returns the last element of an array.',
					syntax: 'last(array)',
				},
			},
		},
		string: {
			label: 'Text and binary functions',
			keywords: {
				tab: {
					help: 'Inserts a tab character in a specific location.',
					label: 'Tab',
				},
				nbsp: {
					help: 'Inserts a non-breaking space character in a specific location.',
					label: 'Non-breaking space',
				},
				space: {
					help: 'Inserts a space character in a specific location.',
					label: 'Space',
				},
				newline: {
					help: 'Inserts a new line character in a specific location.',
					label: 'New line',
				},
				emptystring: {
					help: 'Inserts an empty text in a specific location.',
					label: 'Empty text',
				},
				carriagereturn: {
					help: 'Inserts a carriage return character in a specific location.',
					label: 'Carriage return',
				},
			},
			functions: {
				md5: {
					help: 'Calculates the md5 hash of a string.',
					syntax: 'md5(text)',
					example: 'md5("internet") {c3581516868fb3b71746931cac66390e}',
				},
				sha1: {
					help: 'Calculates the sha1 hash of a string. If the key argument is specified, sha1 HMAC hash is returned instead. Supported output encodings: `hex` (default), `base64` or `latin1`. Supported key encodings: `text` (default), `hex`, `base64` or `binary`. When using `binary` key encoding, a key must be a buffer, not a string.',
					syntax: 'sha1(text; [output encoding]; [key]; [key encoding])',
					example: 'sha1("internet") {4d0fb475b242228032cbdf6d53924d2538df037b}',
				},
				trim: {
					help: 'Removes space characters at the start or end of the text.',
					syntax: 'trim(text)',
				},
				ascii: {
					help: 'Removes all non-ascii characters from a text string.',
					syntax: 'ascii(text; [remove diacritics])',
					example: 'ascii("Hěešlčlřož") {Hello}\nascii("ěščřž"; true) {escrz}',
				},
				lower: {
					help: 'Converts all alphabetical characters in a text string to lowercase.',
					syntax: 'lower (text)',
					example: 'lower("Hello"){hello}',
				},
				split: {
					help: 'Splits a string into an array of substrings split at each point where the separator occurs in the given string. It always trims the substrings (i.e. removes whitespace from both ends of a substring) and if the trimmed substring is an empty string, it is by default omitted from the array. If the keepEmptyString is `true`, empty substrings are preserved in the array.',
					syntax: 'split(text; separator; [keepEmptyString])',
					example: 'split("John, George, Paul"; ",")',
				},
				upper: {
					help: 'Converts all alphabetical characters in a text string to uppercase.',
					syntax: 'upper(text)',
					example: 'upper("Hello") {HELLO}',
				},
				base64: {
					help: 'Transforms text to base64.',
					syntax: 'base64(text)',
					example: 'base64("internet") {aW50ZXJuZXQ=}',
				},
				length: {
					help: 'Returns the length of a text string (number of characters) or binary buffer (buffer size in bytes).',
					syntax: 'length(text or buffer)',
					example: 'length("Hello"){5}',
				},
				sha256: {
					help: 'Calculates the sha256 hash of a string. If the key argument is specified, sha256 HMAC hash is returned instead. Supported encodings: `hex` (default), `base64` or `latin1`. Supported key encodings: `text` (default), `hex`, `base64` or `binary`. When using `binary` key encoding, a key must be a buffer, not a string.',
					syntax: 'sha256(text; [output encoding]; [key]; [key encoding])',
					example: 'sha256("internet") {3b0fe0d342e9fa16a5c68dbba33f2e63c024f72a9d4c1ce1028570101d5229ff}',
				},
				sha512: {
					help: 'Calculates the sha512 hash of a string. If the key argument is specified, sha512 HMAC hash is returned instead. Supported encodings: `hex` (default), `base64` or `latin1`. Supported key encodings: `text` (default), `hex`, `base64` or `binary`. When using `binary` key encoding, a key must be a buffer, not a string.',
					syntax: 'sha512(text; [output encoding]; [key]; [key encoding])',
					example:
						'sha512("internet") {394ec188f58367d2b25af31a11a61435b2544c197b0baeb43cfbcb0d7b0116cd8d412e618272ca23561f787960844f86b8f775803405fd2abc358861c074c595}',
				},
				indexOf: {
					help: "Returns the position of the first occurrence of a specified value in a string. This method returns '-1' if the value searched for never occurs.",
					syntax: 'indexOf(string; value; [start])',
					example: 'indexOf("Internet"; "t") {2}\nindexOf("Internet"; "x") {-1}\nindexOf("Internet"; "t"; 3) {7}',
				},
				replace: {
					help: ' Replaces the search string with the new string.',
					syntax: 'replace(text; search string; replacement string)',
					example: 'replace("Hello World"; "Hello"; "Hi") {Hi World}',
				},
				contains: {
					help: 'Verifies if text contains the search string.',
					syntax: 'contains(text; search string)',
					example: 'contains("Hello World"; "Hello")  {true}\ncontains("Hello World"; "Bye")  {false}',
				},
				toBinary: {
					help: 'Converts any value to binary data. You can also specify encoding as a second argument to apply binary conversions from hex or base64 to binary data.',
					syntax: 'toBinary(value)',
					example: 'toBinary("internet") {696e7465726e6574}\ntoBinary("aW50ZXJuZXQ="; "base64") {696e7465726e6574}',
				},
				toString: {
					help: 'Converts any value to a string.',
					syntax: 'toString(value)',
				},
				decodeURL: {
					help: 'Decodes special characters in URL to text.',
					syntax: 'decodeURL(text)',
					example: 'decodeURL("Automate%20your%20workflow") {Automate your workflow}',
				},
				encodeURL: {
					help: 'Encodes special characters in a text to a valid URL address.',
					syntax: 'encodeURL(text)',
				},
				startcase: {
					help: 'Capitalizes the first letter of every word and lower cases all other letters.',
					syntax: 'startcase(text)',
					example: 'startcase("hello WORLD") {Hello World}',
				},
				stripHTML: {
					help: 'Removes all HTML tags from text.',
					syntax: 'stripHTML(text)',
					example: 'stripHTML("<b>Hello</b>") {Hello}',
				},
				substring: {
					help: 'Returns a portion of a text string between the "start" position and "the end" position.',
					syntax: 'substring(text; start;end)',
					example: 'substring("Hello";0;3) {Hel}\nsubstring("Hello";1;3)  {el}',
				},
				capitalize: {
					help: 'Converts first character in a text string to uppercase.',
					syntax: 'capitalize(text)',
					example: 'capitalize("internet") {Internet}',
				},
				escapeHTML: {
					help: 'Escapes all HTML tags in text.',
					syntax: 'escapeHTML(text)',
					example: 'escapeHTML("<b>Hello</b>") {&lt;b&gt;Hello&lt;/b&gt;}',
				},
				escapeMarkdown: {
					example: 'escapeMarkdown("# Header") {&#35; Header}',
					help: 'Escapes all Markdown tags in text.',
					syntax: 'escapeMarkdown(text)',
				},
			},
			variables: {
				uuid: {
					help: 'RFC 4122 version 4 compliant unique identifier.',
				},
			},
		},
		general: {
			label: 'General functions',
			keywords: {
				null: {
					help: 'Inserts a null (empty) value.',
					label: 'Null',
				},
				true: {
					help: 'Logical value (true).',
					label: 'Yes',
				},
				false: {
					help: 'Logical value (false).',
					label: 'No',
				},
				ignore: {
					help: 'Instruct the engine to act as if the field was empty. Useful in complex IML expressions.',
					label: 'Ignore',
				},
				erase: {
					help: 'Sets the value of the field to empty value. In case of array it sets empty array.',
					label: 'Erase',
				},
			},
			functions: {
				'': {
					help: '',
					syntax: '(expression)',
				},
				if: {
					help: 'Returns value 1 if the expression is evaluated to true, otherwise it returns value 2.',
					syntax: 'if(expression; value1; value2)',
					example: 'if(1 = 1; "A"; "B") {A}\nif(1 = 2; "A"; "B") {B}',
				},
				get: {
					help: 'Returns the value path of an object or array. To access nested objects, use dot notation. First item in an array is index 1.',
					syntax: 'get(object or array; path)',
					example:
						'get(array; 1+1)\nget(array; "5.raw_name")\nget(object; "raw_name")\nget(object; "raw_name.sub_raw_name")',
				},
				equal: {
					help: 'Compares values to see if they are equal.',
					syntax: 'equal(value; value)',
				},
				switch: {
					help: 'Evaluates one value (called the expression) against a list of values, and returns the result corresponding to the first matching value.',
					syntax: 'switch(expression; value1; result1; [value2; result2; ...]; [else])',
					example:
						'switch("B"; "A"; 1; "B"; 2; "C"; 3) {2}\nswitch("C"; "A"; 1; "B"; 2; "C"; 3) {3}\nswitch("X"; "A"; 1; "B"; 2; "C"; 3; 4) {4}',
				},
				ifempty: {
					help: 'Returns value 1 if this value is not empty, otherwise it returns value 2.',
					syntax: 'ifempty(value1; value2)',
					example: 'ifempty("A";"B"){A}\nifempty(unknown;"B"){B}\nifempty("";B){B}',
				},
				omit: {
					help: 'Omits the given keys of the object and returns the rest.',
					syntax: 'omit(object; key1; [key2; ...])',
					example: 'omit(User; "password")',
				},
				pick: {
					help: 'Picks only the given keys from the object.',
					syntax: 'pick(object; key1; [key2; ...])',
					example: 'pick(User; "password"; "email")',
				},
			},
			operators: {
				'&': {
					help: 'Performs a logical conjunction on two expressions.',
					label: 'and',
				},
				'=': {
					help: 'Is equal to.',
				},
				'|': {
					help: 'Performs a logical disjunction on two expressions.',
					label: 'or',
				},
				'!=': {
					help: 'Not equal to.',
				},
			},
			variables: {
				executionId: {
					help: 'Unique ID of the currently running execution.',
				},
			},
		},
	},
	variables: {
		dynamicValue: 'The selected value is dynamic.',
		scenarioValueUnavailable: 'The value will be available after the scenario is saved.',
	},
};
