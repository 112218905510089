// import {normalize} from 'path';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	// normalize(value);
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value instanceof String) return null;
	if (value === '') return null;

	// normalize(value);
}
