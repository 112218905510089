/**
 * This file is used by both font-end and back-end.
 */

import { ValidationError } from './helpers/errors.mjs';
import Buffer from './deps/buffer.mjs';

import * as array from './types/array.mjs';
import * as boolean from './types/boolean.mjs';
import * as collection from './types/collection.mjs';
import * as integer from './types/integer.mjs';
import * as number from './types/number.mjs';
import * as port from './types/port.mjs';
import * as text from './types/text.mjs';
import * as email from './types/email.mjs';
import * as uinteger from './types/uinteger.mjs';
import * as any from './types/any.mjs';
import * as generated from './types/generated.mjs';
import * as url from './types/url.mjs';
import * as password from './types/password.mjs';
import * as hidden from './types/hidden.mjs';
import * as upload from './types/upload.mjs';
import * as select from './types/select.mjs';
import * as date from './types/date.mjs';
import * as editor from './types/editor.mjs';
import * as folder from './types/folder.mjs';
import * as file from './types/file.mjs';
import * as json from './types/json.mjs';
import * as filename from './types/filename.mjs';
import * as cert from './types/cert.mjs';
import * as pkey from './types/pkey.mjs';
import * as color from './types/color.mjs';
import * as timestamp from './types/timestamp.mjs';
import * as time from './types/time.mjs';

const NOOP = () => {};
const TYPES = {
	account: null,
	any,
	array,
	boolean,
	buffer: null,
	cert,
	checkbox: boolean,
	collection,
	color,
	date,
	device: null,
	editor,
	email,
	file,
	filename,
	filter: null,
	folder,
	generated,
	hidden,
	hook: null,
	integer,
	json,
	number,
	password,
	pkey,
	port,
	select,
	separator: null,
	text,
	time,
	timestamp,
	uinteger,
	upload,
	url,
	udtspec: null,
	udttype: null,
	agent: null,
	uuid: text, // There's no UUID input in Formula but apps uses it (formula fallbacks unknown inputs to text). No one know whether all uuid inputs really uses UUID format, so we don't validate it as UUID.
	path: text,
};

/**
 * Validates value against validation function of given type. If the value is not of that type
 * but it is possible to convert it, method returns the converted value.
 *
 * This function is called recursively from within types like boolean, array or collection. That's
 * why we accept more parameter - to allow nested fields to validate against the bundle of its parent.
 *
 * @param {*} value Value to validate.
 * @param {string} type Type name.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {boolean} [options.depth] If zero (`0`), nested structures are not validated. Infinite by default.
 * @param {boolean} [options.passthrough] If false, empty undefined and empty values are converted as null. True by default.
 * @param {boolean} [options.timezone] Default time zone for date-time operations. UTC by default.
 * @param {object} [options.imlContext] IML context for fields with generated type.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the given type.
 */

export function validate(value, type, instructions, options = {}, more = undefined) {
	if (!instructions) throw new Error('Instructions are missing.');

	if (
		instructions.required &&
		(value == null ||
			value === '' ||
			(typeof FileList !== 'undefined' && value instanceof FileList && value.length === 0))
	) {
		throw new ValidationError('Value must not be empty.', 'validate-bundle-param-required');
	}

	// If type is not found, skip validation.
	if (!TYPES[type]) return;

	// Empty strings are evaluated to null or undefined (when passthrough option is enabled)
	if (value === '') {
		if (options.passthrough === false) {
			return null;
		} else {
			// in passthrough mode, empty string remains untouched for any and text types, otherwise parameter is removed completely
			if (type === 'buffer') return Buffer.from('');
			if (type !== 'any' && type !== 'text' && type !== 'hidden') return null;
			return undefined;
		}
	} else if (value == null && type !== 'generated') {
		// Outputs undefined values
		return value;
	}

	// Evaluates {{emptystring}} IML variable to primitive
	if (value instanceof String) value = value.valueOf();

	try {
		value = TYPES[type].validate(value, instructions, options, more || NOOP);
	} catch (ex) {
		if (typeof document !== 'undefined') {
			const forman = document.querySelector('imt-forman');

			if (forman && forman.hasAttribute('debug')) {
				console.warn(ex);
			}
		}
		throw ex;
	}
	return value;
}
