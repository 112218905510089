/**
 * This file is used by both font-end and back-end.
 */

import { Buffer } from '../helpers/buffer.mjs';
import iconv from '../deps/iconv.mjs';
import { ValidationError } from '../helpers/errors.mjs';

function toString(value) {
	if (value == null) return '';

	switch (typeof value) {
		case 'object':
			if (value instanceof String || value instanceof Boolean || value instanceof Number) {
				return String(value.valueOf());
			}

			if (Buffer.isBuffer(value)) {
				// set default codepage
				const codepage = value.codepage || 'utf8';

				if (codepage === 'binary') {
					return '[Buffer]';
				}

				// check if we support codepage
				if (!iconv.encodingExists(codepage)) {
					throw new ValidationError(`Unknown codepage '${codepage}'.`, 'validate-buffer-unknown-codepage', {
						codepage,
					});
				}

				// convert
				return iconv.decode(value, codepage);
			} else if (Array.isArray(value)) {
				if (Object.prototype.hasOwnProperty.call(value, 'serialize') && typeof value.serialize === 'function') {
					return String(value.serialize());
				} else {
					return value.map((item) => toString(item)).join(', ');
				}
			} else if (value instanceof Date) {
				if (isNaN(value)) return null; // Invalid date
				return value.toISOString();
			} else {
				return JSON.stringify(value, (key, value) => {
					if (Buffer.isBuffer(value)) {
						return undefined;
					}
					return value;
				});
			}

		default:
			return String(value);
	}
}

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	if (value == null) return null;

	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null) return null;

	if (typeof value === 'object') {
		if (Buffer.isBuffer(value)) {
			// set default codepage
			const codepage = value.codepage || 'utf8';

			// binary buffers can't be converted
			if (codepage === 'binary') {
				throw new ValidationError("Binary buffer can't be converted to text.", 'validate-text-cant-convert-buff');
			}

			// check if we support codepage
			if (!iconv.encodingExists(codepage)) {
				throw new ValidationError(`Unknown codepage '${codepage}'.`, 'validate-buffer-unknown-codepage', { codepage });
			}

			// convert
			value = iconv.decode(value, codepage);
		} else if (Array.isArray(value)) {
			if (Object.prototype.hasOwnProperty.call(value, 'serialize') && typeof value.serialize === 'function') {
				value = String(value.serialize());
			} else {
				value = toString(value);
			}
		} else if (value instanceof Date) {
			if (isNaN(value)) return null; // Invalid date
			value = value.toISOString();
		} else {
			value = JSON.stringify(value);
		}
	}

	value = String(value != null ? value : '');

	// No need to validate when text is empty
	if (value === '') return value;

	// Custom validations
	const validate = instructions.validate || {};

	if (validate.max != null && value.length > validate.max) {
		throw new ValidationError(
			`Value exceeded maximum length of ${instructions.validate.max} characters.`,
			'validate-text-max-exceeded',
			{ max: instructions.validate.max },
		);
	}

	if (validate.min != null && value.length < validate.min) {
		throw new ValidationError(
			`Value must be at least ${instructions.validate.min} characters long.`,
			'validate-text-not-enough-chars',
			{ min: instructions.validate.min },
		);
	}

	if (validate.pattern != null) {
		const regexp = new RegExp(typeof validate.pattern === 'object' ? validate.pattern.regexp : validate.pattern);

		if (!regexp.exec(value)) {
			throw new ValidationError("Value doesn't match the pattern.", 'validate-text-pattern');
		}
	}

	// Text manipulation

	if (instructions.tags === 'strip') {
		value = value.replace(/<[^>]*>/g, '');
	} else if (instructions.tags === 'stripall') {
		value = value.replace(/<[^>]*>/g, '').replace(/[<>]/g, '');
	} else if (instructions.tags === 'escape') {
		const map = { '&': '&amp;', '<': '&lt;', '>': '&gt;' };

		value = value.replace(/[&<>]/g, (tag) => map[tag] || tag);
	}

	return value;
}
