/* global autosize, FroalaEditor, CodeMirror */

import { TextInput } from './text.mjs';
import { IML } from '../deps/iml.mjs';
import { registerCustomElement } from '../utils.mjs';
import configs from '../configs/config.mjs';

const config = configs.fields.editor;

export class TextEditorInput extends TextInput {
	get value() {
		return this._control.value;
	}

	set value(value) {
		if (this._control.value === value) return;
		this._control.value = value == null ? '' : value;
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		return Object.assign(super.attributesToInstructions(), {
			editor: this.getAttribute('editor'),
			options: (() => {
				try {
					return JSON.parse(this.getAttribute('options'));
				} catch (ex) {
					throw new Error(`Invalid text editor config. ${ex.message}`);
				}
			})(),
		});
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		const type = 'editor';

		this._control = document.createElement('textarea');
		if (instructions.readonly) this._control.setAttribute('readonly', '');
		this._control.disabled = instructions.disabled;
		this._control.name = instructions.name || instructions.name;
		this._control.id = this._id;
		this._control.value = value != null ? value : '';

		this.appendChild(this._control);

		const imlContext = this.form.meta.imlContext || {};
		const options = instructions.options;

		if (options && imlContext) {
			Object.keys(options).forEach((k) => {
				if ('string' === typeof options[k]) {
					options[k] = IML.execute(IML.parse(options[k]), imlContext);
				}
			});
		}

		const overrides = this.form.overrides.editors;

		instructions.editor = instructions.editor || 'codemirror';

		switch (true) {
			case !!overrides[instructions.editor]:
				this._editor = new overrides[instructions.editor](this._control, instructions);
				break;

			case instructions.editor === 'froala':
				this._editor = new FroalaEditor(
					this._control,
					Object.assign(
						{
							key: config.editors.froala.key,
							editorClass: 'imt-input-text-froala',
							fileUpload: false,
							imageUpload: false,
							attribution: false,
							htmlExecuteScripts: false,
							events: {
								'html.get': (html) => {
									this.value = html;
								},
							},
							toolbarButtons: {
								moreText: {
									buttons: [
										'bold',
										'italic',
										'underline',
										'strikeThrough',
										'subscript',
										'superscript',
										'fontSize',
										'textColor',
										'backgroundColor',
										'inlineClass',
										'inlineStyle',
										'clearFormatting',
									],
								},
								moreParagraph: {
									buttons: [
										'alignLeft',
										'alignCenter',
										'formatOLSimple',
										'alignRight',
										'alignJustify',
										'formatOL',
										'formatUL',
										'paragraphFormat',
										'paragraphStyle',
										'lineHeight',
										'outdent',
										'indent',
										'quote',
									],
								},
								moreRich: {
									buttons: [
										'insertLink',
										'insertImage',
										'insertVideo',
										'insertTable',
										'emoticons',
										'fontAwesome',
										'specialCharacters',
										'embedly',
										'insertFile',
										'insertHR',
									],
								},
								moreMisc: {
									buttons: [
										'undo',
										'redo',
										'fullscreen',
										'print',
										'getPDF',
										'spellChecker',
										'selectAll',
										'html',
										'help',
									],
									align: 'right',
									buttonsVisible: 2,
								},
							},
							toolbarButtonsMD: {
								moreText: {
									buttons: [
										'bold',
										'italic',
										'underline',
										'strikeThrough',
										'subscript',
										'superscript',
										'fontSize',
										'textColor',
										'backgroundColor',
										'inlineClass',
										'inlineStyle',
										'clearFormatting',
									],
									buttonsVisible: 6,
								},
								moreParagraph: {
									buttons: [
										'alignLeft',
										'alignCenter',
										'formatOLSimple',
										'alignRight',
										'alignJustify',
										'formatOL',
										'formatUL',
										'paragraphFormat',
										'paragraphStyle',
										'lineHeight',
										'outdent',
										'indent',
										'quote',
									],
								},
								moreRich: {
									buttons: [
										'insertLink',
										'insertImage',
										'insertVideo',
										'insertTable',
										'emoticons',
										'fontAwesome',
										'specialCharacters',
										'embedly',
										'insertFile',
										'insertHR',
									],
								},
								moreMisc: {
									buttons: [
										'undo',
										'redo',
										'fullscreen',
										'print',
										'getPDF',
										'spellChecker',
										'selectAll',
										'html',
										'help',
									],
									align: 'right',
									buttonsVisible: 2,
								},
							},
							toolbarButtonsSM: {
								moreText: {
									buttons: [
										'bold',
										'italic',
										'underline',
										'strikeThrough',
										'subscript',
										'superscript',
										'fontSize',
										'textColor',
										'backgroundColor',
										'inlineClass',
										'inlineStyle',
										'clearFormatting',
									],
									buttonsVisible: 2,
								},
								moreParagraph: {
									buttons: [
										'alignLeft',
										'alignCenter',
										'formatOLSimple',
										'alignRight',
										'alignJustify',
										'formatOL',
										'formatUL',
										'paragraphFormat',
										'paragraphStyle',
										'lineHeight',
										'outdent',
										'indent',
										'quote',
									],
									buttonsVisible: 2,
								},
								moreRich: {
									buttons: [
										'insertLink',
										'insertImage',
										'insertVideo',
										'insertTable',
										'emoticons',
										'fontAwesome',
										'specialCharacters',
										'embedly',
										'insertFile',
										'insertHR',
									],
									buttonsVisible: 2,
								},
								moreMisc: {
									buttons: [
										'undo',
										'redo',
										'fullscreen',
										'print',
										'getPDF',
										'spellChecker',
										'selectAll',
										'html',
										'help',
									],
									align: 'right',
									buttonsVisible: 2,
								},
							},
							toolbarButtonsXS: {
								moreText: {
									buttons: [
										'bold',
										'italic',
										'underline',
										'strikeThrough',
										'subscript',
										'superscript',
										'fontSize',
										'textColor',
										'backgroundColor',
										'inlineClass',
										'inlineStyle',
										'clearFormatting',
									],
									buttonsVisible: 0,
								},
								moreParagraph: {
									buttons: [
										'alignLeft',
										'alignCenter',
										'formatOLSimple',
										'alignRight',
										'alignJustify',
										'formatOL',
										'formatUL',
										'paragraphFormat',
										'paragraphStyle',
										'lineHeight',
										'outdent',
										'indent',
										'quote',
									],
									buttonsVisible: 0,
								},
								moreRich: {
									buttons: [
										'insertLink',
										'insertImage',
										'insertVideo',
										'insertTable',
										'emoticons',
										'fontAwesome',
										'specialCharacters',
										'embedly',
										'insertFile',
										'insertHR',
									],
									buttonsVisible: 0,
								},
								moreMisc: {
									buttons: [
										'undo',
										'redo',
										'fullscreen',
										'print',
										'getPDF',
										'spellChecker',
										'selectAll',
										'html',
										'help',
									],
									align: 'right',
									buttonsVisible: 2,
								},
							},
						},
						options,
					),
				);
				break;

			case instructions.editor === 'codemirror':
				this._editor = CodeMirror.fromTextArea(this._control, {
					lineNumbers: true,
					lineWrapping: true,
					matchBrackets: true,
					mode: instructions.editorMode, // backw
					lint: true,
					gutters: ['CodeMirror-lint-markers'],
					viewportMargin: Infinity,
				});
				this.querySelector('.CodeMirror').style.height = 'auto';
				this._editor.on('change', (e) => {
					// Propagate changes to underlying textarea immediately
					this._editor.save();
				});
		}

		if (!this._editor && type === 'textarea' && window.autosize) {
			autosize(this._control);
		}
	}

	_setDisabled(disabled) {
		if (this._instructions.editor === 'codemirror') {
			this._editor.setOption('readOnly', disabled);
		}

		if (this._control) {
			this._control.disabled = disabled;
		}
	}
}

registerCustomElement('imt-input-editor', TextEditorInput);
