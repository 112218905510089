import deepmerge from 'deepmerge';

export default function config(config) {
	return deepmerge(
		{
			loader: {
				base: {
					api: imt?.apiurl ? imt.apiurl : '',
					rpc: imt?.apiurl ? imt.apiurl + '/rpcs' : '',
				},
			},
			common: {
				icons: {
					path:
						imt?.static && typeof imt.static === 'function'
							? imt?.static('/img/packages/{{name}}_32.png')
							: '/static/img/packages/{{name}}_32.png',
					sdkPath: '/static/img/apps/{{name}}_32.png',
				},
			},
			kb: {
				path: `${imt.helpUrl}{{path}}`,
			},
			fields: {
				text: {
					nestedTimeout: 500,
				},
				array: {
					nestedTimeout: 500,
				},
				cert: {
					api: {
						instructions: {
							path: 'api://imt-forms/keys/extractor',
						},
					},
				},
				pkey: {
					api: {
						instructions: {
							path: 'api://imt-forms/keys/extractor',
						},
					},
				},
				account: {
					kb: {
						path: 'kb://connections',
					},
					api: {
						list: {
							path: 'api://connections',
							label: 'name',
							value: 'id',
							wrapper: 'connections',
							limit: 5000,
						},
						instructions: {
							path: 'api://imt-forms/connections/create',
						},
						auth: {
							path: '/oauth/auth/{{connectionId}}',
						},
						extend: {
							path: '/oauth/extend/{{connectionId}}',
						},
						test: {
							path: 'api://connections/{{connectionId}}/test',
						},
						scoped: {
							path: 'api://connections/{{connectionId}}/scoped',
						},
					},
				},
				hook: {
					kb: {
						path: 'kb://tools/webhooks',
					},
					api: {
						list: {
							path: 'api://hooks',
							label: 'name',
							value: 'id',
							wrapper: 'hooks',
							limit: 5000,
						},
						instructions: {
							create: {
								path: 'api://imt-forms/hooks/create',
							},
							update: {
								path: 'api://imt-forms/hooks/{{hookId}}/set-data',
							},
						},
						ping: {
							path: 'api://hooks/{{hookId}}/ping',
						},
						learnStart: {
							path: 'api://hooks/{{hookId}}/learn-start',
							method: 'POST',
						},
						learnStop: {
							path: 'api://hooks/{{hookId}}/learn-stop',
							method: 'POST',
						},
					},
				},
				device: {
					kb: {
						path: 'kb://tutorials/mobile-app',
					},
					api: {
						list: {
							path: 'api://devices',
							label: 'name',
							value: 'id',
							wrapper: 'devices',
							limit: 5000,
						},
						instructions: {
							request: {
								path: 'api://imt-forms/devices/create-request',
							},
							create: {
								path: 'api://imt-forms/devices/{{deviceUdid}}/create',
							},
						},
					},
				},
				filter: {
					operators: IML.FILTERS,
					defaultOperator: 'text:equal',
				},
				datastore: {
					api: {
						list: {
							path: 'api://data-stores',
							value: 'id',
							label: 'name',
							wrapper: 'dataStores',
							limit: 5000,
						},
						instructions: {
							create: {
								path: 'api://imt-forms/data-stores/create',
							},
						},
					},
				},
				udt: {
					api: {
						list: {
							path: 'api://data-structures',
							wrapper: 'dataStructures',
							value: 'id',
							label: 'name',
							limit: 5000,
						},
						create: {
							path: 'api://data-structures',
						},
						update: {
							path: 'api://data-structures/{{dataStructureId}}',
						},
						clone: {
							path: 'api://data-structures/{{dataStructureId}}/clone',
						},
						instructions: {
							create: {
								path: 'api://imt-forms/data-structures/create',
							},
							update: {
								path: 'api://imt-forms/data-structures/{{datastructureId}}/partial-update',
							},
							clone: {
								path: 'api://imt-forms/data-structures/{{datastructureId}}/clone',
							},
						},
					},
				},
				udtspec: {
					generate: {
						path: 'api://imt-forms/data-structures/generator',
					},
				},
				udttype: {
					list: {
						path: 'api://enums/timezones',
					},
				},
				agent: {
					list: {
						path: 'api://agents',
						limit: 5000,
					},
				},
				keychain: {
					kb: {
						path: 'kb://connections/keys',
					},
					api: {
						listTypes: {
							path: 'api://keys/types',
							wrapper: 'keysTypes',
							value: 'id',
							label: 'name',
							limit: 500,
						},
						list: {
							path: 'api://keys',
							wrapper: 'keys',
							value: 'id',
							label: 'name',
							limit: 5000,
						},
						create: {
							path: 'api://imt-forms/keys',
							wrapper: 'key',
						},
					},
				},
				editor: {
					editors: {
						froala: {
							key: 'PYC4mH3B15A11B7D6B5dNSWXa1c1MDe1CI1PLPFa1F1EESFKVlA6F6D5E4A1E3A9A3A5C4==',
						},
					},
				},
			},
			extendedFields: {
				country: {
					type: 'select',
					options: {
						store: 'api://enums/countries',
						wrapper: 'countries',
					},
				},
				imtregion: {
					type: 'select',
					options: {
						store: 'api://enums/imt-regions',
						wrapper: 'imtRegions',
					},
				},
				language: {
					type: 'select',
					options: {
						store: 'api://enums/languages',
						wrapper: 'languages',
					},
				},
				localization: {
					type: 'select',
					options: {
						store: 'api://enums/languages?localized=true',
						wrapper: 'languages',
					},
				},
				locale: {
					type: 'select',
					options: {
						store: 'api://enums/locales',
						wrapper: 'locales',
					},
				},
				timezone: {
					type: 'select',
					options: {
						store: 'api://enums/timezones',
						wrapper: 'timezones',
						value: 'code',
						label: 'name',
					},
				},
				usersorganizationsrole: {
					type: 'select',
					options: {
						store: 'api://users/roles?cols[]=id&cols[]=name&cols[]=subsidiary&category=organization',
						wrapper: 'usersRoles',
						value: 'id',
						label: 'name',
					},
				},
				userapitokenscope: {
					type: 'select',
					options: {
						store: 'api://enums/user-api-token-scopes',
						wrapper: 'userApiTokenScopes',
						value: 'name',
						label: 'name',
					},
					multiple: true,
				},
			},
			coder: {
				kb: {
					path: 'kb://mapping',
				},
			},
		},
		config,
	);
}
