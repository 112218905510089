import { IML } from '../deps/iml.mjs';

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	return IML.execute(IML.parse(instructions.value), options.imlContext, { functions: options.imlFunctions });
}
