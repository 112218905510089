import { Picker } from '../controls/picker.mjs';
import { create, registerCustomElement } from '../utils.mjs';
import { SelectInput } from './select.mjs';

export class ListInput extends SelectInput {
	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		instructions.mappable = false;

		if (!metadata.build) {
			metadata.build = {};
		}

		metadata.build.picker = create('imt-picker');
		metadata.build.picker.list = true;
		metadata.build.pickerPath = () => this.form.meta.commonRpcParameters;
		metadata.build.picker.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item, options) => {
			console.log(option, item, options);
			options.classList.add('list-group');
			option.classList.add('list-group-item', 'flex-column', 'align-items-start');
			option.textContent = '';

			const labelField = metadata.build.picker.getAttribute('label-field') || 'label';
			const valueField = metadata.build.picker.getAttribute('value-field') || 'value';
			const desc = item.description || item.date;
			const title = create('div.list-group-title');
			const subtitle = create('small.text-muted');

			title.textContent = item[labelField] || item[valueField];
			if (subtitle) {
				subtitle.textContent = desc;
			}

			option.append(title);
			if (subtitle) {
				option.append(subtitle);
			}
		});

		await super._build(instructions, value, metadata);
	}
}

registerCustomElement('imt-input-list', ListInput);
