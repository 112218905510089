import { SelectInput } from './select.mjs';
import configs from '../configs/config.mjs';
import { composedInputBuilder, create, registerCustomElement, resolveDefaultInputValue } from '../utils.mjs';
import Loader from '../loader.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

const config = configs.fields.keychain;

export class KeychainInput extends SelectInput {
	constructor() {
		super();

		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return [];
	}

	extendInstructions(instructions) {
		instructions.options = instructions.options || {};
		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || 'Choose a key';
		instructions.options.team = this.getTeamId(instructions);
		instructions.keyType = this.getAttribute('type');

		const storeQuery = new URLSearchParams();

		storeQuery.append('teamId', instructions.options.team);
		storeQuery.append('pg[limit]', config.api.list.limit);

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;
		instructions.theme = instructions.theme || this.form.meta.module.package?.theme || DEFAULT_THEME;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker

		const picker = create('imt-picker');

		if (typeof value === 'undefined') {
			value = resolveDefaultInputValue(this, instructions.type);
		}

		// Build the component

		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			const storeQuery = new URLSearchParams();

			storeQuery.append('pg[limit]', config.api.listTypes.limit);

			const types = await Loader.load(`${config.api.listTypes.path}?${storeQuery}`);
			const type = types[config.api.listTypes.wrapper]?.find((key) => key.name === instructions.keyType);

			if (!type) {
				this.disabled = false;
				throw new Error('Type not found in the keychain.');
			}

			type.parameters.unshift(
				{
					type: 'hidden',
					name: 'imtKeyTeamId',
					default: instructions.options.team,
				},
				{
					type: 'hidden',
					name: 'imtKeyTypeName',
					default: type.name,
					required: true,
				},
				{
					type: 'text',
					name: 'imtKeyName',
					label: I18n.l('common.name'),
					required: true,
					default: `My ${type.label || type.name} key`,
				},
			);

			const panelConfig = {
				name: I18n.l('panels.keychain.add'),
				help: config.kb.path,
				config: type.parameters,
				buttons: [
					{
						action: 'submit',
						label: 'Create',
					},
				],
				theme: instructions.theme,
			};

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				event.preventDefault(); // Prevent closing panel before the create API call is performed
				this.disabled = false;

				// TODO Call directly in remoteFormPanel
				const response = await event.detail.form.save({
					method: 'POST',
					action: config.api.create.path,
				});

				this.addOption(response[config.api.create.wrapper]);

				event.detail.panel.close();
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			await this.panel.build(panelConfig);

			this.panel.form.addEventListener(EVENTS.FORM.ERROR, (event) => {
				event.preventDefault();

				this.panel.panel.warn(event.detail);
			});

			this.panel.open();
		};
	}
}

registerCustomElement('imt-input-keychain', KeychainInput);
