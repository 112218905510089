import Loader from './loader.mjs';
import { Input } from './inputs/input.mjs';
import { create, createInput, isAttribute, registerCustomElement, getExtendInstructions, dispatchResizeDebounced } from './utils.mjs';
import EVENTS from './events.mjs';
import config from './configs/config.mjs';
import { SemanticGroup } from './controls/semantic.mjs';
import MurmurHash3 from 'imurmurhash';

export class Fieldset extends HTMLElement {
	constructor() {
		super();

		this._built = false;
		this._cache = new Map();

		if (isAttribute(this, 'for')) {
			this.for = new MurmurHash3(this.getAttribute('for')).result();
		}

		this._observer = new MutationObserver((mutationList) => {
			this._cache.delete('fields');
		});

		this._observer.observe(this, { attributes: false, subtree: true, childList: true });
	}

	connectedCallback() {
		this.dispatchEvent(new CustomEvent('connected'));
	}

	get fields() {
		if (this._cache.has('fields')) {
			return this._cache.get('fields');
		}

		function getInput(child) {
			return child instanceof Input && child._built && !child.disabled && !child.omit ? child : null;
		}


		const fields = Array.from(this.childNodes)
			.flatMap((child) => {
				if (child instanceof SemanticGroup) {
					return Array.from(child.querySelector(':scope > div.wrapper')?.childNodes || []).map((groupChild) =>
						getInput(groupChild),
					);
				} else {
					return getInput(child);
				}
			})
			.filter((i) => i);

		this._cache.set('fields', fields);
		return fields;
	}

	get for() {
		return this.getAttribute('for');
	}

	set for(value) {
		this.setAttribute('for', value);
	}

	get value() {
		return this.fields.reduce((out, field) => {
			if (!(field.erasable && (field.value === '' || (Array.isArray(field.value) && field.value.length === 0)))) {
				out[field.name] = field.castedValue;
			}

			return out;
		}, {});
	}

	get nestedFieldsets() {
		const fieldsets = [];

		const getNested = (fs) => {
			fs.fields.forEach((field) => {
				const nfs = field.nestedFieldset;

				if (!nfs) return;

				const nfsDomain = nfs.getAttribute('domain');

				if (nfsDomain === this.domain) {
					fieldsets.push(nfs);
				}

				getNested(nfs);
			});
		};

		getNested(this);

		return fieldsets;
	}

	/**
	 * Return all parameters from this fieldset to the root forman element
	 *
	 * @return {Object}
	 */

	get rpcData() {
		let data = {};
		let parent = this;

		if (this.form?.meta?.parentFieldset) {
			data = { ...this.form.meta.parentFieldset.rpcData };
		}

		do {
			const field = parent.closest('.form-group');

			if (field instanceof Input) Object.assign(data, { [field.name]: field.value });
			parent = parent.closest('imt-fieldset:not(:scope)');
		} while (parent);

		return data;
	}

	get domain() {
		return this.hasAttribute('nested') ? this.fieldset.domain : this.getAttribute('domain');
	}

	set domain(domain) {
		if (this.hasAttribute('nested')) return;
		this.setAttribute('domain', domain);
	}

	get mappable() {
		return isAttribute(this, 'mappable');
	}

	set mappable(value) {
		value ? this.setAttribute('mappable', '') : this.removeAttribute('mappable');
	}

	get form() {
		if (!this._form) {
			this._form = this.closest('imt-forman');
		}

		return this._form;
	}

	get fieldset() {
		return this.closest('imt-fieldset:not([nested])');
	}

	get temporary() {
		return isAttribute(this, 'temporary');
	}

	set temporary(value) {
		value ? this.setAttribute('temporary', '') : this.removeAttribute('temporary');
	}

	get dynamic() {
		return isAttribute(this, 'dynamic');
	}

	set dynamic(value) {
		value ? this.setAttribute('dynamic', '') : this.removeAttribute('dynamic');
	}

	get erasable() {
		return isAttribute(this, 'erasable');
	}

	set erasable(value) {
		value ? this.setAttribute('erasable', '') : this.removeAttribute('erasable');
	}

	get disabled() {
		return isAttribute(this, 'disabled');
	}

	set disabled(value) {
		if (value) {
			this.disable();
		} else {
			this.enable();
		}
	}

	get hidden() {
		return isAttribute(this, 'hidden');
	}

	set hidden(value) {
		if (this.hidden === value) {
			return;
		}

		if (value) {
			this.hide();
		} else {
			this.show();
		}
	}

	get defaultMappable() {
		return isAttribute(this, 'default-mappable');
	}

	set defaultMappable(value) {
		value ? this.setAttribute('default-mappable', '') : this.removeAttribute('default-mappable');
	}

	get inputPaths() {
		return this._inputPaths;
	}

	set inputPaths(value) {
		this._inputPaths = value;
	}

	get domPath() {
		let element = this;
		const path = [];

		while (element && element.nodeName !== 'IMT-FORMAN') {
			const index = element.parentElement ? Array.from(element.parentElement.childNodes).indexOf(element) : '';

			path.push(`${element.nodeName}[${index}]`);
			element = element.parentElement;
		}

		return path.join(' ');
	}

	/**
	 * Builds the fieldset from an array of instructions.
	 *
	 * @param {Array|string} instructions Array of instructions.
	 * @param {Object} value Collection of values.
	 * @param {Object} metadata Collection with metadata for rebuilding of the fieldset.
	 */

	async build(instructions, value, metadata) {
		if (this._built) return;
		this._built = true;

		this.form.loadings++;

		// Inherit fieldset settings
		if (!this.domain) this.domain = (this.fieldset && this.fieldset.domain) || 'default';
		if (!this.mappable) this.mappable = (this.fieldset && this.fieldset.mappable) || this.form.mappable || false;
		if (!this.defaultMappable)
			this.defaultMappable = (this.fieldset && this.fieldset.defaultMappable) || this.form.defaultMappable || false;

		const promises = [];
		const context = this.form ? this.form.meta.imlContext : {};
		const getRpcConfig = () => {
			return {
				context,
				data: this.fieldset.rpcData,
			};
		};

		// If root array is string, resolve as url
		if (typeof instructions === 'string') {
			this.dynamic = true;
			instructions = await Loader.load(instructions, getRpcConfig());
		}

		if (typeof instructions?.store === 'string') {
			instructions = await Loader.load(instructions.store, getRpcConfig());
		}

		const semantics = {};
		const fieldsetState = this.form.getFieldsetState(this) || {};
		const addInput = (inputInstructions) => {
			if (typeof inputInstructions.type === 'undefined') {
				console.warn(
					`${inputInstructions.label} type is undefined, fallbacking to 'text'. You should change config to include the type as this fallback might be removed in the future!`,
				);
				inputInstructions.type = 'text';
			}

			const type = (inputInstructions.type || 'text').toLowerCase();
			let input;

			try {
				input = createInput(type);
			} catch (ex) {
				inputInstructions = getExtendInstructions(inputInstructions, type, config.extendedFields);

				if (!inputInstructions) throw ex;

				input = createInput(inputInstructions.type);
				input.setAttribute('type', type);
			}

			if (inputInstructions.semantic) {
				const [type] = inputInstructions.semantic.split(':');

				if (!semantics[type]) {
					semantics[type] = create(`imt-semantic-group[type="${type}"]`);
					this.append(semantics[type]);
				}

				semantics[type].append(input);
			} else {
				this.append(input);
			}

			// Extract value and metadata for this field
			const val = value === null ? null : value ? value[inputInstructions.name] : undefined;
			const meta = (metadata?.fields && metadata.fields[inputInstructions.name]) || {};

			meta.restore = (metadata?.restore && metadata.restore[inputInstructions.name]) || fieldsetState[inputInstructions.name];
			meta.context = Object.assign({}, metadata?.context || {}, context);

			// Used for building of nested fieldsets
			meta.fieldset = {
				metadata,
				value,
			};

			promises.push(input.build(inputInstructions, val, meta));
		};

		if (instructions) {
			for (let inputInstructions of instructions) {
				// If item is string, resolve as url
				if (typeof inputInstructions === 'string') {
					this.closest('.form-group').dispatchEvent(new CustomEvent(EVENTS.INPUT.HAS_DYNAMIC_NESTED));
					inputInstructions = await Loader.load(inputInstructions, getRpcConfig());
				}

				if (Array.isArray(inputInstructions)) {
					inputInstructions.forEach(addInput);
				} else {
					addInput(inputInstructions);
				}
			}
		} else {
			// Build elements by looking at the dom tree
			for (const input of this.childNodes) {
				if (input instanceof Input) {
					if (!input.hasAttribute('name')) throw new Error('Input without name.');
					const name = input.getAttribute('name');
					// Extract value and metadata for this field
					let val;

					if (input.hasAttribute('value')) {
						val = input.getAttribute('value');
						if (val && input.getAttribute('content-type') === 'application/json') val = JSON.parse(val);
						input.removeAttribute('value');
					} else {
						val = value === null ? null : value ? value[name] : undefined;
					}
					const meta = (metadata?.fields && metadata.fields[name]) || {};

					meta.restore = metadata?.restore && metadata.restore[name];

					promises.push(input.build(undefined, val, meta));
				}
			}
		}

		// Build all fields in parallel
		await Promise.all(promises);
		Object.values(semantics).forEach((group) => group.build());
		this.form.loadings--;
		this.dispatchEvent(new CustomEvent(EVENTS.FIELDSET.BUILT));
		dispatchResizeDebounced(this);
		this.form.saveFieldsetState(this);
	}

	enable() {
		this.removeAttribute('disabled');
		for (const node of this.childNodes) {
			if (node instanceof Input && node.disabled) {
				node.disabled = false;
			}
		}
	}

	disable() {
		this.setAttribute('disabled', '');
		for (const node of this.childNodes) {
			if (node instanceof Input && !node.disabled) {
				node.disabled = true;
			}
		}
	}

	hide() {
		this.setAttribute('hidden', '');
		for (const node of this.querySelectorAll('imt-fieldset')) {
			const value = node.closest('[field]')?.value;
			const hash = new MurmurHash3(value).result();

			if (node instanceof Fieldset && !node.hidden && +node.getAttribute('for') === hash) {
				node.hide();
			}
		}

		dispatchResizeDebounced(this);
	}

	show() {
		this.removeAttribute('hidden');
		for (const node of this.querySelectorAll('imt-fieldset')) {
			const value = node.closest('[field]')?.value;
			const hash = new MurmurHash3(value).result();

			if (node instanceof Fieldset && node.hidden && +node.getAttribute('for') === hash) {
				node.show();
			}
		}

		dispatchResizeDebounced(this);
	}

	collapse() {
		for (const child of this.childNodes) {
			if (child instanceof Input && !child.hasAttribute('invalid')) {
				child.collapsed = true;
			} else if (child.nodeName === 'IMT-SEMANTIC-GROUP' && !child.classList.contains('invalid')) {
				child.collapsed = true;
			}
		}
	}

	expand() {
		for (const child of this.childNodes) {
			if (child instanceof Input) {
				child.collapsed = false;
			}
		}
	}

	validate() {
		if (this.closest('imt-fieldset[hidden]')) {
			return true;
		}

		let valid = true;

		for (const child of this.childNodes) {
			if (child instanceof Input) {
				if (!child.validate()) valid = false;
			}
		}
		return valid;
	}

	setDomainDefaults(defaults) {
		if (defaults?.mappable) this.mappable = true;
		if (defaults?.defaultMappable) this.defaultMappable = true;
		if (defaults?.erasable) this.erasable = true;
		if (defaults?.showErasePill) this.setAttribute('show-erase-pill', ''); // TODO Add to the coder
	}

	getState(fieldset = this) {
		const fields = fieldset.fields;
		const state = {};

		for (const field of fields) {
			state[field.name] = field.getState();
		}

		return state;
	}
}

registerCustomElement('imt-fieldset', Fieldset);
