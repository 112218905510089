import { SelectInput } from './select.mjs';
import { composedInputBuilder, create, registerCustomElement } from '../utils.mjs';
import EVENTS from '../events.mjs';
import configs from '../configs/config.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

const config = configs.fields.datastore;

export class DatastoreInput extends SelectInput {
	constructor() {
		super();
		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return [];
	}

	extendInstructions(instructions, value, metadata) {
		instructions.options = instructions.options || {};

		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || I18n.l('datastore.chooseDatastore');
		instructions.options.team = this.getTeamId(instructions);

		instructions.theme = instructions.theme || this.form.meta.module.package?.theme || DEFAULT_THEME;

		const storeQuery = new URLSearchParams();

		storeQuery.append('teamId', instructions.options.team);
		storeQuery.append('pg[limit]', config.api.list.limit);

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker

		const picker = create('imt-picker');

		// Build the component

		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('buttons.browse'),
						enabled: (option) => option?.value,
						action: () => {
							this.form.dispatchEvent(
								new CustomEvent(EVENTS.DATASTORE.BROWSE, {
									detail: {
										dataStoreId: this.value,
									},
								}),
							);
						},
					},
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				await this.addOption(event.detail.data.dataStore);
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const instructionsQuery = new URLSearchParams();
			const metadata = {};

			instructionsQuery.append('teamId', instructions.options.team);

			if (instructions.options?.team != null) {
				metadata.teamId = instructions.options.team;
			}

			await this.panel.build(
				{
					name: I18n.l('panels.datastores.create'),
					src: `${config.api.instructions.create.path}?${instructionsQuery}`,
					theme: instructions.theme,
				},
				metadata,
			);

			this.panel.open();
		};
	}
}

registerCustomElement('imt-input-datastore', DatastoreInput);
