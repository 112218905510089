/* global Inspector */

import chai from 'chai';

export function blueprintMappingValidate(blueprint, verbose) {
	if (!blueprint) {
		blueprint = localStorage.getItem('debugScenarioBlueprint');
	}

	if (!blueprint) return console.error('No blueprint provided to compare.');

	try {
		blueprint = JSON.parse(blueprint);
	} catch (ex) {}

	const currentBlueprint = Inspector?.instance?.scenario?.toJSON();

	if (!currentBlueprint) return console.error('There is no scenario to be validated.');

	chai.should();

	const messages = [];
	const log = logger(messages, verbose);

	validateMapping(blueprint, currentBlueprint);

	function cleanup(object) {
		try {
			return JSON.parse(JSON.stringify(object));
		} catch (ex) {
			return object;
		}
	}

	function validateMapping(blueprint, currentBlueprint, path = 'root') {
		if (blueprint.flow) {
			if (!currentBlueprint.flow) {
				log('error', `Current blueprint on path '${path}' is missing flow.`);
			} else {
				const flow = blueprint.flow;
				const currentFlow = currentBlueprint.flow;

				flow.forEach((module, i) => {
					const moduleName = `${module.id}.${module.module}`;

					log('log', `Validating '${moduleName}'`);
					const currentModule = currentFlow[i];

					if (module.parameters) {
						if (!currentModule.parameters) {
							log('error', `Current module '${moduleName}' is missing static parameters.`);
						} else {
							try {
								cleanup(currentModule.parameters).should.be.deep.equal(cleanup(module.parameters));
							} catch (ex) {
								log('error', `Failed to validate '${moduleName}' static parameters. ${composeValidationMessage(ex)}`);
							}
						}
					}

					if (module.mapper) {
						if (!currentModule.mapper) {
							log('error', `Current module '${moduleName}' is missing dynamic parameters.`);
						} else {
							try {
								cleanup(currentModule.mapper).should.be.deep.equal(cleanup(module.mapper));
							} catch (ex) {
								log('error', `Failed to validate '${moduleName}' dynamic parameters.`, ...composeValidationMessage(ex));
							}
						}
					}

					if (module.routes) {
						if (!currentModule.routes) {
							log('error', `Current module '${moduleName}' is missing routes.`);
						} else {
							module.routes.forEach((route, i) =>
								validateMapping(route, currentModule.routes[i], (path += ` >  ${moduleName}`)),
							);
						}
					}
				});
			}
		}
	}

	const failed = messages.some((m) => m.type === 'error');

	if (failed) {
		log('state', 'Failed to validate blueprint!');

		exportToJson('report.json', messages);
	} else {
		log('state', 'Validation successfully passed!');
	}
}

function composeValidationMessage(ex) {
	return [ex];
}

function logger(container, verbose = false) {
	return function log(type, ...messages) {
		if (verbose || type === 'state') console[type === 'state' ? 'log' : type]('BP-VALIDATOR:', ...messages);

		container.push({
			type,
			message: [...messages],
		});
	};
}

function exportToJson(filename, json) {
	const contentType = 'application/json;charset=utf-8;';
	const jsonString = JSON.stringify(json, null, '\t');

	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		const blob = new Blob([decodeURIComponent(encodeURI(jsonString))], { type: contentType });

		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		const a = document.createElement('a');

		a.download = filename;
		a.href = 'data:' + contentType + ',' + encodeURIComponent(jsonString);
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
}
