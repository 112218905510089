import { createInput, debounce, registerCustomElement } from '../utils.mjs';
import { ArrayInput, ArrayInputItem } from './array.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { create } from '../utils.mjs';
import configs from '../configs/config.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import { ValidationError } from '../helpers/errors.mjs';

const config = configs.fields.udtspec;

class UdtSpecGenerateBtn extends HTMLElement {
	build() {
		const generateBtn = create(`button[type="button"] ${I18n.l('udtspec.generate')}`);

		generateBtn.addEventListener('click', this._generateHandler(generateBtn));
		this.appendChild(generateBtn);
	}

	_generateHandler(generateBtn) {
		return async (event) => {
			if (this.panel) return;

			const udtSpec = this.closest('imt-input-udtspec');

			generateBtn.setAttribute('disabled', '');
			this.disabled = true;
			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				generateBtn.removeAttribute('disabled');
				this.disabled = false;
				udtSpec.value = event.detail.data;
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				generateBtn.removeAttribute('disabled');
				this.disabled = false;
				this.panel = null;
			});

			await this.panel.build({
				name: I18n.l('udtspec.generate'),
				src: `${config.generate.path}`,
			});

			this.panel.open();
		};
	}
}

class UdtSpecInputItem extends ArrayInputItem {
	/**
	 * Build an udt spec item.
	 *
	 * @param {Array} instructions Array of instructions.
	 * @param {*} value Item value.
	 * @param {Object} metadata Item metadata.
	 */

	async build(instructions, value, metadata) {
		const array = this.closest('[type="array"]');

		this.setAttribute('type', 'array-item');
		this._input = createInput('collection');
		this.appendChild(this._input);
		const newItemResource = I18n.l('buttons.newitem');

		this._input.addEventListener(
			'input',
			debounce(() => {
				const value = this._input.value;

				this._input._labelContent.textContent = value.label || value.name || newItemResource;
				if (this._input._labelContent.textContent === newItemResource) {
					this._input._labelContent.classList.add('new-item');
				} else {
					this._input._labelContent.classList.remove('new-item');
				}

				this._input._labelContent.classList[value.required ? 'add' : 'remove']('font-weight-bold');
			}, 10),
		);

		await this._input.build(
			{
				type: 'collection',
				label: value?.name || value?.label || newItemResource,
				spec: instructions,
				mappable: false,
			},
			value,
			metadata,
		);

		const nameInput = this._input.querySelector(':scope > imt-fieldset > imt-input-text[name="name"]');

		nameInput._validators.push((value) => {
			if (array.value.filter((input) => input.name === value).length > 1) {
				throw new ValidationError('The name has already been used.', 'validate-udtspec-duplicated-key');
			}
		});

		this._registerArrayItemControls(array);

		if (this._input._labelContent.textContent === newItemResource) {
			this._input._labelContent.classList.add('new-item');
		} else {
			this._input._labelContent.classList.remove('new-item');
		}
	}

	_getDomainDefaultValues(currentInput) {
		const parent = currentInput?.closest('[field][name="spec"]');

		if (!parent) return {};

		let tempItem = this;
		let tempParent = parent;
		let domain = '';
		let domainsPath = [];

		// find a path to correct domain value through nested datastructures
		while (tempParent) {
			// save the array item index to path only if we move out to another (parent) array item
			if (tempItem && tempItem !== tempParent.closest('[type="array-item"]')) {
				domainsPath.push(tempItem.index);
				tempItem = tempParent.closest('[type="array-item"]');
			}

			domainsPath.push(tempParent.name);
			domain = tempParent.fieldset.domain;
			tempParent = tempParent.parentNode?.closest('[field][name="spec"]');
		}

		domainsPath = domainsPath.reverse();
		let value = parent.form.domains.getDefaults(domain)?.values;

		if (!value) return {};

		for (const path of domainsPath) {
			if (!value[path]) {
				return {};
			}

			value = value[path];
		}

		return value;
	}
}

export class UdtSpecInput extends ArrayInput {
	constructor() {
		super();
		this._itemSelector = 'imt-input-udtspec-item';
		this._removeItemsOnRemove = false;
	}

	get hasModeSwitch() {
		return false;
	}

	extendInstructions(instructions, value, metadata) {
		instructions.spec = [
			{
				name: 'name',
				label: I18n.l('udtspec.name.label'),
				help: I18n.l('udtspec.name.help'),
				type: 'text',
				required: true,
				validate: instructions?.validate?.name || undefined,
			},
			{
				name: 'label',
				label: I18n.l('udtspec.label.label'),
				help: I18n.l('udtspec.label.help'),
				type: 'text',
				advanced: true,
				validate: instructions?.validate?.label || undefined,
			},
			{
				name: 'type',
				label: I18n.l('udtspec.specification.label'),
				type: 'udttype',
				allowedTypes: instructions.allowedTypes,
				required: true,
				default: 'text',
			},
		];

		return instructions;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		await super._build(instructions, value, metadata);

		const generateBtn = create(`imt-input-udtspec-generate-button`);

		generateBtn.build();
		this.appendChild(generateBtn);
	}

	_setDisabled(disabled) {
		for (const node of this._wrapper?.querySelectorAll('imt-picker,imt-coder') || []) {
			node.disabled = disabled;
		}
	}

	getPreview() {
		const value = this.value;

		if (value === null || value === undefined || !Array.isArray(value) || value.length === 0) {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		}

		return value
			.map(
				(v) =>
					`{ ${I18n.l('udtspec.specification.label')}: ${v.type}, ${I18n.l('udtspec.label.label')}: ${
						v.label || v.name
					} }`,
			)
			.join(', ');
	}
}

registerCustomElement('imt-input-udtspec-generate-button', UdtSpecGenerateBtn);
registerCustomElement('imt-input-udtspec-item', UdtSpecInputItem);
registerCustomElement('imt-input-udtspec', UdtSpecInput);
