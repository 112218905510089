/* global moment, Inspector */

// Inputs
import * as INPUTS from './inputs.mjs';
import { I18n } from './helpers/i18n.mjs';
import EVENTS from './events.mjs';
import { IML } from './deps/iml.mjs';
import config from './configs/config.mjs';
import { Picker } from './controls/picker.mjs';
import { Color, DEFAULT_THEME } from './helpers/color.mjs';
import MurmurHash3 from 'imurmurhash';

const PARSED_SELECTORS_CACHE = {};

export function registerCustomElement(selector, elementClass) {
	if (!customElements.get(selector)) {
		customElements.define(selector, elementClass);
	}
}

export function uuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c == 'x' ? r : (r & 0x3) | 0x8;

		return v.toString(16);
	});
}

export function clone(object, deep = false) {
	if (object == null) return object;

	if (typeof object === 'object') {
		if (Array.isArray(object)) {
			if (!deep) return object.slice(0);

			return object.map((o) => clone(o, true));
		} else if (object instanceof String || object instanceof Number || object instanceof Boolean) {
			return object.valueOf();
		} else if (object instanceof Date) {
			return new Date(object.getTime());
		} else if (object instanceof RegExp) {
			return undefined; // strip regexes off
		} else {
			if (!deep) return Object.assign({}, object);

			const out = {};

			for (const key in object) {
				// object might be created by Object.create(null) se we can't use hasOwnProperty in the instance
				if (Object.prototype.hasOwnProperty.call(object, key)) {
					out[key] = clone(object[key], true);
				}
			}
			return out;
		}
	} else if (typeof object === 'function') {
		return undefined; // strip functions off
	}

	return object;
}

export function deepEqual(a, b, key) {
	const debug = false;

	if (debug) {
		console.log('A', key, ':', a);
		console.log('B', key, ':', b);
	}

	if (a == null || b == null) {
		// if a is null/undefined or b is null/undefined
		if (debug) {
			console.log((a != null) === (b != null), a, b);
		}
		if (a === '' || b === '') {
			return true;
		} // we want empty string to be equal to null/undef
		if ((Array.isArray(a) && a.length === 0) || (Array.isArray(b) && b.length === 0)) {
			return true;
		} // we want [] to be equal to null/undef
		return (a != null) === (b != null);
	}

	switch (typeof a) {
		case 'undefined':
		case 'string':
		case 'number':
		case 'boolean':
		case 'regexp':
		case 'function':
			if (debug) {
				console.log(a === b, a, b);
			}
			return a === b;

		case 'object':
			if (typeof b !== 'object') {
				return false;
			}

			if (a === null) {
				return b === null;
			} else if (a instanceof Array) {
				if (!(b instanceof Array)) {
					return false;
				}
				if (a.length !== b.length) {
					return false;
				}

				if (debug) {
					console.log('--------------------');
				}
				for (let index = 0; index < a.length; index++) {
					if (!deepEqual(a[index], b[index], index)) {
						return false;
					}
				}
			} else if (a instanceof Date) {
				if (!(b instanceof Date)) {
					return false;
				}

				return a.getTime() === b.getTime();
			} else {
				if (debug) {
					console.log('--------------------');
				}
				const seen = [];

				for (key of Object.keys(a)) {
					seen.push(key);
					if (!deepEqual(a[key], b[key], key)) {
						return false;
					}
				}

				for (key of Object.keys(b)) {
					if (seen.indexOf(key) === -1) {
						if (!deepEqual(a[key], b[key], key)) {
							return false;
						}
					}
				}
			}
			break;

		default:
			return false; // this should never happen
	}

	return true;
}

/**
 * Parses selector into collection of properties.
 *
 * @param {string} selector Selector string.
 * @returns {object}
 */

export function parseSelector(selector) {
	if (typeof selector !== 'string') throw new Error('Invalid selector.');
	if (PARSED_SELECTORS_CACHE[selector]) return PARSED_SELECTORS_CACHE[selector];

	const regexp = /(?:(\.|#|\[)([A-Za-z][\w-]+)(?:="([^"]*)")?\]?)/g;
	const tag = selector.match(/^[a-z]*[a-z0-9-]*/i);
	const text = selector.match(/\s+(.*)$/);
	const result = {
		tag: tag ? tag[0].toLowerCase() : null,
		text: (text ? text[1] : null) || '',
		attributes: [],
		classes: [],
		id: null,
	};

	let match;

	// eslint-disable-next-line no-cond-assign
	while ((match = regexp.exec(selector))) {
		switch (match[1]) {
			case '.':
				result.classes.push(match[2]);
				break;
			case '#':
				result.id = match[2];
				break;
			case '[':
				result.attributes.push({ name: match[2], value: match[3] || '' });
				break;
		}

		// Owerflow safety
		if (regexp.lastIndex === match.index) regexp.lastIndex++;
	}

	// Save result in cache
	PARSED_SELECTORS_CACHE[selector] = result;

	return result;
}

export function create(selector) {
	const recipe = parseSelector(selector);
	const node = document.createElement(recipe.tag || 'div');

	if (recipe.text) node.textContent = recipe.text;
	if (recipe.id) node.id = recipe.id;
	for (const cls of recipe.classes) node.classList.add(cls);
	for (const attr of recipe.attributes) node.setAttribute(attr.name, attr.value);
	return node;
}

export function createInput(type) {
	const composedType = type.match(/^(account|hook|device|keychain|agent|tokens):(.+)$/);

	if (composedType) type = composedType[1];

	if (!INPUTS[type]) {
		const err = new Error(`Unknown type '${type}'.`);

		err.name = 'unknown-input-type';
		throw err;
	}

	if (typeof INPUTS[type] === 'string') type = INPUTS[type];

	const element = document.createElement(`imt-input-${type}`);

	if (composedType) element.setAttribute('type', composedType[2]);

	return element;
}

/**
 * TODO: Add support for :not selector
 */

export function is(node, selector) {
	if (!node || node.nodeType !== 1) return false;
	const recipe = parseSelector(selector);

	if (recipe.tag && node.nodeName !== recipe.tag.toUpperCase()) return false;
	if (recipe.id && node.id !== recipe.id) return false;
	if (recipe.classes.length) {
		for (const name of recipe.classes) {
			if (!node.classList.contains(name)) return false;
		}
	}
	if (recipe.attributes.length) {
		for (const attribute of recipe.attributes) {
			if (attribute.value === '' && !node.hasAttribute(attribute.name)) return false;
			if (attribute.value !== '' && node.getAttribute(attribute.name) !== attribute.value) return false;
		}
	}
	return true;
}

export function isNumber(value) {
	return /^\-?\d+(\.\d+)?$/.test(value);
}

export function isIterable(obj) {
	if (obj == null) return false;
	if (typeof Symbol === 'undefined') return Array.isArray(obj);
	return typeof obj[Symbol.iterator] === 'function';
}

export function escapeHtml(value) {
	return String(value)
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

export function markdown(data) {
	const codes = [];

	return escapeHtml(data)
		.replace(/\{\{l:([^@]+)@([^\}]+)\}\}/g, (a, b, c) => I18n.l(b, { ns: c }))
		.replace(/\[([^\]]*)\]\(([^\)]*)\)/g, (a, b, c) => {
			if (/^kb:\/\/(.*)$/i.test(c)) {
				c = IML.execute(IML.parse(config.kb.path), {
					path: RegExp.$1,
				});
				return `<a target=\"_blank\" class=\"i-open-help\" href=\"${c}\">${b}</a>`;
			} else {
				return `<a target=\"_blank\" href=\"${c}\">${b}</a>`;
			}
		})
		.replace(/`([^`]+)`/g, (a, b) => {
			codes.push(`<code>${b}</code>`);
			return `{{code:${codes.length - 1}}}`;
		})
		.replace(/(^|\s)__([^_]+)__(\s|$)/g, (a, pr, b, po) => `${pr}<strong>${b}</strong>${po}`)
		.replace(/(^|\s)_([^_]+)_(\s|$)/g, (a, pr, b, po) => `${pr}<em>${b}</em>${po}`)
		.replace(/(^|\s)\*\*([^*]+)\*\*(\s|$)/g, (a, pr, b, po) => `${pr}<strong>${b}</strong>${po}`)
		.replace(/(^|\s)\*([^*]+)\*(\s|$)/g, (a, pr, b, po) => `${pr}<em>${b}</em>${po}`)
		.replace(/\n/g, (a, b) => '<br>')
		.replace(/\{\{code:(\d+)\}\}/g, (a, b) => codes[b]);
}

const DIACRITICS_MAP = {};

[
	{
		base: 'A',
		letters:
			'\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F',
	},
	{ base: 'AA', letters: '\uA732' },
	{ base: 'AE', letters: '\u00C6\u01FC\u01E2' },
	{ base: 'AO', letters: '\uA734' },
	{ base: 'AU', letters: '\uA736' },
	{ base: 'AV', letters: '\uA738\uA73A' },
	{ base: 'AY', letters: '\uA73C' },
	{ base: 'B', letters: '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181' },
	{ base: 'C', letters: '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E' },
	{ base: 'D', letters: '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779' },
	{ base: 'DZ', letters: '\u01F1\u01C4' },
	{ base: 'Dz', letters: '\u01F2\u01C5' },
	{
		base: 'E',
		letters:
			'\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E',
	},
	{ base: 'F', letters: '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B' },
	{
		base: 'G',
		letters: '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E',
	},
	{ base: 'H', letters: '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D' },
	{
		base: 'I',
		letters:
			'\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197',
	},
	{ base: 'J', letters: '\u004A\u24BF\uFF2A\u0134\u0248' },
	{ base: 'K', letters: '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2' },
	{
		base: 'L',
		letters:
			'\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780',
	},
	{ base: 'LJ', letters: '\u01C7' },
	{ base: 'Lj', letters: '\u01C8' },
	{ base: 'M', letters: '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C' },
	{
		base: 'N',
		letters: '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4',
	},
	{ base: 'NJ', letters: '\u01CA' },
	{ base: 'Nj', letters: '\u01CB' },
	{
		base: 'O',
		letters:
			'\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C',
	},
	{ base: 'OI', letters: '\u01A2' },
	{ base: 'OO', letters: '\uA74E' },
	{ base: 'OU', letters: '\u0222' },
	{ base: 'OE', letters: '\u008C\u0152' },
	{ base: 'oe', letters: '\u009C\u0153' },
	{ base: 'P', letters: '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754' },
	{ base: 'Q', letters: '\u0051\u24C6\uFF31\uA756\uA758\u024A' },
	{
		base: 'R',
		letters: '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782',
	},
	{
		base: 'S',
		letters: '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784',
	},
	{
		base: 'T',
		letters: '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786',
	},
	{ base: 'TZ', letters: '\uA728' },
	{
		base: 'U',
		letters:
			'\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244',
	},
	{ base: 'V', letters: '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245' },
	{ base: 'VY', letters: '\uA760' },
	{ base: 'W', letters: '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72' },
	{ base: 'X', letters: '\u0058\u24CD\uFF38\u1E8A\u1E8C' },
	{
		base: 'Y',
		letters: '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE',
	},
	{ base: 'Z', letters: '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762' },
	{
		base: 'a',
		letters:
			'\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250',
	},
	{ base: 'aa', letters: '\uA733' },
	{ base: 'ae', letters: '\u00E6\u01FD\u01E3' },
	{ base: 'ao', letters: '\uA735' },
	{ base: 'au', letters: '\uA737' },
	{ base: 'av', letters: '\uA739\uA73B' },
	{ base: 'ay', letters: '\uA73D' },
	{ base: 'b', letters: '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253' },
	{ base: 'c', letters: '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184' },
	{ base: 'd', letters: '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A' },
	{ base: 'dz', letters: '\u01F3\u01C6' },
	{
		base: 'e',
		letters:
			'\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD',
	},
	{ base: 'f', letters: '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C' },
	{
		base: 'g',
		letters: '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F',
	},
	{
		base: 'h',
		letters: '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265',
	},
	{ base: 'hv', letters: '\u0195' },
	{
		base: 'i',
		letters:
			'\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131',
	},
	{ base: 'j', letters: '\u006A\u24D9\uFF4A\u0135\u01F0\u0249' },
	{ base: 'k', letters: '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3' },
	{
		base: 'l',
		letters:
			'\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747',
	},
	{ base: 'lj', letters: '\u01C9' },
	{ base: 'm', letters: '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F' },
	{
		base: 'n',
		letters: '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5',
	},
	{ base: 'nj', letters: '\u01CC' },
	{
		base: 'o',
		letters:
			'\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275',
	},
	{ base: 'oi', letters: '\u01A3' },
	{ base: 'ou', letters: '\u0223' },
	{ base: 'oo', letters: '\uA74F' },
	{ base: 'p', letters: '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755' },
	{ base: 'q', letters: '\u0071\u24E0\uFF51\u024B\uA757\uA759' },
	{
		base: 'r',
		letters: '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783',
	},
	{
		base: 's',
		letters:
			'\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B',
	},
	{
		base: 't',
		letters: '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787',
	},
	{ base: 'tz', letters: '\uA729' },
	{
		base: 'u',
		letters:
			'\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289',
	},
	{ base: 'v', letters: '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C' },
	{ base: 'vy', letters: '\uA761' },
	{ base: 'w', letters: '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73' },
	{ base: 'x', letters: '\u0078\u24E7\uFF58\u1E8B\u1E8D' },
	{
		base: 'y',
		letters: '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF',
	},
	{ base: 'z', letters: '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763' },
].forEach((item) =>
	item.letters.split('').forEach((letter) => {
		DIACRITICS_MAP[letter] = item.base;
	}),
);

export function removeDiacritics(text) {
	// eslint-disable-next-line no-control-regex
	return text.replace(/[^\u0000-\u007E]/g, (letter) => DIACRITICS_MAP[letter] || letter);
}

export function isAttribute(element, attribute) {
	const value = element?.getAttribute(attribute);

	return value === '' || value === 'true';
}

export function debounce(func, wait = 0, thisArg = undefined) {
	let timeout = null;

	return function debouncer(...args) {
		const context = thisArg;
		const later = () => {
			timeout = null;
			func.apply(context, args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (!timeout) func.apply(context, args);
	};
}

export const dispatchResizeDebounced = debounce((node) => {
	node.dispatchEvent(new CustomEvent(EVENTS.FORM.RESIZE, { bubbles: true }));
});

/**
 *
 * @param {Object} inputField Field the nested fieldset is related to.
 * @param {Object} value Nested fieldset initial value.
 * @param {Object} metadata Nested fieldset initial metadata including restore.
 * @param {boolean} [temporary=false] Set fieldset as temporary.
 * @param {ImtNested} [nested]  Nested element.
 * @param {Array<ImtNested>} [hideNesteds]  Nested element that should be hidden.
 */

export async function showNestedFieldsets(
	inputField,
	value = null,
	metadata = null,
	temporary = false,
	nested,
	hideNesteds,
) {
	if (inputField?.form) {
		inputField.form.debug(
			'build',
			`showing nested fields for the input '${inputField.name}' in domain '${inputField.fieldset?.domain}'`,
		);
	}

	const temporaryFieldsets = hideNesteds || inputField._nestedFieldsets.filter((f) => f.temporary);
	const fieldsetsToHide = hideNesteds || inputField._nestedFieldsets.filter((f) => !f.hidden);

	// Remove temporary fieldsets
	for (const fieldset of temporaryFieldsets) {
		animateElementHeight(fieldset, { remove: true, hide: true });
	}

	// Hide all visible fieldsets first
	for (const fieldset of fieldsetsToHide) {
		// It is necessary to await for that in order to avoid problems with displaying the same fieldset again
		await animateElementHeight(fieldset, { hide: true });
	}

	const inputValue = inputField.value;

	// Uses JSON to distinguish text vs number
	// E.g.
	// If value is 1 then value attribute is `value = "1"`
	// If value is "1" then value attribute is `value = ""1""`
	let valueHash = new MurmurHash3(JSON.stringify(inputValue)).result();

	if (!nested) {
		nested = inputField.querySelector('imt-nested') || inputField.appendChild(create('imt-nested'));
	}

	if (inputField.mode === 'map') {
		valueHash = 'map-mode';
	}

	let fieldset = nested.querySelector(`:scope > imt-fieldset[for='${valueHash}']`);
	let { instructions, domain, hasPlaceholder } = resolveInstructions(inputField);

	// Show create fieldset from already rendered/built common fieldset DOM
	const commonFieldset = inputField._instructions.renderedCommonNestedFieldset;

	// Select options are dynamic and contains its own nested
	if (!instructions && metadata?.restore && metadata.restore[inputField.name]?.nested) {
		instructions = metadata.restore[inputField.name].nested;
		temporary = true;
	}

	// It is possible to have only fieldset without instructions. It happens when nested is rendered in the DOM.
	if (!instructions && !commonFieldset && !fieldset) return;

	if (inputField.mode !== 'map') {
		if (!inputField._runValidators() || inputField.hasAttribute('error')) return;

		if (!shouldDisplay(inputValue, hasPlaceholder, !!inputField.form.closest('div.formula > :scope'))) return;
	}

	if (fieldset) {
		await handleBuild(fieldset.build());
		fieldset.show();
		inputField.dispatchEvent(new CustomEvent(EVENTS.INPUT.NESTED_CHANGED, { bubbles: true }));
		await animateElementHeight(fieldset, { emitEvent: true });
		return;
	}

	// Build fieldset from JSON spec
	if (instructions && domain) {
		if (inputField.mode === 'map') {
			instructions = nestedToMapMode(instructions);
		} else if (
			fieldsetsToHide[0]?.getAttribute('for') === 'map-mode' &&
			!deepEqual(value, fieldsetsToHide[0]?.value) &&
			inputField.mode === 'pick'
		) {
			// ensure that value from mapped nested fieldset is used when switching to pick mode for the first time
			value = fieldsetsToHide[0].value;
		}

		fieldset = document.createElement('imt-fieldset');
		fieldset.domain = domain;
		fieldset.temporary = temporary;
		fieldset.for = valueHash;
		inputField._nestedFieldsets.push(fieldset);

		const domainSwitch = domain !== inputField.fieldset.domain;

		const form = inputField.form;
		const domainData = form.domains.getDefaults(fieldset.domain) || {};

		// It is not possible to switch between domain back and forth so 'domainData.values' and 'form.meta.module.restore[fieldset.domain]'
		// should be used only in case of switch to a new domain where are expected root values and restore data

		const restore = !domainSwitch && metadata?.restore ? metadata.restore : form.meta.module.restore[fieldset.domain];

		value = !domainSwitch && value ? value : domainData.values;

		fieldset.setDomainDefaults(domainData?.defaults);

		// Is dynamic if nested instructions are dynamic or of select options are dynamic and includes nested
		const parsedOptions = Picker.normalizeOptions(inputField._instructions.options);
		const dynamic =
			'string' === typeof instructions || ('string' === typeof parsedOptions?.store && !parsedOptions?.nested);

		if (dynamic) {
			fieldset.dynamic = true;
			inputField.dispatchEvent(new CustomEvent(EVENTS.INPUT.HAS_DYNAMIC_NESTED));

			const restoreInstructions = restore && restore[inputField.name]?.nested;

			if (restoreInstructions) {
				instructions = restoreInstructions;
				fieldset.temporary = true;
			}
		}

		nested.appendChild(fieldset);

		// ensure that the loader will not appear if the fieldset is build quickly
		const loadingTimeout = setTimeout(() => {
			inputField.loading = true;
		}, 5);

		await handleBuild(fieldset.build(instructions, value, { restore }));

		clearTimeout(loadingTimeout);
		inputField.loading = false;

		inputField.dispatchEvent(new CustomEvent(EVENTS.INPUT.NESTED_CHANGED, { bubbles: true }));
		await animateElementHeight(fieldset, { emitEvent: true });
		return;
	}

	if (commonFieldset) {
		// TODO add support for map mode for DOM rendered fieldsets if necessary
		fieldset = commonFieldset.cloneNode(true);
		fieldset.setAttribute('for', valueHash);
		nested.appendChild(fieldset);
		await handleBuild(fieldset.build());
	}

	/**
	 * Find the closest collection and use it as values for nested fieldset
	 * @param {Object} value
	 * @param {String} input
	 * @return {*|{}}
	 */
	// eslint-disable-next-line no-unused-vars
	function resolveFieldsetValues(value, input) {
		const pathArr = input.path.split('.');

		if (pathArr.length === 1) return value;

		pathArr.pop(); // Remove self as we need only parents

		let out = value;

		while (pathArr.length > 0) {
			const step = pathArr.shift();
			const match = step.match(/^(.+)\[(\d+)\]$/);
			let ref;

			if (match) {
				// Array
				ref = out[match[1]] && out[match[1]][match[2]];
			} else {
				// Anything else
				ref = out[step];
			}

			if (ref && typeof ref === 'object') {
				out = ref;
			} else {
				// Data in path missing, create empty object
				out = {};
			}
		}

		return out || {};
	}

	function resolveInstructions(inputField) {
		// Text or boolean input nested
		if (inputField._instructions?.nested) {
			return {
				instructions: clone(inputField._instructions?.nested.store || inputField._instructions?.nested, true),
				domain: inputField._instructions?.nested.domain || inputField.fieldset.domain,
			};
		}

		// Searching for value and spec here because we render common nested fields on demand

		const item = !inputField._instructions.multiple && Array.isArray(inputField?.options?.store) && findOption(inputField.options.store, inputValue);
		const nested =
			(item && item.nested) ||
			inputField.options?.nested ||
			inputField._instructions.options?.nested ||
			inputField._instructions.options?.placeholder?.nested;

		let instructions;

		if (nested) {
			if (Array.isArray(nested)) {
				instructions = clone(nested, true);
			} else if ('string' === typeof nested) {
				instructions = nested;
			} else {
				instructions = clone(nested.store, true);
			}
		}

		// Show nested for placeholder
		const hasPlaceholder = !!inputField._instructions.options?.placeholder?.nested;

		const domain = (nested && nested.domain) || inputField.options?.domain || inputField?.fieldset?.domain;

		return { instructions, domain, hasPlaceholder };
	}

	async function handleBuild(build) {
		return build
			.then(() => {
				inputField.removeAttribute('error');
			})
			.catch((ex) => {
				console.error(ex);
				inputField.setAttribute('error', '');
				inputField._errorMessages.textContent = ex.message;
				inputField.form.loadings--;
			});
	}

	function shouldDisplay(value, hasPlaceholder, compatibilityMode) {
		const dynamic = typeof instructions === 'string';

		if (compatibilityMode) {
			switch (true) {
				case inputField instanceof INPUTS['boolean']:
					if (value !== true) return;
					break;
				case inputField instanceof INPUTS['select']:
					if (typeof value === 'undefined') return;
					if (value === '' && !hasPlaceholder) return;
					break;
				case inputField instanceof INPUTS['folder']:
				case inputField instanceof INPUTS['file']:
					if (typeof value === 'undefined' || value === '' || value === null) return;
					break;
				case inputField instanceof INPUTS['text']:
					break;
				case inputField instanceof INPUTS['array']:
					if (!Array.isArray(value) || value.length === 0) return;
					break;
				default:
					if (typeof value === 'undefined') return;
			}
		} else {
			if (!dynamic) {
				switch (true) {
					case inputField instanceof INPUTS['boolean']:
						if (value !== true) return;
						break;
					case inputField instanceof INPUTS['select']:
						if (typeof value === 'undefined') return;
						if (value === '' && !hasPlaceholder) return;
						break;
					case inputField instanceof INPUTS['folder']:
					case inputField instanceof INPUTS['file']:
						if (typeof value === 'undefined' || value === '' || value === null) return;
						break;
					case inputField instanceof INPUTS['text']:
						if (typeof value === 'undefined' || value === '') return;
						break;
					case inputField instanceof INPUTS['array']:
						if (!Array.isArray(value) || value.length === 0) return;
						break;
				}
			}
		}

		return true;
	}

	function nestedToMapMode(instructions) {
		if (!Array.isArray(instructions)) return instructions;

		instructions = clone(instructions, true);

		const output = [];

		for (const item of instructions) {
			output.push(item);

			if (item.type === 'select') {
				if ('string' === typeof item.options) {
					delete item.options;
					item.type = 'any';

					if (item.multiple) {
						delete item.multiple;
						item.type = 'array';
						item.spec = { type: 'any' };
					}
				} else if ('string' === typeof item.options?.store) {
					if (Array.isArray(item.options.nested)) {
						output.push(...Array.from(nestedToMapMode(item.options.nested) || []));
					}

					delete item.options;
					item.type = 'any';

					if (item.multiple) {
						delete item.multiple;
						item.type = 'array';
						item.spec = { type: 'any' };
					}
				}
			}
		}

		return output;
	}
}

/**
 * Animates the element's height. The animation supports elements that were just inserted into the DOM.
 * @param {HTMLElement} element Html element
 * @param {Object} options (optional) Settings for the animation
 * @param {number} options.timing (optional) Sets the time of the animation in ms. Defaults to 300ms
 * @param {boolean} options.hide (optional | string) If the element should be hidden (display: none). Defaults to false. You can also use any valid css `display` value (this value will be set on the element before the animation starts).
 * @param {boolean} options.remove (optional) Removes the element after the animation ends. This is useful if you don't want to await the promise. Defaults to false.
 * @param {string} options.timingFunction (optional) Css timing function. Defaults to `cubic-bezier(0.4, 0.0, 0.2, 1)`
 * @returns Promise that resolves when the animations ends
 */

export function animateElementHeight(element, options) {
	options = {
		timing: 300,
		hide: false,
		remove: false,
		timingFunction: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
		emitEvent: false,
		...options,
	};

	// reset padding styles from previous animation - this forces padding animation
	if (!options.hide) {
		element.style.paddingBottom = '';
		element.style.paddingTop = '';
	}

	const paddingTop = parseInt(window.getComputedStyle(element).getPropertyValue('padding-top'));
	const paddingBottom = parseInt(window.getComputedStyle(element).getPropertyValue('padding-bottom'));
	const animationStyles = {};
	let contentHeight = 0;

	if (options.hide) {
		// also animate padding to avoid flickering artifacts
		animationStyles['padding-bottom'] = '0px';
		animationStyles['padding-top'] = '0px';
		animationStyles['height'] = `${contentHeight}px`;
	} else {
		for (const el of element.childNodes) {
			if (el.nodeType !== Node.TEXT_NODE) {
				// this might need optimizations, avoiding quering DOM and storing animationHeight in an object would be ideal
				for (const animChild of el.querySelectorAll('[animationHeight]')) {
					contentHeight += parseInt(animChild.getAttribute('animationHeight'));
					// remove the attribute as it's height was already used and it's contained in current content height
					animChild.removeAttribute('animationHeight');
				}
			}

			contentHeight += el.clientHeight || 0;
		}

		animationStyles['height'] = `${contentHeight + paddingTop + paddingBottom}px`;
	}

	element.style.overflow = 'hidden';
	if (options.hide) {
		element.style.setProperty('display', typeof options.hide === 'string' ? options.hide : 'inherit', 'important');
		element.style.height = `${element.clientHeight}px`;
	} else {
		element.style.height = `0px`;
		// save the height of this element without paddings - this height will be added to parent's contentHeight if
		// there is a parent element that will be also animated
		element.setAttribute('animationHeight', contentHeight);
	}

	return animateElement(element, {
		timing: options.timing,
		timingFunction: options.timingFunction,
		animationStyles,
	}).then(() => {
		if (options.remove) {
			element.remove();
		} else {
			element.style.display = '';
			element.style.height = '';
			element.style.overflow = '';

			if (element.hasAttribute('animationHeight')) {
				element.removeAttribute('animationHeight');
			}

			if (options.hide && typeof element.hide === 'function') {
				element.hide();
			}

			if (options.emitEvent) {
				dispatchResizeDebounced(element);
			}
		}
	});
}

/**
 * Animates the element css properties. The animation supports elements that were just inserted into the DOM.
 * @param {HTMLElement} element Html element
 * @param {Object} options Settings for the animation
 * @param {number} options.timing Sets the time of the animation in ms.
 * @param {string} options.timingFunction Css timing function.
 * @param {Object} options.animationStyles Object that sets the styles that should change during an animation.
 * @returns Promise that resolves when the animations ends
 */

export function animateElement(element, options) {
	return new Promise((resolve) => {
		const animationProperties = Object.keys(options.animationStyles);
		const transitions = [];

		for (const prop of animationProperties) {
			transitions.push(`${prop} ${options.timing}ms ${options.timingFunction}`);
		}
		element.style.transition = transitions.join(',');

		requestAnimationFrame(() => {
			for (const prop of animationProperties) {
				element.style.setProperty(prop, options.animationStyles[prop]);
			}

			setTimeout(() => resolve(), options.timing);
		});
	});
}

/**
 * Extends inputInstructions with extended config
 * @param {Object} inputInstructions input instructions
 * @param {string} type extended input type
 * @param {Object} extendedFields config file with extendedFields configs
 * @return {void|Object} returns modified input instructions or undefined if no extended config found
 */

export function getExtendInstructions(inputInstructions, type, extendedFields = {}) {
	const extendedInstructions = extendedFields[type];

	if (!extendedInstructions) return;

	const instructions = clone(inputInstructions, true);

	// TODO Supports select input only
	if (extendedInstructions.type) instructions.type = extendedInstructions.type;
	if (extendedInstructions.multiple) instructions.multiple = true;

	if (extendedInstructions.options) {
		instructions.options = {
			...extendedInstructions.options,
			...Picker.normalizeOptions(instructions.options),
		};

		if (!inputInstructions.options?.store && !inputInstructions.options) {
			instructions.options.store = extendedInstructions.options.store;
		}
	}

	return instructions;
}

/**
 * Parses string value to a valid type according to the content type
 * @param {any} value
 * @param {string} contentType
 * @return {any|boolean|number|Object}
 */

export function parseAttributeValue(value, contentType) {
	switch (contentType) {
		case 'number':
			return Number(value);
		case 'boolean':
			return value === 'true';
		case 'application/json':
			return JSON.parse(value);
		default:
			return value;
	}
}

/**
 * Creates a composed input with module icon and control buttons.
 * @param {Object} spec
 * @param {HTMLDivElement} spec.picker imt-picker HTML element
 * @param {Object} spec.config input config NOT USED
 * @param {string} spec.name package name
 * @param {string} spec.theme package theme
 * @param {Object[]} spec.buttons buttons definition
 * @param {string} spec.buttons[].label button label
 * @param {Function} spec.buttons[].action function called when button is clicked
 * @param {Function} spec.buttons[].enabled function defining when the button is enabled, input is option HTML element
 * @return {HTMLDivElement}
 */

export function composedInputBuilder(spec) {
	const form = spec.picker.closest('imt-forman');
	const group = create('div.input-group');
	let icon;

	if ((/^app#/).test(spec.name)) {
		icon = createIcon(config.common.icons.sdkPath, spec.name.replace(/^app#/, ''));
	} else {
		icon = createIcon(config.common.icons.path, spec.name);
	}

	if (icon) {
		icon.style.backgroundColor = spec.theme;

		group.append(icon);
	}

	if (spec.picker) {
		spec.picker.style.borderColor = spec.theme;

		// TODO Temporary fix, picker-dropdown should be rendered synchronously
		setTimeout(() => {
			const dropdown = spec.picker.querySelector('imt-picker-dropdown');

			if (dropdown) dropdown.style.borderColor = spec.theme;
		}, 0);

		group.append(spec.picker);
	}

	// Buttons
	if (Array.isArray(spec.buttons) && spec.buttons.length && !spec.readonly) {
		const buttons = create('div.input-group-append');
		const dropdownButton = create('button.btn.imt-btn-dropdown[type="button"]');
		const dropdownMenu = create('div.imt-btn-dropdown-menu');
		const textColor = new Color(spec.theme).determineForegroundColor().toHex();

		dropdownButton.style.backgroundColor = spec.theme;
		dropdownButton.style.color = '#' + textColor;
		dropdownButton.appendChild(create('i.fas.fa-ellipsis-v'));
		spec.buttons.forEach((button) => {
			const btnElement = !button.collapsible
				? buttons.appendChild(create(`button.btn[type="button"]`))
				: dropdownMenu.appendChild(create(`button.dropdown-item[type="button"]`));

			btnElement.textContent = button.label;
			if (!button.collapsible) {
				btnElement.style.backgroundColor = spec.theme;
				btnElement.style.color = '#' + textColor;
			}

			if (button.action) {
				btnElement.addEventListener('mousedown', (e) => {
					return button.action(e, button.collapsible ? dropdownButton : undefined);
				});
			}

			if (button.enabled) {
				function setButtonState() {
					const selected = spec.picker.getSelectedElements();

					button.enabled(selected && selected[0])
						? btnElement.removeAttribute('disabled')
						: btnElement.setAttribute('disabled', '');
				}

				setButtonState();

				spec.picker.addEventListener(EVENTS.PICKER.CHANGED, (event) => setButtonState());

				if (form) {
					form.addEventListener(EVENTS.PANEL.CLOSE, (event) => setButtonState());
				}
			}
		});

		if (dropdownMenu.childNodes.length > 0) {
			let dropdownOpened = false;

			buttons.insertBefore(dropdownButton, buttons.firstChild);
			buttons.appendChild(dropdownMenu);
			dropdownButton.addEventListener('click', toggleDropdown);
			dropdownButton.addEventListener('focusout', (e) => {
				if (dropdownOpened) {
					toggleDropdown();
				}
			});

			function toggleDropdown() {
				const box = dropdownButton.getBoundingClientRect();

				dropdownOpened = !dropdownOpened;
				dropdownMenu.style.display = dropdownOpened ? 'block' : 'none';
				dropdownMenu.style.top = `${box.y + buttons.clientHeight - 8}px`;
				dropdownMenu.style.left = `${box.x - dropdownMenu.clientWidth + 20}px`;
			}
		}

		group.append(buttons);
	}

	return group;

	function createIcon(iconPath, name) {
		if (!iconPath || !name) return;

		const icon = create('div.input-group-prepend.input-icon');
		const img = create('img');

		img.setAttribute('src', IML.execute(IML.parse(iconPath), { name }));
		icon.append(img);

		return icon;
	}
}

/**
 * Creates datetimepicker in a panel that's attached to target and bound to the input.
 * @param {HTMLElement} target Target element to which the panel will be attached
 * @param {HTMLElement} input Datepicker will bind it's value to this input
 * @param {Object} config Config for the datepicker
 * @param {string} config.type Type of the input
 * @param {string} config.theme Theme of the input
 * @param {Boolean} config.time Time flag from input instructions
 * @param {Function} config.onChange Callback for datepicker value change event
 * @returns Object containing datepicker instance and abort controller to remove bound events and instance of the panel
 */

export function createDateTimePickerPanel(target, input, config) {
	const panel = create('imt-panel');
	let titleRes = 'panels.datetimepicker.dateTitle';

	if (config.type === 'time') {
		titleRes = 'panels.datetimepicker.timeTitle';
	}

	const theme = config.theme || input.form?.meta?.module?.package?.theme || DEFAULT_THEME;

	if (theme) {
		const color = (theme.toHex ? theme.toHex() : theme).toLowerCase();

		if (/^#[0-9A-F]{6}$/i.test(color)) {
			panel.header.classList.add('gradient', `theme-${color.replace('#', '')}`);
		}
	}

	panel.classList.add('imt-datetimepicker', 'remote');
	panel.header.appendChild(create(`h1 ${I18n.l(titleRes)}`));
	panel.header.addCloseButton();
	panel.width = 260;
	panel.position = 'right';
	panel.relative = target;
	panel.spacing = 5;

	const { dp, abortController } = createDateTimePicker(panel.body, input, { ...config, listenInputChange: true });

	dp.on('update.datetimepicker', (event) => {
		panel.update();
	});

	return { panel, dp, abortController };
}

/**
 * Creates datetimepicker on target element and bounds it to the input input.
 * @param {HTMLElement} target Target element to which the panel will be attached
 * @param {HTMLElement} input Datepicker will bind it's value to this input
 * @param {Object} config Config for the datepicker. You can use input's instructions as config.
 * @param {string} config.type Type of the input
 * @param {Boolean} config.time Time flag from input instructions
 * @param {Boolean} config.listenInputChange If the datepicker should listen to input's change event
 * @param {Function} config.onChange Callback for datepicker value change event
 * @returns Object containing datepicker instance and abort controller to remove bound events
 */

export function createDateTimePicker(target, input, config) {
	let format = 'L LT';
	const control = input._control || input;
	const dp = jQuery(target);
	const abortController = new AbortController();
	let updating = false;
	const onChange = config.onChange
		? (e) => config.onChange(e, e.date.format(format), e.oldDate ? e.oldDate.format(format) : null)
		: (event) => {
			if (updating) return;
			control.value = event.date.format(format);
			input.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		};

	if (config.type === 'date' && config.time === false) {
		format = 'L';
	} else if (config.type === 'time') {
		format = 'LT';
	}

	dp.datetimepicker({
		inline: true,
		format: format,
		timeZone: input.form?.timezone || 'UTC',
		useCurrent: false,
		buttons: {
			showToday: true,
		},
	});

	const update = () => {
		let value = control.value;

		if (!value) return;
		const m2 = moment.tz(value, format, input.form?.timezone || 'UTC');

		if (!m2.isValid()) value = new Date();

		updating = true;
		dp.datetimepicker('date', value);
		updating = false;
	};

	update();

	if (config.listenInputChange) {
		control.addEventListener('input', update, { signal: abortController.signal });
	}

	dp.on('change.datetimepicker', onChange);
	return { dp, abortController };
}

export function getStateObject(fields) {
	const state = Array.from(fields).reduce((state, field) => {
		if (is(field, '[field]')) {
			const fieldState = field.getState();

			if (Object.keys(fieldState).length === 0) return state;

			return { ...state, [field.name]: fieldState };
		}

		return state;
	}, {});

	if (Object.keys(state).length) return state;
}

/**
 * Gets connection, keychain, agent or device ID used in the nearest module that uses the same connection.
 * @param {Object} input
 * @param {String} type Connection type e.g. "account:facebook"
 * @return {Number|void}
 * @private
 */

export function resolveDefaultInputValue(input, type) {
	if (typeof Inspector === 'undefined') return;

	for (const m of input.form.meta?.pills || []) {
		const module = Inspector.seek(m.id);

		if (!module) continue;

		const input = module.config?.parameters?.find((c) => c.type === type);

		if (!input) continue;

		const id = module.parameters && module.parameters[input.name];

		if (id) return id;
	}
}

// https://stackoverflow.com/a/42543908/13156397
export function getScrollParent(element, axis = 'both') {
	let style = getComputedStyle(element);
	const excludeStaticParent = style.position === "absolute";
	const overflowRegex = /(auto|scroll|hidden)/;

	if (style.position === "fixed") return document.body;
	for (let parent = element; (parent = parent.parentElement);) {
		style = getComputedStyle(parent);
		if (excludeStaticParent && style.position === "static") {
			continue;
		}

		if (parent.clientHeight < parent.scrollHeight &&
			((axis === 'both' && overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) ||
				(axis === 'x' && overflowRegex.test(style.overflowX)) ||
				(axis === 'y' && overflowRegex.test(style.overflowY)))) {
			return parent;
		}
	}

	return document.body;
}

export function findOption(options, value) {
	for (const option of options) {

		if (option.value === value) {
			return option;
		} else if (option.options) {
			const res = findOption(option.options, value);

			if (res) {
				return res;
			}
		}
	}

	return undefined;
}
