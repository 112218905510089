import Loader from '../loader.mjs';
import { Input } from './input.mjs';
import { create, registerCustomElement, getStateObject } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class CollectionInput extends Input {
	get hasModeSwitch() {
		return false;
	}

	get value() {
		if (this.mode === 'map') return this._coder.value;
		if (!this._fieldset) return null;

		return this._fieldset.nestedFieldsets.reduce((value, fs) => {
			return Object.assign(value, fs.value);
		}, this._fieldset.value);
	}

	set value(value) {
		if (this.mode === 'map') {
			this._coder.value = value;
		}

		// TODO: Value setter in pick mode???
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this.setAttribute('type', 'collection');

		if (typeof instructions.spec === 'string') {
			// Spec are RPC, resolve
			const form = this.form;
			const context = form ? form.meta.imlContext : {};

			instructions.spec = await Loader.load(instructions.spec, { context });
		}

		this._fieldset = this.querySelector('imt-fieldset[nested]') || create('imt-fieldset[nested]');
		this.appendChild(this._fieldset);

		const restore = metadata?.restore?.nested;
		const fsMetadata = restore ? Object.assign({}, metadata, { restore }) : metadata;

		await this._fieldset.build(instructions.spec, value, fsMetadata);
	}

	isInternalChangeEvent(event) {
		if (this.mode === 'map') {
			return false;
		}

		return event.target !== this;
	}

	validate() {
		this.untouched = false;

		this.querySelectorAll(':scope > imt-fieldset > [field]').forEach((field) => {
			if (field.validate) {
				field.untouched = false;
				field.validate();
			}
		});

		this._runValidators();

		return this.valid;
	}

	/**
	 * Performs value validation for type defined by this input field.
	 *
	 * @param {*} value Value to validate, same as getting `this.value`.
	 * @param {Array} problems Array of strings containing all generated validation problems.
	 * @returns {boolean} Method can return false to indicate this field is no valid without providing a reason. Otherwise, return value is ignored.
	 */

	_validate(value, problems) {
		super._validate(value, problems);

		// Ignore rest of validation in map mode
		if (this.mode === 'map') return;

		this.empty = !this.querySelector(':scope > imt-fieldset > *:not(.empty)');

		if (this.querySelector(':scope > imt-fieldset > *[invalid]')) {
			return false; // False means display validation problem, but with no additional message
		}
	}

	getState() {
		const state = super.getState();

		let nested = {};

		// Add inner inputs and its nested fieldsets
		// it doesn't include collections and arrays content

		const iterateInput = (items) => {
			nested = Object.assign(nested, getStateObject(items));

			items.forEach((item) => {
				const nestedFs = item.nestedFieldset;

				if (nestedFs) iterateInput(nestedFs.fields);
			});
		};

		iterateInput(this._fieldset.fields);

		if (Object.keys(nested).length) state.nested = nested;

		return state;
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		const spec = [];

		// Add inner inputs and its nested fieldsets
		// it doesn't include collections and arrays content

		const iterateInput = (item) => {
			spec.push(item.getValidateInstructions());

			const nested = item.nestedFieldset;

			if (nested) nested.fields.forEach(iterateInput);
		};

		this._fieldset.fields.forEach(iterateInput);

		// Spec have to be present everytime to be backward compatible with Formula
		instructions.spec = spec;

		if (this._instructions.sequence) {
			instructions.sequence = this._instructions.sequence;
		}

		return instructions;
	}

	_setDisabled(disabled) {
		this._fieldset.disabled = disabled;
	}

	getPreview() {
		if (this.parentElement.nodeName === 'IMT-INPUT-UDTSPEC-ITEM') {
			const value = this.value;

			if (typeof value.type !== 'undefined') {
				return `${I18n.l('udtspec.specification.label')}: ${value.type}`;
			}
		}

		if (Array.isArray(this._instructions?.spec) && this._instructions.spec.length === 2) {
			const value = this.value;
			const valueSpec = this._instructions.spec.find((spec) => spec.name === 'value');
			const labelSpec = this._instructions.spec.find((spec) => spec.name === 'label' || spec.name === 'name');

			if (valueSpec && labelSpec) {
				return `${value[labelSpec.name]}: ${value[valueSpec.name]}`;
			}
		}

		const fields = Array.from(this.querySelectorAll(':scope > imt-fieldset > .form-group'));

		return fields
			.slice(0, 15) // take only a small slice to avoid computing unnecessarily long strings
			.filter((f) => f instanceof Input)
			.map((f) => `${f._instructions.label || f._instructions.name}: ${f.getPreview()}`)
			.join(', ');
	}
}

registerCustomElement('imt-input-collection', CollectionInput);
