import { SelectInput } from './select.mjs';
import { registerCustomElement } from '../utils.mjs';

export class UdtTypeInput extends SelectInput {
	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return [];
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Initial value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		await super._build(instructions, value, metadata);
	}

	extendInstructions(instructions, value, metadata) {
		const types = [
			{
				label: 'Array',
				value: 'array',
				nested: [
					{
						name: 'spec',
						type: 'collection',
						label: 'Array Item Specification',
						spec: [
							{
								name: 'type',
								label: 'Type',
								type: 'udttype',
								required: true,
								default: 'text',
							},
						],
						mappable: false,
					},
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Collection',
				value: 'collection',
				nested: [
					{
						name: 'spec',
						label: 'Specification',
						type: 'udtspec',
					},
					{
						name: 'sequence',
						label: 'Preserve the order of object keys',
						type: 'boolean',
						mappable: false,
					},
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Date',
				value: 'date',
				nested: [
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Text',
				value: 'text',
				nested: [
					{
						name: 'default',
						label: 'Default',
						help: 'Default value.',
						type: 'text',
					},
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
					{
						name: 'multiline',
						label: 'Multi-line',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Number',
				value: 'number',
				nested: [
					{
						name: 'default',
						label: 'Default',
						help: 'Default value.',
						type: 'number',
					},
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Boolean',
				value: 'boolean',
				nested: [
					{
						name: 'default',
						label: 'Default',
						help: 'Default value.',
						type: 'boolean',
						mappable: false,
					},
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
				],
			},
			{
				label: 'Binary Data',
				value: 'buffer',
				nested: [
					{
						name: 'required',
						label: 'Required',
						type: 'boolean',
						required: true,
						default: false,
						mappable: false,
					},
					{
						name: 'codepage',
						label: 'Codepage',
						type: 'text',
						help: "Possible values: `binary`, `utf8`. Leave empty if you're not sure.",
					},
				],
			},
		];

		instructions.options = {
			store: Array.isArray(instructions.allowedTypes)
				? types.reduce((acc, type) => {
						if (instructions.allowedTypes.includes(type.value)) {
							acc.push(type);
						}

						return acc;
				  }, [])
				: types,
		};

		return instructions;
	}
}

registerCustomElement('imt-input-udttype', UdtTypeInput);
