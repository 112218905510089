/* eslint-env node */

const wrapper = (...args) => {
	return wrapper._library(...args);
};

wrapper._library = typeof window !== 'undefined' ? window.moment : null;

wrapper.tz = (...args) => {
	return wrapper._library.tz(...args);
};

export default wrapper;
