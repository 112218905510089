import { create, clone, registerCustomElement } from '../utils.mjs';
import './searcher.scss';

export class Searcher extends HTMLElement {
	constructor() {
		super();

		this._built = false;
	}

	static get observedAttributes() {
		return ['container'];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		this[name] = document.querySelector(newValue);
	}

	connectedCallback() {
		if (this._built) return;
		this._built = true;

		this._input = create('input[type="text"]');
		this.appendChild(this._input);
		this._input.addEventListener('input', (event) => {
			event.stopPropagation();
			if (this.container) this.update();
		});
	}

	get container() {
		return this.__container;
	}

	set container(value) {
		if (this.__container === value) return;
		if (this.__container && this._input.value) {
			const items = this.__container.querySelectorAll(this.getAttribute('item'));

			for (const item of items) item.classList.remove('hidden-by-searcher');
			return;
		}

		this.__container = value;

		if (value && this._input) this.update();
	}

	clear() {
		this._input.value = '';
		this.update();
	}

	focus() {
		this._input.focus();
	}

	update() {
		const phrase = (this._input.value || '')
			.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
		const items = this.__container.querySelectorAll(this.getAttribute('item'));
		const groups = this.hasAttribute('group') ? this.__container.querySelectorAll(this.getAttribute('group')) : [];

		if (!phrase) {
			for (const item of items) item.classList.remove('hidden-by-searcher');
			for (const group of groups) group.classList.remove('hidden-by-searcher');
			return;
		}

		const words = phrase.split(/\s+/g);

		// Track searches to Google Analytics
		const analytics = this.getAttribute('analytics');

		if (analytics && window.ga) {
			clearTimeout(this._analyticsTimer);
			this._analyticsTimer = setTimeout(() => {
				window.ga(
					'send',
					'pageview',
					`/search?ql=${imt.user.language}&qc=${analytics}&q=${encodeURIComponent(words.join(' '))}`,
				);
			}, 1000);
		}

		for (const item of items) {
			const text = item.textContent
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
			const lookup = clone(words);

			if (lookup.filter((word) => text.indexOf(word) === -1).length === 0) {
				item.classList.remove('hidden-by-searcher');
			} else {
				item.classList.add('hidden-by-searcher');
			}
		}

		// If picker is grouped, we need to hide groups where all items are also hidden
		if (groups.length) {
			for (const group of groups) {
				const groupItems = group.querySelectorAll(this.getAttribute('item'));

				if (
					Array.prototype.filter.call(groupItems, (item) => !item.classList.contains('hidden-by-searcher')).length === 0
				) {
					group.classList.add('hidden-by-searcher');
				} else {
					group.classList.remove('hidden-by-searcher');
				}
			}
		}
	}
}

registerCustomElement('imt-searcher', Searcher);
