import formanEn from '../locale/en.mjs';
import formanCs from '../locale/cs.mjs';
import validationsEn from '../locale/validations.en.mjs';
import i18next from 'i18next';
import config from '../configs/config.mjs';

export class I18n {
	static get i18next() {
		if (i18next && !i18next.isInitialized) {
			this.init();
		}

		return i18next;
	}

	static get globalParams() {
		if (!I18n.GLOBAL_PARAMS) {
			I18n.GLOBAL_PARAMS = {};
		}

		return I18n.GLOBAL_PARAMS;
	}

	static init(language) {
		if (i18next && i18next.isInitialized) {
			return;
		}

		const resources = {
			en: {
				forman: formanEn,
				validations: validationsEn,
			},
			cs: {
				forman: formanCs,
				validations: validationsEn,
			},
		};

		if (i18next) {
			i18next.init({
				lng: language || 'en',
				debug: config && config.env === 'test',
				resources,
				defaultNS: 'forman',
			});
		}
	}

	static l(key, options, noEscape) {
		options = Object.assign({}, this.globalParams, options || {});
		const m = key.match(/^{{!(.+?)}}$/);

		key = (m && m[1]) || key;

		if (noEscape) {
			options.escapeValue = false;
			options.interpolation = { escapeValue: false };
		}

		if (this.i18next && this.i18next.t) {
			return this.i18next.t(key, options);
		}

		return key;
	}

	static addDictionary(language, dictionary) {
		this.addNamespace(language, 'forman', dictionary);
	}

	static addNamespace(language, namespace, dictionary) {
		this.i18next.addResourceBundle(language, namespace, dictionary);
	}

	static addGlobalParam(key, value) {
		this.globalParams[key] = value;
	}

	static changeLanguage(language) {
		this.i18next.changeLanguage(language, () => {
			// TODO reload forman texts
		});
	}
}
