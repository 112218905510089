import { Input } from './input.mjs';
import { create, markdown, registerCustomElement, showNestedFieldsets } from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import Loader from '../loader.mjs';
import EVENTS from '../events.mjs';

export class RadioInput extends Input {
	get value() {
		const radio = this._control.querySelector('input[type="radio"]:checked');

		if (!radio) return;

		if (this.getAttribute('content-type') === 'number') {
			return Number(radio.getAttribute('value'));
		} else {
			return radio.getAttribute('value');
		}
	}

	set value(value) {
		const radio = this._control.querySelector(
			`input[type="radio"][value="${value}"]${'number' === typeof value ? 'content-type="number"' : ''}`,
		);

		if (radio) {
			radio.checked = true;
		}
	}

	get hasModeSwitch() {
		return this.mappable;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this._control = create('div.wrapper');

		this.options = Picker.normalizeOptions(instructions.options);

		if ('string' === typeof this.options.store) {
			this.options.store = await Loader.load(this.options.store);
		}

		this.options.store.forEach((option, index) => {
			const wrapper = create('div.form-check');

			const value = option[instructions.options.value || 'value'];
			const id = `${this._id}-${++index}`;

			const radio = create('input.form-check-input[type="radio"]');

			radio.setAttribute('id', id);
			radio.setAttribute('name', instructions.name);
			radio.setAttribute('value', value);

			if ('number' === typeof value) {
				radio.setAttribute('content-type', 'number');
			}

			if (option.default || instructions.default === value) {
				radio.checked = true;
			}

			const label = create('label.form-check-label');

			label.setAttribute('for', id);
			label.textContent = option[this.options.label || 'label'];

			wrapper.append(radio);
			wrapper.append(label);

			if (option.nested) {
				wrapper.append(create('imt-nested'));
			}

			if (option.help) {
				const help = create(`div.form-text.text-muted`);

				help.innerHTML = markdown(option.help);
				wrapper.appendChild(help);
			}

			this._control.append(wrapper);
		});

		this.appendChild(this._control);

		this.addEventListener(EVENTS.FORM.CHANGE, (e) => {
			const item = this.querySelector(`input[value="${this.value}"]`);
			const nested = item.querySelector(`:scope ~ imt-nested`) || item.parentElement.appendChild(create('imt-nested'));
			const nestedsToHide = Array.from(this.querySelectorAll(':scope > .wrapper > .form-check > imt-nested')).filter(
				(n) => n !== nested,
			);

			// TODO send value and metadata
			showNestedFieldsets(this, null, null, false, nested, nestedsToHide);
		});
	}

	_setDisabled(disabled) {
		for (const node of this._control.querySelectorAll('.form-check-input')) {
			if (disabled) {
				node.setAttribute('disabled', '');
			} else {
				node.removeAttribute('disabled');
			}
		}
	}
}

registerCustomElement('imt-input-radio', RadioInput);
