import { ArrayInput } from './array.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { create, registerCustomElement } from '../utils.mjs';

export class TokensInput extends ArrayInput {
	get hasModeSwitch() {
		return false;
	}

	extendInstructions(instructions, value, metadata) {
		if (!instructions.spec) {
			instructions.spec = [
				{ label: I18n.l('tokens.token'), name: 'token', type: 'text', required: true },
				{ label: I18n.l('common.note'), name: 'note', type: 'text' },
			];
		}

		if (!instructions.options && /^tokens(?::(.*))?$/.exec(instructions.type)) {
			instructions.type = 'tokens';
			instructions.options = {
				tokenType: RegExp.$1,
			};
		}

		return instructions;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		await super._build(instructions, value, metadata);
		let btn;
		const that = this;

		if (instructions.options.tokenType === 'safari' && window.safari) {
			btn = create(`button.token-btn-safari[type="button"] Add this Safari`);
			btn.addEventListener('click', (e) => {
				e.stopPropagation();

				const perm = window.safari.pushNotification.permission(`web.com.integromat.${imt.env || 'development'}`);

				if (perm.permission === 'granted') {
					that.addItem({ token: perm.deviceToken, note: 'My Chrome' });
					return;
				}

				if (perm.permission === 'denied') {
					const alert = create('imt-alert');

					alert.message = I18n.l('tokens.safariDenied');
					that.form._alerts.append(alert);
					return;
				}

				try {
					window.safari.pushNotification.requestPermission(
						`https://${window.location.hostname}/apn/safari/push`,
						`web.com.integromat.${imt.env || 'development'}`,
						{},
						(perm) => {
							if (perm.permission === 'granted') {
								that.addItem({ token: perm.deviceToken, note: 'My Safari' });
							}
						},
					);
				} catch (ex) {
					const alert = create('imt-alert');

					alert.message = I18n.l('tokens.safariDisabled');
					that.form._alerts.append(alert);
					return;
				}
			});
		} else if (instructions.options.tokenType === 'chrome' && window.chrome) {
			btn = create(`button.token-btn-chrome[type="button"] Add this Chrome`);
			btn.addEventListener('click', (e) => {
				e.stopPropagation();

				window.chrome.runtime.sendMessage('cfamjaolobgncfhomepbmjjihlbkchgb', 'permissions', (perm) => {
					if (!perm) {
						const alert = create('imt-alert');

						alert.message = I18n.l('tokens.chromeExtNotInstalled');
						that.form._alerts.append(alert);
						return;
					}

					if (perm.permissions !== 'granted') {
						const alert = create('imt-alert');

						alert.message = I18n.l('tokens.chromeActivateExtension');
						that.form._alerts.append(alert);
						return;
					}

					that.addItem({ token: perm.deviceToken, note: 'My Chrome' });
				});
			});
		}

		if (btn) {
			const arrayBtn = this.getElementsByTagName('imt-input-array-button')[0];

			arrayBtn.append(btn);
		}
	}
}

registerCustomElement('imt-input-tokens', TokensInput);
