/* eslint-disable max-len */

export default {
	'validate-number-invalid': 'Invalid number.',
	'validate-number-too-big': 'Value is too big. Maximum value is {{max}}.',
	'validate-number-too-small': 'Value is too small. Minimum value is {{min}}.',
	'validate-bundle-param-required': 'Value must not be empty.',
	'validate-array-has-less': 'Array has less than {{minItems}} items.',
	'validate-array-has-more': 'Array has more than {{maxItems}} items.',
	'validate-array-not-object': 'Array of objects expected.',
	'validate-collection-invalid': 'Invalid collection.',
	'validate-collection-unexpected': `Unexpected parameter '{{key}}' found.`,
	'validate-color-invalid-format': `Value is not a valid color in format '{{format}}'.`,
	'validate-color-invalid': 'Value is not a valid color.',
	'validate-date-invalid': 'Value is not a valid date.',
	'validate-date-invalid-past': 'Field contains date in past.',
	'validate-email-invalid': 'Value is not a valid email address.',
	'validate-filename-invalid': 'Value is not a valid filename.',
	'validate-integer-invalid': 'Invalid integer.',
	'validate-port-invalid': 'Invalid port.',
	'validate-buffer-unknown-codepage': `Unknown codepage '{{codepage}}'.`,
	'validate-text-cant-convert-buff': `Binary buffer can't be converted to text.`,
	'validate-text-max-exceeded': 'Value exceeded maximum length of {{max}} characters.',
	'validate-text-not-enough-chars': 'Value must be at least {{min}} characters long.',
	'validate-text-pattern': `Value doesn't match the pattern.`,
	'validate-time-invalid': 'Value is not a valid time.',
	'validate-timestamp-invalid': 'Value is not a valid timestamp.',
	'validate-uinteger-must-unsigned': 'Number must be unsigned.',
	'validate-url-invalid': 'Value is not a valid URL address.',
	'validate-select-invalid': `Missing value of required parameter '{{label}}'.`,
	'validate-select-has-less': `Selected less than {{minItems}} items in parameter '{{label}}'.`,
	'validate-select-has-more': `Selected more than {{maxItems}} items in parameter '{{label}}'.`,
	'validate-select-value-not-found': `Value not found in options in parameter '{{label}}'.`,
};
