/* global browser */

import { create } from '../utils.mjs';
import Loader from '../loader.mjs';
import config from '../configs/config.mjs';
import { IML } from '../deps/iml.mjs';
import { I18n } from '../helpers/i18n.mjs';

const FormulaOAuth = {
	/*
	Called from RemoteFormula to open popup on user's click event and prevent popup to be blocked.
	*/

	activeWindow: null,

	win(url = '/oauth/wait') {
		if (browser.mobile) {
			return;
		}
		const win = FormulaOAuth.openWindow(url);

		FormulaOAuth.activeWindow = win;
		FormulaOAuth.setContent(win);
		return win;
	},

	async auth(oauthWindow, connectionId, scope) {
		this.setLoading(I18n.l('panels.account.creating'));

		if (!scope) scope = [];

		const url = FormulaOAuth.getUrl(
			IML.execute(IML.parse(config.fields.account.api.auth.path), { connectionId }),
			connectionId,
			scope,
		);

		console.log('[imt:oauth_auth]', url);

		const check = async () => {
			this.setLoading(I18n.l('panels.account.waiting'));

			return new Promise((resolve, reject) => {
				const tmr = setInterval(async () => {
					if (oauthWindow.closed) {
						clearInterval(tmr);

						this.setLoading(I18n.l('panels.account.verifying'));

						try {
							resolve(await FormulaOAuth.test(connectionId));
						} catch (ex) {
							reject(ex);
						}
					}
				}, 500);
			});
		};

		if (browser.mobile) {
			this.setLoading(false);

			// on mobile browsers theres a problem that i cant make ajax request in inactive window so i have to ask user to click once again to open popup
			this.content = create('button.btn.btn-primary[type="button"]');
			this.content.textContent = I18n.l('panels.account.authorize');
			this.content.addEventListener('click', async () => {
				oauthWindow = FormulaOAuth.openWindow(url);

				if (!oauthWindow) throw new Error(I18n.l('common.popup'));

				return await check();
			});
		} else {
			oauthWindow = FormulaOAuth.openWindow(url);
			return await check();
		}
	},

	async test(connectionId) {
		const response = await Loader.load(config.fields.account.api.test.path, {
			context: { connectionId },
			method: 'POST',
		});

		if (!response.verified) {
			throw new Error(I18n.l('panels.account.verifyfailed'));
		}
	},

	async extend(connectionId, scope) {
		const url = FormulaOAuth.getUrl(
			IML.execute(IML.parse(config.fields.account.api.extend.path), { connectionId }),
			connectionId,
			scope,
		);

		console.log('[imt:oauth_extendScope]', url);

		const oauthWindow = FormulaOAuth.openWindow(url);

		if (!oauthWindow) {
			throw new Error(I18n.l('common.popup'));
		}

		return new Promise((resolve, reject) => {
			const tmr = setInterval(async () => {
				if (oauthWindow.closed) {
					clearInterval(tmr);
					this.setLoading(I18n.l('panels.account.verifying'));

					try {
						const response = await Loader.load(config.fields.account.api.scoped.path, {
							context: { connectionId },
							method: 'POST',
							body: { scope: scope },
						});

						if (!response.connection?.scoped) {
							throw new Error(I18n.l('panels.account.extendfailed'));
						}
						resolve();
					} catch (ex) {
						reject(ex);
					}
				}
			}, 500);
		});
	},

	getUrl(url, connectionId, scope) {
		const params = [];

		if (scope?.length) {
			params.push(`scope=${scope.map(encodeURIComponent).join('&scope=')}`);
		}

		if (imt.standalone) {
			url = imt.apiurl + url;
		}

		const queryString = params.length ? `?${params.join('&')}` : '';

		return url + queryString;
	},

	setContent(oauthWindow) {
		return oauthWindow.document.write('<p> Authorizing...</p>');
	},

	openWindow(url) {
		return window.open(
			url,
			'authAccountWindow',
			'toolbar=no, scrollbars=yes,location=no,status=no,width=1024,height=768',
		);
	},

	close(res) {
		FormulaOAuth.activeWindow.close();
		return (FormulaOAuth.activeWindow = null);
	},
};

export default FormulaOAuth;
window.FormulaOAuth = FormulaOAuth;
