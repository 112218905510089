/* global autosize */

import { create, showNestedFieldsets, registerCustomElement } from '../utils.mjs';
import { Input } from './input.mjs';
import configs from '../configs/config.mjs';
import { I18n } from '../helpers/i18n.mjs';
import MurmurHash3 from 'imurmurhash';

const config = configs.fields.text;

const FORMAN_TYPE_TO_HTML_TYPE = {
	email: 'email',
	number: 'number',
	integer: 'number',
	uinteger: 'number',
	port: 'number',
	time: 'time',
	url: 'url',
};

export class TextInput extends Input {
	constructor() {
		super();

		this._nestedTimer = null;
		this.panel = null;
	}

	get mappable() {
		// Text inputs don't have mappable a attribute so when fieldset is mappable the input is also mappable
		return this.fieldset.mappable;
	}

	get hasModeSwitch() {
		return false;
	}

	get value() {
		let value = this._control.value;

		if (this._instructions.type !== 'text') {
			// All other types than text should not contain white spaces on either side
			value = String(value).trim();
		}

		return value;
	}

	get castedValue() {
		const value = this.value;

		if (this.isCoder()) {
			return value;
		}
		return this.castValue(value);
	}

	set value(value) {
		if (this._control.value === value) return;
		this._control.value = value == null ? '' : value;

		if (this._control.nodeName === 'TEXTAREA' && window.autosize) {
			autosize.update(this._control);
		}
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		return Object.assign(super.attributesToInstructions(), {
			multiline: this.hasAttribute('multiline'),
			prefix: this.getAttribute('prefix'),
			postfix: this.getAttribute('postfix'),
		});
	}

	isCoder() {
		return this._control.nodeName === 'IMT-CODER';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		const type = this.mappable ? 'imt-coder' : instructions.multiline ? 'textarea' : 'input';

		this._control = document.createElement(type);

		if (type !== 'imt-coder') this._control.className = 'form-control';
		if (instructions.readonly) this._control.setAttribute('readonly', '');
		this._control.disabled = instructions.disabled;
		this._control.name = instructions.name || instructions.name;
		this._control.id = this._id;
		this._control.value = value != null ? value : '';

		if (type !== 'imt-coder' && FORMAN_TYPE_TO_HTML_TYPE[instructions.type]) {
			this._control.type = FORMAN_TYPE_TO_HTML_TYPE[instructions.type];
		}

		this.addEventListener('input', (event) => {
			if (this._nestedTimer) clearTimeout(this._nestedTimer);

			this._nestedTimer = setTimeout(() => {
				const valueHash = new MurmurHash3(JSON.stringify(this.value)).result();
				const valueFieldset = this.querySelector(`imt-nested > imt-fieldset[for='${valueHash}']`);

				if (!valueFieldset || valueFieldset.hidden) {
					showNestedFieldsets(this);
				}
			}, config.nestedTimeout);
		});

		if (instructions.prefix || instructions.postfix || instructions.buttons || instructions.rpc) {
			const group = create('div.input-group');

			if (instructions.prefix) {
				const prefix = create('span.input-group-prepend');
				const text = prefix.appendChild(create('span.input-group-text'));

				text.textContent = instructions.prefix;
				group.append(prefix);
			}

			group.appendChild(this._control);

			if (instructions.postfix) {
				const postfix = create('span.input-group-append');
				const text = postfix.appendChild(create('span.input-group-text'));

				text.textContent = instructions.postfix;
				group.append(postfix);
			}

			if (instructions.buttons && instructions.buttons.length) {
				const append = create('div.input-group-append');

				instructions.buttons.forEach((b) => append.append(b));
				group.append(append);
			}

			if (instructions.rpc) {
				const postfix = create('span.input-group-append');
				const rpcButton = create(`button.btn.btn-outline-secondary[type="button"] ${instructions.rpc.label}`);

				rpcButton.addEventListener('click', this._rpcSearchHandler(rpcButton, instructions.rpc, false));
				postfix.appendChild(rpcButton);
				group.append(postfix);
			}

			this.appendChild(group);
		} else {
			this.appendChild(this._control);
		}

		showNestedFieldsets(this, metadata?.fieldset?.value, metadata?.fieldset?.metadata);
	}

	/**
	 * Builds the list of hints to be shown with the field.
	 *
	 * @param {function} hint Function that adds the hint. First argument is icon, second is text.
	 */

	_hints(hint) {
		const inst = this._instructions;

		if (inst.type === 'text') {
			if (inst.validate) {
				if (inst.validate.max > 0 && inst.validate.min > 0) {
					if (inst.validate.max === inst.validate.min) {
						hint('font', I18n.l('hints.exactlength', { value: inst.validate.max }));
					} else {
						hint(
							'font',
							I18n.l('hints.lengthbetween', {
								a: inst.validate.min,
								b: inst.validate.max,
							}),
						);
					}
				} else if (inst.validate.max > 0) {
					hint('font', I18n.l('hints.lengthmost', { value: inst.validate.max }));
				} else if (inst.validate.min > 0) {
					hint('font', I18n.l('hints.lengthleast', { value: inst.validate.min }));
				}

				if (inst.validate.pattern && typeof inst.validate.pattern === 'object') {
					hint('check', inst.validate.pattern.label);
				} else if (inst.validate.pattern) {
					hint('check', I18n.l('hints.pattern', { pattern: `${inst.validate.pattern}` }, true));
				}
			}

			switch (inst.tags) {
				case 'strip':
				case 'stripall':
					hint('code', I18n.l('hints.striptags'));
					break;
				case 'escape':
					hint('code', I18n.l('hints.escapetags'));
					break;
			}
		} else if (inst.type === 'number' || inst.type === 'integer' || inst.type === 'uinteger') {
			if (inst.validate) {
				if (inst.validate.min) {
					if (inst.validate.max) {
						if (inst.validate.min === inst.validate.max) {
							hint(
								'arrows-h',
								I18n.l('hints.numberequal', {
									value: inst.validate.min,
								}),
							);
						} else {
							hint(
								'arrows-h',
								I18n.l('hints.numberbetween', {
									min: inst.validate.min,
									max: inst.validate.max,
								}),
							);
						}
					} else {
						hint('chevron-up', I18n.l('hints.numberhigher', { value: inst.validate.min }));
					}
				} else if (inst.validate.max) {
					hint('chevron-down', I18n.l('hints.numberlower', { value: inst.validate.max }));
				}
			}
		} else if (inst.type === 'port') {
			hint('chevron-up', I18n.l('hints.port'));
		}
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		if (this._instructions.tags) {
			instructions.tags = this._instructions.tags;
		}

		return instructions;
	}

	/**
	 * Performs value validation for type defined by this input field.
	 *
	 * @param {*} value Value to validate, same as getting `this.value`.
	 * @param {Array} problems Array of strings containing all generated validation problems.
	 * @returns {boolean} Method can return false to indicate this field is no valid without providing a reason. Otherwise, return value is ignored.
	 */

	_validate(value, problems) {
		if (this.isCoder() && this._control.containsIML) {
			// When field contains IML, use Coder validations instead
			problems.push(...this._control.errors);
		} else {
			// Any _validation method can return false to indicate this field is no valid without providing a reason
			return super._validate(value, problems);
		}
	}

	_setDisabled(disabled) {
		this._control.disabled = disabled;
	}
}

registerCustomElement('imt-input-text', TextInput);
