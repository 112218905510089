import { Input } from './input.mjs';
import { registerCustomElement } from '../utils.mjs';

export class HiddenInput extends Input {
	get hasModeSwitch() {
		return false;
	}

	get value() {
		return this._value;
	}

	set value(value) {
		if (this._value === value) return;
		this._value = value;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this._value = value || instructions.default;
	}
}

registerCustomElement('imt-input-hidden', HiddenInput);
