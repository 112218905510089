import { TextInput } from './text.mjs';
import { registerCustomElement } from '../utils.mjs';
import { cast } from '../types/number.mjs';

export class NumberInput extends TextInput {
	castValue(value) {
		return cast(value);
	}
}

registerCustomElement('imt-input-number', NumberInput);
