import { registerCustomElement, markdown } from '../utils.mjs';

export class Markdown extends HTMLElement {
	/**
	 * Builds the separator dom.
	 *
	 * @param instructions {object} Collection of directives.
	 */

	async build(instructions) {
		this.setAttribute('name', instructions.name || '');
		this.className = 'form-group';
		this.innerHTML = markdown(instructions.label);
	}
}

registerCustomElement('imt-input-markdown', Markdown);
