import { TextInput } from './text.mjs';
import { registerCustomElement } from '../utils.mjs';

export class JSONInput extends TextInput {
	// Temporarily disabled codermirror as it makes problems in platforms
	// get value() {
	// 	const value = this._control.value;
	//
	// 	if (typeof CodeMirror === 'undefined' && this.valid) {
	// 		// remove formating from the json string
	// 		return value.replace(/("[^"]*")|\s/g, '$1');
	// 	}
	//
	// 	return value;
	// }
	//
	// /**
	//  * Converts attributes of the dom element to instructions object.
	//  *
	//  * @return {object}
	//  */
	//
	// attributesToInstructions() {
	// 	return Object.assign(super.attributesToInstructions(), {
	// 		schema: this.getAttribute('schema'),
	// 	});
	// }
	//
	// /**
	//  * Builds the input dom.
	//  *
	//  * @param instructions {object} Collection of directives.
	//  * @param value {any} Initial value.
	//  * @param metadata {object} Metadata.
	//  */
	//
	// async _build(instructions, value, metadata) {
	// 	this._control = document.createElement('textarea');
	// 	if (instructions.readonly) this._control.setAttribute('readonly', '');
	// 	this._control.disabled = instructions.disabled;
	// 	this._control.name = instructions.name || instructions.name;
	// 	this._control.id = this._id;
	// 	this._control.value = value != null ? value : '';
	//
	// 	this.appendChild(this._control);
	//
	// 	if (typeof CodeMirror !== 'undefined') {
	// 		this._editor = CodeMirror.fromTextArea(this._control, {
	// 			lineNumbers: true,
	// 			lineWrapping: true,
	// 			matchBrackets: true,
	// 			indentWithTabs: true,
	// 			indentUnit: 4,
	// 			tabSize: 4,
	// 			mode: 'application/json',
	// 			viewportMargin: Infinity,
	// 		});
	// 		this._editor.setOption('lint', this._editor.getValue().trim());
	//
	// 		this.querySelector('.CodeMirror').style.height = 'auto';
	// 		this._editor.on('change', (e) => {
	// 			// this._control.value = this._editor.getValue();
	// 			this._editor.save();
	// 		});
	// 	} else {
	// 		if (value && typeof value === 'string') {
	// 			this._control.value = JSON.stringify(JSON.parse(value), ' ', 4);
	// 		}
	//
	// 		this._control.classList.add('form-control');
	// 	}
	// }
	//
	// _setDisabled(disabled) {
	// 	this._editor.setOption('readOnly', disabled);
	// }
}

registerCustomElement('imt-input-json', JSONInput);
