import { SelectInput } from './select.mjs';
import config from '../configs/config.mjs';
import { registerCustomElement, resolveDefaultInputValue } from '../utils.mjs';

export class AgentInput extends SelectInput {
	get stateDataProps() {
		return [];
	}

	extendInstructions(instructions, value, metadata) {
		instructions.agentType = this.getAttribute('type');

		const storeQuery = new URLSearchParams();

		storeQuery.append('teamId', this.getTeamId(instructions));
		storeQuery.append('service', instructions.agentType);
		storeQuery.append('pg[limit]', config.fields.agent.list.limit);

		instructions.options = {
			store: `${config.fields.agent.list.path}?${storeQuery}`,
			value: 'id',
			label: 'name',
			wrapper: 'agents',
			team: this.getTeamId(instructions),
		};
		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	async _build(instructions, value, metadata) {
		if (typeof value === 'undefined') {
			value = resolveDefaultInputValue(this, instructions.type);
		}

		await super._build(instructions, value, metadata);
	}
}

registerCustomElement('imt-input-agent', AgentInput);
