/* global browser */

import { create, uuid } from '../utils.mjs';
import Loader from '../loader.mjs';
import EVENTS from '../events.mjs';
import OAuth from './oauth.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

export default class RemoteFormPanel extends EventTarget {
	constructor(relative) {
		super();

		this.panel = null;
		this.relative = relative;
	}

	async build(instructions, metadata = {}) {
		if (!this.panel) {
			this.panel = create('imt-panel.remote');
			this.panel.width = 450;
			this.panel.position = ['right', 'left'];
			this.panel.relative = this.relative;
			this.panel.spacing = 8;

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) =>
				this.dispatchEvent(new CustomEvent(EVENTS.PANEL.CLOSE)),
			);
		} else {
			// Clear content when rebuilding
			this.panel.header.innerHTML = '';
			this.panel.body.innerHTML = '';
			this.panel.footer.innerHTML = '';
		}

		const parentFieldset = this.relative?.closest('[field]')?.fieldset;

		if (parentFieldset?.rpcData) {
			metadata.data = { ...metadata.data, ...parentFieldset.rpcData };
		}

		// Header

		this.panel.header.append(create(`h1 ${instructions.name}`));

		const parentForm = this.relative?.closest('imt-forman');
		const theme = instructions.theme || parentForm?.meta?.module?.package?.theme || DEFAULT_THEME;

		if (theme) {
			const color = (theme.toHex ? theme.toHex() : theme).toLowerCase();

			if (/^#[0-9A-F]{6}$/i.test(color)) {
				this.panel.header.classList.add('gradient', `theme-${color.replace('#', '')}`);
			}
		}

		const headerButtons = create('div.header-buttons');
		const menu = create('button.panel-menu[type="button"]');

		menu.append(create('i.fas.fa-ellipsis-v'));
		menu.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();
			const relativeForm = this.relative.closest('imt-forman');

			if (relativeForm) {
				relativeForm.dispatchEvent(
					new CustomEvent(EVENTS.PANEL.OPEN_MENU, {
						detail: {
							relative: menu,
						},
						cancelable: true,
					}),
				);
			}
		});
		headerButtons.append(menu);

		if ('undefined' !== typeof sim && instructions.help) {
			const helpBtn = create('button.close[type="button"]');

			helpBtn.append(create('i.fas.fa-question'));
			helpBtn.addEventListener('click', (event) => {
				event.preventDefault();
				event.stopPropagation();

				window.sim(window).openHelp(instructions.help);
			});
			headerButtons.append(helpBtn);
		}

		const close = create('button.close[type="button"]');

		close.append(create('i.far.fa-times'));
		close.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();

			this.panel.close();
		});
		headerButtons.append(close);

		this.panel.header.append(headerButtons);

		let config;

		try {
			config = !instructions.src ? instructions : await Loader.load(instructions.src, metadata);
		} catch (error) {
			this.panel.warn(error);
			console.error(error);
			return;
		}

		// Form

		this.form = create('imt-forman');

		const meta = parentForm?.meta;

		if (meta) this.form.meta = { ...{}, ...meta, ...metadata, parentFieldset };
		this.form.values.load(config.values);

		if (parentForm?.hasAttribute('debug')) {
			this.form.setAttribute('debug', '');
		}

		const [display, buttons] = this.panel.footerControls;

		this.form.addEventListener(EVENTS.FORM.HAS_ADVANCED_PARAMETERS, (event) => {
			const id = uuid();
			const advanced = create(`imt-switch.xs#${id}`);
			const label = create(`label[for="${id}"].show-advanced-label`);

			label.textContent = I18n.l('common.showAdvanced');

			advanced.addEventListener('input', () => (this.form.advancedParametersVisible = advanced.active));

			display.append(advanced);
			display.append(label);
		});

		await this.form.build(config.config);

		// Body

		this.panel.body.append(this.form);

		// Footer

		if (config.buttons) {
			for (const buttonConfig of config.buttons) {
				// TODO Does it support all types?
				switch (buttonConfig.action) {
					case 'close': {
						this._generateCloseButton(buttonConfig.label, buttons);
						break;
					}

					case 'submit': {
						const button = create('button.btn.btn-sm.btn-primary[type="button"]');

						button.textContent = I18n.l(buttonConfig.label);
						button.addEventListener('click', async (event) => {
							event.preventDefault();

							if (!this.form.validate()) return;

							if (
								this.dispatchEvent(
									new CustomEvent(EVENTS.FORM.SUBMITTED, {
										detail: {
											get value() {
												return this.form.value;
											},
											panel: this.panel,
											form: this.form,
										},
										cancelable: true,
									}),
								)
							) {
								this.panel.close();
							}
						});

						buttons.append(button);
						break;
					}

					case 'http': {
						const button = create('button.btn.btn-sm.btn-primary[type="button"]');
						let oauthWindow;

						button.textContent = I18n.l(buttonConfig.label);
						button.addEventListener('click', async (event) => {
							event.preventDefault();

							if (!this.form.validate()) return;

							this.panel.setLoading(true);

							const accountType = this.form.querySelector('imt-input-select[name="accountType"] imt-option[selected]');

							if (!browser.mobile && accountType?.getAttribute('data-method') === 'oauth') {
								oauthWindow = OAuth.win();

								if (!oauthWindow) {
									button.removeAttribute('disabled');
									this.panel.warn(new Error(I18n.l('common.popup')));
								}
							}
							// on desktops, pre-open the window here because we're in click event handler so popup is not blocked
							// on mobiles, a special prompt is showed to the user later in formanAuth process

							const path = 'api://' + buttonConfig.address.replace(/^\//, '');

							const response = await (['GET'].includes(buttonConfig.method)
								? Loader.load(path)
								: this.form.save({
										method: buttonConfig.method,
										action: path,
										multipart: config.multipart || false,
								  }));

							if (!response) {
								oauthWindow?.close();
								this.panel.setLoading(false);
								return;
							}

							if (response.formula) {
								if (!response.formula.oauth) oauthWindow?.close();

								if (response.formula.oauth) {
									try {
										await OAuth.auth.call(
											this.panel,
											oauthWindow,
											response.formula.oauth.account,
											instructions.options.oauth?.scope
												?.filter((s) => s.type === response.formula.oauth.provider)
												.flatMap((s) => s.scope),
										);

										this.dispatchEvent(
											new CustomEvent(EVENTS.FORM.SUBMITTED, {
												detail: {
													data: [
														response.connection.id,
														response.connection.name,
														response.connection.accountName,
														response.connection.metadata,
													],
												},
											}),
										);

										this.panel.close();
									} catch (ex) {
										button.removeAttribute('disabled');

										return this.panel.warn(ex);
									}
								} else if (response.formula.rebuild) {
									await this.build(
										Object.assign(instructions, {
											src: response.formula.rebuild.url, // Pass context to replace path parameter (e.g. device)
										}),
									);

									this.panel.setLoading(false);
								} else if (response.formula.success) {
									this.dispatchEvent(
										new CustomEvent(EVENTS.FORM.SUBMITTED, {
											detail: { data: response.formula.success },
										}),
									);

									this.panel.close();
								}
							} else {
								this.dispatchEvent(
									new CustomEvent(EVENTS.FORM.SUBMITTED, {
										detail: { data: response },
									}),
								);

								this.panel.close();
							}
						});

						buttons.append(button);
						break;
					}

					case 'handler':
						const button = create('button.btn.btn-sm.btn-primary[type="button"]');

						button.addEventListener('click', buttonConfig.handler);
						button.textContent = buttonConfig.label;
						buttons.append(button);
						break;
				}
			}
		}
	}

	open() {
		if (this.panel) this.panel.open();
	}

	_generateCloseButton(label, buttons) {
		const button = create('button.btn.btn-sm.btn-outline-secondary.mr-1[type="button"]');

		button.textContent = I18n.l(label);
		button.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();
			this.panel.close();
		});

		buttons.append(button);
	}
}
