import './polyfills.mjs';

import './controls/switch.mjs';
import './controls/coder.mjs';
import './controls/picker.mjs';
import './controls/panel.mjs';
import './controls/searcher.mjs';
import './fieldset.mjs';
import './alerts.mjs';
import { clone, create, registerCustomElement, parseAttributeValue, dispatchResizeDebounced } from './utils.mjs';
import { Values } from './values.mjs';
import Loader from './loader.mjs';
import { IML } from './deps/iml.mjs';
import Domains from './domains.mjs';
import EVENTS from './events.mjs';
import { Input } from './inputs/input.mjs';
import { I18n } from './helpers/i18n.mjs';
import './forman.scss';
import { isAttribute } from './utils.mjs';
import { Fieldset } from './fieldset.mjs';

import { blueprintMappingValidate } from './helpers/blueprintValidator.mjs';
window.formanBlueprintMappingValidate = blueprintMappingValidate;
// eslint-disable-next-line no-undef
window.IMT_FORMAN_VERSION = FORMAN_VERSION;

export default class Forman extends HTMLElement {
	constructor() {
		super();
		// External overrides of the default Forman functionality
		this.overrides = Forman._overrides;

		this._loadings = 0;

		this._built = false;
		this._advancedParametersVisible = false;
		this._hasAdvancedParameters = false;

		this.options = {};

		this.values = new Values({
			debug: this.debug.bind(this),
		});
		this.domains = new Domains(this);
		this.states = new Map();

		this.meta = {
			imlOptions: {},
			imlContext: {},
			module: {
				id: null,
				config: null,
				package: null,
				restore: {},
			},
			pills: [],
			samples: {},
			timezone: null, // Organization timezone
			teamId: imt?.team?.id,
			organizationId: imt?.organization?.id,
			brand: imt?.brand,
		};

		if (imt?.team?.id) {
			this.meta.imlContext.teamId = imt.team.id;
		}
		if (imt?.organization?.id) {
			this.meta.imlContext.organizationId = imt?.organization?.id;
		}

		this.init();
	}

	async init() {
		await this.i18n.init(this.lang);

		// Prevent bubbling the event in order to prevent blur integromat panel
		this.addEventListener(EVENTS.PANEL.CLOSE, (event) => event.stopPropagation());

		// Watch for options being added to our DOM
		const observer = new MutationObserver((mutationList) => {
			const additions = [];

			for (const mutation of mutationList) {
				for (const node of mutation.addedNodes) {
					// const walker = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT);
					// while (walker.nextNode() !== null) {
					// 	const tagName = walker.currentNode.tagName;
					// 	if (tagName) {
					// 		const lazyDefinition = lazyDefinitions.get(tagName);
					// 		if (lazyDefinition !== undefined) {
					// 			lazyDefinitions.delete(tagName);
					// 			(async () => {
					// 				customElements.define(tagName, await lazyDefinition());
					// 			})();
					// 		}
					// 	}
					// }

					// Only consume imt-value nodes
					if (node.nodeName === 'IMT-VALUE') {
						additions.push(node);
					}
				}
			}

			// Ignore rest if there're no additions we're interested about
			if (!additions.length) return;

			// Make sure we have been built
			if (!this._built) this._build();

			for (const node of additions) {
				this.values.set(node.getAttribute('name'), node.textContent, node.fieldset.domain);
				this.removeChild(node);
			}
		});

		observer.observe(this, {
			childList: true,
			subtree: true,
		});
	}

	/**
	 * Defines custom editor for editor input. It is possible to rewrite default editors.
	 * @param {string} name Name of the editor. The custom editor will be used if the `editor` option of the editor input matches with the defined name.
	 * @param {Class} editor Editor class.
	 */
	static registerCustomEditor(name, editor) {
		Forman._overrides.editors[name] = editor;
	}

	/**
	 * @deprecated
	 * @param {Functions} handler
	 */
	static registerSubmitResponseHandler(handler) {
		Forman._overrides.loader.handleResponse = handler;
	}

	static async createForm(options = {}) {
		const selector = ['imt-forman'];

		if (options.action) selector.push(`[action="${options.action}"]`);
		if (options.src) selector.push(`[src="${options.src}"]`);
		if (options.mappable) selector.push('[mappable=true]');
		if (options.tree) selector.push('[tree=true]');

		const form = create(selector.join(''));

		await form.build(options.src || options.instructions);

		return form;
	}

	connectedCallback() {
		this.dispatchEvent(new CustomEvent(EVENTS.FORM.CONNECT));
		if (this._built) return;
		// Allows to use overrides like Forman.registerEditor
		setTimeout(() => {
			this.build(this.getAttribute('src'));
		});
	}

	disconnectedCallback() {
		this.dispatchEvent(new CustomEvent(EVENTS.FORM.DISCONNECT));
	}

	get value() {
		return this.domains.value();
	}

	get module() {
		return this.meta.module;
	}

	set module(module) {
		this.meta.module = module;
	}

	get mappable() {
		return this.hasAttribute('mappable');
	}

	set mappable(value) {
		value ? this.setAttribute('mappable', '') : this.removeAttribute('mappable');
	}

	get defaultMappable() {
		return isAttribute(this, 'default-mappable');
	}

	set defaultMappable(value) {
		value ? this.setAttribute('default-mappable', '') : this.removeAttribute('default-mappable');
	}

	get tree() {
		return this.hasAttribute('tree');
	}

	set tree(value) {
		if (value) {
			this.setAttribute('tree', '');
		} else {
			this.removeAttribute('tree');
		}
	}

	/**
	 * User timezone
	 * @return {string}
	 */
	get timezone() {
		return this.getAttribute('timezone');
	}

	set timezone(value) {
		if (value) {
			this.setAttribute('timezone', value);
		} else {
			this.removeAttribute('timezone');
		}
	}

	get submitOnEnter() {
		return this.hasAttribute('submit-on-enter');
	}

	set submitOnEnter(value) {
		if (value) {
			this.setAttribute('submit-on-enter', '');
		} else {
			this.removeAttribute('submit-on-enter');
		}
	}

	get advancedParametersVisible() {
		return this._advancedParametersVisible;
	}

	set advancedParametersVisible(value) {
		if (this._advancedParametersVisible === value) return;
		this._advancedParametersVisible = value;

		function setVisibility(node) {
			node.classList[value ? 'remove' : 'add']('d-none');
		}

		function manageChildren(node) {
			if (!node) return;

			for (const input of node.childNodes) {
				if (input instanceof Input && input.advanced) {
					setVisibility(input);
				} else if (input.nodeName === 'IMT-SEMANTIC-GROUP') {
					if (isAttribute(input, 'advanced')) setVisibility(input);
					manageChildren(input.querySelector(':scope > div.wrapper'));
				}
			}
		}

		for (const fs of this.querySelectorAll('imt-fieldset')) {
			manageChildren(fs);
		}

		dispatchResizeDebounced(this);
	}

	get hasAdvancedParameters() {
		return this._hasAdvancedParameters;
	}

	set hasAdvancedParameters(value) {
		if (this._hasAdvancedParameters === value) return;
		this._hasAdvancedParameters = value;

		if (value) this.dispatchEvent(new CustomEvent(EVENTS.FORM.HAS_ADVANCED_PARAMETERS));
	}

	get lang() {
		return this.getAttribute('lang');
	}

	set lang(value) {
		if (value) {
			this.setAttribute('lang', value);
		} else {
			this.removeAttribute('lang');
		}
	}

	get i18n() {
		return I18n;
	}

	get disabled() {
		return isAttribute(this, 'disabled');
	}

	set disabled(value) {
		if (value) {
			this.disable();
		} else {
			this.enable();
		}
	}

	get hideLabels() {
		return isAttribute(this, 'hideLabels');
	}

	set hideLabels(value) {
		const hideLabels = this.hideLabels;

		if (hideLabels === value) return;

		if (value) {
			this.setAttribute('hideLabels', '');
		} else {
			this.removeAttribute('hideLabels');
		}
	}

	get loadings() {
		return this._loadings;
	}

	set loadings(value) {
		if (value < 0) value = 0;
		if (value === this._loadings) return;

		if (this._loadings === 0 && value > 0) {
			this.dispatchEvent(new CustomEvent(EVENTS.FORM.LOADING));
		}

		if (this._loadings > 0 && value === 0) {
			this.dispatchEvent(new CustomEvent(EVENTS.FORM.LOADED));
		}

		this._loadings = value;
		dispatchResizeDebounced(this);
	}

	get help() {
		if ('undefined' !== typeof sim) {
			const panel = this.closest('.i-panel');

			if (panel) {
				return window.sim(panel).help;
			}
		}

		return undefined;
	}

	/**
	 * Builds the form.
	 *
	 * @param {Array|string} [instructions] Array of instructions. Optional; if not provided, forman will try to find nodes directly in the dom tree.
	 * @param {Object} [value] Value of the form (of the default default domain).
	 */

	async build(instructions, value = {}) {
		if (this._built) return;
		this._originalInstructions = clone(instructions, true);
		this._originalValue = value;
		this._built = true;

		this.classList.add('forman');

		this.dispatchEvent(new CustomEvent(EVENTS.FORM.BUILD));

		const promises = [];

		this._alerts = this.querySelector('imt-alerts');
		if (!this._alerts) {
			this._alerts = create('imt-alerts');
			this.insertBefore(this._alerts, this.firstChild);
		}

		// Set value as a value of "default" fieldset

		value = {
			default: value,
		};

		function getFieldsetValue(domain) {
			return value[domain || 'default'];
		}

		for (const node of this.querySelectorAll('imt-value')) {
			let fieldValue = node.hasAttribute('value') ? node.getAttribute('value') : node.textContent;
			const fieldDomain = node.getAttribute('domain') || 'default';

			fieldValue = parseAttributeValue(fieldValue, node.getAttribute('content-type'));

			value[fieldDomain] = value[fieldDomain] || {};
			value[fieldDomain][node.getAttribute('name')] = fieldValue;
			node.parentNode.removeChild(node);
		}

		for (const node of this.querySelectorAll('imt-meta')) {
			let value = node.hasAttribute('value') ? node.getAttribute('value') : node.textContent;

			if (value && node.getAttribute('content-type') === 'application/json') value = JSON.parse(value);
			this.meta[node.getAttribute('name')] = value;
			node.parentNode.removeChild(node);
		}

		const context = this.form ? this.form.meta.imlContext : {};

		if (instructions) {
			const fieldset = create('imt-fieldset');

			const defaultDomainDefault = this.domains.getDefaults('default');

			if (defaultDomainDefault) {
				fieldset.setDomainDefaults(defaultDomainDefault.defaults);
			}

			const slot = this.querySelector('slot[name="form"]');

			if (slot) {
				this.replaceChild(fieldset, slot);
			} else {
				this.appendChild(fieldset);
			}

			if (typeof instructions === 'string') {
				// Support for remote formula and it's values returned in RPC call
				const form = await Loader.load(instructions, { context });

				promises.push(
					fieldset.build(
						form.instructions || form,
						Array.isArray(form) ? getFieldsetValue(fieldset.domain) : form.values,
						{
							restore: clone(this.meta.module.restore.default, true),
						},
					),
				);
			} else {
				promises.push(
					fieldset.build(clone(instructions, true), getFieldsetValue(fieldset.domain), {
						restore: clone(this.meta.module.restore.default, true),
					}),
				);
			}
		} else {
			const fieldsets = this.querySelectorAll(':not(imt-nested) > imt-fieldset');

			if (!fieldsets.length && !this.domains.length)
				throw new Error('The imt-fieldset element was not found in the tree.');
			for (const fieldset of fieldsets) {
				promises.push(fieldset.build(undefined, getFieldsetValue(fieldset.domain)));
			}
		}

		for (const domain of Array.from(this.domains.list).sort((a, b) => a.priority - b.priority)) {
			const fieldset = create(`imt-fieldset[domain="${domain.name}"`);

			fieldset.setDomainDefaults(domain.defaults);

			this.append(fieldset);

			domain.metadata.restore = clone(this.meta.module.restore[domain.name], true);
			domain.metadata.context = Object.assign(domain.values || {}, context);

			promises.push(fieldset.build(domain.instructions, domain.values, domain.metadata));
		}

		const submit = this.querySelector('button[type="submit"]');

		if (submit) {
			submit.addEventListener('click', (e) => {
				e.preventDefault();

				const restore = () => {
					submit.removeAttribute('disabled');
				};

				submit.setAttribute('disabled', '');
				this.save().then(restore).catch(restore);
			});
		}

		if (this.submitOnEnter) {
			this.addEventListener('keydown', (event) => {
				if (event.key === 'Enter') {
					this.save();
				}
			});
		}

		// Build all fieldsets in parallel
		await Promise.all(promises).catch((ex) => this._handleError(ex));

		this.meta.module.restore = {};

		this.dispatchEvent(new CustomEvent(EVENTS.FORM.BUILT));
	}

	collapse() {
		for (const fieldset of this.querySelectorAll('imt-fieldset:not([nested])')) {
			fieldset.collapse();
		}
	}

	expand() {
		for (const fieldset of this.querySelectorAll('imt-fieldset:not([nested])')) {
			fieldset.expand();
		}
	}

	validate() {
		let valid = true;

		for (const fieldset of this.querySelectorAll('imt-fieldset:not([hidden])')) {
			if (!fieldset.validate()) valid = false;
		}
		return valid;
	}

	async save(instructions) {
		// Returns false if called event.preventDefault()
		if (!this.dispatchEvent(new CustomEvent(EVENTS.FORM.SUBMIT, { detail: this.data, cancelable: true }))) {
			return;
		}

		const method = instructions?.method || this.getAttribute('method') || 'POST';
		const action = instructions?.action || this.getAttribute('action');
		const headers = instructions?.headers || {};
		const redirect = this.getAttribute('redirect');

		if (!action) throw new Error('Action URL was not specified.');
		if (!this.validate()) return false;

		// Clean up old error alerts
		this._alerts.innerHTML = '';

		// Clean up old custom message
		if (this._message) {
			this._message.parentNode && this._message.parentNode.removeChild(this._message);
			this._message = undefined;
		}

		try {
			const options = {
				method,
				headers,
			};

			// TODO: Add support for nested objects and arrays if necessary
			if (this.querySelector('imt-input-upload') || instructions?.multipart) {
				const formData = new FormData();

				Object.keys(this.value).forEach((key) => {
					const value = this.value[key];

					if (value instanceof FileList) {
						Array.from(value).forEach((file) => {
							formData.append(`${key}[]`, file);
						});
					} else if (Array.isArray(value)) {
						value.forEach((elm) => {
							formData.append(`${key}[]`, elm);
						});
					} else {
						formData.append(key, value);
					}
				});

				options.body = formData;
			} else {
				options.headers['content-type'] = 'application/json; charset=utf-8';
				options.body = JSON.stringify(this.value);
			}

			const res = await Loader.load(action, options);

			const SUCCESS_MESSAGE = 'Successfully saved.';

			// Returns false if called event.preventDefault()
			if (
				this.dispatchEvent(
					new CustomEvent(EVENTS.FORM.SUBMITTED, {
						detail: {
							response: res,
						},
						cancelable: true,
					}),
				)
			) {
				const template = document.querySelector('template#forman-notifications-success');

				this._showNotificationFromTemplate(template, { message: SUCCESS_MESSAGE });
			}

			if (redirect) {
				window.location = IML.execute(IML.parse(redirect), {
					res,
				});
			} else if (redirect === '') {
				window.location.reload();
			}

			return res || true;
		} catch (ex) {
			this._handleError(ex);
		}
	}

	enable() {
		this.removeAttribute('disabled');

		for (const node of this.childNodes) {
			if (node instanceof Fieldset) {
				node.enable();
			}
		}
	}

	disable() {
		this.setAttribute('disabled', '');

		for (const node of this.childNodes) {
			if (node instanceof Fieldset) {
				node.disable();
			}
		}
	}

	async rebuild() {
		this.innerHTML = ``;
		const loader = create('div.loader');

		loader.style.display = 'flex';
		loader.append(
			create('div.loader-circle'),
			create('div.loader-circle'),
			create('div.loader-circle'),
			create('div.loader-circle'),
		);
		this.append(loader);
		this._built = false;
		await new Promise((resolve) => {
			setTimeout(async () => {
				loader.remove();
				await this.build(this._originalInstructions, this._originalValue);
				resolve();
			}, 100);
		});
	}

	_handleError(ex) {
		this.debug('build', ex);

		// Returns false if called event.preventDefault()
		if (!this.dispatchEvent(new CustomEvent(EVENTS.FORM.ERROR, { detail: ex, cancelable: true }))) {
			return;
		}

		let found = false;

		if (ex.location) {
			const target = this.querySelector(ex.location);

			if (target) {
				found = true;
				target.addServerValidationError(ex.message);
			}
		}

		if (!found) {
			const template = document.querySelector('template#forman-notifications-error');

			this._showNotificationFromTemplate(template, ex);

			if (template && ['', 'true'].includes(template.getAttribute('data-prevent-default'))) return;

			const alert = create('imt-alert');

			alert.message = ex.message;

			if (ex.detail) {
				alert.detail = ex.detail;
			}

			this._alerts.appendChild(alert);
		}
	}

	_showNotificationFromTemplate(template, message) {
		if (!template) return;

		function getMessageDom(obj, path) {
			const msg = path && IML.execute(IML.parse(path), obj);

			if (!msg) return;

			if (Array.isArray(msg)) {
				const ul = create('ul');

				msg.forEach((m) => ul.appendChild(create(`li ${m}`)));
				return ul;
			}

			return document.createTextNode(msg);
		}

		function displayMessage(slot, defaultMessagePath) {
			if (!slot) return;

			const domMessage = getMessageDom(message, slot.getAttribute('data-path') || defaultMessagePath);

			if (domMessage) {
				slot.parentNode.replaceChild(domMessage, slot);
			} else {
				slot.parentNode.removeChild(slot);
			}
		}

		const dismissMessage = (templateClone) => {
			templateClone.parentNode && templateClone.parentNode.removeChild(templateClone);
			this._message = undefined;
		};

		const clone = template.content.firstElementChild.cloneNode(true);

		displayMessage(clone.querySelector('slot[name="forman-notification-message"]'), '{{message}}');
		clone.querySelectorAll('slot[name="forman-notification"]').forEach((n) => displayMessage(n));

		template.parentNode.insertBefore(clone, template);
		this._message = clone;

		const hide = template.getAttribute('data-hide');

		let timeout;

		if (hide && /\d+/.test(hide)) {
			timeout = setTimeout(() => {
				dismissMessage(clone);
			}, hide);
		}

		const dismissBtn = clone.querySelector(`[data-dismiss="true"], [data-dismiss]`);

		if (dismissBtn) {
			dismissBtn.addEventListener('click', () => {
				dismissMessage(clone);
				if (timeout) {
					clearTimeout(timeout);
				}
			});
		}
	}

	/**
	 * Logs messages to the console based on the debug configuration.
	 * @param {('events', 'values', 'submission', 'validation', 'build')} scope
	 * @param {...string} message
	 */

	debug(scope, ...message) {
		let debug = localStorage.getItem('formanDebug');

		if (debug === null) {
			debug = this.getAttribute('debug');
		}

		if (debug === null || debug === 'false') return;

		if (debug === '' || debug === 'true' || debug.split(',').includes(scope)) {
			if (message.length === 1 && message[0] instanceof Error) {
				console.error(`%c[forman:%s]`, 'color: #bbb', scope, message[0]);
			} else {
				console.log(`%c[forman:%s]`, 'color: #bbb', scope, ...message);
			}
		}
	}

	saveFieldsetState(fieldset) {
		this.states.set(fieldset.domPath, fieldset.getState());
	}

	getFieldsetState(fieldset) {
		return this.states.get(fieldset.domPath);
	}
}

// External overrides of the default Forman functionality
Forman._overrides = {
	editors: [],
	loader: {},
};

registerCustomElement('imt-forman', Forman);
