import { SelectInput } from './select.mjs';
import configs from '../configs/config.mjs';
import {
	isAttribute,
	create,
	registerCustomElement,
	composedInputBuilder,
	dispatchResizeDebounced,
} from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import Loader from '../loader.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

const config = configs.fields.hook;

export class HookInput extends SelectInput {
	constructor() {
		super();

		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return ['editable'];
	}

	extendInstructions(instructions) {
		instructions.options = instructions.options || {};
		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || 'Choose a hook';
		instructions.hookType = this.getAttribute('type');

		if (this.form?.meta.module?.package && !instructions.help) {
			instructions.help = I18n.l('hints.webhook', {
				service: this.form.meta.module.package.label,
				name: this.form.meta.module.package.name.replace(/^app#/, ''),
			});
		}

		// scenarioId is only present in DLQScenario
		const scenarioId =
			this.form.meta.Inspector?.instance?.scenario?.scenarioId || this.form.meta.Inspector?.instance?.scenario?.id;

		const storeQuery = new URLSearchParams();

		if (!instructions.options.team) {
			instructions.options.team = this.getTeamId(instructions);
			if (scenarioId) {
				storeQuery.append('viewForScenarioId', scenarioId);
			}
		}

		storeQuery.append('teamId', instructions.options.team);
		storeQuery.append('assigned', 'false');
		storeQuery.append('typeName', instructions.hookType);
		storeQuery.append('pg[limit]', config.api.list.limit);

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;
		instructions.theme = instructions.theme || this.form.meta.module.package?.theme || DEFAULT_THEME;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker

		const picker = create('imt-picker');

		picker.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item) => {
			option.setAttribute('data-editable', item.editable);
		});

		// Build the component

		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('common.edit'),
						action: this._updateHandler(picker, instructions),
						enabled: (option) => isAttribute(option, 'data-editable'),
					},
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);

		// If in readonly mode, disable all hook features
		if (this.readonly) return;

		// Info

		this._hookInfo = create('div.hook-info.text-right.collapse');

		this._hookUrl = this._hookInfo.appendChild(create('div.hook-url-wrapper.mb-2'));

		this._learnInfo = this._hookInfo.appendChild(create('div.collapse.small.text-left'));
		this._learnInfo.appendChild(create('p')).textContent = I18n.l('hooks.listening', {
			title: this.form?.brand?.title,
		});

		if (instructions.options.learning) {
			this._learnButton = this._hookInfo.appendChild(create('button.btn.btn-sm.btn-xs.btn-info.mr-2'));
			this._learnButton.textContent = I18n.l('hooks.redetermine');
		}

		const copyButton = this._hookInfo.appendChild(create('button.btn.btn-sm.btn-xs.btn-secondary'));

		copyButton.textContent = I18n.l('hooks.copy');
		copyButton.addEventListener('click', async (event) => {
			try {
				await navigator.clipboard.writeText(this._hookUrl.textContent);
				// TODO new Flash imt.l 'inspector', 'panels.hooks.copied'
			} catch (ex) {
				// TODO new Prompt(hookUrl.textContent);
			}
		});

		// Attach info

		this._attachedInfo = create('div.hook-attach.collapse');
		this._attachedInfo.appendChild(create('p.small.text-muted')).textContent = I18n.l('hooks.attached');

		const showButton = this._attachedInfo.appendChild(create('button.btn.btn-sm.btn-info'));

		showButton.textContent = I18n.l('hooks.show');
		showButton.addEventListener('click', (event) => {
			event.target.remove();
			this._hookInfo.classList.toggle('collapse');
			dispatchResizeDebounced(this);
		});

		this._goneInfo = create('div.collapse');
		this._goneInfo
			.appendChild(create('p.small.text-muted.mt-3'))
			.append(create('i.fa.far.fa-fw.fa-ban.mr-2.text-danger'), I18n.l('hooks.gone'));

		const tools = this.appendChild(create('div.input-tools'));

		tools.append(this._goneInfo);
		tools.append(this._attachedInfo);
		tools.append(this._hookInfo);

		this._learning = false;
		this._timer = null;
		let _fails = 0;

		const stop = async (err, success) => {
			if (!this._learning) return;
			this._learning = false;

			this._learnInfo.classList.add('collapse');
			this._learnButton.classList.remove('btn-danger');
			this._learnButton.classList.add('btn-info');
			this._learnButton.textContent = I18n.l('hooks.redetermine');

			if (!success) {
				clearTimeout(this._timer);

				try {
					await Loader.load(config.api.learnStop.path, {
						method: config.api.learnStop.method,
						context: {
							hookId: this.value,
						},
					});
				} catch (ex) {
					console.error(ex);
				}
			} else {
				const notification = create('small.text-success.mr-3');

				notification.append(create('i.far.fa-check-circle.mr-2'), I18n.l('hooks.determined'));

				this._learnButton.parentNode.replaceChild(notification, this._learnButton);
			}

			dispatchResizeDebounced(this);
		};

		const ping = (value) => {
			return async () => {
				const query = new URLSearchParams();
				let res;

				query.append('hook', value);
				try {
					res = await Loader.load(config.api.ping.path + `?${query.toString()}`, {
						method: config.api.ping.method,
						context: {
							hookId: value,
						},
					});
				} catch (ex) {
					if (++_fails > 3) {
						return stop(ex);
					}

					this._timer = setTimeout(ping(value), 2000);
					return;
				}

				if (res.learning) {
					this._timer = setTimeout(ping(value), 2000);
					return;
				}

				stop(null, true);
			};
		};

		const start = async () => {
			if (this._learning) return;
			this._learning = true;

			this._learnInfo.classList.remove('collapse');
			this._learnButton.classList.remove('btn-info');
			this._learnButton.classList.add('btn-danger');
			this._learnButton.innerHTML = '';
			this._learnButton.append(create('i.far.fa-spin.fa-circle-notch.mr-2'), 'Stop');

			dispatchResizeDebounced(this);

			try {
				await Loader.load(config.api.learnStart.path, {
					method: config.api.learnStart.method,
					context: {
						hookId: this.value,
					},
				});

				this._timer = setTimeout(ping(this.value), 5000);
			} catch (ex) {
				return stop();
			}
		};

		this._learnButton?.addEventListener('click', () => {
			if (this._learning) {
				stop();
			} else {
				start();
			}
		});

		const reloadHookInfo = async () => {
			const autoLearn = this._autoLearn;

			this._autoLearn = false;

			if (!this.value) {
				this._hookInfo.classList.add('collapse');
				dispatchResizeDebounced(this);
				return;
			}

			let res;

			try {
				res = await Loader.load(config.api.ping.path, {
					context: {
						hookId: this.value,
					},
					method: config.api.ping.method,
				});
			} catch (ex) {
				return console.error(ex);
			}

			if (res.gone) {
				this._attachedInfo.classList.add('collapse');
				this._goneInfo.classList.remove('collapse');
				dispatchResizeDebounced(this);

				return;
			}

			this._attachedInfo.classList[res.attached ? 'remove' : 'add']('collapse');
			this._hookUrl.textContent = res.address;
			this._hookInfo.classList[!res.attached ? 'remove' : 'add']('collapse');

			dispatchResizeDebounced(this);

			if (autoLearn) {
				start();
			}
		};

		reloadHookInfo();

		this.addEventListener('input', () => reloadHookInfo());
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				if (instructions.options.learning) this._autoLearn = true;

				await this.addOption(event.detail.data.hook);
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const instructionsQuery = new URLSearchParams();
			const metadata = {};

			instructionsQuery.append('teamId', instructions.options.team);
			instructionsQuery.append('typeName', instructions.hookType);

			if (instructions.options?.team != null) {
				metadata.teamId = instructions.options.team;
			}

			await this.panel.build(
				{
					name: I18n.l('panels.hook.create'),
					help: 'kb://tools/webhooks',
					src: `${config.api.instructions.create.path}?${instructionsQuery}`,
					theme: instructions.theme,
				},
				metadata,
			);

			this.panel.open();
		};
	}

	_updateHandler(picker, instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(event.target);
			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const selected = picker.querySelector('imt-option[selected]');
			const metadata = { context: { hookId: selected.value } };

			if (instructions.options?.team != null) {
				metadata.teamId = instructions.options.team;
			}

			await this.panel.build(
				{
					name: selected.textContent,
					src: config.api.instructions.update.path,
					help: config.kb.path,
					theme: instructions.theme,
				},
				metadata,
			);

			this.panel.open();
		};
	}

	async stop(err, success) {
		if (!this._learning) return;
		this._learning = false;

		this._learnInfo.classList.add('collapse');
		this._learnButton.classList.remove('btn-danger');
		this._learnButton.classList.add('btn-info');
		this._learnButton.textContent = I18n.l('hooks.redetermine');

		if (!success) {
			clearTimeout(this._timer);

			try {
				await Loader.load(config.api.learnStop.path, {
					method: config.api.learnStop.method,
					context: {
						hookId: this.value,
					},
				});
			} catch (ex) {
				console.error(ex);
			}
		} else {
			const notification = create('small.text-success.mr-3');

			notification.append(create('i.far.fa-check-circle.mr-2'), I18n.l('hooks.determined'));

			this._learnButton.parentNode.replaceChild(notification, this._learnButton);
		}

		dispatchResizeDebounced(this);
	}

	disconnectedCallback() {
		this.stop();
	}
}

registerCustomElement('imt-input-hook', HookInput);
