import { TextInput } from './text.mjs';
import { registerCustomElement } from '../utils.mjs';

export class FilenameInput extends TextInput {
	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		if (this._instructions.extension) {
			instructions.extension = this._instructions.extension;
		}

		return instructions;
	}
}

registerCustomElement('imt-input-filename', FilenameInput);
