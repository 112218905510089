import Loader from '../loader.mjs';
import { Input } from './input.mjs';
import configs from '../configs/config.mjs';
import {
	animateElementHeight,
	clone,
	create,
	createInput,
	dispatchResizeDebounced,
	getStateObject,
	isAttribute,
	registerCustomElement,
	showNestedFieldsets,
	uuid,
	is,
	deepEqual,
	getScrollParent,
	debounce,
} from '../utils.mjs';
import EVENTS from '../events.mjs';
import { IML } from '../deps/iml.mjs';

const config = configs.fields.array;
const DRAG_SCROLL_TRESHOLD = 20;

export class ArrayInputButton extends HTMLElement {
	/**
	 * Build an array item.
	 *
	 * @param {Array} instructions Array of instructions.
	 * @param {*} value Item value.
	 * @param {Object} metadata Item metadata.
	 * @param {Boolean} primitive Whether the array item is of primitive type or not.
	 * @param {Number} index Ordinal number of the item. Starts with 0.
	 */

	async build(instructions) {
		const add = create(`button[type="button"] ${(instructions.labels || {}).add || 'Add item'}`);

		add.addEventListener('click', async () => {
			const parent = this.closest('[type="array"]');

			if (!parent || parent.hasAttribute('readonly') || parent.hasAttribute('disabled')) return;

			await parent.closest('[type="array"]').addItem(undefined, {}, true, false, true);
			this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		});
		this.appendChild(add);
	}
}

export class ArrayInputItem extends HTMLElement {
	get disabled() {
		return isAttribute(this, 'disabled');
	}

	set disabled(value) {
		if (value === this.disabled) return;

		if (value) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}

		this.querySelectorAll('[field]').forEach((field) => {
			field.disabled = value;
		});
	}

	/**
	 * Build an array item.
	 *
	 * @param {Array} instructions Array of instructions.
	 * @param {*} value Item value.
	 * @param {Object} metadata Item metadata.
	 * @param {Boolean} primitive Whether the array item is of primitive type or not.
	 * @param {Number} index Ordinal number of the item. Starts with 0.
	 * @param {String} label Array item label.
	 */

	async build(instructions, value, metadata, primitive, index, label) {
		const array = this.closest('[type="array"]');
		let timeout = null;

		this.setAttribute('type', 'array-item');
		if (primitive) {
			// Build primitive input
			const itemInstructions = clone(instructions);
			const restore = itemInstructions.type === 'collection' ? metadata.restore : metadata.restore?.value;
			const itemMetadata = restore ? Object.assign({}, metadata, { restore }) : metadata;

			metadata.restore = metadata.restore?.value;
			this._primitive = primitive;

			this._input = createInput(itemInstructions.type || 'text');
			itemInstructions.label = `${label} ${index + 1}`;
			this.appendChild(this._input);
			await this._input.build(itemInstructions, value, itemMetadata);
		} else {
			// Build collection for complex input

			const restore = metadata.restore && { nested: metadata.restore };
			const itemMetadata = restore ? Object.assign({}, metadata, { restore }) : metadata;

			this._input = createInput('collection');
			this.appendChild(this._input);
			await this._input.build(
				{
					type: 'collection',
					label: `${label} ${index + 1}`,
					spec: instructions,
					mappable: false, // TODO It would be great to allow mapping of a single array items but it requires a different restore structure that is not compatible with the current one.
				},
				value,
				itemMetadata,
			);
		}

		this._registerArrayItemControls(array);

		this.addEventListener('input', (event) => {
			if (timeout) {
				clearTimeout(timeout);
			}

			timeout = setTimeout(() => showNestedFieldsets(array, null, null, false, array._nested), config.nestedTimeout);
		});
	}

	get index() {
		let index = 0;
		let node = this.previousSibling;

		while (node) {
			if (node instanceof ArrayInputItem) index++;
			node = node.previousSibling;
		}
		return index;
	}

	get path() {
		const parent = this.parentNode && this.parentNode.closest('[type="array"]');

		if (!parent) return `[${this.index}]`;
		return `${parent.path}[${this.index}]`;
	}

	get value() {
		return this._input.value;
	}

	getState() {
		// Return state only for array not for UDT
		if (this.nodeName !== 'IMT-INPUT-ARRAY-ITEM') return;

		const input = this.closest('[type="array"]');
		const collection = this._primitive && input._instructions.spec.type === 'collection';

		// Get state of single items if primitive array or get collection state but use only collection's nested
		return this._primitive && !collection ? getStateObject(this.childNodes) : this._input.getState()?.nested;
	}

	/**
	 * @deprecated
	 * @return {{}|*}
	 * @private
	 */
	_getDomainDefaultValues() {
		const parent = this.parentNode?.closest('[type="array"]');

		if (!parent) return {};

		try {
			return parent.form.domains.getDefaults(parent.fieldset.domain)?.values[parent.name][this.index];
		} catch (ex) {
			return {};
		}
	}

	_registerArrayItemControls(array) {
		const remover = create('button.form-remover[type="button"]');
		const dragDropBtn = create('div.form-drag-drop');

		dragDropBtn.setAttribute('draggable', true);
		this._input._actions.appendChild(remover);
		remover.addEventListener('click', async () => {
			this._input.form.values.delete(this._input.fieldset.domain, this.path);
			await this.closest('[type="array"]').removeItem(this);
		});

		this._input._actions.appendChild(dragDropBtn);
	}
}

export class ArrayInput extends Input {
	constructor() {
		super();
		this._itemSelector = 'imt-input-array-item';
		this._itemLabel = 'Item';
		this._buttonSelector = 'imt-input-array-button';
		this._removeItemsOnRemove = true;
	}

	get hasModeSwitch() {
		return this.mappable;
	}

	get value() {
		if (this.mode === 'map') {
			return IML.stringify(IML.replace(IML.parse(this._coder.value), 'keyword', 'erase', 'erasearray'));
		}

		const values = [];

		for (const child of this._list.childNodes) {
			if (child.getAttribute('type', 'array-item')) {
				values.push(child.value);
			}
		}
		return values;
	}

	set value(value) {
		if (this.mode === 'map') {
			if ('string' === typeof value) {
				this._coder.value = IML.stringify(IML.replace(IML.parse(value), 'keyword', 'erasearray', 'erase'));
			} else {
				this._coder.value = value;
			}
			return;
		}

		if (!Array.isArray(value)) return;

		let valueChanged = false;
		const currentValue = this.value;

		if (Array.isArray(currentValue)) {
			valueChanged = this.value.length !== value.length || !deepEqual(value, this.value);
		}

		// Erase current items and recreate them only when the value has changed
		if (valueChanged) {
			this._list.innerHTML = '';

			for (const val of value) {
				this.addItem(val);
			}
		}
	}

	async addItem(value, metadata = {}, validate = true, skipAnimation = false, ensureEmpty = false) {
		const element = create(this._itemSelector);
		const index = this._list.childNodes.length;

		// Ugly hack that prevents bidirectional binding default value to be set
		if (ensureEmpty && typeof value === 'undefined')
			this.form.values.delete(this.fieldset.domain, `${this.path}[${index}].value`);

		this._list.appendChild(element);
		await element.build(this._instructions.spec, value, metadata, this._primitive, index, this._itemLabel);

		if (validate) this._runValidators();

		if (!skipAnimation) {
			animateElementHeight(element, { timing: 200, timingFunction: 'ease-in', emitEvent: true }).then(() => {
				element.style.transition = '';
			});
		}

		await showNestedFieldsets(this, null, null, false, this._nested);
		return element;
	}

	async removeItem(item) {
		if (!item || item.parentNode !== this._list) return;
		await animateElementHeight(item, { hide: true, timing: 200, timingFunction: 'ease-out', emitEvent: true });
		this._list.removeChild(item);

		if (this._removeItemsOnRemove) {
			this._list.childNodes.forEach((x, i) => {
				x.querySelector('label > span').innerHTML = `${this._itemLabel} ${i + 1}`;
			});
		}

		await showNestedFieldsets(this, null, null, false, this._nested);
		this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		dispatchResizeDebounced(this);
	}

	/**
	 * Moves array item to a new position
	 * @param {ArrayInputItem} item Item that should be moved
	 * @param {number} newPosition Index this item should be moved to
	 */

	moveItem(item, newPosition) {
		const itemPosition = Array.from(this._list.childNodes).indexOf(item);

		this._list.insertBefore(item, this._list.childNodes[itemPosition > newPosition ? newPosition : newPosition + 1]);
	}

	getItem(index) {
		return this._list.childNodes[index];
	}

	hideItems() {
		if (this._itemsHidden) return;
		this._itemsHidden = true;

		for (const item of this._list.querySelectorAll(':scope > [type="array-item"] > .form-group')) {
			item._wasCollapsed = item.collapsed;
			item.collapsed = true;
		}
	}

	showItems(restore = false) {
		if (!this._itemsHidden) return;
		this._itemsHidden = false;

		for (const item of this._list.querySelectorAll(':scope > [type="array-item"] > .form-group')) {
			item.collapsed = restore ? item._wasCollapsed : false;
		}
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this.setAttribute('type', 'array');

		if (typeof instructions.spec === 'string') {
			// Spec is RPC, resolve
			const form = this.closest('imt-forman');
			const context = form ? form.meta.imlContext : {};

			instructions.spec = await Loader.load(instructions.spec, { context });
		}

		if (!instructions.spec || (Array.isArray(instructions.spec) && instructions.spec.length === 0)) {
			// Default spec for array = text primitive
			this._emptySpec = instructions.spec?.length === 0;
			instructions.spec = { type: 'text' };
		}

		if (!Array.isArray(instructions.spec)) {
			// If spec is an object, this is a primitive array
			this._primitive = true;
			instructions.spec.name = 'value';
			if (typeof instructions.spec.label !== 'undefined') this._itemLabel = instructions.spec.label;
		}

		this._list = create('div.list');
		this.appendChild(this._list);

		const addButton = create(this._buttonSelector);

		this.appendChild(addButton);
		await addButton.build(instructions);

		if (instructions.rpc) {
			const rpcButton = create(`button.array-rpc-button[type="button"] ${instructions.rpc.label}`);

			rpcButton.addEventListener('click', this._rpcSearchHandler(rpcButton, instructions.rpc, true));
			addButton.append(rpcButton);
		}

		if (instructions.nested) {
			this._nested = create('imt-nested');
			this.appendChild(this._nested);
		}

		if (Array.isArray(value)) {
			const itemsRestore = metadata?.restore?.items;

			for (const [i, val] of value.entries()) {
				await this.addItem(
					val,
					{
						context: metadata.context,
						restore: Array.isArray(itemsRestore) && itemsRestore[i],
					},
					false,
					true,
				);
			}
		} else if ('undefined' !== typeof value) {
			// Backward compatibility with Formula, it automatically expects if the value is not an array it's a mapped value
			if (!metadata.restore) metadata.restore = {};
			metadata.restore.mode = 'map';
		}

		this.id = uuid();
		showNestedFieldsets(this, metadata?.fieldset?.value, metadata?.fieldset?.metadata);
	}

	isInternalChangeEvent(event) {
		if (this.mode === 'map') {
			return false;
		}

		if (!event.target) {
			return true;
		}

		if (event.target === this || (is(event.target, 'imt-input-array-button') && event.target.parentElement === this)) {
			return false;
		}

		return true;
	}

	validate() {
		this.untouched = false;
		if (this._list?.childNodes) {
			this._list.childNodes.forEach((item) => {
				item._input.untouched = false;
				if (item._input.validate) {
					item._input.validate();
				}
			});
		}

		this._runValidators();

		return this.valid;
	}

	/**
	 * Performs value validation for type defined by this input field.
	 *
	 * @param {*} value Value to validate, same as getting `this.value`.
	 * @param {Array} problems Array of strings containing all generated validation problems.
	 * @returns {boolean} Method can return false to indicate this field is no valid without providing a reason. Otherwise, return value is ignored.
	 */

	_validate(value, problems) {
		if (this._instructions.required && !value.length) {
			problems.push('Field must not be empty.');
		}

		super._validate(value, problems);

		// Ignore rest of validation in map mode
		if (this.mode === 'map') return;

		this.empty = !this._list.querySelector(':scope > [type="array-item"] > *:not(.empty)');

		if (this._list.querySelector(':scope > [type="array-item"] > *[invalid]')) {
			return false; // False means display validation problem, but with no additional message
		}
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		// Not possible to resolve instructions from DOM because array can be empty

		function normalizeInstructions(instructions) {
			function normalize(instructions) {
				if (!instructions) return;

				// Remove all RPC strings
				if (typeof instructions === 'string') return;

				if (Array.isArray(instructions)) {
					return instructions.map(normalizeInstructions);
				}

				instructions = clone(instructions, true);

				// Clean up auxiliary properties
				[
					'help',
					'coder',
					'buttons',
					'on',
					'advanced',
					'editable',
					'default',
					'prefix',
					'erasable',
					'showErasePill',
					'mappable',
					'multiline',
				].forEach((prop) => {
					if (typeof instructions[prop] !== 'undefined') delete instructions[prop];
				});

				if (instructions.required === false) delete instructions.required;

				if (typeof instructions.nested === 'string') delete instructions.nested;

				// Select options should be always present
				if (typeof instructions.options === 'string') {
					instructions.options = [];
					instructions.dynamic = true;
				}
				if (typeof instructions.options?.store === 'string') {
					instructions.options.store = [];
					instructions.dynamic = true;
				}

				if (instructions.options?.nested) delete instructions.options.nested; // Formula doesn't store nested global nested at all

				if (instructions.spec) {
					instructions.spec = normalizeInstructions(instructions.spec);
				}

				return instructions;
			}

			const out = normalize(instructions);

			return Array.isArray(out) ? out.filter((input) => input) : out;
		}

		if (this._instructions.spec) {
			if (this._emptySpec) {
				instructions.spec = [];
			} else {
				// let spec = this._list.childNodes[0]?.childNodes[0]?.getValidateInstructions()?.spec;
				const spec = normalizeInstructions(this._instructions.spec);

				if (spec) instructions.spec = spec;
			}
		}

		return instructions;
	}

	getState() {
		const state = super.getState();

		if (this.value.length) {
			const items = Array.from(this._list.childNodes).map((item) => item.getState());

			if (items.length) state.items = items;
		}

		return state;
	}

	getPreview() {
		if (this.mode !== 'map') {
			const fields = Array.from(this._list.childNodes);

			return fields
				.slice(0, 50) // take only a small slice to avoid computing unnecessarily long strings
				.filter((f) => f._input instanceof Input)
				.map((f) => {
					if (f._input._instructions.type === 'text' && !(f._input._coder || f._input._control)?.containsIML) {
						return `"${f._input.getPreview()}"`;
					} else if (f._input._instructions.type === 'collection') {
						return `{ ${f._input.getPreview()} }`;
					}

					return f._input.getPreview();
				})
				.join(', ');
		}

		return super.getPreview();
	}

	_setDisabled(disabled) {
		this._list.querySelectorAll(':scope > [type="array-item"]').forEach((item) => {
			item.disabled = disabled;
		});
	}

	_toCoderValue(value) {
		if (this._primitive && Array.isArray(value)) {
			return value.join(',');
		}

		super._toCoderValue(value);
	}

	_fromCoderValue(value) {
		if (this._primitive && typeof value === 'string') {
			if (value.trim() === '') {
				return [];
			}

			return value.split(',').filter((val) => val.trim() !== '');
		}

		super._fromCoderValue(value);
	}
}

function registerDragAndDrop() {
	// internal order of array elements for keeping track of current order of elements
	// contains objects with references to HTMLElement and Y axis bounds of that element (for resolving over which element is cursor currently hovering - this cannot be done by DOM API because of animations that would interfere)
	let draggingOrder = [];
	// offset on Y axis of item user is currently draging
	let previewElement = null;
	let array = null;
	let draggedElement = null;
	let draggedElementRect = null;
	let indexBeforeDragging = null;
	let scrollContainer = null;
	let originalScrollPosition = 0;

	const handleMouseMovementScroll = debounce((e, arrayHoverItem) => {
		if (!array) return;

		const containerRect = scrollContainer.getBoundingClientRect();
		const listContainer = array._list.getBoundingClientRect();
		let scrollDirection = null;

		// mouse should be in the array container, with some treshold
		if (listContainer.top - DRAG_SCROLL_TRESHOLD <= e.y && listContainer.bottom + DRAG_SCROLL_TRESHOLD >= e.y) {
			if (containerRect.top + DRAG_SCROLL_TRESHOLD >= e.y) {
				scrollDirection = 'top';
			} else if (containerRect.bottom - DRAG_SCROLL_TRESHOLD <= e.y) {
				scrollDirection = 'bottom';
			}
		}

		// there's nothing to scroll - mouse is not on the edge of the scroll container
		if (!scrollDirection) {
			return;
		}

		// only scroll when the mouse moves in a correct direction using the e.movementY delta
		if (scrollDirection === 'top' && e.movementY < 0) {
			scrollContainer.scrollTop -= arrayHoverItem.clientHeight / 4;
		} else if (scrollDirection === 'bottom' && e.movementY > 0) {
			scrollContainer.scrollTop += arrayHoverItem.clientHeight / 4;
		}

		// kdyz se pohne mysi hodne, prestane se z nejakeho duvodu scrollovat
		if (
			(scrollDirection === 'top' && scrollContainer.scrollTop > listContainer.top) ||
			(scrollDirection === 'bottom' && scrollContainer.scrollTop < listContainer.bottom)
		) {
			requestAnimationFrame(() => handleMouseMovementScroll(e, arrayHoverItem));
		}
	});

	document.addEventListener('mousedown', (e) => {
		const dndButton = is(e.target, 'div.form-drag-drop') ? e.target : e.target.closest('div.form-drag-drop');

		if (e.button === 0 && dndButton) {
			e.stopPropagation();
			draggedElement = dndButton.closest('[type="array-item"]');
			array = draggedElement.closest('[type="array"]');
			array.classList.add('forman-dragging-array');
			indexBeforeDragging = draggedElement.index;
			scrollContainer = getScrollParent(array, 'y');
			const itemRect = draggedElement.getBoundingClientRect();
			const arrayRect = array.getBoundingClientRect();

			// generate preview element, remove all controls from it and attach it to array
			// controls are removed to avoid unnecessary forman field initializations (they are not displayed anyway)
			array.hideItems();
			draggedElement?._input?._control?.panelController?.close();

			if (arrayRect.top < 0) {
				array.scrollIntoView();
			}
			originalScrollPosition = scrollContainer.scrollTop;
			dispatchEvent(new CustomEvent(EVENTS.FORM.RESIZE, { bubbles: true }));

			previewElement = draggedElement.cloneNode(true);
			previewElement.querySelectorAll('.form-control, imt-fieldset').forEach((el) => el.remove());
			previewElement.classList.add('forman-array-dragging-preview');
			// top/left offsets the preview element relative to mouse cursor (so that the cursor is placed the same as on the original element)
			previewElement.style.top = `${itemRect.top - e.clientY}px`;
			previewElement.style.left = `${itemRect.left - e.clientX + 18}px`;
			previewElement.style.width = `${itemRect.width}px`;
			// position is changed via transform as the top/left is used for offsetting the preview element
			previewElement.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
			array.appendChild(previewElement);

			draggedElement.classList.add('drop-zone');
			draggedElementRect = draggedElement.getBoundingClientRect();
			document.documentElement.classList.add('forman-dragging');

			// prevent click event on the item input so that the form-control doesn't expand when user drops an item on drop zone
			// this listener has to be removed when invoked
			draggedElement.querySelector(':scope > .form-group').addEventListener(
				'click',
				(e) => {
					e.preventDefault();
					e.stopPropagation();
				},
				{ once: true, capture: true },
			);

			draggingOrder = Array.from(array._list.childNodes).map((n) => {
				const rect = n.getBoundingClientRect();

				n.style.zIndex = '10';
				return {
					element: n,
					top: rect.top,
					bottom: rect.bottom,
					rect: rect,
				};
			});
			e.preventDefault();
		}
	});

	document.addEventListener('mouseup', (e) => {
		if (e.button === 0 && array && draggedElement) {
			array.moveItem(
				draggedElement,
				draggingOrder.findIndex((el) => el.element === draggedElement),
			);
			dragDropCleanup();
			e.preventDefault();
		}
	});

	document.addEventListener('mousemove', (e) => {
		if (array && draggedElement && e.button === 0) {
			const arrayHoverItem = findArrayHoverItem(e);

			previewElement.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
			if (arrayHoverItem && arrayHoverItem !== draggedElement) {
				const draggedIndex = draggingOrder.findIndex((item) => item.element === draggedElement);
				const itemIndex = draggingOrder.findIndex((item) => item.element === arrayHoverItem);
				const minIndex = Math.min(itemIndex, indexBeforeDragging);
				const maxIndex = Math.max(itemIndex, indexBeforeDragging);
				let draggedOffset = 0;

				// change order of the elements by moving the dragged item to the position of the item user is hovering over
				draggingOrder.splice(itemIndex, 0, draggingOrder.splice(draggedIndex, 1)[0]);

				for (let i = 0; i < draggingOrder.length; i++) {
					const item = draggingOrder[i];

					if (item.element !== draggedElement) {
						// reset transform to get rect without Y coords offset
						let offset = 0;

						if (i >= minIndex && i <= maxIndex) {
							if (maxIndex > indexBeforeDragging) {
								offset = -draggedElementRect.height;
								draggedOffset += item.rect.height;
							} else {
								offset = draggedElementRect.height;
								draggedOffset -= item.rect.height;
							}
						}

						item.element.style.transform = `translateY(${offset}px)`;
						item.top = item.rect.top + offset;
						item.bottom = item.rect.bottom + offset;
					}
				}

				draggedElement.style.transform = `translateY(${draggedOffset}px)`;
				draggingOrder[itemIndex].top = draggedElementRect.top + draggedOffset;
				draggingOrder[itemIndex].bottom = draggedElementRect.bottom + draggedOffset;
			}

			if (arrayHoverItem) {
				handleMouseMovementScroll(e, arrayHoverItem);
			}
		}
	});

	document.addEventListener('keydown', (e) => {
		if (e.key === 'Escape' && array && draggedElement) {
			dragDropCleanup();
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();
		}
	});

	function findArrayHoverItem(e) {
		if (!array) return {};

		const arrayRect = array.getBoundingClientRect();
		let arrayHoverItem = null;
		const scrollDelta = originalScrollPosition - scrollContainer.scrollTop;

		if (arrayRect.top <= e.clientY && arrayRect.bottom >= e.clientY) {
			arrayHoverItem = draggingOrder.find(
				(item) => item.top + scrollDelta <= e.clientY && item.bottom + scrollDelta >= e.clientY,
			)?.element;
		} else if (arrayRect.top < e.clientY) {
			arrayHoverItem = draggingOrder[draggingOrder.length - 1].element;
		} else if (arrayRect.bottom > e.clientY) {
			arrayHoverItem = draggingOrder[0].element;
		}

		return arrayHoverItem;
	}

	function dragDropCleanup() {
		draggingOrder.forEach((item) => {
			item.element.style.transform = '';
			item.element.style.zIndex = '';
		});
		previewElement.remove();
		draggedElement.classList.remove('drop-zone');
		document.documentElement.classList.remove('forman-dragging');
		array.showItems(true);
		array.classList.remove('forman-dragging-array');
		dispatchResizeDebounced(array);

		draggingOrder = [];
		previewElement = null;
		array = null;
		draggedElement = null;
		indexBeforeDragging = null;
		draggedElementRect = null;
		scrollContainer = null;
		originalScrollPosition = 0;
	}
}

registerCustomElement('imt-input-array-button', ArrayInputButton);
registerCustomElement('imt-input-array-item', ArrayInputItem);
registerCustomElement('imt-input-array', ArrayInput);
registerDragAndDrop();
