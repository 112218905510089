import { I18n } from '../helpers/i18n.mjs';

export class ValidationError extends Error {
	constructor(message, code, options) {
		super(ValidationError.l(message, code, options));
		this.name = 'ValidationError';
		this.code = code;

		if (Error.captureStackTrace) Error.captureStackTrace(this, ValidationError);
	}

	static l(message, code, options) {
		if (!code) {
			return message;
		}
		options = options || {};
		options.defaultValue = message;

		return I18n.l(`validations:${code}`, options);
	}
}
