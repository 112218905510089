import { registerCustomElement } from '../utils.mjs';

export class Separator extends HTMLElement {
	/**
	 * Builds the separator dom.
	 *
	 * @param instructions {object} Collection of directives.
	 */

	async build(instructions) {
		this.setAttribute('title', instructions.label || '');
	}
}

registerCustomElement('imt-input-separator', Separator);
