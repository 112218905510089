import { registerCustomElement } from '../utils.mjs';

export class HTML extends HTMLElement {
	/**
	 * Builds the separator dom.
	 *
	 * @param instructions {object} Collection of directives.
	 */

	async build(instructions) {
		this.setAttribute('name', instructions.name || '');
		this.className = 'form-group';
		this.innerHTML = instructions.label;
	}
}

registerCustomElement('imt-input-html', HTML);
