/* global IMTDevTool */
import { IML } from './deps/iml.mjs';
import configs from './configs/config.mjs';

const config = configs.loader;

const PARSERS = {
	MANIFEST: class MANIFEST {
		constructor(data) {
			this.data = data;
		}

		parse(key, value) {
			if (/^rpc:\/\/(?:([^\/@]*)(?:@(\d+))?\/)?([^\/]+)$/.exec(value)) {
				const pkg = RegExp.$1;
				const ver = RegExp.$2;
				const mdl = RegExp.$3;

				value = `rpc://${pkg || encodeURIComponent(this.data['package-name'])}/${
					ver || this.data['package-version']
				}/${mdl}`;
			}

			return value;
		}
	},
};

export default {
	/**
	 * Calls API endpoint
	 * @param {string|URL} url relative API url
	 * @param {Object} options fetch request options
	 * @return {Promise<*|string>}
	 */

	async api(url, options = {}) {
		options.headers = options.headers || {};
		options.headers['imt-remote-formula'] = 1;

		if (shouldJsonify(options)) {
			options.headers['content-type'] = 'application/json; charset=utf-8';

			if ('string' !== typeof options.body) {
				options.body = JSON.stringify(options.body);
			}
		}

		return await this.fetch(getURL(url, 'api'), options);
	},

	/**
	 * Calls RPC endpoint
	 * @param {string|URL} url - relative RPC url
	 * @param {Object} options - fetch request options
	 * @return {Promise<*|string>}
	 */

	async rpc(url, options = {}) {
		options.method = 'POST';
		options.headers = options.headers || {};
		options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';

		return await this.fetch(getURL(url, 'rpc'), options, PARSERS.MANIFEST);
	},

	/**
	 * Fetch wrapper with additional settings
	 * @param {string|URL} url URL
	 * @param {Object} options fetch request options
	 * @param {Class} Parser parser class that handles parsing of the response while JSON.parse
	 * @return {Promise<*|string>}
	 */

	async fetch(url, options = {}, Parser) {
		if (!url) throw new Error('No URL provided.');

		options.headers = options.headers || {};
		options.headers.accept = 'application/json; charset=utf-8';

		let res;

		try {
			res = await fetch(url, options);
		} catch (ex) {
			if (ex.name === 'AbortError') {
				throw ex;
			} else {
				throw new Error(`Failed to connect to remote server. ${ex.message}`);
			}
		}

		// Temporary crippling network speed
		// await new Promise(resolve => setTimeout(resolve, 500));

		const body = await res.text();

		const imtHeaders = {};

		if (Parser) {
			for (const [key, value] of res.headers) {
				const name = key.match(/^x-imt-(.*)/);

				if (name) imtHeaders[name[1]] = value;
			}
		}

		return this.handleResponse(res, body, Parser ? new Parser(imtHeaders) : undefined);
	},

	/**
	 * Calls a specific endpoint with a specific method accordingly the provided URL
	 * @param {string} url  url to call based on the provided url the request method is selected
	 * @param {Object} config load config, not fetch options
	 * @param {string} [config.method=GET] request method
	 * @param {Object} [config.headers] request headers
	 * @param {*} [config.body] request body
	 * @param {Object} [config.signal] abort controller signal
	 * @param {string} [config.data] request payload to be applied according to the request method to qs or body
	 * @param {Object} [config.context] IML context for execution IML in URL
	 * @return {Promise<*|string>}
	 */

	async load(url, config = {}) {
		if (config.context) {
			url = IML.execute(IML.parse(url), config.context);
		}

		const options = {
			signal: config.signal,
			cache: 'no-store',
			method: config.method || 'GET',
			headers: config.headers || {},
			body: config.body,
		};

		if (/^api:\/\/(.*)$/.exec(url)) {
			const parsedUrl = getURL(RegExp.$1, 'api');

			if (options.method === 'GET' && config.data) {
				Object.entries(config.data).forEach(([key, value]) => {
					if (typeof value !== 'undefined' && !parsedUrl.searchParams.has(key)) {
						if (Array.isArray(value)) {
							if (value.length) {
								value.forEach((item) => parsedUrl.searchParams.append(key + '[]', item));
							}
						} else {
							parsedUrl.searchParams.append(key, value);
						}
					}
				});
			}

			return await this.api(parsedUrl, options);
		} else if (/^rpc:\/\/(.*)$/.exec(url)) {
			const { url, params } = qsToBody(RegExp.$1);

			options.body = JSON.stringify({
				data: Object.assign({}, config.data || {}, params),
			});

			return await this.rpc(url, options);
		} else {
			return await this.fetch(url, options);
		}
	},

	/**
	 * Handles HTTP response and processes API errors
	 * @param {Object} res fetch response object
	 * @param body parsed response body
	 * @param {Object} parser JSON parser of the response
	 * @return {*}
	 */

	handleResponse(res, body, parser) {
		if (res.status >= 400) {
			// We got an error body
			try {
				// Try parsing it as a json
				body = JSON.parse(body);
			} catch (ex) {
				body = {
					message: `[${res.status}] ${body}`,
				};
			}

			let message;

			if (res.status === 424 && body.suberrors?.length) {
				message = body.suberrors.map((err) => err.message).join(' ');
			} else {
				message = body.message || body.error?.message || res.statusText;
			}

			const err = new Error(message);

			err.code = body.code || body.error?.code || res.status;
			if (body.location) err.location = body.location;
			if (body.details) err.details = body.details;
			if (body.detail) err.detail = body.detail;
			if (body.suberrors) err.suberrors = body.suberrors;

			throw err;
		}

		try {
			body = parser ? JSON.parse(body, (key, value) => parser.parse(key, value)) : JSON.parse(body);
		} catch (ex) {
			throw new Error(`Failed to parse response body. ${ex.message}`);
		}

		// Log debug data to the browser's developer console
		this.logDebugData(body);

		return body.formula?.data || body.response || body;
	},

	/**
	 * Log response using DevTool or console.log
	 * @param {Object} data response body
	 */

	logDebugData(data) {
		let entry;

		const devToolConnected = typeof IMTDevTool !== 'undefined' && IMTDevTool.connected;

		if (Array.isArray(data?.debug)) {
			for (entry of data.debug) {
				if (devToolConnected) {
					IMTDevTool.pushLiveStreamEvent(entry);
				}

				if (!devToolConnected || IMTDevTool.settings.console) {
					console.log('%c[rpc:debug]', 'color: #a4a9ae', ...Array.from(entry));
				}
			}
		}

		if (Array.isArray(data?.log)) {
			for (entry of data.log) {
				if (devToolConnected) {
					IMTDevTool.pushLiveStreamEvent(entry);
				}

				if (!devToolConnected || IMTDevTool.settings.console) {
					console.log(`[rpc:log] ${entry}`);
				}
			}
		}
	},
};

/**
 * Parses parameters from qs and returns them as Object together with cleared URL
 * @param {string} url
 * @return {{params: {}, url}}
 */

function qsToBody(url) {
	const query = url.indexOf('?');
	const params = {};

	if (query !== -1) {
		try {
			for (const param of url.match(/(\?|\&)[^\&\#\=]+=[^\&\#]+/g)) {
				const qs = /^[\?\&]([^=]+)=(.*)$/g.exec(param);

				// is the parameter already defined?
				if (params[qs[1]] != null) {
					// mutliple params of the same name
					if (!Array.isArray(params[qs[1]])) {
						// convert value to an array if not already converted
						params[qs[1]] = [params[qs[1]]];
					}

					// add value to an array
					params[qs[1]].push(qs[2]);
				} else {
					params[qs[1]] = qs[2];
				}
			}
		} catch (ex) {
			console.warn('Failed to parse query string to post data.');
		}

		return {
			url: url.substr(0, query),
			params,
		};
	}

	return {
		url,
		params: {},
	};
}

/**
 *
 * @param {string|URL} url relative or absolute URL
 * @param {('api'|'rpc')} type[api] based on the type the relative URL is prefixed with the path according the config
 * @return {URL}
 */

function getURL(url, type) {
	// Absolute path
	if (url instanceof URL) return url;
	if (/^http/.test(url)) return new URL(url);

	// Relative path
	return new URL(type === 'rpc' ? `${config.base.rpc}/${url}` : `${config.base.api}/${url}`, window.location.origin);
}

function shouldJsonify(options) {
	return (
		!(options.body instanceof FormData) &&
		!Object.keys(options.headers)
			.map((k) => k.toLowerCase())
			.includes('content-type') &&
		['POST', 'PATCH', 'DELETE', 'PUT'].includes(options.method.toUpperCase())
	);
}
