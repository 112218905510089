import RemoteForm from './remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import configs from '../configs/config.mjs';

const config = configs.fields.account;

export default class ConnectionManager extends EventTarget {
	static async getCreateConnectionPanel(instructions, target) {
		const panel = new RemoteForm(target);

		const instructionsQuery = new URLSearchParams();

		instructionsQuery.append('teamId', instructions.options.team);
		instructions.connectionTypes.forEach((acc) => instructionsQuery.append('type', acc.type));

		await panel.build({
			name: I18n.l('panels.account.create'),
			help: (target && target.closest('imt-panel')?.help) || this.form?.help || config.kb.path,
			src: `${config.api.instructions.path}?${instructionsQuery}`,
			options: {
				oauth: {
					scope: instructions.connectionTypes,
				},
			},
			theme: instructions.theme,
		});

		return {
			handler: panel,
			dom: panel.panel,
		};
	}
}

window.FormanConnectionManager = ConnectionManager;
