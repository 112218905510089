/* eslint-env node */

const wrapper = {
	_library: null,
	encodingExists(encoding) {
		if (wrapper._library) return wrapper._library.encodingExists(encoding);
		return false;
	},
	encode(value, encoding) {
		if (wrapper._library) return wrapper._library.encode(value, encoding);
		return value;
	},
	decode(value, encoding) {
		if (wrapper._library) return wrapper._library.decode(value, encoding);
		return value;
	},
};

export default wrapper;
