import { CertInput } from './cert.mjs';
import { create, registerCustomElement } from '../utils.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import config from '../configs/config.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class PkeyInput extends CertInput {
	extendInstructions(instructions, value, metadata) {
		const button = create(`button.btn.btn-outline-secondary Extract`);

		button.addEventListener('click', async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(button);

			await this.panel.build({
				name: I18n.l('buttons.extract'),
				src: `${config.fields.pkey.api.instructions.path}?type=pkey`,
				theme: instructions.theme,
			});

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, (event) => {
				this.disabled = false;

				if (Array.isArray(event.detail.data) && event.detail.data[0]) {
					this.value = event.detail.data[0];
					this.onChange();
				}
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			this.panel.open();
		});

		instructions.multiline = true;
		instructions.buttons = [button];
		return instructions;
	}
}

registerCustomElement('imt-input-pkey', PkeyInput);
