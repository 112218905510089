import Loader from '../loader.mjs';
import './switch.scss';
import { create, registerCustomElement } from '../utils.mjs';

/**
 * @emits beforeinput Emitted before the change occurs. Cancelable. Does not bubble.
 */

export class Switch extends HTMLElement {
	constructor() {
		super();

		this.addEventListener('keyup', (event) => {
			if (event.keyCode === 32) {
				// space
				this.toggle();
				event.stopPropagation();
				event.preventDefault();
			}
		});

		this.addEventListener('click', (event) => {
			this.toggle();
			event.stopPropagation();
			event.preventDefault();
		});
	}

	static get observedAttributes() {
		return ['active'];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'active') {
			if (newValue) {
				// Activate switch
			} else {
				// Deactivate switch
			}
		}
	}

	connectedCallback() {
		// Make switch focusable
		this.setAttribute('tabindex', 0);

		if (!this._slider) {
			this._slider = this.querySelector('div.imt-switch-slider') || create('div.imt-switch-slider');
			this.appendChild(this._slider);
		}
	}

	get active() {
		return this.hasAttribute('active');
	}

	set active(value) {
		if (this.hasAttribute('disable')) {
			throw new Error('Switch is disabled.');
		}

		if (value) {
			this.setAttribute('active', '');
		} else {
			this.removeAttribute('active');
		}
	}

	get disabled() {
		return this.hasAttribute('disabled');
	}

	set disabled(value) {
		if (value) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}
	}

	async toggle() {
		if (this.hasAttribute('disable')) return;

		const redirect = this.hasAttribute('redirect');
		const form = this.closest('imt-forman');
		const context = form ? form.meta.imlContext : {};

		// Allow cancelation of the click event by the outside world
		const event = new CustomEvent('beforeinput', { cancelable: true });

		this.dispatchEvent(event);
		if (event.defaultPrevented) return;

		if (this.active) {
			const url = this.dataset['stop-url'];

			if (url) {
				this.disabled = true;

				if (redirect) {
					window.location.href = url;
					return;
				}

				try {
					await Loader.load(url, { context });
					this.disabled = false;
					this.active = false;
				} catch (err) {
					document.dispatchEvent(new CustomEvent('imt.flash', { detail: err }));
				}
			} else {
				this.active = false;
			}
		} else {
			const url = this.dataset['start-url'];

			if (url) {
				this.disabled = true;

				if (redirect) {
					window.location.href = url;
					return;
				}

				try {
					await Loader.load(url, { context });
					this.disabled = false;
					this.active = true;
				} catch (err) {
					document.dispatchEvent(new CustomEvent('imt.flash', { detail: err }));
				}
			} else {
				this.active = true;
			}
		}

		this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
	}
}

registerCustomElement('imt-switch', Switch);
