/* eslint-env node */

const wrapper = {
	_library: typeof global !== 'undefined' ? global.Buffer : null,
	from(...args) {
		if (wrapper._library) return wrapper._library.from(...args);
		return null;
	},
};

export default wrapper;
