/* global sim */
import './pill.mjs';
import { create, is, removeDiacritics, registerCustomElement, createDateTimePicker } from '../utils.mjs';
import {
	astToDOM,
	domToAST,
	createPill,
	SPACER,
	resolveFullDOM,
	getIMLCategory,
	IML_CATEGORIES,
} from '../helpers/iml.mjs';
import { I18n } from '../helpers/i18n.mjs';
import './coder.scss';
import configs from '../configs/config.mjs';
import { IML } from '../deps/iml.mjs';

const config = configs.coder;

const RE_IML_GLOBAL = /{{([\s\S]*?)}}/g;

class CoderPanelController {
	constructor(Coder, form) {
		this.panel = null;
		this.form = form;

		this.variables = null;
		this.pills = null;

		this.state = {
			onPanel: false,
			keepFocused: false,
			onSearchInput: false,
			lastActiveCoder: null,
			lastActiveCoderCaretOffset: 0,
		};

		this._hintPanelTimer = null;

		this._bestPanelMasterCollapse = null;

		this._prebuildCommonPanelContent(Coder, form);
	}

	_prebuildCommonPanelContent(Coder, form) {
		const isTemplater = form.parentElement?.classList?.contains('is-templater');

		this.pillsHeader = create('ul.nav.nav-tabs');
		this.pillsBody = create('div.tab-content');

		const bestNav = create('li.nav-item');

		bestNav.append(create(`a.nav-link[href="#pills-best"][data-toggle="tab"][data-history="no"]`));

		const bestPane = create('div#pills-best.tab-pane.tree');
		const search = bestPane.appendChild(create('input.form-control.coder-search[placeholder="Search items"]'));

		// search.addEventListener('focusout', (event) => {
		// 	this.state.onSearch = false;
		//
		// 	if (!this.state.onPill) {
		// 		this.focus();
		// 	}
		// });

		this._attachHintPanel();

		search.addEventListener('input', (event) => {
			this._hideHintPanel(this._hintPanel, true);

			bestPane
				.querySelectorAll('.coder-pill-row, .coder-pill-container')
				.forEach((row) => row.classList.remove('search-hide'));

			const imlMatches =
				bestPane.querySelector('div.search-iml-matches') ||
				bestPane.appendChild(create('div.search-iml-matches.mt-3'));

			imlMatches.innerHTML = '';

			const phrase = event.target.value;
			let words = removeDiacritics(phrase.trim().toLowerCase());

			if (words && words.length) {
				words = words.split(' ');

				bestPane.querySelectorAll('.coder-pill-row').forEach((elm) => {
					const text = (
						(elm.querySelector('imt-pill').getAttribute('prefix') || '') + removeDiacritics(elm.textContent)
					).toLowerCase();
					const lookup = words.filter((word) => !text.includes(word));

					if (lookup.length) {
						// Hide not matching pills
						elm.classList.add('search-hide');
					} else if (elm.matches('.coder-pill-row-nested')) {
						// Keep parent pill visible if child matches
						let currentLevel = parseInt(elm.getAttribute('level'));
						let e = elm.previousElementSibling;

						while (e) {
							// [level="${level - 1}"] -> look only for parents not for sibling objects/collections
							if (e.matches(`.coder-pill-row-has-children[level="${currentLevel - 1}"]`)) {
								e.classList.remove('search-hide');
								this._togglePill(e, false);

								currentLevel = parseInt(e.getAttribute('level'));
							}

							if (currentLevel === 0) break;

							e = e.previousElementSibling;
						}
					}
				});

				// Hide containers with no matches or expand matches
				bestPane.querySelectorAll('.coder-pill-container').forEach((elm) => {
					let found = false;

					let e = elm.querySelector('.coder-pill-row');

					while (e && e.matches('.coder-pill-row')) {
						if (!e.classList.contains('search-hide')) found = true;
						e = e.nextElementSibling;
					}

					if (!found) {
						elm.classList.add('search-hide');
					} else {
						elm.classList.remove('search-hide');
						elm.classList.remove('collapsed');
					}
				});

				// SEARCH
				IML_CATEGORIES.concat(this.form?.meta?.imlOptions?.variables?.map(x => x.category) || []).forEach((type) => {
					if (Coder[type]) {
						const matches = Object.entries(Coder[type])
							.filter(([name, elm]) => {
								const text = removeDiacritics(elm.textContent.toLowerCase());
								const lookup = words.filter((word) => !text.includes(word));

								return !lookup.length;
							})
							.map(([name, elm]) => {
								const clone = elm.cloneNode(true);

								if (type === 'variables') {
									const row = create('div.coder-pill-row');

									row.append(clone);
									return row;
								} else {
									return clone;
								}
							});

						// match.every.. -> template fix
						if (
							matches.length &&
							(!isTemplater ||
								matches.every(
									(elm) => !is(elm, '.custom-variable') || elm.hasAttribute('custom-iml_system'),
								))
						) {
							const customType = this.form?.meta?.imlOptions?.variables?.find(x => x.category === type);

							imlMatches.append(create(`h5 ${customType?.label || type.replace('_', ' ')}`));

							const groupWrapper = imlMatches.appendChild(create('div.coder-pill-group'));

							groupWrapper.append(...matches);
						}
					}
				});
			}
		});

		this.pillsHeader.append(bestNav);
		this.pillsBody.append(bestPane);

		const tabs = ['general', 'math', 'string', 'date', 'array'];

		const customCategories = this.form.meta.imlOptions?.tabs || [];
		const customTabs = customCategories.map(x => x.name) || [];

		tabs.concat(customTabs).forEach((group) => {
			// Nav items
			const item = create('li.nav-item');
			const link = create(`a.nav-link[href="#pills-${group}"][data-toggle="tab"][data-history="no"]`);

			item.append(link);

			this.pillsHeader.append(item);

			// Tab panels
			const pane = create(`div#pills-${group}.tab-pane`);
			const customCategory = customCategories.find(x => x.name === group);

			if (customCategory) {
				pane.appendChild(create(`h4 ${customCategory.label}`));
				if (customCategory.extraElement) {
					pane.appendChild(customCategory.extraElement);
				}
			} else {
				pane.appendChild(create(`h4 ${I18n.l(`iml.${group}.label`, {}, true)}`));
			}

			if (group === 'date') {
				pane.appendChild(create('h5#pickerLabel'));
				this.datePickerContainer = pane.appendChild(create('div.coder-pill-group.coder-datepicker'));
			}

			const getCustoms = (type) => {
				return this.form.meta.imlOptions?.[type]?.filter(x => x.tab === group)?.map(x => x.category) || [];
			}

			const customVariables = getCustoms('variables');
			const customFunctions = getCustoms('functions');

			const types = IML_CATEGORIES.concat(customVariables.concat(customFunctions));
			types.forEach((type, index) => {
				if (Coder[type]) {
					if (types.indexOf(type) !== index) {
						return;
					}
					const col = Object.entries(Coder[type])
						.filter(([name, elm]) => {
							const elmGroup = elm.getAttribute('group');

							if (elmGroup && elmGroup.includes(',')) {
								const groups = elmGroup.split(',');

								return groups.includes(group);
							}

							return elmGroup === group;
						})
						.map((x) => [x[0], x[1].cloneNode(true)]);

					if (!col.length) return;

					const customType = this.form?.meta?.imlOptions?.variables?.find(x => x.category === type);

					pane.appendChild(create(`h5 ${customType?.label || type.replace('_', ' ')}`));

					const groupWrapper = pane.appendChild(create('div.coder-pill-group'));

					col.forEach(([name, elm]) => {
						if (type === 'variables') {
							const row = create('div.coder-pill-row');

							row.append(elm);
							groupWrapper.append(row);
						} else {
							groupWrapper.append(elm);
						}
					});
				}
			});

			this.pillsBody.appendChild(pane);
		});
	}

	_build() {
		if (this.panel) return;

		this.panel = create('imt-panel.coder-pills');
		this.panel.controller = this;
		this.panel.width = 350;
		this.panel.position = ['right', 'left'];
		this.panel.header.appendChild(this.pillsHeader);

		const close = create('button.close[type="button"]');

		close.append(create('i.far.fa-times'));
		close.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();
			this.panel.relative.focus();
			this.close();
		});
		this.panel.header.append(close);

		const help = create('button.help[type="button"]');

		help.append(create('i.fas.fa-question'));
		help.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();

			// Use existing kb:// resolver as it is out of Forman's scope

			if ('undefined' !== typeof sim) {
				sim(window).openHelp(config.kb.path);
			}
		});
		this.panel.header.append(help);

		const bestPane = this.pillsBody.querySelector('#pills-best');
		const recursiveLookup = (group, variables = []) => {
			group.pills.forEach((pill) => {
				variables.push(this.variables[pill.code]);

				if (pill.pills) recursiveLookup(pill, variables);
			});

			return variables;
		};

		const pills = this.pills.filter((group) => group.pills && group.pills.length);

		if (!pills.length) {
			this._setTab('general');
		} else {
			this._setTab('best');

			if (pills.length) {
				this._bestPanelMasterCollapse = bestPane.appendChild(create('imt-coder-panel-master-expander'));
			}

			pills.forEach((group, i) => {
				const pillsGroup = bestPane.appendChild(create('div.coder-pill-container'));

				// if (i > 2) pillsGroup.classList.add('collapsed');

				const title = pillsGroup.appendChild(create('div.coder-pill-container-title'));

				// TODO Send theme, name and enlarge in root not on every pill
				const referenceNode = group.pills[0].node;

				const icon = title.appendChild(create('div.coder-pill-container-icon'));
				const img = icon.appendChild(create('img'));

				img.setAttribute(
					'src',
					IML.execute(IML.parse(configs.common.icons.path), { name: referenceNode.package.name }),
				);
				icon.style.backgroundColor = group.pills[0].theme.toHex();

				const label = title.appendChild(create(`h4 ${group.name} `));

				label.append(create(`span.square ${group.id}`), ` - ${group.description}`);

				title.addEventListener('mouseover', (event) => (referenceNode.enlarged = true));
				title.addEventListener('mouseout', (event) => (referenceNode.enlarged = false));

				title.addEventListener('click', (event) => {
					pillsGroup.classList.toggle('collapsed');
					this._bestPanelMasterCollapse?.resolveMode();
				});

				recursiveLookup(group).forEach((variable) => {
					const row = create('div.coder-pill-row');

					row.setAttribute('level', variable.getAttribute('level'));

					if (variable.hasAttribute('nested')) {
						row.classList.add('coder-pill-row-nested', `coder-level-${variable.getAttribute('level')}`);
						row.setAttribute('nth', variable.getAttribute('nth'));

						if (
							variable.getAttribute('level') > 1 ||
							'collection' !== variable.getAttribute('parent-type')
						) {
							row.classList.add('out');
						}
					}

					if (
						parseInt(variable.getAttribute('level')) === 0 &&
						'collection' === variable.getAttribute('type')
					) {
						row.classList.add('open');
					}

					row.append(variable);

					if (variable.hasAttribute('has-children')) {
						row.classList.add('coder-pill-row-has-children');

						row.addEventListener('click', (event) => {
							if (event.target !== event.currentTarget) return;
							const collapse = row.classList.contains('open');

							this._togglePill(row, collapse);
						});
					} else {
						if (variable.sample) row.append(variable.sample);
					}

					pillsGroup.append(row);
				});
			});
		}

		this.panel.body.append(this.pillsBody);
	}

	open(coder) {
		this._build();

		// if the picker exists, remove it and recreate a new picker to maker sure it's always connected to correct input
		if (this.datePickerContainer.firstChild) {
			this.datePickerContainer.firstChild.remove();
			this.datePickerAbortController?.abort();
			this.datePickerAbortController = null;
		}
		const input = this.state?.lastActiveCoder?.closest('[field]');
		const { abortController } = createDateTimePicker(
			this.datePickerContainer.appendChild(create('div')),
			this.state.lastActiveCoder,
			{
				type: input._instructions.type,
				time: input._instructions.time,
				onChange: (event, formatedDate, oldFormattedDate) => {
					const caret = getCaret();

					if (!caret.node || caret.node.nodeType !== 3) return;

					if (event.oldDate) {
						const diff = event.date.diff(event.oldDate);
						const maxDiff = 1000 * 60 * 60 * 12; // 12 hours for AM/PM
						const prev = caret.node.textContent.substr(
							caret.offset - oldFormattedDate.length,
							oldFormattedDate.length,
						);

						// time was incremented, update current date instead of inserting new date when old date exists
						if (Math.abs(diff) <= maxDiff && prev === oldFormattedDate) {
							caret.node.textContent = caret.node.textContent.replace(oldFormattedDate, formatedDate);
							setCaret(caret.node, caret.offset + formatedDate.length);
							return;
						}
					}

					injectTextNode(caret.node, caret.offset, document.createTextNode(formatedDate));
					if (input.onChange) {
						input.onChange();
					}
				},
			},
		);

		this.datePickerAbortController = abortController;
		if (input._instructions.type === 'time') {
			this.datePickerContainer.parentNode.querySelector('#pickerLabel').textContent = I18n.l(
				`panels.datetimepicker.timeTitle`,
			);
		} else {
			this.datePickerContainer.parentNode.querySelector('#pickerLabel').textContent = I18n.l(
				`panels.datetimepicker.dateTitle`,
			);
		}

		if (this.panel.parentNode && this.panel.relative === coder) return; // Prevents open already opened panel
		this.panel.relative = coder; // Necessary for position of the coder
		this.panel.open();
	}

	close() {
		this._hideHintPanel(this._hintPanel, true);
		this.panel?.close();
	}

	_attachHintPanel() {
		this.form.addEventListener('mouseover', (event) => {
			if (event.target.nodeName !== 'IMT-PILL' || event.target.closest('.coder-hint') || event.target._hintPanel)
				return;

			const elm = event.target;
			let group = elm.getAttribute('group');
			const name = elm.getAttribute('name');
			const type = elm.getAttribute('type');

			if (elm._pill && elm._pill.node) elm._pill.node.enlarged = true;

			if (group && group.includes(',')) {
				group = group.split(',')[0];
			}

			if (!group || name == null) {
				const pill = elm._pill;

				if (!pill || !pill.name) {
					return;
				}

				return this._showHintPanel(elm, {
					name: pill.name,
					help: `Raw: ${pill.raw}`,
					type: pill.type,
				});
			}

			switch (true) {
				// Module variable
				case elm.hasAttribute('prefix'):
					return this._showHintPanel(elm, elm._pill);

				// IML Variable
				case elm.classList.contains('variable'):
					return this._showHintPanel(elm, {
						name,
						help: I18n.l(`iml.${group}.variables.${name}.help`),
						type,
					});

				case elm.classList.contains('function'):
					if (elm.classList.contains('custom-function')) {
						return this._showHintPanel(elm, {
							name: name + `(${elm.getAttribute('function-arguments')})`,
							help: elm.getAttribute('description'),
							type,
						});
					}

					return this._showHintPanel(elm, {
						name: I18n.l(`iml.${group}.functions.${name}.syntax`),
						help: I18n.l(`iml.${group}.functions.${name}.help`),
						example: I18n.l(`iml.${group}.functions.${name}.example`, {
							defaultValue: null,
							skipInterpolation: true,
						}),
						type,
					});

				case elm.classList.contains('operator'):
					return this._showHintPanel(elm, {
						name,
						help: I18n.l(`iml.${group}.operators.${name}.help`),
					});

				case elm.classList.contains('keyword'):
					this._showHintPanel(elm, {
						name: I18n.l(`iml.${group}.keywords.${name}.label`),
						help: I18n.l(`iml.${group}.keywords.${name}.help`),
					});

				case elm.classList.contains('custom-variable'):
					const help = elm.getAttribute('custom-variable_help');
					const value = elm.getAttribute('custom-variable_value');
					const variableType = elm.getAttribute('custom-variable_type');
					const isDynamicValue = ['executionId', 'dataConsumed', 'executionStartedAt', 'operationsConsumed'].includes(name);

					this._showHintPanel(elm, {
						name,
						help,
						type,
						variableType,
						variableValue: isDynamicValue ? { isDynamic: isDynamicValue } : value,
					});
			}
		});

		this.form.addEventListener('mouseout', (event) => {
			if (event.target.nodeName !== 'IMT-PILL') return;
			if (event.target._pill && event.target._pill.node) {
				event.target._pill.node.enlarged = false;
			}

			if (event.target?._hintPanel?.ignoreMouseLeave) {
				return;
			}

			this._hideHintPanel(event.target._hintPanel);
		});
	}

	_showHintPanel(relative, pill, immediately = false, ignoreMouseLeave = false, showCloseBtn = false) {
		if (this._hintPanel) {
			this._hideHintPanel(this._hintPanel, true);
		}

		clearTimeout(this._hintPanelTimer);

		this._hintPanelTimer = setTimeout(
			() => {
				const panel = create('imt-panel.coder-hint');

				if (showCloseBtn) {
					const panelClose = create('div.panel-close');
					const panelCloseBtn = create('button.close.hint-close');

					panelCloseBtn.appendChild(create('i.far.fa-times'));

					panelClose.appendChild(panelCloseBtn);
					panel.appendChild(panelClose);
				}

				// Create reference to panel in pill
				relative._hintPanel = panel;

				panel.width = 'auto';
				panel.position = relative.closest('#pills-best') ? ['left', 'right'] : ['top', 'bottom'];
				panel.relative = relative;
				panel.compact = true;
				panel.spacing = 9;
				panel._closeTimer = null;

				const relativeStyle = window.getComputedStyle(relative);

				panel.style.color = relativeStyle.getPropertyValue('color');
				panel.style.backgroundColor = relativeStyle.getPropertyValue('background-color');

				const header = create(`h6 ${pill.name}`);

				if (pill.args) {
					header.innerText += '(';
					pill.args.forEach((arg, index) => {
						const argElm = create(`span`);

						argElm.classList.add('argument');
						argElm.innerText = arg.trim();
						if (index < pill.args.length - 1) {
							argElm.innerText += ';';
						}

						if (pill.activeArg === index) {
							argElm.classList.add('active-argument');
						}

						header.append(argElm);
					});
					header.append(document.createTextNode(')'));

					panel.setActiveArg = (index) => {
						header.querySelectorAll('.argument').forEach((elm, idx) => {
							if (index === idx) {
								elm.classList.add('active-argument');
							} else {
								elm.classList.remove('active-argument');
							}
						});
					};
				}

				if (pill.type) {
					header.append(create(`small ${pill.type}`));
				}
				panel.body.append(header);

				if (pill.raw && pill.name !== pill.raw) {
					panel.body.append(create(`p.raw ${pill.raw}`));
				}

				if (pill.help) {
					panel.body.append(create(`p ${pill.help}`));
				}

				if (pill.variableValue || typeof pill.variableValue === 'boolean') {
					const p = create(`p.coder-hint-value`);

					p.append(create(`strong ${I18n.l('common.value')}`));
					if (pill.variableValue.isDynamic) {
						p.append(create(`i ${I18n.l('variables.dynamicValue')}`));
					} else {
						switch (pill.type) {
							case 'boolean':
								p.append(pill.variableValue.toString());
								break;
							default:
								p.append(pill.variableValue);
								break;
						}
					}

					panel.body.append(p);
				} else if (pill.variableType === 'scenario_variable' && ['id', 'name', 'url'].includes(pill.name) && pill.variableValue === null) {
					const p = create(`p.coder-hint-value`);

					p.append(create(`strong ${I18n.l('common.value')}`));
					p.append(create(`i ${I18n.l('variables.scenarioValueUnavailable')}`));
					panel.body.append(p);
				}

				if (pill.example) {
					for (let example of pill.example.trim().split(/\n/g)) {
						example = example.match(/^([^\{]+)(?:\{([^\}]*)\})?$/);
						if (example) {
							const pre = create('pre');
							const code = create(`code {{${example[1]}}}`);

							code.form = relative.form;
							pre.append(normalize(code, undefined, true, this.imlItems));

							if (example[2]) pre.append(create(`div =${example[2]}`));

							panel.body.append(pre);
						}
					}
				}

				panel.addEventListener('mouseenter', (event) => clearTimeout(panel._closeTimer));

				if (!ignoreMouseLeave) {
					panel.addEventListener('mouseleave', (event) => this._hideHintPanel(panel));
				} else {
					panel.ignoreMouseLeave = true;
				}

				panel.open();
				this._hintPanel = panel;
			},
			immediately ? 0 : 750,
		);
	}

	_hideHintPanel(panel, immediately = false) {
		clearTimeout(this._hintPanelTimer);
		if (!panel) return;

		const hide = () => {
			this._hintPanel = null;
			panel.close();
			panel.relative._hintPanel = null;
		};

		if (immediately) {
			hide();
			return;
		}

		panel._closeTimer = setTimeout(hide, 250);
	}

	_setTab(name) {
		this.pillsHeader.querySelector('a.nav-link.active')?.classList.remove('active');
		this.pillsBody.querySelector('div.tab-pane.active')?.classList.remove('active');

		this.pillsHeader.querySelector(`a[href="#pills-${name}"]`).classList.add('active');
		this.pillsBody.querySelector(`#pills-${name}`).classList.add('active');
	}

	/**
	 * Collapses/expands array or collection pill in coder panel
	 * @param {HTMLElement} row
	 * @param {boolean} collapse
	 * @private
	 */
	_togglePill(row, collapse) {
		if (collapse) {
			row.classList.remove('open');
		} else {
			row.classList.add('open');
		}

		const level = parseInt(row.getAttribute('level')) + 1;
		let elm = row;
		let index = 1;

		while (elm) {
			elm =
				elm.nextElementSibling && elm.nextElementSibling.matches('.coder-pill-row-nested')
					? elm.nextElementSibling
					: null;
			if (!elm) break;
			if (parseInt(elm.getAttribute('level')) < level) break;

			if (collapse) {
				if (elm.classList.contains('coder-pill-row-has-children')) {
					elm.classList.remove('open');
				}

				elm.classList.add('out');
			} else {
				if (parseInt(elm.getAttribute('level')) === level && parseInt(elm.getAttribute('nth')) === index) {
					elm.classList.remove('out');
					index++;
				}
			}
		}
	}
}

class CoderPanelExpander extends HTMLElement {
	constructor() {
		super();
		this._built = false;
		this._mode = null;
		this._container = null;
		this._collapseItemSelector = ':scope > div.coder-pill-container';
	}

	set container(value) {
		this._collapseContainer = value;
	}

	set collapseItemSelector(selector) {
		this._collapseItemSelector = selector;
	}

	get mode() {
		return this._mode;
	}

	set mode(value) {
		if (this._mode === value) return;

		this._mode = value;
		this.classList[value === 'expand' ? 'add' : 'remove']('collapsed');
		this._title.textContent = I18n.l(`buttons.${value}All`);
	}

	connectedCallback() {
		if (this._built) return;
		this._built = true;

		if (!this._container) this._container = this.parentElement;

		this._title = this.appendChild(create('div.coder-master-expander-title'));

		this.mode = 'collapse';

		this.addEventListener('click', (event) => {
			this._container.querySelectorAll(this._collapseItemSelector).forEach((elm) => {
				elm.classList[this._mode === 'expand' ? 'remove' : 'add']('collapsed');
			});

			this.mode = this.mode === 'expand' ? 'collapse' : 'expand';
		});
	}

	resolveMode() {
		const oneItemNotCollapsed = this._container.querySelector(this._collapseItemSelector + ':not(.collapsed)');

		if (oneItemNotCollapsed) this.mode = 'expand';
	}
}

registerCustomElement('imt-coder-panel-master-expander', CoderPanelExpander);

export class Coder extends HTMLElement {
	constructor() {
		super();

		this._built = false;
		this.panelEnabled = true;
		this.fnPanelEnabled = true;

		this.addEventListener('keydown', (event) => {
			if (event.key === 'Escape' && this.panelController?._hintPanel) {
				this.panelController._hideHintPanel(this.panelController._hintPanel, true);
				this.fnPanelEnabled = false;
				return;
			}

			if (this.hasAttribute('readonly') || this.hasAttribute('disabled')) {
				if (!event.ctrlKey && !event.metaKey && (event.which < 33 || event.which > 40)) {
					// Allow any key in combination with ctrl/command
					// Allow pageup/pagedown/home/end/arrow keys
					event.preventDefault();
					return;
				}
			}

			const caret = getCaret();

			if (!caret.node || !caret.collapsed) return;

			if (event.which === 37 || event.which === 8) {
				// Arrow left or backspace
				if (caret.offset > 0 && caret.node.textContent.charAt(caret.offset - 1) === SPACER) {
					// Move cursor to the left one place so we'll be jumping to the correct position
					setCaret(caret.node, --caret.offset, true);
				}
			} else if (event.which === 39 || event.which === 46) {
				// Arrow right or delete
				if (
					caret.offset < caret.node.textContent.length &&
					caret.node.textContent.charAt(caret.offset) === SPACER
				) {
					// Move cursor to the left one place so we'll be jumping to the correct position
					setCaret(caret.node, ++caret.offset);
				}
			}

			if (event.which === 8) {
				// Backspace when caret is collapsed (no range selection is active)
				if (caret.offset === 0 && caret.node.previousSibling && caret.node.previousSibling.nodeType === 1) {
					// Deleting a pill to the left of the cursor
					this.removeChild(caret.node.previousSibling);
					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				} else if (caret.offset === 1 && caret.node.length === 1 && !caret.node.nextSibling) {
					// Removing last char of a text node - automatically transform to SPACER.
					// Chrome by default moves cursor to first text node to the left. That's not what we want.
					caret.node.textContent = SPACER;
					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			} else if (event.which === 46) {
				// Delete Key when caret is collapsed (no range selection is active)
				if (
					caret.offset === caret.node.textContent.length &&
					caret.node.nextSibling &&
					caret.node.nextSibling.nodeType === 1
				) {
					// Deleting a pill to the right of the cursor
					this.removeChild(caret.node.nextSibling);
					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				} else if (caret.offset === caret.node.textContent.length - 1) {
					// In Chrome there's a (bug? behavior?) - when deleting last character in text node, the caret jumps to the next text
					// node automatically. That's why we're doing the removal manually.
					caret.node.textContent = caret.node.textContent.substr(0, caret.node.textContent.length - 1);
					event.preventDefault();
					setCaret(caret.node, caret.node.textContent.length);
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			}
		});

		this.addEventListener('keypress', (event) => {
			if (event.which === 13) {
				// Enter (new line)
				const caret = getCaret();

				if (!caret.node || caret.node.nodeType !== 3) return;

				injectTextNode(caret.node, caret.offset, document.createTextNode('\n'));

				event.preventDefault();
				this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
			} else if (event.which === 59) {
				// Semicolon (;)
				const caret = getCaret();

				if (!caret.node || caret.node.nodeType !== 3) return;

				if (isNodeNestedIn('function', caret.node)) {
					injectTextNode(caret.node, caret.offset, create('imt-pill.function.separator ;'));

					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			} else if (event.which === 41) {
				// Brace close )
				const caret = getCaret();

				if (!caret.node || caret.node.nodeType !== 3) return;

				if (isNodeNestedIn('function', caret.node)) {
					injectTextNode(caret.node, caret.offset, create('imt-pill.function.last )'));

					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			} else if (event.which === 93) {
				// Bracket close ]
				const caret = getCaret();

				if (!caret.node || caret.node.nodeType !== 3) return;

				if (isNodeNestedIn('variable.compound', caret.node)) {
					injectTextNode(caret.node, caret.offset, create('imt-pill.variable.compound.last ]'));

					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			} else if (event.which === 40) {
				// Brace open (
				const caret = getCaret();

				if (!caret.node || caret.node.nodeType !== 3) return;

				const name = (caret.node.textContent.substr(0, caret.offset).match(/[a-zA-Z][a-zA-Z0-9]*$/) || [])[0];
				const func = find('function', name);

				if (func) {
					caret.node.textContent = `${caret.node.textContent.substr(
						0,
						caret.offset - name.length,
					)}${caret.node.textContent.substr(caret.offset)}`;
					caret.offset -= name.length;

					injectTextNode(caret.node, caret.offset, func);

					event.preventDefault();
					this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
				}
			}
		});

		this.addEventListener('input', (event) => {
			normalize(this, this.panelController.variables, true, this.imlItems);
		});

		this.addEventListener('copy', (event) => {
			event.preventDefault();
			event.stopImmediatePropagation();

			const range = getRange();

			if (!range.length) return;
			const ast = domToAST(range, true);

			if (window.clipboardData) {
				window.clipboardData.setData('Text', IML.stringify(ast, undefined, { keepUnwantedOperators: true })); // ie
			} else {
				event.clipboardData.setData(
					'text/plain',
					IML.stringify(ast, undefined, { keepUnwantedOperators: true }),
				); // browsers
			}
		});

		this.addEventListener('cut', (event) => {
			if (this.hasAttribute('readonly') || this.hasAttribute('disabled')) {
				event.preventDefault();
			}
		});

		this.addEventListener('paste', (event) => {
			if (this.hasAttribute('readonly') || this.hasAttribute('disabled')) {
				event.preventDefault();
			}
		});

		this.addEventListener('drop', (event) => {
			if (this.hasAttribute('readonly') || this.hasAttribute('disabled')) {
				event.preventDefault();
			}

			// sets caret after dropped item
			setTimeout(() => {
				const caret = getCaret();

				if (caret.node?.nextSibling && caret.node?.nextSibling?.nextSibling) {
					setCaret(caret.node.nextSibling.nextSibling, 1, true);
				}
			}, 0);
		});

		this.addEventListener('focusin', (event) => {
			if (this.panelEnabled && this.panelController?.state) {
				this.panelController.state.lastActiveCoder = this;
				this.panelController.open(event.target);
			}
		});

		this.addEventListener('click', (event) => {
			if (this.panelEnabled) {
				if (!this.panelController?.panel) {
					this.panelController.state.lastActiveCoder = this;
					this.panelController.open(event.target);
				} else if (!this.panelController.panel.isConnected) {
					this.panelController.panel.open();
				}
			}
		});

		this.addEventListener('focusout', (event) => {
			if (this.panelEnabled) {
				this.panelController.state.lastActiveCoderCaretOffset = getCaret().offset;

				if (
					!this.panelController.state.onPanel &&
					!this.panelController.state.onSearchInput &&
					!this.panelController._hintPanel
				) {
					this.panelController.close();
				}
			}
			if (this.panelController?._hintPanel) {
				this.panelController._hideHintPanel(this.panelController._hintPanel, true);
			}
			if (this.panelController.state.keepFocused) {
				this.focus();
			}
		});
	}

	initIML() {
		if (IML && !(Coder.functions || Coder.variables || Coder.operators || Coder.keywords)) {
			this._buildImlPills();
		} else if (this.changedIml()) {
			Coder.team_variables = null;
			Coder.organization_variables = null;
			Coder.scenario_inputs = null;
			this._buildImlPills();
		}
	}

	imlCustomVariables() {
		const TEAM_VARIABLES = getIMLCategory('TEAM_VARIABLES', this.imlItems);
		const ORGANIZATION_VARIABLES = getIMLCategory('ORGANIZATION_VARIABLES', this.imlItems);
		const SCENARIO_INPUTS = getIMLCategory('SCENARIO_INPUTS', this.imlItems);
		const SCENARIO_VARIABLES = getIMLCategory('SCENARIO_VARIABLES', this.imlItems);

		return {
			TEAM_VARIABLES,
			ORGANIZATION_VARIABLES,
			SCENARIO_INPUTS,
			SCENARIO_VARIABLES,
		};
	}

	changedIml() {
		const compare = this.imlCustomVariables();

		return !Coder.pillCacheValue || Coder.pillCacheValue !== JSON.stringify(compare);
	}

	connectedCallback() {
		if (this._built) return;
		this._built = true;

		this.initIML();

		this.classList.add('form-control');
		this.setAttribute('contenteditable', !this.hasAttribute('disabled'));
		this.setAttribute('spellcheck', 'false');

		this.variables = this._buildPills();

		this.form.coderPanelController = this.form.coderPanelController || new CoderPanelController(Coder, this.form);
		this.panelController = this.form.coderPanelController;

		this.panelController.variables = this.variables;
		this.panelController.pills = this.form.meta.pills;

		if (this._pendingValue) {
			this.renderValue(this._pendingValue);
			this._pendingValue = null;

			const field = this.closest('[field]');

			if (field && field._runValidators) {
				field._runValidators();
			}
		}
	}

	get containsIML() {
		return this.querySelector('imt-pill') != null;
	}

	get disabled() {
		return this.hasAttribute('disabled');
	}

	set disabled(value) {
		if (value) {
			this.setAttribute('disabled', '');
			this.setAttribute('contenteditable', 'false');
		} else {
			this.removeAttribute('disabled');
			this.setAttribute('contenteditable', 'true');
		}
	}

	get errors() {
		const problems = [];

		for (const pill of this.querySelectorAll('imt-pill[invalid]')) {
			problems.push(pill.getAttribute('invalid'));
		}
		return problems;
	}

	get readonly() {
		return this.hasAttribute('readonly');
	}

	set readonly(value) {
		if (value) {
			this.setAttribute('readonly', '');
		} else {
			this.removeAttribute('readonly');
		}
	}

	get value() {
		if (this._pendingValue) {
			return this._pendingValue;
		}

		if (!this.childNodes || !this.childNodes.length) {
			return '';
		}

		if (this.childNodes.length === 1 && this.childNodes[0].nodeName === '#text') {
			return this.childNodes[0].textContent;
		}

		return IML.stringify(domToAST(this.childNodes, true), undefined, { keepUnwantedOperators: true });
	}

	set value(value) {
		// render value on build
		if (!this._built) {
			this._pendingValue = value;
		} else {
			this.renderValue(value);
		}
	}

	get form() {
		if (!this._form) {
			this._form = this.closest('imt-forman');
		}

		return this._form;
	}

	renderValue(value) {
		// Erase content
		this.textContent = value;

		// Erase current content;
		this.innerHTML = '';

		// Attach DOM created from AST
		const ast = IML.parse(value, undefined, undefined, { keepUnwantedOperators: true });

		for (const elm of astToDOM(
			ast,
			this.panelController.variables,
			undefined,
			false,
			this.form ? Coder.cache.get(this.form.meta?.pills) : {},
			this.imlItems,
		)) {
			this.appendChild(elm);
		}

		normalize(this, this.panelController.variables, true, this.imlItems);
	}

	get form() {
		if (!this._form) {
			this._form = this.closest('imt-forman');
		}

		return this._form;
	}

	get imlItems() {
		return (this.form?.meta?.imlOptions?.variables || []).concat(this.form?.meta?.imlOptions?.functions || []);
	}

	get imlCategories() {
		return this.imlItems?.map(x => x.category) || [];
	}

	_buildImlPills() {
		IML_CATEGORIES.concat(this.imlCategories).forEach((type) => {
			const vars = getIMLCategory(type.toUpperCase(), this.imlItems);

			if (vars) {
				let items = Object.entries(vars);

				if (this.imlCategories.includes(type)) {
					items = items.sort((a, b) => sortVariables(a[1], b[1]));
				}

				items.forEach(([name, spec]) => {
					if (spec.hidden || this.isHiddenIml(type, name)) {
						return;
					}

					const elm = createPill(type.slice(0, -1), name, null, null, this.imlItems);

					elm.setAttribute('preview', true);

					switch (type) {
						case 'functions':
							elm.textContent = name || '()';
							break;
						case 'operators':
							elm.textContent = spec.label || name;
							break;
						case 'variables':
						case 'keywords':
						case 'organization_variable':
						case 'team_variable':
							elm.textContent = name;
					}

					elm._pill = spec;

					Coder[type] = Coder[type] || {};
					Coder[type][name] = elm;
				});
			}
		});

		Coder.pillCacheValue = JSON.stringify(this.imlCustomVariables());
	}

	_buildPills() {
		let variables = {};

		const createPills = (group, nested, level = 0, parent) => {
			group.pills
				.filter((spec) => !variables[spec.code])
				.forEach((spec, index) => {
					const elm = createPill('variable', spec.code, null, null, this.imlItems);

					const showIDs = imt?.config?.showIDs;

					elm.textContent = spec.name;
					elm.setAttribute(
						'prefix',
						showIDs && spec.node ? `${spec.node.id}. ${spec.prefix}` : '' + spec.prefix,
					);
					elm.setAttribute('level', level);
					elm.setAttribute('type', spec.type);

					if (nested) {
						elm.setAttribute('nested', true);
						elm.setAttribute('parent-type', group.type);
						elm.setAttribute('nth', index + 1);
					}

					if (spec.theme) {
						elm.style.color = spec.theme.determineForegroundColor();
						elm.style.backgroundColor = spec.theme.toHex();
					}

					elm._pill = spec;

					if (/^(\d+)\.(.*)$/.test(spec.code)) {
						elm.sample = create(`div.coder-sample`);
						elm.sample.textContent = sample(IML._mapVariable(this.form.meta.samples, spec.code));
					}

					if (spec.pills && spec.pills.length) {
						elm.setAttribute('has-children', true);
						createPills(spec, true, level + 1, elm);
					}

					variables[spec.code] = elm;
				});
		};

		const pills = this.form.meta?.pills;

		if (pills && pills.length) {
			if (Coder.cache.has(pills)) {
				variables = Coder.cache.get(pills);
			} else {
				pills.forEach((group) => {
					group.pills && group.pills.length && createPills(group);
				});

				Coder.cache.set(pills, variables);
			}
		}

		return variables;
	}

	isHiddenIml(type, name) {
		const hiddenImls = [{ type: 'operators', name: '++' }];

		return hiddenImls.some((x) => x.type === type && x.name === name);
	}
}

Coder.functions = null;
Coder.variables = null;
Coder.operators = null;
Coder.keywords = null;
Coder.pillCacheValue = null;
Coder.cache = new WeakMap();

registerCustomElement('imt-coder', Coder);

const closeHintInActive = () => {
	if (document.activeElement.closest) {
		const coder = document.activeElement.closest('imt-coder');

		if (coder?.panelController?._hintPanel) {
			coder.panelController._hideHintPanel(coder.panelController._hintPanel, true);
		}
	}
};

// Event listeners
document.addEventListener('selectionchange', (event) => {
	const showFnHint = () => {
		const node = getCaret().node;

		if (!node) {
			closeHintInActive();
		} else {
			let current = node;
			let skip = 0;
			let position = 0;

			while (current.previousSibling) {
				if (!current || is(current, 'imt-pill.function')) {
					if (is(current, 'imt-pill.function.last')) {
						skip++;
					} else if (is(current, 'imt-pill.function.first')) {
						if (skip < 1) {
							break;
						}
						skip--;
					} else if (is(current, 'imt-pill.function.separator') && skip < 1) {
						position++;
					}
				}
				current = current.previousSibling;
			}

			if (current && is(current, 'imt-pill.function')) {
				const coder = current && current.closest && current.closest('imt-coder');
				const name = current.getAttribute('name');
				const type = current.getAttribute('type');

				let group = current.getAttribute('group');

				if (group?.includes(',')) {
					group = group.split(',')[0];
				}

				const syntax = current.classList.contains('custom-function')
					? name + `(${current.getAttribute('function-arguments')})`
					: I18n.l(`iml.${group}.functions.${name}.syntax`);

				const nameSegments = syntax.match(/^\s*(\w+)\s*\((.*)\)/);
				let fnName;
				let fnArgs;

				if (nameSegments) {
					fnName = nameSegments[1];
					fnArgs = nameSegments[2].split(';');
				}

				if (!coder?.fnPanelEnabled) {
					return;
				}

				if (
					coder?.panelController?._hintPanel?.relative !== current ||
					!coder?.panelController?._hintPanel?.setActiveArg
				) {
					if (coder.panelController._hintPanel) {
						coder.panelController._hideHintPanel(coder.panelController._hintPanel, true);
					}
					coder.panelController._showHintPanel(
						current,
						{
							name: fnName || syntax,
							args: fnArgs,
							activeArg: position,
							help: I18n.l(`iml.${group}.functions.${name}.help`),
							example: I18n.l(`iml.${group}.functions.${name}.example`, {
								defaultValue: null,
								skipInterpolation: true,
							}),
							type,
						},
						true,
						true,
						true,
					);
				} else {
					coder.panelController._hintPanel.setActiveArg(position);
				}
			} else {
				closeHintInActive();
			}
		}
	};

	// Remove .selection class from all pills
	for (const pill of document.querySelectorAll('imt-pill.selection')) {
		pill.classList.remove('selection');
	}

	showFnHint();

	// Add .selection class to all pills in selection
	const pills = getRange().filter((node) => node.nodeType === 1);

	if (!pills.length) return;

	for (const pill of pills) {
		pill.classList.add('selection');
	}
});

document.addEventListener('touchstart', (event) => {
	const panel = event.target.closest('.coder-pills');

	if (!panel) return;

	panel.controller.state.onPanel = true;
});

document.addEventListener('mousedown', (event) => {
	if (is(event.target, '.hint-close') || is(event.target.parentElement, '.hint-close')) {
		const panel = event.target.closest('imt-panel');

		if (panel) {
			panel.relative.closest('imt-coder').fnPanelEnabled = false;
		}
	}

	const panel = event.target.closest('.coder-pills');

	if (!panel) return;

	if (is(event.target, '.coder-search')) {
		panel.controller.state.onSearchInput = true;
		return;
	}

	if (!event.target.draggable) {
		panel.controller.state.keepFocused = true;
	}

	panel.controller.state.onPanel = true;
});

const mouseupListener = (event) => {
	// This is only emitted when user clicked the pill but drag has not started
	const panel = event.target?.closest ? event.target?.closest('.coder-pills') : null;

	if (!panel) return;

	if (panel.controller.state.lastActiveCoder && panel.controller.state.onPanel) {
		// TODO: Is setTimeout really necessary?
		setTimeout(() => panel.controller.state.lastActiveCoder.focus(), 0);

		if (event.target.nodeName === 'IMT-PILL') {
			const nodesAffectedByDrag = resolveFullDOM(event.target);
			const iml = IML.stringify(domToAST(nodesAffectedByDrag, true), undefined, { keepUnwantedOperators: true });

			const caret = getCaret();

			if (!caret.node || caret.node.nodeType !== 3) return;

			injectTextNode(caret.node, caret.offset, document.createTextNode(iml));

			panel.controller.state.lastActiveCoder.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		}
	}

	if (panel.controller.state.onSearchInput && !is(event.target, '.coder-search')) {
		// TODO: Add to the focus out
		panel.controller.state.lastActiveCoder.focus();

		const caret = getCaret();

		setCaret(caret.node, panel.controller.state.lastActiveCoderCaretOffset);

		panel.controller.state.onSearchInput = false;
	}

	panel.controller.state.keepFocused = false;
	panel.controller.state.onPanel = false;
};

document.addEventListener('mouseup', mouseupListener);
document.addEventListener('touchend', mouseupListener);

// Helper functions

function sortVariables(a, b) {
	if (!a || !b) {
		return 0;
	}

	if (a.isSystem && !b.isSystem) {
		return 1;
	} else if (!a.isSystem && b.isSystem) {
		return -1;
	}

	if (!a.name || !b.name) {
		return 0;
	}

	return a?.name.localeCompare(b.name);
}

/**
 * Accepts raw caret position and translates it to the position on text node. Sometimes raw caret position may point to a HTML element.
 *
 * @param node
 * @param offset
 * @param forward
 * @returns {object} Object with `node` and `offset` properties. Node is always a text node.
 */

function resolveCaretPositionInCoder(node, offset, forward = false) {
	if (node.nodeName === 'IMT-CODER') {
		if (offset === 0 || !node.firstChild) {
			// If offset is 0 or coder is empty
			if (node.firstChild && node.firstChild.nodeType !== 3) {
				// First child is not a text field, build a SPACER there
				node.insertBefore(document.createTextNode(SPACER), node.firstChild);
			} else if (!node.firstChild) {
				// Empty coder
				node.appendChild(document.createTextNode(''));
			}
			// Set the first child to be an anchor
			node = node.firstChild;
		} else {
			if (!node.childNodes[offset]) {
				// Sometimes if happens in Chrome that offset points to undefined position in childNodes
				node = node.firstChild;
			} else {
				node = node.childNodes[offset];
			}
		}
	}

	if (node.nodeType === 3) {
		// Text node - make sure we're a direct child of a coder
		if (!node.parentNode || node.parentNode.nodeName !== 'IMT-CODER') {
			return { node: null, offset: 0 };
		}
	}

	if (node.nodeType !== 3) {
		// Traverse up the dom to find imt-coder
		while (node) {
			if (node.parentNode && node.parentNode.nodeName === 'IMT-CODER') break;
			node = node.parentNode;
		}

		// Break when we find orphaned node
		if (!node) return { node: null, offset: 0 };

		if (forward) {
			// Search first text sibling to the left
			if (node.nextSibling && node.nextSibling.nodeType === 3) {
				// Next is text, good for us
				node = node.nextSibling;
				offset = 0;
			} else {
				// No next or next isnt text, create SPACER
				const spacer = document.createTextNode(SPACER);

				if (node.nextSibling) {
					node.parentNode.insertBefore(spacer, node.nextSibling);
				} else {
					node.parentNode.appendChild(spacer);
				}
				node = spacer;
			}
		} else {
			// Search first text sibling to the left
			if (node.previousSibling && node.previousSibling.nodeType === 3) {
				// Prev is text, good for us
				node = node.previousSibling;
				offset = node.textContent.length;
			} else {
				// No prev or prev isnt text, create SPACER
				const spacer = document.createTextNode(SPACER);

				node.parentNode.insertBefore(spacer, node);
				node = spacer;
			}
		}
	}

	return {
		node,
		offset,
	};
}

function getCaret() {
	const sel = document.getSelection();

	if (!sel.anchorNode) return { node: null, offset: 0, collapsed: true };

	const { node, offset } = resolveCaretPositionInCoder(sel.anchorNode, sel.anchorOffset);

	if (!node) return { node: null, offset: 0, collapsed: true };

	return {
		node,
		offset,
		collapsed: sel.isCollapsed,
	};
}

function setCaret(node, offset, force = false) {
	offset = Math.max(offset, 0);

	if (node && node.nodeType === 3) {
		offset = Math.min(node.textContent.length, offset);

		// Make sure we always set offset to 1 on SPACERs
		if (isSpacer(node) && !force) offset = 1;
	}

	const sel = window.getSelection();
	const range = document.createRange();

	range.setStart(node, offset);
	range.collapse(true);
	sel.removeAllRanges();
	sel.addRange(range);
}

function getRange() {
	const sel = document.getSelection();

	if (sel.isCollapsed || !sel.rangeCount) return [];

	const range = sel.getRangeAt(0);
	const start = resolveCaretPositionInCoder(range.startContainer, range.startOffset);

	if (!start.node) return [];
	const end = resolveCaretPositionInCoder(range.endContainer, range.endOffset, true);

	if (start.node === end.node) {
		// Start & end on the same node
		if (start.offset === 0 && end.offset === end.node.textContent.length) {
			// Selection covers the whole node
			return [start.node];
		} else {
			return [document.createTextNode(start.node.textContent.substring(start.offset, end.offset))];
		}
	}

	const nodes = [];
	let node = start.node;

	if (start.offset === 0) {
		// Push the whole node
		nodes.push(node);
	} else {
		// Push just a part of the text node
		nodes.push(document.createTextNode(node.textContent.substr(start.offset)));
	}

	while (node.nextSibling) {
		node = node.nextSibling;

		if (node === end.node) {
			// Found end of the selection
			if (end.offset === end.node.textContent.length) {
				// Push the whole node
				nodes.push(end.node);
			} else {
				// Push just a part of the text node
				nodes.push(document.createTextNode(end.node.textContent.substr(0, end.offset)));
			}

			return nodes;
		} else {
			nodes.push(node);
		}
	}

	// This should never occur - that means we didn't reach end node in siblings.
	return nodes;
}

function isSpacer(elm) {
	if (!elm || elm.nodeType !== 3) return false;
	return elm.textContent === SPACER;
}

function isDoubleSpacer(elm) {
	if (!elm || elm.nodeType !== 3) return false;
	return elm.textContent === SPACER + SPACER;
}

/**
 * Injects text node with an element.
 *
 * @param node Text node to inject.
 * @param {number} offset Offset.
 * @param elm Element to inject.
 */

function injectTextNode(node, offset, elm) {
	if (!node || node.nodeType !== 3) return;

	if (offset === node.textContent.length) {
		// Inject end of the text node
		if (elm.nodeType === 3) {
			// With text node
			node.textContent += elm.textContent;
			setCaret(node, offset + elm.textContent.length);
		} else {
			// With HTML element
			if (node.nextSibling) {
				if (node.nextSibling.nodeType !== 3) {
					// Next sibling is HTML element
					node.parentNode.insertBefore(document.createTextNode(SPACER), node.nextSibling);
				}
				setCaret(node.nextSibling, 0);
				node.parentNode.insertBefore(elm, node.nextSibling);
			} else {
				// No sibling, append to the end
				node.parentNode.appendChild(elm);
				// Create SPACER
				const spacer = document.createTextNode(SPACER);

				node.parentNode.appendChild(spacer);
				setCaret(spacer, 0);
			}
		}
	} else if (offset === 0) {
		// Inject start of the text node
		if (elm.nodeType === 3) {
			// With text node
			node.textContent = elm.textContent + node.textContent;
			setCaret(node, elm.textContent.length);
		} else {
			// With HTML element
			node.parentNode.insertBefore(elm, node);
			setCaret(node, elm.textContent.length);
		}
	} else {
		// Split the text node
		if (elm.nodeType === 3) {
			// With text node
			node.textContent = node.textContent.substr(0, offset) + elm.textContent + node.textContent.substr(offset);
			setCaret(node, offset + elm.textContent.length);
		} else {
			// With HTML element
			const next = node.splitText(offset);

			node.parentNode.insertBefore(elm, next);
			setCaret(next, 0);
		}
	}
}

/**
 * Returns where the given element lays inside a function/variable or not. It calculates the number of function/variable openings and that number
 * must be higher than function/variable ends.
 *
 * @param {string} type Type of lookup - `function` or `variable`.
 * @param {HTMLElement} elm Element to check.
 * @returns {boolean}
 */

function isNodeNestedIn(type, elm) {
	if (!elm) return false;

	let counter = 0;
	let prev = elm.previousSibling;

	while (prev) {
		if (is(prev, `imt-pill.${type}.first`)) counter++;
		if (is(prev, `imt-pill.${type}.last`)) counter--;
		prev = prev.previousSibling;
	}

	return counter > 0;
}

function find(type, name) {
	const result = Coder[type + 's'][name];

	return result ? result.cloneNode(true) : undefined;
}

function sample(value) {
	if (!value) return '';

	switch (typeof value) {
		case 'string':
			if (/^IMTBuffer\((\d+), ([^,]*), ([^,]*)\): ([\s\S]*)$/.exec(value)) {
				return RegExp.$4.replace(/(.{2})/g, '$1 ');
			}

			if (/^IMTString\((\d+)\): ([\s\S]*)$/.exec(value)) {
				return RegExp.$2;
			}

			return value;

		case 'object':
			if (Array.isArray(value)) {
				return `[${value.map((item) => sample(item)).join(', ')}]`;
			}
			if (value instanceof Date) {
				// eslint-disable-next-line no-undef
				return format(value, 'datetime', { timezone: imt.user.timezone });
			} // TODO add format function
			return '{collection}';

		default:
			return String(value);
	}
}

function normalize(element, variables = undefined, keepUnwantedOperators = true, imlItems = null) {
	const caret = getCaret();
	let next = element.firstChild;
	let last = null;

	// First, remove unwanted and unnecessary nodes and join text siblings together

	while (next) {
		const child = next;

		if (child.nodeType === 3) {
			// Child is text node
			if (last && last.nodeType === 3) {
				// Last child was also a text node, join them together

				if (child.textContent.length === 0 || isSpacer(child)) {
					// Remove empty text node
					next = child.nextSibling;
					if (caret.node === child) {
						caret.node = last;
						caret.offset = last.textContent.length;
					}
					element.removeChild(child);
					continue;
				}

				if (caret.node === child) {
					// Caret is positioned inside child, move caret to last
					caret.node = last;
					caret.offset += last.textContent.length;
				}

				last.textContent += child.textContent;
				next = child.nextSibling;
				element.removeChild(child);
				continue;
			} else if (child.textContent.length === 0) {
				// First text node is empty, make it a SPACER
				child.textContent = SPACER;
			}
		} else if (child.nodeType === 1) {
			// Child is HTML element
			if (child.nodeName === 'IMT-PILL') {
				// Remove all non-text nodes from our pills (e.g. cased by formatting in copy/paste)
				const text = child.textContent;

				child.innerHTML = '';
				child.textContent = text;
			} else {
				// Unwanted node
				if (child.childNodes.length) {
					// Replace node with its content
					next = child.firstChild;
					while (child.firstChild) {
						element.insertBefore(child.firstChild, child);
					}

					element.removeChild(child);
					continue;
				} else {
					next = child.nextSibling;
					element.removeChild(child);
					continue;
				}
			}
		} else {
			if (caret.node === child) {
				// Caret is positioned inside child, move caret to prev/next
				if (last) {
					caret.node = last;
					caret.offset = last.textContent.length;
				} else {
					if (!child.nextSibling) {
						// No next sibling, we're removing the last child, so create empty text node as placeholder
						next = document.createTextNode('');
						element.appendChild(next);
						element.removeChild(child);
						continue;
					} else {
						caret.node = child.nextSibling;
						caret.offset = 0;
					}
				}
			}

			// Unknown node, remove
			element.removeChild(child);
			continue;
		}

		last = child;
		next = child.nextSibling;
	}

	// Performance boost - break out when there's nothing to normalize

	if (element.childNodes.length === 1 && element.childNodes[0].nodeType === 3) {
		if (isSpacer(element.childNodes[0]) || isDoubleSpacer(element.childNodes[0])) {
			element.childNodes[0].textContent = '';
		}

		// Single empty text node, skip normalization
		if (element.childNodes[0].textContent.length === 0) return;
	}

	// Next, find new IML nodes in text

	last = null;
	next = element.firstChild;

	while (next) {
		const child = next;

		if (child.nodeType === 3) {
			// Child is text node
			RE_IML_GLOBAL.lastIndex = 0; // Reset the lastIndex
			const result = RE_IML_GLOBAL.exec(child.textContent);

			if (result) {
				// Child contains IML expression
				const ast = IML.parse(result[0], undefined, undefined, { keepUnwantedOperators });

				for (const err of IML.errors) console.warn(err);
				const dom = astToDOM(ast, variables, undefined, false, Coder.cache.get(element.form?.meta?.pills), imlItems);

				if (result.index + result[0].length === child.textContent.length) {
					// IML is on the end of the text node
					child.textContent = child.textContent.substr(0, result.index);

					if (child.textContent.length === 0) {
						// IML expression was all around the text content, replace it with SPACER
						child.textContent = SPACER;
					}

					if (!child.nextSibling) {
						// We have no sibling on the right - we must create SPACER
						element.appendChild(document.createTextNode(SPACER));
					} else if (child.nextSibling.nodeType !== 3) {
						// We have no text sibling on the right - we must create SPACER
						element.insertBefore(document.createTextNode(SPACER), child.nextSibling);
					}

					if (caret.node === child && caret.offset > result.index) {
						// Cursor was originally in the place where the code was
						caret.node = child.nextSibling;
						caret.offset = 0;
					}

					// We need to store next sibling as constand because it's about to change once we start adding new dom
					const nextSibling = child.nextSibling;

					for (const elm of dom) {
						element.insertBefore(elm, nextSibling);
					}

					last = dom[dom.length - 1];
					next = last && last.nextSibling;
					continue;
				} else if (result.index === 0) {
					// IML is right on the text start
					child.textContent = child.textContent.substr(result[0].length);

					if (child.textContent.length === 0) {
						// IML expression was all around the text content, replace it with SPACER
						child.textContent = SPACER;
					}

					if (!child.previousSibling || child.previousSibling.nodeType !== 3) {
						// We have no text sibling on the left - we must create SPACER
						element.insertBefore(document.createTextNode(SPACER), child);
					}

					if (caret.node === child) {
						// Cursor was originally in the place where the code was
						caret.offset = Math.max(0, caret.offset - result[0].length);
					}

					for (const elm of dom) {
						element.insertBefore(elm, child);
					}

					// Iterate trought the child again to find another code occurences
					next = child;
					last = child.previousSibling;
					continue;
				} else {
					next = child.splitText(result.index);
					next.textContent = next.textContent.substr(result[0].length);

					if (caret.node === child && caret.offset > result.index) {
						// Cursor was originally in the place where the code was
						caret.node = next;
						caret.offset = Math.max(0, caret.offset - (result.index + result[0].length));
					}

					for (const elm of dom) {
						element.insertBefore(elm, next);
					}

					last = next.previousSibling;
					continue;
				}
			}
		}

		last = child;
		next = child.nextSibling;
	}

	// Last, inject pill siblings and edges with invisible SPACER

	last = null;
	next = element.firstChild;

	while (next) {
		const child = next;

		if (child.nodeType === 3) {
			// Child is text node
			if (isSpacer(child)) {
				if (
					(child.previousSibling && child.previousSibling.nodeType !== 1) ||
					(child.nextSibling && child.nextSibling.nodeType !== 1)
				) {
					// Remove SPACER when not between two elements (except edges)
					next = child.nextSibling;
					element.removeChild(child);
					continue;
				}
			} else if (isDoubleSpacer(child)) {
				child.textContent = SPACER;
				if (caret.node === child && caret.offset > 1) caret.offset--;
			} else {
				// Search for SPACERs and remove unwanted ones
				let cursor = child.textContent.indexOf(SPACER);
				const originalOffset = caret.offset;

				// There's a SPACER inside text node, clean it up (ignore last character bacuse it is probably SPACER)
				while (cursor !== -1 && cursor < child.textContent.length) {
					if (cursor === 0 && child.textContent.charAt(1) === '\n') {
						// SPACER on the first position followed by newline - SPACER stays
						cursor = child.textContent.indexOf(SPACER, 1);
						continue;
					} else if (
						cursor === child.textContent.length - 1 &&
						child.textContent.charAt(child.textContent.length - 2) === '\n'
					) {
						// SPACER on the last position preceded by newline - SPACER stays
						break;
					}

					// Caret is inside this child
					if (caret.node === child && cursor < originalOffset) caret.offset--;

					// Remove unnecessary SPACER
					child.textContent = child.textContent.substr(0, cursor) + child.textContent.substr(cursor + 1);

					cursor = child.textContent.indexOf(SPACER, cursor);
				}

				if (child.textContent.charAt(0) === '\n') {
					child.textContent = SPACER + child.textContent;
					if (caret.node === child && caret.offset > 0) caret.offset += 1;
				}
				if (child.textContent.charAt(child.textContent.length - 1) === '\n') {
					child.textContent = child.textContent + SPACER;
				}
			}
		} else if (child.nodeType === 1) {
			// Child is HTML element
			if (last && last.nodeType === 1) {
				// Pill siblings, inject SPACER
				element.insertBefore(document.createTextNode(SPACER), child);
			}
		}

		last = child;
		next = child.nextSibling;
	}

	if (element.firstChild && element.firstChild.nodeType === 1) {
		// HTML element on the start, add SPACER
		element.insertBefore(document.createTextNode(SPACER), element.firstChild);
	}

	if (element.lastChild && element.lastChild.nodeType === 1) {
		// HTML element on the end, add SPACER
		element.appendChild(document.createTextNode(SPACER));
	}

	if (document.activeElement === element) {
		// This coder is focused, make sure we move caret accordingly to changes
		if (caret.node) {
			setCaret(caret.node, caret.offset);
		} else {
			// In case we have no anchor, seek to last possible caret position
			if (element.lastChild) {
				setCaret(element.lastChild, element.lastChild.textContent.length);
			}
		}
	}

	return element;
}
