import { SelectInput } from './select.mjs';
import configs from '../configs/config.mjs';
import { composedInputBuilder, create, registerCustomElement, resolveDefaultInputValue } from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

const config = configs.fields.device;

export class DeviceInput extends SelectInput {
	constructor() {
		super();

		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return ['editable'];
	}

	extendInstructions(instructions) {
		instructions.options = instructions.options || {};
		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || 'Choose a device';

		const scenarioId = this.form.meta.Inspector?.instance?.scenario?.id;
		const deviceScope = this.getAttribute('type')?.split(',');

		const storeQuery = new URLSearchParams();

		if (!instructions.options.team) {
			instructions.options.team = this.getTeamId(instructions);
			if (scenarioId) {
				storeQuery.append('viewForScenarioId', scenarioId);
			}
		}

		if (deviceScope) {
			deviceScope.forEach((scope) => storeQuery.append('scope', scope));
		}

		storeQuery.append('teamId', instructions.options.team);
		storeQuery.append('assigned', 'false');
		storeQuery.append('pg[limit]', config.api.list.limit);

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;
		instructions.theme = instructions.theme || this.form.meta.module.package?.theme || DEFAULT_THEME;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker

		const picker = create('imt-picker');

		if (typeof value === 'undefined') {
			value = resolveDefaultInputValue(this, instructions.type);
		}

		picker.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item) => {
			option.setAttribute('data-editable', item.editable);
		});

		// Build the component

		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				await this.addOption(event.detail.data.device);
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const instructionsQuery = new URLSearchParams();

			instructionsQuery.append('teamId', instructions.options.team);

			await this.panel.build({
				name: I18n.l('panels.device.add'),
				help: config.kb.path,
				src: `${config.api.instructions.request.path}?${instructionsQuery}`,
				theme: instructions.theme,
			});

			this.panel.open();
		};
	}
}

registerCustomElement('imt-input-device', DeviceInput);
