import { Input } from './input.mjs';
import { animateElementHeight, clone, create, registerCustomElement } from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import config from '../configs/config.mjs';
import { I18n } from '../helpers/i18n.mjs';
import EVENTS from '../events.mjs';
import Loader from '../loader.mjs';

function processFilter(wrapper, parser) {
	if (!wrapper?.childNodes) return [];

	return Array.from(wrapper.childNodes)
		.map((group) =>
			Array.from(group.querySelectorAll('div.item'))
				.map(parser)
				.filter((item) => !!item),
		)
		.filter((group) => group.length > 0);
}

export class FilterInput extends Input {
	constructor() {
		super();

		this._defaultOperators = null;
		this._wrapper = null;
		this._optionsCache = null;
		this._metadata = {};
	}

	get value() {
		if (!this._wrapper) return [];

		return processFilter(this._wrapper, (item) => {
			const out = {
				a: item.querySelector('.operand-a')?.value,
				o: item.querySelector('imt-picker.operator')?.value,
				b: item.querySelector('.operand-b:not(.d-none)')?.value,
			};

			return out.a || out.b ? out : null;
		});
	}

	set value(value) {
		if (!Array.isArray(value)) return;

		this._wrapper.innerHTML = '';

		const restore = this._metadata.restore?.label;

		value.forEach((group, i) => {
			const groupElement = this._buildGroup(null);

			this._wrapper.append(groupElement);

			group.forEach((item, ii) => {
				const restoreLabel = restore && restore[i] && restore[i][ii];
				const itemElement = this._buildItem(item, restoreLabel ? { restore: { label: restoreLabel } } : null);

				groupElement.querySelector('div.group-items').append(itemElement);
			});
		});
	}

	get hasModeSwitch() {
		return false;
	}

	get operators() {
		if (this._instructions.options?.operators) {
			return this._instructions.options.operators;
		}

		if (this._defaultOperators) {
			return this._defaultOperators;
		}

		this._defaultOperators = [];

		const groups = { general: [] };

		Object.entries(config.fields.filter.operators).forEach(([name, op]) => {
			let group;

			if (!name.includes(':')) {
				group = groups.general;
			} else {
				const type = name.split(':')[0];

				if (groups[type] == null) {
					groups[type] = [];
				}
				group = groups[type];
			}

			group.push({
				value: name,
				short: op.symbol,
				label: I18n.l(`filter.operators.${name}.label`, { keySeparator: '.', nsSeparator: '|' }),
			});
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.basic'),
			options: groups.general,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.text'),
			options: groups.text,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.number'),
			options: groups.number,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.date'),
			options: groups.date,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.time'),
			options: groups.time,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.boolean'),
			options: groups.boolean,
		});

		this._defaultOperators.push({
			label: I18n.l('filter.groups.array'),
			options: groups.array,
		});

		return this._defaultOperators;
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		const options = [];

		for (const node of this.querySelectorAll('imt-option')) {
			if (!node.hasAttribute('value')) throw new Error('Option without value attribute.');
			this.removeChild(node);

			options.push({
				label: node.textContent,
				value: node.value,
			});
		}

		return Object.assign(super.attributesToInstructions(), {
			options: options.length ? options : this.getAttribute('options'),
		});
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		if (metadata) this._metadata = metadata;

		this._wrapper = create('div.wrapper');
		this.appendChild(this._wrapper);

		if (instructions.options?.logic) this.setAttribute('logic', instructions.options.logic);

		// Do not use Picker.normalizeOptions. It is necessary to preserve options.store undefined if it's not defined otherwise coder input is not displayed.
		this._options =
			Array.isArray(instructions.options) || typeof instructions.options === 'string'
				? { store: instructions.options }
				: instructions.options || {};

		// Options have to be loaded directly here because if the loading fails,
		// coder should be displayed instead of the select input.
		// TODO Ensure the caching feature works for this solution
		if (typeof this._options?.store === 'string') {
			const form = this.form;
			const context = form ? form.meta.imlContext : {};

			try {
				this._options.store = await Loader.load(this._options.store, {
					data: this.fieldset.rpcData || {},
					context,
				});
			} catch (ex) {
				console.error('Failed to load filter data.', ex);
			}
		}

		if (Array.isArray(value) && value.length) {
			this.value = value;

			// Ensure to use restore data only while building
			this._metadata.restore = null;
		} else {
			// Fresh start
			this._wrapper.append(this._buildGroup(true));
		}
	}

	/**
	 * Build filter group
	 * @param addEmptyItem {boolean}
	 * @private
	 */

	_buildGroup(addEmptyItem) {
		const group = create('div.group');
		const groupItems = group.appendChild(create('div.group-items'));

		if (addEmptyItem) {
			groupItems.append(this._buildItem());
		}

		const logic = this.getAttribute('logic');

		if (logic !== 'or') {
			const button = group.appendChild(create('button.btn.btn-sm.btn-success.add-item[button]'));

			if (logic === 'reverse') {
				button.textContent = I18n.l('buttons.addor');
			} else {
				button.textContent = I18n.l('buttons.addand');
			}

			button.addEventListener('click', async (event) => {
				const item = this._buildItem();

				groupItems.append(item);
				await animateElementHeight(item, { timing: 200, timingFunction: 'ease-in', emitEvent: true });
			});
		}

		if (logic !== 'and') {
			const button = group.appendChild(create('button.btn.btn-sm.btn-success.add-group.ml-2[button]'));

			if (logic === 'reverse') {
				button.textContent = I18n.l('buttons.addand');
			} else {
				button.textContent = I18n.l('buttons.addor');
			}

			button.addEventListener('click', async (event) => {
				const item = this._buildGroup(true);

				group.parentElement.insertBefore(item, group.nextElementSibling);
				await animateElementHeight(item, { timing: 200, timingFunction: 'ease-in', emitEvent: true });
			});
		}

		return group;
	}

	/**
	 * Builds filter item asynchronously and doesn't wait till it's done
	 * @param filter {Object} Item config
	 * @param metadata {Object}
	 * @private
	 */

	_buildItem(filter = { a: null, o: null, b: null }, metadata) {
		const item = create('div.item');

		// Operand A

		if (Array.isArray(this._options.store)) {
			const operandA = item.appendChild(create('imt-picker.operand-a'));

			operandA.register(Picker.COMPONENTS.RPC_DATA_GETTER, () => this.fieldset.rpcData);

			// Cache loaded data for other items
			operandA.addEventListener(EVENTS.PICKER.LOAD_DONE, (event) => {
				this._optionsCache = event.detail;
			});

			if ('string' === typeof this._options.store) {
				operandA.setAttribute('provider', this._options.store);

				if (!metadata?.restore && !this._optionsCache) {
					// Initial start load RPC immediately
					operandA.reload(filter.a);
				} else {
					// Load cached or restore
					operandA.addOptions(clone(this._optionsCache || this._options), filter.a, metadata);

					// Prevent reloading if loaded from cache
					if (this._optionsCache) {
						operandA._loaded = true;
					}
				}
			} else {
				operandA.addOptions(clone(this._options), filter.a, metadata);
			}
		} else {
			const operandA = item.appendChild(create('imt-coder.operand-a'));

			operandA.value = filter?.a;
		}

		// Operator

		const operator = item.appendChild(create('imt-picker.operator[grouped]'));

		operator.addOptions(Picker.normalizeOptions(this.operators), filter?.o || config.fields.filter.defaultOperator);

		// Operand B

		const operandB = item.appendChild(create('imt-coder.operand-b'));

		operandB.value = filter.b;

		function resolveVisibility(operator, element) {
			if (!operator.value || ['exist', 'notexist'].includes(operator.value)) {
				element.classList.add('d-none');
			} else {
				element.classList.remove('d-none');
			}
		}

		resolveVisibility(operator, operandB);
		operator.addEventListener('input', () => resolveVisibility(operator, operandB));

		const close = create('button.close[type="button"][data-dismiss="alert"]');

		close.innerHTML = '&times;';
		close.addEventListener('click', async () => {
			await animateElementHeight(item, { hide: true, timing: 200, timingFunction: 'ease-in', emitEvent: true });

			if (item.parentElement.querySelectorAll('div.item').length === 1) {
				if (this._wrapper.children.length === 1) {
					item.remove();
				} else {
					item.closest('div.group').remove();
				}
			} else {
				item.remove();
			}
		});
		item.appendChild(close);

		return item;
	}

	_setDisabled(disabled) {
		for (const node of this._wrapper.querySelectorAll('imt-picker,imt-coder')) {
			node.disabled = disabled;
		}
	}

	getState() {
		const state = super.getState();

		if ('string' === typeof this._options.store) {
			state.label = processFilter(this._wrapper, (item) => {
				const value = item.querySelector('.operand-a')?.getSelectedElements();

				return value && value.length && value[0].textContent;
			});
		}

		return state;
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		if (this._instructions.options) {
			instructions.options = this._instructions.options;
		}

		return instructions;
	}

	getPreview() {
		const res = processFilter(this._wrapper, (item) => {
			const out = {
				a: item.querySelector('.operand-a'),
				o: item.querySelector('imt-picker.operator'),
				b: item.querySelector('.operand-b:not(.d-none)'),
			};
			const empty = `<${I18n.l('common.empty').toLowerCase()}>`;
			const a = out.a?.valueLabel || out.a?.value || empty;
			const b = out.b?.valueLabel || out.b?.value || empty;

			return (out.a || out.b) && out.o.value ? `${a} ${out.o.valueLabel}${out.b ? ' ' + b : ''}` : empty;
		});

		return res.map((arr) => arr.join(' OR ')).join(' AND ');
	}
}

registerCustomElement('imt-input-filter', FilterInput);
