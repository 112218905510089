/**
 * This file is used by both font-end and back-end.
 */

import * as text from './text.mjs';
import { ValidationError } from '../helpers/errors.mjs';
import { parseTime } from '../helpers/dateparser.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	return text.cast(value);
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null || value === '') return null;

	value = parseTime(value);
	if (value || value === 0) return value;

	throw new ValidationError('Value is not a valid time.', 'validate-time-invalid');
}
