/* eslint-env node */

const wrapper = {
	_library: typeof window !== 'undefined' ? window.ajv7 : null,
	get Ajv() {
		return typeof window !== 'undefined' ? window.ajv7 : wrapper._library;
	},
};

export default wrapper;
