/* global moment */

import { Input } from './input.mjs';
import { createDateTimePickerPanel, registerCustomElement } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class DateInput extends Input {
	get hasModeSwitch() {
		return false;
	}

	get value() {
		const value = this._control.nodeName === 'IMT-CODER' ? this._control.value : this._control.value || null;

		if (this.fieldset.mappable && this._instructions.mappable !== false) {
			return value;
		}

		if (this.time === false) {
			const m = moment.tz(value, 'L', this.timezone || 'UTC');

			if (!m.isValid()) return value;
			return m.toDate();
		} else {
			const m = moment.tz(value, 'L LT', this.timezone || 'UTC');

			if (!m.isValid()) return value;
			return m.toDate();
		}
	}

	set value(value) {
		if (this._control.value === value) return;

		if (this.time === false) {
			const m = moment.tz(value, 'UTC');

			if (m.isValid()) value = m.tz(this.timezone || 'UTC').format('L');
		} else {
			const m = moment.tz(value, 'UTC');

			if (m.isValid()) value = m.tz(this.timezone || 'UTC').format('L LT');
		}

		this._control.value = value == null ? '' : value;
	}

	get timezone() {
		return this.form?.timezone || 'UTC';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		const type = this.fieldset.mappable && instructions.mappable !== false ? 'imt-coder' : 'input';

		this._control = document.createElement(type);
		if (type !== 'imt-coder') this._control.className = 'form-control';
		this._control.readonly = instructions.readonly;
		this._control.disabled = instructions.disabled;
		this._control.name = instructions.name || instructions.name;
		this._control.id = this._id;
		this._metaTimezone = this.form?.meta?.timezone;

		if (type !== 'imt-coder') {
			const format = instructions.time === false ? 'L' : 'L LT';
			const m = moment.tz(value, 'UTC');

			if (m.isValid() && value) value = m.tz(this.timezone || 'UTC').format(format);

			let picker;

			this._control.type = 'text';
			this._control.addEventListener('focusin', () => {
				picker = createDateTimePickerPanel(this, this, instructions);
				picker.panel.open();
			});

			this._control.addEventListener('focusout', () => {
				if (picker?.panel) {
					picker?.abortController.abort();
					picker?.panel.close();
					picker = null;
				}
			});
		}

		this._control.value = value || null;
		this.appendChild(this._control);
	}

	/**
	 * Builds the list of hints to be shown with the field.
	 *
	 * @param {function} hint Function that adds the hint. First argument is icon, second is text.
	 */

	_hints(hint) {
		const time = this._instructions.time !== false;

		if (this.fieldset.mappable && this._instructions.mappable !== false) {
			hint('globe', I18n.l('hints.timezone', { value: this._metaTimezone || this.timezone || 'UTC' }, true));
		} else {
			hint('globe', I18n.l('hints.timezone', { value: this.timezone || 'UTC' }, true));
		}

		if (this.fieldset.mappable && this._instructions.mappable !== false) {
			hint('clock', I18n.l('hints.formats'));
		} else {
			hint(
				'clock',
				I18n.l(
					'hints.format',
					{
						value: `${moment.localeData().longDateFormat('L')} ${time ? moment.localeData().longDateFormat('LT') : ''}`,
					},
					true,
				),
			);
		}
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		// Default value is true so the false values has to be stored in instructions
		if (typeof this._instructions.time !== 'undefined') {
			instructions.time = this._instructions.time;
		}

		return instructions;
	}

	/**
	 * Performs value validation for type defined by this input field.
	 *
	 * @param {*} value Value to validate, same as getting `this.value`.
	 * @param {Array} problems Array of strings containing all generated validation problems.
	 * @returns {boolean} Method can return false to indicate this field is no valid without providing a reason. Otherwise, return value is ignored.
	 */

	_validate(value, problems) {
		if (this._control.nodeName === 'IMT-CODER' && this._control.containsIML) {
			// When field contains IML, use Coder validations instead
			problems.push(...this._control.errors);
		} else {
			// Any _validation method can return false to indicate this field is no valid without providing a reason
			return super._validate(value, problems);
		}
	}

	_setDisabled(disabled) {
		this._control.disabled = disabled;
	}

	getPreview() {
		const value = this.value;
		const date = moment(value);

		if (!date.isValid() && (value == null || value?.length === 0)) {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		} else {
			return this._control.value;
		}
	}
}

registerCustomElement('imt-input-date', DateInput);
