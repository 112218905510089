import { TextInput } from './text.mjs';
import { create, registerCustomElement } from '../utils.mjs';

export class PasswordInput extends TextInput {
	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		await super._build(instructions, value, metadata);
		this._control.type = 'password';

		if (!this.mappable) {
			this.classList.add('has-password');
			const btnContainer = create('div.password-button-container');
			const showPasswordBtn = create('button.show-password');
			const icon = create('i.fas.fa-eye-slash');

			this.append(btnContainer);
			btnContainer.append(this._control);
			btnContainer.append(showPasswordBtn);
			showPasswordBtn.append(icon);
			showPasswordBtn.addEventListener('click', (e) => {
				if (this._control.type === 'password') {
					this._control.type = 'text';
					icon.classList.remove('fa-eye-slash');
					icon.classList.add('fa-eye');
				} else {
					this._control.type = 'password';
					icon.classList.remove('fa-eye');
					icon.classList.add('fa-eye-slash');
				}
			});
		}
	}
}

registerCustomElement('imt-input-password', PasswordInput);
