import { domToAST, resolveFullDOM } from '../helpers/iml.mjs';
import { is, registerCustomElement } from '../utils.mjs';

export class Pill extends HTMLElement {
	constructor() {
		super();

		this._built = false;
	}

	connectedCallback() {
		if (this._built) return;
		this._built = true;

		this.addEventListener('mouseenter', (event) => {
			if (this.hasAttribute('preview')) return;

			for (const node of resolveFullDOM(this, true)) {
				if (node.nodeType === 1) node.classList.add('hover');
			}
		});

		this.addEventListener('mouseleave', (event) => {
			if (this.hasAttribute('preview')) return;

			for (const node of this.parentNode.querySelectorAll('.hover')) {
				node.classList.remove('hover');
			}
		});

		this.setAttribute('draggable', 'true');
		this.setAttribute('contenteditable', 'false');
	}

	toIML() {
		return IML.stringify(domToAST(resolveFullDOM(this)));
	}

	disconnectedCallback() {
		if (this._hintPanel && this._hintPanel.close) {
			this._hintPanel.close();
		}
	}
}

let nodesAffectedByDrag;

document.addEventListener('dragstart', (event) => {
	if (!(event.target instanceof Pill)) return;

	if (event.target._hintPanel) {
		event.target._hintPanel.close();
		event.target._hintPanel = null;
	} else {
		const hintPanel = event.target.closest('imt-panel.coder-pills');

		if (hintPanel.controller) {
			hintPanel.controller._hideHintPanel();
		}
	}

	nodesAffectedByDrag = resolveFullDOM(event.target);
	const iml = IML.stringify(domToAST(nodesAffectedByDrag, true), undefined, { keepUnwantedOperators: true });

	// console.log('DOM', nodesAffectedByDrag);
	// console.log('AST', domToAST(nodesAffectedByDrag, true), true);
	// console.log('IML', IML.stringify(domToAST(nodesAffectedByDrag, true), undefined, { keepUnwantedOperators: true }));

	// Prevent dragging when DOM didn't resolve in to IML
	if (!iml) return;

	event.dataTransfer.effectAllowed = 'copyMove';
	event.dataTransfer.setData('Text', iml); // ie/edge
	event.dataTransfer.setData('text/plain', iml); // browsers
	event.stopPropagation();
});

document.addEventListener('dragend', (event) => {
	if (!(event.target instanceof Pill)) return;

	nodesAffectedByDrag = null;
});

document.addEventListener('dragover', (event) => {
	if (!is(event.target, 'imt-coder')) return event.preventDefault();

	if (Array.isArray(nodesAffectedByDrag) && nodesAffectedByDrag.length) {
		if (nodesAffectedByDrag[0].parentNode === event.target) {
			// Drag over the same coder field
			event.dataTransfer.dropEffect = 'move';
		} else {
			// Drag over different coder field
			event.dataTransfer.dropEffect = 'copy';
		}
	} else {
		event.dataTransfer.dropEffect = 'all';
	}
});

document.addEventListener('drop', (event) => {
	if (!is(event.target, 'imt-coder')) return event.preventDefault();
	const isCopy = event.altKey || event.ctrlKey;

	if (Array.isArray(nodesAffectedByDrag) && nodesAffectedByDrag.length) {
		if (!isCopy && nodesAffectedByDrag[0]?.parentNode?.nodeName === 'IMT-CODER') {
			for (const node of nodesAffectedByDrag) {
				if (node.parentNode) node.parentNode.removeChild(node);
			}
		}
	}
});

registerCustomElement('imt-pill', Pill);
