import { create, registerCustomElement } from '../utils.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import './panel.scss';

const PANEL_MARGIN = 10;

export class Panel extends HTMLElement {
	constructor() {
		super();

		this._built = false;
		this._observers = [];
		this._updateListener = null;

		this._arrow = create('imt-panel-arrow');

		this.__width = 'auto';
		this.__height = 'auto';

		// Update panel coords when resized
		new ResizeObserver((entries) => {
			this.update();
		}).observe(this);

		window.addEventListener(
			'scroll',
			(event) => {
				if (this.isConnected && this._relative && event.target.contains(this._relative)) {
					this.update();
				}
			},
			true,
		);
	}

	static get observedAttributes() {
		return ['position', 'relative', 'spacing'];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'relative') {
			this.relative = newValue;
		} else if (name === 'position' || name === 'spacing' || name === 'compact') {
			this.update();
		}
	}

	connectedCallback() {
		if (!this._built) {
			this._built = true;

			this.classList.add('forman-panel'); // To define higher specificity for CSS

			// Make sure everything is in the right order
			if (this.relative) this.appendChild(this._arrow);
			this.appendChild(this.header);
			this.appendChild(this.body);
			this.appendChild(this.footer);

			if (this.hasAttribute('relative')) this.relative = this.getAttribute('relative');

			this._updateListener = () => this.update();

			window.addEventListener('resize', this._updateListener);
			window.addEventListener('scroll', this._updateListener);

			if (this.relative) {
				const observer = new MutationObserver((mutations) => {
					if (mutations.some((mutation) => mutation.target === this || mutation.target === this._arrow)) return;
					this._updateListener();
				});

				this._observers.push(observer);

				observer.observe(document.body, {
					attributes: true,
					attributeFilter: ['style'],
					subtree: true,
				});
			}
		}

		this.update();
	}

	disconnectedCallback() {
		window.removeEventListener('resize', this._updateListener);
		window.removeEventListener('scroll', this._updateListener);

		this._observers.forEach((observer) => observer.disconnect());
		this._observers = [];
	}

	get body() {
		let body = this.querySelector(':scope > imt-panel-body');

		if (!body) {
			body = create('imt-panel-body');
			this.appendChild(body);
		}
		return body;
	}

	get compact() {
		return this.hasAttribute('compact');
	}

	set compact(value) {
		if (value) {
			this.setAttribute('compact', '');
		} else {
			this.removeAttribute('compact');
		}
		this.update();
	}

	get footer() {
		let footer = this.querySelector(':scope > imt-panel-footer');

		if (!footer) {
			footer = create('imt-panel-footer');
			this.appendChild(footer);
		}
		return footer;
	}

	get footerControls() {
		const footer = this.footer;
		let display = footer.querySelector('div.footer-display');
		let buttons = footer.querySelector('div.footer-buttons');

		if (!display) {
			display = create('div.footer-display');
			footer.append(display);
		}

		if (!buttons) {
			buttons = create('div.footer-buttons');
			footer.append(buttons);
		}

		return [display, buttons];
	}

	get header() {
		let header = this.querySelector(':scope > imt-panel-header');

		if (!header) {
			header = create('imt-panel-header');
			this.appendChild(header);

			header.addCloseButton = () => {
				const close = create('button.close[type="button"]');

				close.append(create('i.far.fa-times'));
				close.addEventListener('click', (event) => {
					event.preventDefault();
					event.stopPropagation();

					this.close();
				});

				header.append(close);
			};
		}
		return header;
	}

	get spacing() {
		return parseInt(this.getAttribute('spacing') || 0);
	}

	set spacing(value) {
		this.setAttribute('spacing', value);
		this.update();
	}

	get position() {
		return (this.getAttribute('position') || 'inline').split(',');
	}

	set position(value) {
		if (value) {
			this.setAttribute('position', Array.isArray(value) ? value.join(',') : value);
		} else {
			this.removeAttribute('position');
		}
	}

	set resolvedPosition(value) {
		this.setAttribute('resolved-position', value);
	}

	get relative() {
		return this._relative;
	}

	set relative(value) {
		if (typeof value === 'string') value = document.querySelector(value);
		if (this._relative === value) return;

		if (value) {
			this.appendChild(this._arrow);
		} else {
			this.removeChild(this._arrow);
		}

		this._relative = value;
		this.update();
	}

	get title() {
		return this.querySelector(':scope > imt-panel-header').textContent;
	}

	set title(value) {
		this.querySelector(':scope > imt-panel-header').textContent = value;
	}

	get width() {
		return this.__width;
	}

	set width(value) {
		value = parseInt(value);
		if (isNaN(value)) return;
		if (this.__width === value) return;
		this.__width = value;
		this.update();
	}

	get height() {
		return this.__height;
	}

	set height(value) {
		value = parseInt(value);
		if (isNaN(value)) return;
		if (this.__height === value) return;
		this.__width = value;
		this.update();
	}

	setLoading(status) {
		if (status) {
			this.header.classList.add('d-none');
			this.body.classList.add('d-none');
			this.footer.classList.add('d-none');

			let loading = this.querySelector('div.imt-panel-loading');

			if (!loading) {
				loading = create('div.imt-panel-loading');
				loading.append(create('i.far.fa-circle-notch.fa-spin.loading-loader'));
			}

			if ('string' === typeof status) {
				const statusText = loading.querySelector('span.status') || create('span.status');

				statusText.textContent = status;
				loading.append(statusText);
			}

			this.append(loading);
		} else {
			const loading = this.querySelector('div.imt-panel-loading');

			if (loading) loading.parentElement.removeChild(loading);

			this.header.classList.remove('d-none');
			this.body.classList.remove('d-none');
			this.footer.classList.remove('d-none');
		}
	}

	warn(message) {
		this.setLoading(false);

		const warningSelector = 'div.alert.alert-danger';
		const warning = create(warningSelector);
		const header = warning.appendChild(create('h6'));

		header.textContent = I18n.l('common.error');

		if (message instanceof Error) {
			const body = warning.appendChild(create('span'));

			body.textContent = message.detail || message.message;

			if (message.suberrors?.length) {
				const ul = body.appendChild(create('ul'));

				message.suberrors.forEach((err) => {
					const li = ul.appendChild(create('li'));

					li.textContent = err.detail || err.message;
					// li.prepend(create('i.far.fa-fw.fa-angle-right'));
				});
			}
		} else if (typeof message === 'string') {
			const body = warning.appendChild(create('span'));

			body.textContent = message;
		}

		const existingWarning = this.body.querySelector(warningSelector);

		if (existingWarning) {
			existingWarning.replaceWith(warning);
		} else {
			this.body.prepend(warning);
		}
	}

	close() {
		if (!this.parentNode) return;
		this.dispatchEvent(new CustomEvent(EVENTS.PANEL.CLOSE, { bubbles: true }));
		this.parentNode.removeChild(this);
	}

	open() {
		this.dispatchEvent(new CustomEvent(EVENTS.PANEL.OPEN));
		const relativeElement = this.relative?.closest('imt-forman');

		if (this.relative && !!relativeElement) {
			relativeElement.appendChild(this);
		} else {
			document.body.appendChild(this);
		}
	}

	update() {
		if (!this._built) return;

		const relative = this.relative;

		if (!relative || this.position.includes('inline')) {
			this.style.left = null;
			this.style.top = null;
			return;
		}

		const relativeOffset = relative.getBoundingClientRect();
		// const requiredWidth = this.outerWidth + this.spacing;
		const width = this.__width;
		const height = this.__height;

		if (width && width !== 'auto') this.style.width = `${width}px`;
		if (height && height !== 'auto') this.style.height = `${height}px`;

		// TODO: Handle special viewport cases
		// if (position === 'left' && relativeOffset.left - requiredWidth < PANEL_MARGIN) position = 'auto';
		// if (position === 'right' && window.innerWidth - relativeOffset.left < requiredWidth + PANEL_MARGIN && relativeOffset.left > requiredWidth)
		// position = 'auto';
		// if (position === 'auto') position = relativeOffset.left > (window.innerWidth - relativeOffset.left) ? 'left' : 'right';

		let { left, top, position } = this._resolvePosition(relativeOffset, this.position);

		this.resolvedPosition = position;

		// Prevent content overflowing the edge
		left = Math.ceil(Math.max(Math.min(left, window.innerWidth - this.offsetWidth - PANEL_MARGIN), PANEL_MARGIN));
		top = Math.ceil(Math.max(Math.min(top, window.innerHeight - this.offsetHeight - PANEL_MARGIN), PANEL_MARGIN));

		// Arrow setup
		if (position === 'right' || position === 'left') {
			this._arrow.style.left = '';
			this._arrow.style.top = `${relativeOffset.top + relativeOffset.height / 2 - top}px`;
		} else {
			this._arrow.style.left = `${relativeOffset.left + relativeOffset.width / 2 - left}px`;
			this._arrow.style.top = '';
		}

		this.style.left = `${left}px`;
		this.style.top = `${top}px`;
	}

	_resolvePosition(relativeOffset, positions) {
		let left = 0;
		let top = 0;
		let position;

		let initialLeft;
		let initialTop;
		let initialPosition;

		do {
			position = positions.shift();

			if (position === 'right') {
				left = relativeOffset.right + this.spacing;
				top = relativeOffset.top + relativeOffset.height / 2 - this.offsetHeight / 2;

				if (left + this.offsetWidth <= window.innerWidth) {
					return { left, top, position };
				}
			} else if (position === 'left') {
				left = relativeOffset.left - this.offsetWidth - this.spacing;
				top = relativeOffset.top + relativeOffset.height / 2 - this.offsetHeight / 2;

				if (left >= 0) {
					return { left, top, position };
				}
			} else if (position === 'bottom') {
				left = relativeOffset.left + relativeOffset.width / 2 - this.offsetWidth / 2;
				top = relativeOffset.bottom + this.spacing;

				if (top + this.offsetHeight <= window.innerHeight) {
					return { left, top, position };
				}
			} else if (position === 'top') {
				left = relativeOffset.left + relativeOffset.width / 2 - this.offsetWidth / 2;
				top = relativeOffset.top - this.offsetHeight - this.spacing;

				if (top >= 0) {
					return { left, top, position };
				}
			}
			initialLeft = initialLeft || left;
			initialTop = initialTop || top;
			initialPosition = initialPosition || position;
		} while (positions.length);

		// If nothing matches use initial position
		return {
			left: initialLeft,
			top: initialTop,
			position: initialPosition,
		};
	}
}

registerCustomElement('imt-panel', Panel);
