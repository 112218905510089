import { create, isNumber, isIterable, is } from '../utils.mjs';
import initDebug from './debug.mjs';

const debug = initDebug ? initDebug('imt:coder') : null;

export const SPACER = debug && debug.enabled ? '\u2022' : '\u200B'; // invisible: \u200B, bullet: \u2022

function has(object, name) {
	if (!object) return false;
	return Object.prototype.hasOwnProperty.call(object, name);
}

function clonePill(pill) {
	if (!pill) {
		return pill;
	}

	const result = pill.cloneNode(true);

	result._pill = pill._pill;
	result._type = pill._type;
	result.sample = pill.sample;

	return result;
}

function textToImlType(node) {
	if (isNumber(node.value)) {
		node.type = 'number';
		node.value = parseFloat(node.value);
	} else {
		node.type = 'string';
	}

	return node;
}

export const IML_CATEGORIES = ['variables', 'functions', 'operators', 'keywords'];
// wannabe enum
export const IML_CATEGORY = {
	VARIABLES: 'variables',
	FUNCTIONS: 'functions',
	OPERATORS: 'operators',
	KEYWORDS: 'keywords',
}

export function getIMLCategory(category, imlItems) {
	category = category.toUpperCase();
	const imlCategory = imlItems && imlItems.find(x => x.category.toUpperCase() === category);

	if (imlCategory) {
		if (!window.IML[category]) {
			return imlCategory.items;
		}

		return {...window.IML[category], ...imlCategory.items};
	}

	return window.IML[category];
}

export function createPill(type, name, additional, options, imlItems) {
	const customVariable = /^var.(:?\S+)\.(:?\S+)/.exec(name);

	if (customVariable) {
		if (customVariable[1] === 'input') {
			type = 'scenario_input';
		} else {
			type = customVariable[1] + '_variable';
		}

		name = customVariable[2];
	}

	const types = `${type}s`;

	const customCategory = imlItems && imlItems.find(x => x.category === types);
	const isCustomVar = customCategory && !IML_CATEGORIES.some(y => y === types);
	const isCustomFn = !isCustomVar && types === IML_CATEGORY.FUNCTIONS && customCategory?.items?.hasOwnProperty(name);

	const elm = create('imt-pill');

	elm.classList.add(type);
	elm.setAttribute('name', name);

	let label = name;

	switch (name) {
		case '&': {
			label = 'and';
			break;
		}
		case '|': {
			label = 'or';
			break;
		}
		case '%': {
			label = 'mod';
			break;
		}
	}

	const defaults = getIMLCategory(type.toUpperCase() + 'S', imlItems);
	const source = has(defaults, name) ? defaults[name] : null;

	if (isCustomVar && source?.label) {
		label = source.label;
	}

	elm.textContent = label;

	if (isCustomVar) {
		const customType = type.endsWith('_variable') ? type.split('_')[0] : type.split('_')[1];

		elm._pill = source;
		elm.setAttribute('custom-variable', `var.${customType}.${name}`);
		elm.setAttribute('custom-variable_type', type);
		elm.classList.add('custom-variable');
		const imlItem = imlItems.find(x => x.category === `${type}s`);

		elm.style.color = imlItem.color;
		elm.style.backgroundColor = imlItem.backgroundColor;

		if (source?.isSystem) {
			elm.setAttribute('custom-iml_system', true);
			if (source.help) {
				elm.setAttribute('custom-variable_help', source.help);
			}
		}

		if (source?.value || typeof source?.value === 'boolean') {
			elm.setAttribute('custom-variable_value', source.value);
		}
	} else if(isCustomFn) {
		if (!source?.isSystem) {
			elm.setAttribute('custom-iml_system', false);
		}
	}

	if (source) {
		elm.setAttribute('type', source.type);
		elm.setAttribute('group', source.group);
		if (source.label) elm.setAttribute('label', source.label);
	} else {
		const additionalSource = has(additional, name) ? additional[name] : null;

		// TODO: Copy/paste from old scripts, refactor to get module from metadata
		const getModule = (name) => {
			if (window['Inspector']?.instance?._surface?.find && window['Surface']?.Module) {
				const res = /^(\d+)\.(.*)$/.exec(name);

				if (res) {
					const id = parseInt(/^(\d+)\.(.*)$/.exec(name)[1]);
					const knownModule = window['Inspector'].instance._surface
						.find(window['Surface'].Module)
						.filter((item) => item.id === id)[0];

					return knownModule;
				}
			}
		};

		if (additionalSource) {
			elm.style.color = additionalSource._pill.theme.determineForegroundColor();
			elm.style.backgroundColor = additionalSource._pill.theme.toHex();
			elm._pill = additionalSource._pill;
			elm._type = additionalSource._type;
		} else {
			const knownModule = getModule(name);

			if (knownModule) {
				elm.classList.add('unknown');
				elm.style.color = knownModule.theme.toHex();
				elm.style.backgroundColor = 'transparent';
				if (options?.firstUnknown) {
					elm.style.setProperty('--pill-color', knownModule.theme.determineForegroundColor());
					elm.style.setProperty('--pill-backgroundColor', knownModule.theme.toHex());
					elm.classList.add('first-unknown');
				}
				elm.style.boxShadow = `inset 0px 0px 0px 1px ${knownModule.theme.toHex()}`;
			} else {
				elm.classList.add('unknown');
			}
		}
	}

	if (type === 'function') {
		elm.classList.add('first');
		if (source) {
			let argsLength = source?.value?.length || 0;

			if (source.custom) {
				elm.classList.add('custom-function');
				if (source.args?.includes && source.args.includes(',')) {
					argsLength = source.args.split(',').length;
				}
				elm.setAttribute('function-arguments', source.args.replaceAll(',', ';'));
				if (source.description) {
					elm.setAttribute('description', source.description);
				}
			}
			elm.setAttribute('arguments', argsLength);
		}
	}

	return elm;
}

/**
 * Creates AST into HTML DOM.
 *
 * @param ast AST.
 * @param variables Collection of custom variables.
 * @param functions Collection of custom functions.
 * @param incode Private indicator, should not be set from outside.
 */

export function astToDOM(ast, variables, functions, incode = false, domCache = null, imlItems = null) {
	const dom = [];
	let containsText = false;

	const showIDs = imt?.config?.showIDs;
	const getVariableText = (reference, pathName) => {
		let result = '';

		try {
			if (!reference || !pathName || !reference.includes(pathName)) {
				return result;
			}

			const prefix = reference.substring(0, reference.indexOf(pathName));
			const path = reference.substring(reference.indexOf(pathName));
			const pathSegments = path.split('.');

			let current = prefix;

			pathSegments.forEach((segment) => {
				if (!!current && current.charAt(current.length - 1) !== '.') {
					current += '.';
				}
				current += segment;

				const pillName = variables && variables[current]?._pill?.name;

				if (pillName) {
					result += (!!result ? '.' : '') + pillName;
				}
			});
		} catch (error) {
			console.error(error);
			result = null;
		}

		return result;
	};

	const render = (ast) => {
		let quotes = false;

		if (incode && containsText && ast.length === 1 && (ast[0].value === '' || isNumber(ast[0].value))) {
			quotes = true;
			dom.push(document.createTextNode('"'));
		}

		for (const item of ast) {
			switch (item.type) {
				case 'variable': {
					const variable = createPill('variable', item.name, variables, {
						path: item.path,
					}, imlItems);

					// Items with path are variables containing one or more property acessors (e.g. `variable[prop]`)
					if (item.path) {
						variable.classList.add('compound');

						let variableReference = '';
						let hasFirstUnknown = false;

						for (let i = 0; i < item.path.length; i++) {
							if (item.path[i].type === 'variable') {
								variableReference += item.path[i].name;
								let elm;

								if (!variable._pill) {
									elm = createPill('variable', variableReference, variables, { firstUnknown: !hasFirstUnknown }, imlItems);
									elm.classList.add('compound');
									if (!hasFirstUnknown && elm.classList.contains('unknown')) {
										hasFirstUnknown = true;
									}
								} else {
									elm = clonePill(variable);
								}

								elm.setAttribute('name', item.path[i].name);
								elm.textContent =
									getVariableText(variableReference, item.path[i].name) ||
									(variables && variables[variableReference]?._pill?.name) ||
									item.path[i].name;
								if (i === 0) {
									elm.classList.add('first');
									if (!elm.getAttribute('prefix') && variable._pill) {
										const pill = variable._pill;

										elm.setAttribute('prefix', showIDs && pill.node ? `${pill.node.id}. ` : '');
									} else if (showIDs) {
										const segments = item.path && item.path[0]?.name?.split('.');

										if (segments && !isNaN(segments[0])) {
											elm.setAttribute('prefix', `${segments[0]}. `);
										}
									}
								}
								if (i + 1 === item.path.length) elm.classList.add('last');
								dom.push(elm);
							} else if (item.path[i].type === 'property') {
								variableReference += '[].';
								dom.push(...astToDOM(item.path[i], variables, functions, true, null, imlItems));
								if (i + 1 === item.path.length) {
									// This is a direct reference to an array item, add ending bracket manually
									const elm = clonePill(variable);

									elm.removeAttribute('name');
									elm.textContent = ']';
									elm.classList.add('last');
									dom.push(elm);
								}
							}
						}
					} else {
						if (domCache && domCache[item.name]) {
							dom.push(clonePill(domCache[item.name]));
						} else {
							dom.push(variable);
						}
					}

					break;
				}

				case 'function':
					dom.push(createPill('function', item.name, functions, null, imlItems));

					for (let i = 0; i < item.arguments.length; i++) {
						const expr = item.arguments[i];

						if (i > 0) {
							dom.push(create('imt-pill.function.separator ;'));
						}

						dom.push(...astToDOM(expr, variables, functions, true, null, imlItems));
					}

					dom.push(create('imt-pill.function.last )'));
					break;

				case 'operator':
					if (!(containsText && item.name === '+')) {
						// ignore + operators in expressions with strings
						dom.push(createPill('operator', item.name, null, null, imlItems));
					}
					break;

				case 'number':
					dom.push(document.createTextNode(item.value.toString()));
					break;

				case 'text':
				case 'string':
					dom.push(document.createTextNode(item.value));
					break;

				case 'keyword':
					dom.push(createPill('keyword', item.name, null, null, imlItems));
					break;
			}
		}

		if (quotes) dom.push(document.createTextNode('"'));
	};

	const exp = [];
	let index = 0;

	while (ast[index]) {
		if (incode) {
			if (ast[index].type === 'operator' && ast[index].name !== '+') {
				render(exp); // render operand side
				render([ast[index]]); // render operator

				// reset
				exp.splice(0, exp.length);
				containsText = false;

				index++;
				continue;
			} else if (ast[index].type === 'string') {
				containsText = true;
			}
		}

		exp.push(ast[index]);
		index++;
	}

	if (exp.length) {
		render(exp);
	}

	return dom;
}

/**
 * Fine-tunes an IML layer.
 *
 * @param {array} layer IML array.
 * @param {boolean} isExpression Truthy when layer is argument of a function or property of a compound variable.
 */

function finalizeASTLayer(layer, isExpression, keepUnwantedOperators = false) {
	// TODO: Validate if it works as expected
	if (!layer.length) return layer;

	// find operators and convert text operands to number, otherwise remove them because they're useless
	let index = 0;

	while (index < layer.length) {
		if (layer[index].type === 'operator') {
			if (!keepUnwantedOperators) {
				if (index === 0 && layer[index].name !== '!') {
					// useless operator on the begining of the layer
					layer.shift();
					continue;
				} else if (index === layer.length - 1) {
					// useless operator on the end of the layer
					layer.pop();
					break;
				} else if (layer[index + 1].type === 'operator' && layer[index + 1].name !== '!') {
					// operator next to operator
					layer.splice(index, 1);
					continue;
				}
			}

			if (!isExpression) {
				if (layer[index - 1]?.type === 'text') {
					textToImlType(layer[index - 1]);
				}

				if (layer[index + 1]?.type === 'text') {
					textToImlType(layer[index + 1]);
				}
			}
		}

		index++;
	}

	if (isExpression && layer.length > 1) {
		let containsNonOperatedString = false;

		if (layer[0].type === 'string' && layer[layer.length - 1].type === 'string') {
			if (layer[0].value.charAt(0) === '"' && layer[layer.length - 1].value.slice(-1) === '"') {
				// remove quotes
				layer[0].value = layer[0].value.slice(1);
				layer[layer.length - 1].value = layer[layer.length - 1].value.slice(0, -1);

				if (layer[0].value === '') {
					layer.shift();
				}
				if (layer[layer.length - 1].value === '') {
					layer.pop();
				}
			}
		}

		let index = 0;

		while (index < layer.length) {
			if (
				layer[index].type === 'string' &&
				layer[index - 1]?.type !== 'operator' &&
				layer[index + 1]?.type !== 'operator'
			) {
				containsNonOperatedString = true;
				break;
			}

			index++;
		}

		if (containsNonOperatedString) {
			// if layer contains string that is not operated with, we must move operations to brackets
			index = 1; // there is no chance of operator on index 0, se we can start from 1
			while (index < layer.length) {
				if (layer[index].type === 'operator') {
					let subindex = index;
					const operation = IML.Array();

					operation.push(layer[subindex - 1]);

					while (layer[subindex]?.type === 'operator') {
						operation.push(layer[subindex]);
						operation.push(layer[subindex + 1]);
						subindex += 2;
					}

					// replace nodes with brackets
					layer.splice(index - 1, operation.length, {
						type: 'function',
						name: '', // brackets
						arguments: [operation],
					});
				}

				index++;
			}
		}
	}

	if (isExpression && layer.length > 1) {
		let index = 0;

		while (index < layer.length) {
			// if text node lay exactly next to the variable, add operator between them
			if (layer[index].type === 'string') {
				if (['variable', 'function', 'keyword'].includes(layer[index - 1]?.type)) {
					layer.splice(index++, 0, {
						type: 'operator',
						name: '+',
					});
				}

				if (['variable', 'function', 'keyword'].includes(layer[index + 1]?.type)) {
					layer.splice(index++ + 1, 0, {
						type: 'operator',
						name: '+',
					});
				}
			} else if (['variable', 'function', 'keyword'].includes(layer[index].type)) {
				if (['variable', 'function', 'keyword'].includes(layer[index - 1]?.type)) {
					layer.splice(index++, 0, {
						type: 'operator',
						name: '+',
					});
				}
			} else if (layer[index].type === 'operator' && layer[index].name === '!') {
				if (['variable', 'function', 'keyword'].includes(layer[index - 1]?.type)) {
					layer.splice(index++, 0, {
						type: 'operator',
						name: '&',
					});
				}
			}

			index++;
		}
	}

	return layer;
}

export function domToAST(nodes, keepUnwantedOperators = false) {
	if (!isIterable(nodes)) nodes = [nodes];

	let layer = IML.Array(undefined, undefined, { keepUnwantedOperators });
	const stack = [];

	function getParent(type) {
		if (!stack.length) return null;

		const opposite = type === 'function' ? 'variable' : 'function';
		let tempLayer = stack[stack.length - 1];
		let parent = tempLayer[tempLayer.length - 1];

		if (parent.type === type) return parent;

		// There's an unclosed function or compound variable, auto-close it
		while (parent && parent.type !== type) {
			parent._ref.setAttribute('invalid', `Unclosed ${opposite}.`);
			finalizeASTLayer(layer, true, keepUnwantedOperators);
			layer = stack.pop();

			// Move one level up
			if (!stack.length) return null;
			tempLayer = stack[stack.length - 1];
			parent = tempLayer[tempLayer.length - 1];
		}

		return parent;
	}

	for (const node of nodes) {
		if (node.nodeType === 3) {
			// Text node
			let text = node.textContent.replace(new RegExp(SPACER, 'g'), '');

			if (text.length) {
				const hasVariableSibling = () => {
					if (node.nextSibling) {
						if (is(node.nextSibling, 'imt-pill.variable') && !is(node.nextSibling, 'imt-pill.variable.compound')) {
							return true;
						}
					}
					if (node.previousSibling) {
						if (
							is(node.previousSibling, 'imt-pill.variable') &&
							!is(node.previousSibling, 'imt-pill.variable.compound')
						) {
							return true;
						}
					}

					return false;
				};

				if (stack.length) {
					// We're in arguments

					// TODO We can't trim as it removes expected spaces and breaks compatibility with formula
					// text = text.trim();
					if (text.trim().length > 0) {
						// if pill has sibling convert to text
						if (isNaN(text) || hasVariableSibling()) {
							if (/^\"([\s\S]*)\"$/.exec(text)) {
								if (RegExp.$1 === '' || isNumber(RegExp.$1)) {
									text = RegExp.$1;
								}
							}
							layer.push({
								type: 'string',
								value: text,
							});
						} else {
							layer.push({
								type: 'number',
								value: Number(text),
							});
						}
					}
				} else {
					// We're in the root
					layer.push({
						type: 'text',
						value: text,
					});
				}
			}
		} else if (is(node, 'imt-pill.function.first')) {
			node.removeAttribute('invalid');

			const func = {
				_ref: node,
				type: 'function',
				name: node.getAttribute('name'),
				arguments: [],
			};

			layer.push(func);
			stack.push(layer);
			layer = IML.Array();
			layer.type = 'argument';
			func.arguments.push(layer);
		} else if (is(node, 'imt-pill.function.last')) {
			const parent = getParent('function');

			if (!parent) continue; // We have not parent to add this node to, ignore

			finalizeASTLayer(layer, true, keepUnwantedOperators);

			parent._ref = undefined;
			layer = stack.pop();
		} else if (is(node, 'imt-pill.function.separator')) {
			const parent = getParent('function');

			if (!parent) continue; // We have not parent to add this node to, ignore

			finalizeASTLayer(layer, true, keepUnwantedOperators);

			layer = IML.Array();
			parent.arguments.push(layer);
		} else if (is(node, 'imt-pill.variable.compound.first')) {
			node.removeAttribute('invalid');

			const variable = {
				_ref: node,
				type: 'variable',
				path: [
					{
						type: 'variable',
						name: node.getAttribute('name'),
					},
				],
			};

			layer.push(variable);
			stack.push(layer);
			layer = IML.Array();
			layer.type = 'property';
			variable.path.push(layer);
		} else if (is(node, 'imt-pill.variable.compound.last')) {
			const parent = getParent('variable');

			if (!parent) continue; // We have not parent to add this node to, ignore

			finalizeASTLayer(layer, true, keepUnwantedOperators);

			const name = node.getAttribute('name');

			if (name) {
				parent.path.push({
					type: 'variable',
					name,
				});
			}

			parent._ref = undefined;
			layer = stack.pop();
		} else if (is(node, 'imt-pill.variable.compound')) {
			const parent = getParent('variable');

			if (!parent) continue; // We have not parent to add this node to, ignore

			finalizeASTLayer(layer, true, keepUnwantedOperators);

			parent.path.push({
				type: 'variable',
				name: node.getAttribute('name'),
			});

			layer = IML.Array();
			layer.type = 'property';
			parent.path.push(layer);
		} else if (is(node, 'imt-pill.variable')) {
			layer.push({
				type: 'variable',
				name: node.getAttribute('name'),
			});
		} else if (is(node, 'imt-pill.operator')) {
			layer.push({
				type: 'operator',
				name: node.getAttribute('name'),
			});
		} else if (is(node, 'imt-pill.keyword')) {
			layer.push({
				type: 'keyword',
				name: node.getAttribute('name'),
			});
		} else if (is(node, 'imt-pill.custom-variable')) {
			layer.push({
				type: 'variable',
				name: node.getAttribute('custom-variable'),
			});
		}
	}

	finalizeASTLayer(layer, false, keepUnwantedOperators);

	// Finalize unclosed layers
	while (stack.length) {
		layer = stack.pop();
		const last = layer[layer.length - 1];

		if (last.type === 'function') {
			last._ref.setAttribute('invalid', 'Unclosed function.');
		} else {
			last._ref.setAttribute('invalid', 'Unclosed variable.');
		}

		last._ref = undefined;
		finalizeASTLayer(layer, true, keepUnwantedOperators);
	}

	return layer;
}

function lookupFirst(node, selector) {
	let level = 0;

	// The node matches the selector
	if (is(node, selector)) return node;

	while (node) {
		node = node.previousSibling;

		if (level === 0 && is(node, selector)) {
			// First node found
			return node;
		}

		if (is(node, 'imt-pill.last')) {
			level++;
		} else if (is(node, 'imt-pill.first')) {
			level--;
		}
	}

	return node;
}

export function resolveFullDOM(node, relatedOnly = false) {
	let first;
	const level = [];
	let type;

	if (is(node, 'imt-pill.function')) {
		first = lookupFirst(node, 'imt-pill.function.first');
		type = 'function';
	} else if (is(node, 'imt-pill.variable.compound')) {
		first = lookupFirst(node, 'imt-pill.variable.compound.first');
		type = 'variable.compound';
	} else {
		// Not a compound type
		return [node];
	}

	if (!first) return [node]; // First node not found, return the input node alone
	node = first;

	const nodes = [node];

	// This is what we use as selector for target closing type.... and also can use it later to close unclosed compounds.
	level.push(`imt-pill.${type}.last`);

	// Create function when dragging from panel
	if (is(node, 'imt-pill.function')) {
		if (node.closest('.coder-pills')) {
			const attributes = parseInt(node.getAttribute('arguments') || 0);

			for (let i = 1; i < attributes; i++) {
				nodes.push(create('imt-pill.function.separator ;'));
			}

			nodes.push(create('imt-pill.function.last )'));

			return nodes;
		}
	}

	while (node.nextSibling) {
		node = node.nextSibling;
		nodes.push(node);

		if (is(node, 'imt-pill.first')) {
			level.push(`imt-pill.${is(node, '.function') ? 'function' : 'variable.compound'}.last`);
			if (relatedOnly) nodes.pop();
		} else if (level.length > 0 && is(node, level[level.length - 1])) {
			level.pop();

			if (level.length === 0) {
				return nodes;
			}

			if (relatedOnly) nodes.pop();
		} else if (relatedOnly && level.length > 1) {
			nodes.pop();
		}
	}

	// Ignore unclosed function or variable

	return nodes;
}
