/**
 * This file is used by both font-end and back-end.
 */

import { ValidationError } from '../helpers/errors.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	if (value == null || value === '') return null;

	value = Number(value);
	if (isNaN(value)) return null;

	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null || value === '') return null;

	if (typeof value === 'object' || isNaN(value)) {
		throw new ValidationError('Invalid number.', 'validate-number-invalid');
	}

	value = Number(value);

	// Custom validations
	const validate = instructions.validate || {};

	if (validate.max != null && value > validate.max) {
		throw new ValidationError(`Value is too big. Maximum value is ${validate.max}.`, 'validate-number-too-big', {
			max: validate.max,
		});
	}

	if (validate != null && value < validate.min) {
		throw new ValidationError(`Value is too small. Minimum value is ${validate.min}.`, 'validate-number-too-small', {
			min: validate.min,
		});
	}

	return value;
}
