import { Input } from './input.mjs';
import { create, registerCustomElement, clone, showNestedFieldsets } from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class FolderInput extends Input {
	constructor() {
		super();

		this._control = null;
	}

	get value() {
		if (this.mode === 'map') return this._coder.value;

		return this._getValue();
	}

	set value(value) {
		if (this.mode === 'map') {
			this._value = value;
			return;
		}

		if (this._getValue() === value) return;

		this._control.innerHTML = '';
		this._setValue(value);
	}

	get hasModeSwitch() {
		return this.mappable;
	}

	get isFileSelect() {
		return false;
	}

	get mode() {
		return this.getAttribute('mode') || 'pick';
	}

	set mode(value) {
		value = this._parseMode(value);
		if (this.mode === value || this.loading) return;

		this.dispatchEvent(new CustomEvent(EVENTS.INPUT.MODE_CHANGE, { detail: value, bubbles: true }));

		if (value === 'map') {
			// Transfer current value to Coder
			this._coder.value = this._toCoderValue(this.value);

			this.insertBefore(this._coder, this._label.nextElementSibling);
		} else {
			this.removeChild(this._coder);
		}

		this.setAttribute('mode', value);

		if (value !== 'map') {
			this.value = '';
		}

		if (this._building) {
			return;
		}

		this.setHelp();
		this.hints();
		this.validate();

		this.querySelector('imt-switch[type="mode-switch"]').active = this.mode === 'map';

		this.setIcon();

		// Notify other that we have changed display mode
		this.dispatchEvent(new CustomEvent(EVENTS.INPUT.MODE_CHANGED, { detail: value, bubbles: true }));
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		return Object.assign(super.attributesToInstructions(), {
			options: this.getAttribute('options'),
			ids: this.hasAttribute('ids'),
			showRoot: this.hasAttribute('show-root'),
			singleLevel: this.getAttribute('single-level'),
		});
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		instructions.options = Picker.normalizeOptions(instructions.options);

		if ('undefined' === typeof instructions.options.showRoot) {
			instructions.options.showRoot = true;
		}

		this._control = this.appendChild(create('div.form-control.wrapper'));

		if (metadata?.restore?.mode === 'edit') {
			this.mode = 'map';
			this._coder.value = value;
			this._value = value;
		} else {
			this._setValue(value, metadata);
		}

		showNestedFieldsets(this, metadata?.fieldset?.value, metadata?.fieldset?.metadata);
	}

	addFolder(initialValue, label, addButton, isFile) {
		const options = this._instructions.options;

		for (const folder of this._control.childNodes) {
			folder.classList.remove('imt-input-folder-add');
		}

		const folder = create('imt-picker');

		folder.defaultClassList = [];
		folder.inputField = this;

		if (addButton) folder.classList.add('imt-input-folder-add');

		folder.register(Picker.COMPONENTS.RPC_DATA_GETTER, () => {
			return Object.assign(this.fieldset.rpcData, {
				[this.name]: this._getValue(folder) ? this._getValue(folder) : this._isRoot(folder) ? '/' : null,
			});
		});

		folder.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item) => {
			if (item.file) {
				option.setAttribute('data-type', 'file');
			} else {
				option.setAttribute('data-type', 'folder');
			}
		});

		if (!Array.isArray(options.store)) folder.setAttribute('provider', options.store);

		folder.setAttribute('label-field', options.label || 'label');
		folder.setAttribute('value-field', options.value || 'value');

		if (initialValue) {
			folder.addOptions(clone(options), initialValue, label ? { restore: { label: label } } : undefined, true);
			if (this._isRoot() && initialValue === '/') folder.classList.add('imt-input-folder-root');
		}

		if (isFile) {
			folder.firstChild.setAttribute('data-type', 'file');
		}

		if (!this._control.childNodes.length) {
			folder.setAttribute(
				'placeholder',
				options.placeholder || `Click here to choose ${this._instructions.type === 'folder' ? 'folder' : 'file'}`,
			);

			if (this._isRoot()) {
				folder.staticOptions = [{ label: '/', value: '/' }];
			}
		}

		folder.addEventListener(EVENTS.PICKER.CHANGED, () => {
			if (this._isRoot(folder) && folder.value === '/') {
				folder.classList.add('imt-input-folder-root');
			} else {
				folder.classList.remove('imt-input-folder-root');
			}

			if (folder.value) {
				folder.classList.remove('imt-input-folder-add');
			}

			// On change remove all subsequent folders
			while (folder.nextElementSibling) {
				folder.nextElementSibling.remove();
			}

			const prevFolder = folder.previousElementSibling;

			// If updating second folder after a root folder set to /
			if (this._instructions.options.showRoot && prevFolder && this._isRoot(prevFolder) && prevFolder.value === '/') {
				// if prevFolder does't have loaded options
				if (
					!prevFolder?.options?.store ||
					!Array.isArray(prevFolder.options.store) ||
					!prevFolder.options.store.some((x) => x.value === folder.value)
				) {
					// copy folder options to prevFolder
					prevFolder.loadCustomOptions(folder.options, folder.value);
				} else {
					prevFolder.value = folder.value;
				}

				prevFolder.classList.remove('imt-input-folder-root');
				folder.value = undefined;
			} else {
				if (
					'undefined' !== typeof folder.value &&
					!this._instructions.options.singleLevel &&
					folder.valueType !== 'file'
				) {
					this.addFolder(null, null, true);
				}
			}

			this.dispatchEvent(new CustomEvent('input', { bubbles: true }));

			showNestedFieldsets(this);
		});

		this._control.appendChild(folder);
	}

	getState() {
		const state = super.getState();

		if (this._instructions.options.ids) {
			state.path = this._getPathElements()
				.map((elm) => {
					const folder = elm.getSelectedElements();

					if (folder && folder[0]) {
						return folder[0].textContent;
					}
				})
				.filter((elm) => elm);
		}

		return state;
	}

	_setValue(value, metadata) {
		if (value) {
			if (value === '/') {
				this.addFolder('/');
			} else {
				const restore = metadata?.restore?.path;
				const folders = (this._instructions.options.showRoot ? value.replace(/^\//, '') : value).split('/');

				folders.forEach((folder, i) => {
					this.addFolder(
						folder,
						restore ? restore[i] : undefined,
						false,
						this.isFileSelect && i === folders.length - 1,
					);
				});
			}
			if (!this._instructions.options.singleLevel && !(this.isFileSelect && value && value !== '/')) {
				this.addFolder(null, null, true);
			}
		} else {
			this.addFolder(null, null, true);
		}
	}

	_getPathElements(element) {
		const elements = [];

		for (const child of this._control.childNodes) {
			if (element === child) break; // Get path till the reference folder/file
			if (child.nodeName === 'IMT-PICKER') {
				elements.push(child);
			}
		}

		return elements;
	}

	_getValue(element, onlyLabel = false) {
		const elements = this._getPathElements(element);

		if (!elements.length) return null;

		const value = elements
			.filter((f) => !f.classList.contains('imt-input-folder-add'))
			.map((elm) => (onlyLabel ? elm.valueLabel : elm.value))
			.filter((f) => f);

		if (value.length === 1 && value[0] === '/') return '/';

		if (value.length) {
			return (this._instructions.options.showRoot ? '/' : '') + value.join('/').replace(/^\/\//, '/');
		}

		return null;
	}

	_isRoot(element) {
		const path = this._getPathElements();

		return this._instructions.options.showRoot && (path.length === 1 || path[0] === element);
	}

	_setDisabled(disabled) {
		this._getPathElements().forEach((el) => {
			el.disabled = disabled;
		});
	}

	getPreview() {
		const value = this._getValue(null, true);

		if (!value) {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		}

		return value;
	}
}

registerCustomElement('imt-input-folder', FolderInput);
