import {
	uuid,
	create,
	is,
	markdown,
	isAttribute,
	registerCustomElement,
	showNestedFieldsets,
	getExtendInstructions,
	clone,
	dispatchResizeDebounced,
} from '../utils.mjs';
import { validate } from '../types.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import EVENTS from '../events.mjs';
import Loader from '../loader.mjs';
import { I18n } from '../helpers/i18n.mjs';
import configs from '../configs/config.mjs';
import { astToDOM } from '../helpers/iml.mjs';
import { Coder } from '../controls/coder.mjs';

const TYPES_WITH_ICON = ['text', 'number', 'date', 'password', 'integer', 'uinteger', 'email', 'time'];

/**
 * @emits modechange Emitted when mode has changed. Does not bubble.
 */

export class Input extends HTMLElement {
	constructor() {
		super();

		this._id = uuid();
		this._built = false;
		this._building = false;
		this._reportValueChange = true;

		this._pristine = true;
		this._untouched = true;
		this._valid = true;
		this._cache = new Map();
		this._nestedFieldsets = [];
		this._validators = [validate];

		this.addEventListener('input', (event) => {
			// Input events from map mode switches are also handled by this handler so
			// make sure we only process events generated by the input, not by the switch.
			if (is(event.target, 'imt-switch')) return;

			// Do not handle value change if the field is not built. It prevents handling 'input' events
			// of nested inputs that bubbles up the tree on parent input (e.g. collection) before it's fully built.
			if (!this._built || (this._built && this._building)) return;

			// The event 'input' should be listened on this input only not on nested inputs where the event bubbles up the tree
			if (this.querySelector('imt-nested')?.contains(event.target)) return;

			this.onChange();

			if (this.isInternalChangeEvent(event)) return;

			if (this.form) {
				this.form.debug('events', 'input event handled on', this._instructions.name);
			} else {
				console.log('input event handled on not attached field', this._instructions.name);
			}

			if (this._reportValueChange && this.form) {
				this.form.values.set(this.fieldset.domain, this.path, this.value, this);
			}
		});

		this.addEventListener('focusin', (event) => {
			if (is(event.target, 'imt-switch') && event.target.getAttribute('type') === 'mode-switch') {
				return;
			}

			this.untouched = false;
			this.field.classList.add('focused');
			event.preventDefault();
			event.stopPropagation();
		});

		this.addEventListener('focusout', (event) => {
			const activeElm = document.activeElement;
			const contains = this.field.contains && this.field.contains(activeElm);

			if (contains || this.hasConnectedCoderPanel) {
				return;
			}
			this.field.classList.remove('focused');
		});

		this.addEventListener('click', (event) => {
			event.stopPropagation();
			this.collapsed = false;
		});

		// TODO: this.addEventListener('focusout', this.validate.bind(this));
	}

	connectedCallback() {
		if (this.collapsed) {
			this._setPreview();
		}
	}

	castValue(value) {
		return value;
	}

	isInternalChangeEvent(event) {
		return false;
	}

	get castedValue() {
		return this.castValue(this.value);
	}

	get valid() {
		return this._valid;
	}

	set valid(value) {
		if (value) {
			this.removeAttribute('invalid');

			this.classList.add('valid');
			this.classList.remove('invalid');
		} else {
			this.setAttribute('invalid', '');

			this.classList.add('invalid');
			this.classList.remove('valid');
		}
		this._valid = value;
	}

	get pristine() {
		return this._pristine;
	}

	set pristine(value) {
		if (value) {
			this.classList.add('pristine');
			this.classList.remove('dirty');
		} else {
			this.classList.add('dirty');
			this.classList.remove('pristine');
		}
		this._pristine = value;
	}

	get untouched() {
		return !this._untouched;
	}

	set untouched(value) {
		if (value) {
			this.classList.add('untouched');
			this.classList.remove('touched');
		} else {
			this.classList.add('touched');
			this.classList.remove('untouched');
		}
		this._untouched = value;
	}

	get hasModeSwitch() {
		return false;
	}

	get disabled() {
		return isAttribute(this, 'disabled');
	}

	set disabled(value) {
		if (value === this.hasAttribute('disabled')) return;

		if (value) {
			if (this.panel?.panel) {
				this.panel.panel.close();
			}

			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}

		// disable all nested fieldsets
		for (const fieldset of this.querySelectorAll('imt-nested > imt-fieldset')) {
			if (value) {
				fieldset.disable();
			} else {
				fieldset.enable();
			}
		}

		if (this._coder) {
			this._coder.disabled = value;
		}

		// disable all buttons associated with this input
		for (const btn of this.querySelectorAll('button')) {
			if (value) {
				btn.setAttribute('disabled', '');
			} else {
				btn.removeAttribute('disabled');
			}
		}

		this._setDisabled(value);
	}

	get readonly() {
		return isAttribute(this, 'readonly');
	}

	get omit() {
		return isAttribute(this, 'omit');
	}

	get advanced() {
		return isAttribute(this, 'advanced');
	}

	get required() {
		return isAttribute(this, 'required');
	}

	get mappable() {
		if (this.fieldset?.mappable) {
			if (this.hasAttribute('mappable')) {
				if (isAttribute(this, 'mappable')) return true;
			} else {
				if (this.fieldset.defaultMappable) return true;
			}
		}

		// Backward compatibility with Formula
		// In formula every input of the array item is mappable by default
		if (this.closest('imt-input-array-item')) return true;

		return false;
	}

	get erasable() {
		if (this.hasAttribute('erasable')) return isAttribute(this, 'erasable');

		return this.fieldset?.erasable;
	}

	get mode() {
		return this.getAttribute('mode') || 'pick';
	}

	set mode(value) {
		value = this._parseMode(value);
		if (this.mode === value || this.loading) return;

		this.dispatchEvent(new CustomEvent(EVENTS.INPUT.MODE_CHANGE, { detail: value, bubbles: true, cancelable: true }));

		if (value === 'map') {
			// Transfer current value to Coder
			this._coder.value = this._toCoderValue(this.value);

			this.insertBefore(this._coder, this._label.nextElementSibling);
		} else {
			this.removeChild(this._coder);
		}

		this.setAttribute('mode', value);

		if (value !== 'map') {
			// Transfer value from coder to picker
			this.value = this._fromCoderValue(this._coder.value);
		}

		this.setHelp();
		this.hints();
		this.validate();

		this.querySelector('imt-switch[type="mode-switch"]').active = this.mode === 'map';

		this.setIcon();

		// Notify other that we have changed display mode
		this.dispatchEvent(new CustomEvent(EVENTS.INPUT.MODE_CHANGED, { detail: value, bubbles: true }));
	}

	get name() {
		return this._instructions.name;
	}

	get path() {
		if (this._cache.has('path')) {
			return this._cache.get('path');
		}

		const parent = this.parentNode && this.parentNode.closest('imt-input-collection, [type="array-item"]');

		const name = this._instructions.name;

		if (!parent) return name;

		const path = `${parent.path}${
			typeof name !== 'undefined' ? '.' + (typeof name === 'number' ? name : IML._escape(this._instructions.name)) : ''
		}`;

		this._cache.set('path', path);

		return path;
	}

	get form() {
		if (!this._form) {
			this._form = this.closest('imt-forman');
		}

		return this._form;
	}

	get empty() {
		if (this._empty === undefined) {
			this._empty = this.classList.contains('empty');
		}

		return this._empty;
	}

	set empty(value) {
		if (this.empty === value) return;

		this._empty = value;

		if (value) {
			this.classList.add('empty');
		} else {
			this.classList.remove('empty');
		}
	}

	get fieldset() {
		return this.closest('imt-fieldset:not([nested])');
	}

	get parentField() {
		return this.closest('[field]:not(:scope)');
	}

	get field() {
		return this.closest('[field]');
	}

	get loading() {
		return this.classList.contains('loading');
	}

	set loading(value) {
		if (this.loading === value) return;

		if (value) {
			this.classList.add('loading');
		} else {
			this.classList.remove('loading');
		}

		// this.disabled = value;
	}

	get collapsed() {
		return this.classList.contains('collapsed');
	}

	set collapsed(value) {
		if (this.collapsed === value) return;

		if (value) {
			this.classList.add('collapsed');
			this._setPreview();
		} else {
			this.classList.remove('collapsed');
		}
	}

	get hasConnectedCoderPanel() {
		return (
			!!this._control?.panelController?.panel?.isConnected &&
			this._control.panelController.panel.relative === this._control
		);
	}

	get nestedFieldset() {
		return this._nestedFieldsets.find((f) => !f.hidden);
	}

	get skipValidation() {
		return this._instructions && 'validate' in this._instructions && this._instructions.validate === false;
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		const instructions = {
			name: this.getAttribute('name'),
			label: this.getAttribute('label'),
			help: this.getAttribute('help'),
			required: this.hasAttribute('required'),
			disabled: this.hasAttribute('disabled'),
			readonly: this.hasAttribute('readonly'),
			advanced: this.hasAttribute('advanced'),
			omit: this.hasAttribute('omit'),
			default: this.getAttribute('default'),
			type: this.nodeName.toLowerCase().match(/^imt-input-(.*)$/)[1],
		};

		const extendedType = this.getAttribute('type');

		if (extendedType) {
			const extendedInstructions = getExtendInstructions(instructions, extendedType, configs.extendedFields);

			if (extendedInstructions) {
				return extendedInstructions;
			}
		}

		return instructions;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Initial value.
	 * @param {object} metadata Metadata.
	 */

	async build(instructions, value, metadata = false) {
		if (this._built) return;
		this._building = true;
		this._built = true;

		// TODO [field] and .form-group seems to be used as the same selector, reduce it to use only .form-group
		this.setAttribute('field', '');

		this.className = 'form-group empty';

		if (!this.form) throw Error('Element must be attached to Forman DOM before it can be built.');

		// If input was built from html element, build instructions from the node
		if (!instructions) instructions = this.attributesToInstructions();

		// WARNING All instructions operations have to be done after this line.
		// The instructions parameter is undefined if the input is created from the DOM
		// and is set by this.attributesToInstructions();

		instructions = clone(instructions, true);
		value = clone(value, true);

		if (typeof instructions.type === 'undefined') {
			console.warn(
				`${instructions.label} type is undefined, fallbacking to 'text'. You should change config to include the type as this fallback might be removed in the future!`,
			);
			instructions.type = 'text';
		}

		if (instructions.advanced) {
			this.form.hasAdvancedParameters = true;

			if (!this.form.advancedParametersVisible) this.classList.add('d-none');
		}

		// Store instructions for further usage
		this._instructions = instructions;

		// Handle value changes on other fields with the same path
		this.form.values.on('change', (domain, path, value, sender) => {
			if (!this.fieldset || !this.isConnected) return; // Input not attached to the DOM TODO Find a better solution

			if (domain !== this.fieldset.domain || path !== this.path || sender === this) return;

			this._reportValueChange = false;
			this.value = value;
			this._reportValueChange = true;

			// Validate new value
			setTimeout(this.validate.bind(this), 1);
		});

		// Resolve initial value
		if (value === undefined || value === '') {
			// Value is empty, let's check whether there's a value in values store from another field with same name
			value = this.form.values.get(this.fieldset.domain, this.path);
		}

		// Because of OAuth flow
		if (instructions.visible === false) this.classList.add('d-none');

		if (instructions.name) this.setAttribute('name', instructions.name);
		if (instructions.required) this.setAttribute('required', '');

		// Formula maxResults default default value hack, should be defined in the instructions directly
		if (
			instructions.name === 'maxResults' &&
			/{{l:panels\.module\.maxresults@inspector}}/.test(instructions.help) &&
			typeof instructions.default === 'undefined'
		) {
			instructions.default = 1;
		}

		if ((value === undefined || value === '') && instructions.default && !metadata?.restore?.empty) {
			value = instructions.default;
		}

		// Store initial value to form's values
		this.form.values.set(this.fieldset.domain, this.path, value, this, true);

		if (instructions.readonly) this.setAttribute('readonly', '');
		if (instructions.advanced) this.setAttribute('advanced', '');

		let mappable = undefined;

		if ('editable' in instructions) mappable = instructions.editable;
		if ('mappable' in instructions) mappable = instructions.mappable;
		if (typeof mappable !== 'undefined') {
			if (Object(mappable) === mappable) {
				this.setAttribute('mappable', 'enabled' in mappable ? mappable.enabled : true);
			} else {
				this.setAttribute('mappable', mappable);
			}
		}
		if (instructions.erasable === false) this.setAttribute('erasable', 'false');
		if (instructions.omit) this.setAttribute('omit', '');

		this.inputType = instructions.type;
		this.specType = this.inputType === 'select' ? instructions?.spec?.type : null;

		const expander = create('div.form-expander');

		expander.appendChild(create('span')); // To make animations possible without rotating whole square
		this.appendChild(expander);
		expander.addEventListener('click', (event) => {
			this.validate();
			this.collapsed = !this.collapsed;
			dispatchResizeDebounced(this);
			event.stopImmediatePropagation();
		});

		this._label = create('label');
		this._label.setAttribute('for', this._id);
		this.appendChild(this._label);

		// Add textContent after the icon
		// markdown escapes HTML, therefore innerHTML is safe to use
		const labelContent = create('span.label-text-content');

		labelContent.innerHTML = instructions.label ? markdown(instructions.label) : instructions.name;
		this._label.appendChild(labelContent);
		this._labelContent = labelContent;
		this.setIcon();

		// Input actions wrapper
		this._actions = this._label.appendChild(create('div.input-actions'));
		this._labelPreview = this._label.insertBefore(create('div.label-preview'), this._actions);

		let modeSwitch;

		if (!instructions.readonly && this.hasModeSwitch) {
			// Attach extra text input for map mode
			this._coder = create(`imt-coder#${this._id}_coder`);
			this._coder.value = value != null ? value : '';
			if (instructions.readonly) this._coder.readonly = true;
			if (instructions.disabled) this._coder.disabled = true;

			const modeSwitchWrapper = create('div.mode-switch');
			const label = create('span.mode-switch-label.ml-1.text-muted');

			label.textContent = I18n.l('buttons.map');

			// Attach mode switch
			modeSwitch = create('imt-switch.xs[type="mode-switch"]');

			modeSwitch.addEventListener('input', () => {
				this.mode = modeSwitch.active ? 'map' : 'pick';
				this.form.saveFieldsetState(this.fieldset);
			});

			modeSwitchWrapper.append(modeSwitch, label);
			this._actions.append(modeSwitchWrapper);

			this.form.addEventListener(EVENTS.INPUT.MODE_CHANGED, (event) => {
				if (event.target === this || event.target.path !== this.path || this.parentField === event.target.parentField)
					return;
				this.mode = event.target.mode;
				modeSwitch.active = this.mode === 'map';
			});
		}

		this.addEventListener(EVENTS.INPUT.HAS_DYNAMIC_NESTED, (event) => {
			if (!instructions.readonly && !this._actions.querySelector('div.nested-refresh')) {
				const nestedRefreshWrapper = create('div.nested-refresh.text-muted');
				const refresh = create('i.fa.fa-fw.fa-redo.nested-refresh-button');
				const label = create('span.nested-refresh-label.ml-1');

				label.textContent = I18n.l('buttons.refresh');

				nestedRefreshWrapper.addEventListener('click', () => {
					this.querySelector('imt-nested').remove();

					if (this.nodeName === 'IMT-INPUT-SELECT') {
						this.loading = true;
						this._control.reload().then(() => {
							showNestedFieldsets(this);
							this.loading = false;
						});
					} else {
						showNestedFieldsets(this);
					}
				});

				nestedRefreshWrapper.append(refresh, label);
				this._actions.appendChild(nestedRefreshWrapper);
			}
		});

		instructions = this.extendInstructions(instructions, value, metadata);

		this._validationMessages = create('div.form-validations');
		this._errorMessages = create('div.form-errors');

		await this._build(instructions, value, metadata);

		this.append(this._errorMessages, this._validationMessages);

		this.setHelp();

		const hints = create('ul.form-hints');

		this.appendChild(hints);
		this.hints();

		const loader = create('div.loader');

		loader.append(
			create('div.loader-circle'),
			create('div.loader-circle'),
			create('div.loader-circle'),
			create('div.loader-circle'),
		);
		this.appendChild(loader);

		// Re-append the nested fieldsets to keep them as the last element of the input
		// in order to preserve hints and validation errors immediately below the input
		const nested = this.querySelector(':scope > imt-nested');

		if (nested) this.appendChild(nested);

		if (!instructions.readonly && this.hasModeSwitch) {
			// This must occurs after the input field is built
			const currentMode = this.mode;

			this.mode = metadata?.restore?.mode || this._instructions.mode || 'pick';
			modeSwitch.active = this.mode === 'map';
			if (currentMode !== this.mode && this.value !== value) {
				this.value = value;
			}
		}

		this.disabled = !!instructions.disabled;

		// Validate initial value
		this._init();

		if (metadata?.restore?.collapsed) this.collapsed = true;
		this._building = false;
	}

	setHelp() {
		const instructions = this._instructions;
		let help = instructions.help || '';

		if (this.mode === 'map') {
			help = instructions?.mappable?.help || instructions?.editable?.help || help;
		}

		if (!this.helpElement) {
			this.helpElement = create(`div.form-text.text-muted`);
			this.appendChild(this.helpElement);
		}

		this.helpElement.innerHTML = markdown(help);
	}

	setTitle(titleType) {
		const title = `Type: ${titleType}`;

		if (window.jQuery) {
			if (this._labelContent.hasAttribute('data-original-title')) {
				jQuery(this._labelContent).attr('data-original-title', title);
			} else {
				jQuery(this._labelContent).tooltip({ title });
			}
		}
	}

	setIcon() {
		const prependIcon = (type) => {
			const icon = create(`div.form-icon.form-icon-${type}`);

			if (this.hasAttribute('type')) {
				icon.classList.add(`type-${this.getAttribute('type')}`);
			}

			this._label.prepend(icon);
		};

		const mapType = this.specType || this.inputType;

		if (TYPES_WITH_ICON.includes(this.inputType)) {
			prependIcon(this.inputType);
			this.setTitle(this.inputType);
		} else if (this.mode === 'map' && TYPES_WITH_ICON.includes(mapType)) {
			prependIcon(this.specType || this.inputType);
			this.setTitle(this.specType || this.inputType);
		} else {
			const icon = this._label.querySelector('.form-icon');

			if (icon) {
				this._label.removeChild(icon);
			}

			this.setTitle(this.inputType);
		}
	}

	/**
	 * Updates list of hints with most recent values based on the current mode.
	 */

	hints() {
		if (!this._built) return;

		const wrapper = this.querySelector(':scope > ul.form-hints');

		wrapper.innerHTML = '';

		this._hints((icon, text) => {
			const li = create('li.text-muted');
			const span = create('span');

			span.innerHTML = markdown(text);
			li.appendChild(create(`i.far.fa-fw.fa-${icon}`));
			li.appendChild(span);
			wrapper.appendChild(li);
		});
	}

	/**
	 * Builds the list of hints to be shown with the field.
	 */

	_hints(hint) {
		// Nothing to do here, whould be overrided by the specific field implementation.
	}

	/**
	 * Forces validate and mark field as touched (shows errors)
	 * @return {boolean} Method returns false to indicate this field is no valid. Otherwise, returns true.
	 */
	validate() {
		this.untouched = false;
		this._runValidators();

		return this.valid;
	}

	_init() {
		this.pristine = true;
		this.untouched = true;
		this.valid = true;

		this._runValidators(true);
	}

	onChange() {
		this.pristine = false;
		this.untouched = false;

		this._runValidators(false);
	}

	_runValidators(init = false) {
		if (!this._built) return;

		if (this.skipValidation) {
			return true;
		}

		if (this.form) {
			this.form.debug('validation', `validating value '${this.name}' in domain '${this.fieldset?.domain}'`);
		}

		const value = this.value;
		const problems = [];
		let valid = true;

		this.empty = value == null || value === '';

		if (this.containsIML()) {
			// When value contains IML, use Coder validations instead
			problems.push(...this._coder.errors);
		} else {
			// Any _validation method can return false to indicate this field is no valid without providing a reason
			valid = this._validate(value, problems, init);
		}

		if (problems.length || valid === false) {
			if (problems.length) {
				const msgs = problems.map((err) => {
					if (typeof err === 'string') return err;
					return err.message;
				});

				this.errors = msgs;
				this._validationMessages.textContent = msgs.join('\n');
			}

			if (this.valid) {
				this.valid = false;
				dispatchResizeDebounced(this);
			}

			return false;
		} else {
			this.errors = [];
			if (!this.valid) {
				this.valid = true;
				dispatchResizeDebounced(this);
			}
			return true;
		}
	}

	/**
	 * Performs value validation for type defined by this input field.
	 *
	 * @param {*} value Value to validate, same as getting `this.value`.
	 * @param {Array} problems Array of strings containing all generated validation problems.
	 * @returns {boolean} Method can return false to indicate this field is no valid without providing a reason. Otherwise, return value is ignored.
	 */

	_validate(value, problems) {
		for (const validator of this._validators) {
			try {
				validator(value, this._instructions.type, this._instructions, {
					timezone: this.form.timezone,
					depth: 0, // Prevents validation of nested structures.
				});
			} catch (err) {
				problems.push(err);
			}
		}
	}

	extendInstructions(instructions, value, metadata) {
		return instructions;
	}

	addServerValidationError(message) {
		this.setAttribute('invalid', '');
		this._validationMessages.textContent = message;
	}

	_toCoderValue(value) {
		return value;
	}

	_fromCoderValue(value) {
		return value;
	}

	_parseMode(value) {
		if (value !== 'map' && value !== 'pick') {
			// edit = map, choose = pick - backward compatibility with Integromat 1.x
			if (value === 'edit') {
				value = 'map';
			} else if (value === 'chose' || value === 'choose') {
				// We have to support both 'chose' and 'choose'. 'chose' is used in blueprint and 'choose' is defined in apps doc
				value = 'pick';
			} else {
				throw new Error(`Invalid mode '${value}'.`);
			}
		}

		return value;
	}

	getState() {
		const state = {};

		if (!this._instructions.readonly && this.hasModeSwitch) {
			state.mode = this.mode === 'pick' ? 'chose' : 'edit'; // TODO Set mode to be compatible with Formula in case we have to switch back to Formula
		}

		if (this.collapsed) {
			state.collapsed = true;
		}

		if ((this.value === undefined || this.value === '') && this._instructions.default) {
			state.empty = true;
		}

		const nestedFieldset = this.querySelector(
			':scope > imt-nested > imt-fieldset[dynamic]:not([disabled]):not([hidden])',
		);

		if (nestedFieldset) {
			state.nested = nestedFieldset.fields.map((field) => field._instructions);
		}

		return state;
	}

	getValidateInstructions() {
		const instructions = {
			name: this._instructions.name,
			type: this._instructions.type,
		};

		if (this._instructions.label !== this._instructions.name) {
			instructions.label = this._instructions.label;
		}

		if (this._instructions.validate) {
			instructions.validate = this._instructions.validate;
		}

		if (this._instructions.metadata) {
			instructions.metadata = this._instructions.metadata;
		}

		if (this._instructions.mode) {
			instructions.mode = this._instructions.mode;
		}

		if (this.required) {
			instructions.required = true;
		}

		return instructions;
	}

	containsIML() {
		return this.mode === 'map' && this._coder && this._coder.containsIML;
	}

	getPreview(value) {
		value = this.value;

		if (value === null || value === undefined || value === '') {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		} else if (typeof value === 'string') {
			const trimmed = value.trim();

			return trimmed === '' && trimmed.length < value.length
				? `<${I18n.l('common.whitespace').toLowerCase()}>`
				: this.value;
		} else if (typeof value === 'string' || typeof value === 'number') {
			return this.value;
		} else if (typeof value === 'boolean') {
			return value ? 'True' : 'False';
		} else {
			return JSON.stringify(value);
		}
	}

	_rpcSearchHandler(rpcButton, rpc, isArrayInput) {
		return async (event) => {
			if (this.panel) return;
			if (isAttribute(this.closest('[field]'), 'disabled')) return;

			rpcButton.setAttribute('disabled', '');
			this.disabled = true;

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				rpcButton.removeAttribute('disabled');
				this.disabled = false;
				this.panel = null;
			});

			await this.panel.build(
				{
					name: rpc.label,
					config: rpc.parameters,
					buttons: [
						{
							action: 'close',
							label: I18n.l('common.close'),
						},
						{
							label: I18n.l('panels.rpc.ok'),
							action: 'handler',
							handler: async (e) => {
								let response;
								const panel = this.panel.panel;

								panel.body.innerHTML = '';
								panel.setLoading(true);
								try {
									response = await Loader.load(rpc.url, {
										data: {
											...this.panel.form.value,
											...this.fieldset.rpcData,
										},
									});
								} catch (e) {
									panel.warn(e.message);
									return;
								}

								panel.setLoading(false);

								if (!Array.isArray(response)) {
									panel.warn(I18n.l('errors.invalidResponse'));
								} else if (response.length === 0) {
									panel.warn(I18n.l('errors.noMatches'));
								}

								if (response.length === 1) {
									panel.close();

									if (isArrayInput) {
										this.addItem(response[0].value);
										showNestedFieldsets(this);
									} else {
										this.value = response[0].value;
										showNestedFieldsets(this);
									}
									if (this._runValidators) {
										this._runValidators();
									}
								} else {
									const wrapper = create('div.list-group');

									for (const item of response) {
										const el = create(`button.list-group-item[type="button"] ${item.label || item.value}`);

										el.addEventListener('click', (e) => {
											panel.close();

											if (isArrayInput) {
												this.addItem(item.value);
											} else {
												this.value = item.value;
												showNestedFieldsets(this);
											}
											if (this._runValidators) {
												this._runValidators();
											}
										});

										wrapper.append(el);
									}

									panel.body.append(wrapper);
								}
							},
						},
					],
				},
				{ body: { ...this.fieldset.rpcData } },
			);

			this.panel.open();
		};
	}

	_setDisabled(disabled) {}

	_setPreview() {
		if (!this._labelPreview) {
			return;
		}

		const preview = this.getPreview();
		const ast = IML.parse(preview, undefined, undefined, { keepUnwantedOperators: true });
		const controller = this.form?.coderPanelController;

		if (ast?.length > 0 && controller) {
			this._labelPreview.innerHTML = '';
			for (const el of astToDOM(
				ast,
				controller.variables,
				undefined,
				false,
				this.form ? Coder.cache.get(this.form.meta?.pills) : {},
				this.form ? this.form.meta.zone?.customVariables?.variables : [],
			)) {
				el.style = '';
				this._labelPreview.appendChild(el);
			}
		} else {
			this._labelPreview.textContent = preview;
		}
	}
}

export class Nested extends HTMLElement {
	constructor() {
		super();
	}
}

registerCustomElement('imt-input', Input);
registerCustomElement('imt-nested', Nested);
