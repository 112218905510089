import { BooleanInput } from './boolean.mjs';
import { create, registerCustomElement } from '../utils.mjs';

const STATE_LABEL = ['Yes'];

export class CheckboxInput extends BooleanInput {
	get value() {
		if (this.mode === 'map') return this._coder.value;

		return !!this._control.checked;
	}

	set value(value) {
		if (this.mode === 'map') {
			this._coder.value = value;
			return;
		}

		this._control.checked = value === true || value === 'true';
	}

	get hasModeSwitch() {
		return this.mappable;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		const wrapper = create('div');

		wrapper.className = 'wrapper';

		const element = create('div.custom-control.custom-checkbox.custom-control-inline');

		this._control = create('input.custom-control-input[type="checkbox"]');
		this._control.name = `${instructions.name}_${this._id}`;
		this._control.id = this._id;
		this._control.disabled = instructions.disabled || instructions.readonly;

		const innerLabel = create('label.custom-control-label');

		innerLabel.setAttribute('for', this._id);
		innerLabel.textContent = STATE_LABEL[0];

		element.appendChild(this._control);
		element.appendChild(innerLabel);
		wrapper.appendChild(element);

		this.appendChild(wrapper);
		this.value = value;
	}

	_setDisabled(disabled) {
		this._control.disabled = disabled;
	}
}

registerCustomElement('imt-input-checkbox', CheckboxInput);
