import { Input } from './input.mjs';
import { clone, create, showNestedFieldsets, registerCustomElement, isAttribute } from '../utils.mjs';
import { Picker } from '../controls/picker.mjs';
import { IML } from '../deps/iml.mjs';
import EVENTS from '../events.mjs';
import { I18n } from '../helpers/i18n.mjs';
import MurmurHash3 from 'imurmurhash';

export class SelectInput extends Input {
	get value() {
		if (this.mode === 'map') {
			const value = this._coder.value;

			if (this._control.multiple) {
				if (/^{{[^}]*}}$/.test(value)) {
					return IML.stringify(IML.replace(IML.parse(value), 'keyword', 'erase', 'erasearray'));
				}
				return value;
			}

			return value;
		}

		return this._control?.value;
	}

	set value(value) {
		if (this.mode === 'map') {
			if ('string' === typeof value) {
				this._coder.value = IML.stringify(IML.replace(IML.parse(value), 'keyword', 'erasearray', 'erase'));
			} else {
				this._coder.value = value;
			}
			return;
		}

		// try to convert the value to number if the source value is a number
		if (typeof this._control.value === 'number' && typeof value === 'string') {
			value = parseInt(value);
		}

		if (this._control.value === value) return;
		this._control.value = value;
	}

	get hasModeSwitch() {
		// Workaround to show map switch if this.hasModeSwitch or has 'editable' attribute
		// Works the same as Formula
		// The logic is not directly in the this.mappable because we don't want to show coder for text inputs
		// TODO ideally there should be multiple modes: map with coder, map without coder, no-map
		return this.mappable || (this.nodeName === 'IMT-INPUT-SELECT' && isAttribute(this, 'mappable'));
	}

	get options() {
		return (this._control && this._control.options) || [];
	}

	/**
	 * Converts attributes of the dom element to instructions object.
	 *
	 * @return {object}
	 */

	attributesToInstructions() {
		const grouped = this.hasAttribute('grouped');
		const options = [];

		if (grouped) {
			for (const node of this.querySelectorAll('imt-optgroup')) {
				if (!node.hasAttribute('label')) throw new Error('Option group without label attribute.');
				this.removeChild(node);

				const group = {
					label: node.getAttribute('label'),
					options: [],
				};

				for (const node2 of node.querySelectorAll('imt-option')) {
					if (!node2.hasAttribute('value')) throw new Error('Option without value attribute.');

					group.options.push({
						label: node2.textContent,
						value: node2.value,
					});
				}

				options.push(group);
			}
		} else {
			for (const node of this.querySelectorAll('imt-option')) {
				if (!node.hasAttribute('value')) throw new Error('Option without value attribute.');
				this.removeChild(node);

				options.push({
					label: node.textContent,
					value: node.value,
				});
			}
		}

		let renderedCommonNestedFieldset;

		// Hide nested fieldset inserted into DOM
		for (const fieldset of this.querySelectorAll('imt-nested imt-fieldset')) {
			if (!fieldset.hasAttribute('for')) {
				renderedCommonNestedFieldset = fieldset;
				fieldset.parentNode.removeChild(fieldset);
			} else {
				fieldset.hide();
			}
		}

		const instructions = super.attributesToInstructions();

		return Object.assign(instructions, {
			options: options.length ? options : this.getAttribute('options') || instructions.options, // TODO: Fixes functionality that already worked in past
			multiple: this.hasAttribute('multiple'),
			dropdown: this.hasAttribute('dropdown'),
			selectType: this.getAttribute('type'),
			grouped,
			renderedCommonNestedFieldset,
		});
	}

	/**
	 * Builds the input dom.
	 *
	 * @param {object} instructions Collection of directives.
	 * @param {any} value Initial value.
	 * @param {object} metadata Metadata.
	 */

	async _build(instructions, value, metadata) {
		const options = Picker.normalizeOptions(instructions.options);

		this._control = metadata?.build?.picker || create('imt-picker');
		if (instructions.options?.showSearcher) {
			this._control.showSearcher = instructions.showSearcher;
		}
		this._control.inputField = this;
		if (instructions.readonly) this._control.setAttribute('readonly', '');
		if (instructions.grouped) this._control.setAttribute('grouped', '');
		if (instructions.multiple) this._control.setAttribute('multiple', '');
		if (instructions.dropdown) this._control.setAttribute('dropdown', '');
		if (!Array.isArray(options.store)) this._control.setAttribute('provider', options.store);
		this._control.setAttribute('placeholder', options.placeholder?.label || options.placeholder || '');
		this._control.setAttribute('label-field', options.label || this.getAttribute('label-field') || 'label');
		this._control.setAttribute('value-field', options.value || this.getAttribute('value-field') || 'value');
		if (options.wrapper) this._control.setAttribute('wrapper-field', options.wrapper);
		if (options.type === 'image') {
			this._control.register(Picker.COMPONENTS.OPTION_RENDERER, (option, item, store) => {
				store.classList.add('image-options');
				const img = create('img');

				img.setAttribute('src', item.src || item.value);
				option.append(img);
			});
		}

		if (typeof options.default !== 'undefined') {
			this._control.register(Picker.COMPONENTS.DEFAULT_OPTION_RESOLVER, (option) => !!option[options.default]);
		}

		this._control.id = this._id;

		if (metadata?.build?.pickerPath) {
			this._control.register(Picker.COMPONENTS.RPC_DATA_GETTER, metadata?.build?.pickerPath);
		} else {
			this._control.register(Picker.COMPONENTS.RPC_DATA_GETTER, () => this.fieldset.rpcData);
		}

		this._control.addEventListener(EVENTS.PICKER.LOAD_ERROR, (event) => {
			this.setAttribute('error', '');
			this._errorMessages.textContent = event.detail.message;
			console.error(event.detail);
		});

		this._control.addEventListener(EVENTS.PICKER.LOAD_DONE, (event) => {
			this.removeAttribute('error');
		});

		this.appendChild(this._control);

		const showEmptyValue = !instructions.required && !instructions.multiple;

		if (showEmptyValue) {
			let label = I18n.l('common.empty');

			if (typeof instructions.options?.placeholder === 'string') {
				label = instructions.options.placeholder;
			} else if (typeof instructions.options?.placeholder?.label === 'string') {
				label = instructions.options.placeholder.label;
			}

			this._control.staticOptions = [
				{
					[instructions.options?.label || 'label']: label,
					[instructions.options?.value || 'value']: '',
					empty: (!instructions.required && !instructions.multiple) || !instructions.options.placeholder,
					nested: instructions.options?.placeholder?.nested,
					default: !!instructions.options?.placeholder,
				},
			];
		}

		const defaultValue = (() => {
			if (typeof value !== 'undefined') {
				return value;
			} else if (typeof instructions.default !== 'undefined') {
				return instructions.default;
			} else if (showEmptyValue) {
				return '';
			}
		})();

		const temporaryNested =
			typeof defaultValue !== 'undefined' && typeof (options.nested?.store || options.nested) === 'string';

		try {
			if (
				!Array.isArray(options.store) &&
				(((this._control.multiple || this._control.list) && !this._control.dropdown) ||
					!(metadata?.restore && this.hasValidStateData(metadata.restore?.data)) ||
					this._shouldLoadOnBuild(options, defaultValue))
			) {
				// Initial start load RPC immediately
				await this._control.reload(defaultValue);
			} else {
				await this._control.addOptions(clone(options), defaultValue, metadata);
			}

			// If this.value is set it means that default value has been resolved from the options
			if (instructions.options.placeholder && typeof value === 'undefined' && typeof this.value === 'undefined') {
				this.value = '';
			}

			showNestedFieldsets(
				this,
				metadata?.fieldset?.value,
				metadata?.fieldset?.metadata,
				temporaryNested,
				undefined,
				undefined,
			).then(() => {
				// Input event has to be listened on the imt-picker not on the input itself,
				// input itself emits also nested field input events that bubble up the tree.
				this._control.addEventListener('input', () => showNestedFieldsets(this));

				this.addEventListener(EVENTS.INPUT.MODE_CHANGED, (event) => {
					if (this === event.target) showNestedFieldsets(this);
				});
			});
		} catch (ex) {
			// Errors are dispatched by event
		}

		this.addEventListener(EVENTS.INPUT.MODE_CHANGE, (event) => {
			if (this === event.target) this.loading && event.preventDefault();
		});
	}

	async addOption(option) {
		this.value = this._control.renderOption(option).value;
		const valueHash = new MurmurHash3(JSON.stringify(this.value)).result();
		const fieldset = this.querySelector(`imt-fieldset[for="${valueHash}"`);

		// hide the newly created fieldset so that animation will not break
		if (fieldset) {
			fieldset.hide();
		}
		await showNestedFieldsets(this);
	}

	getState() {
		const state = super.getState();

		if (this.mode === 'pick') {
			const selected = this._control.getSelectedElements();

			if (this._instructions.multiple) {
				if (selected.length && this._control.hasAttribute('provider')) {
					state.label = selected.map((option) => option.textContent);
				}
			} else {
				if (selected[0]) {
					state.label = selected[0].textContent;
				}
			}

			const stateData = this.getStateData(selected[0]);

			if (stateData) {
				state.data = stateData;
			}
		}

		return state;
	}

	// Returns array of dataset props for state
	get stateDataProps() {
		return null;
	}

	getStateData(item) {
		if (this.stateDataProps && item && this.stateDataProps.length) {
			const data = {};

			this.stateDataProps.forEach((prop) => {
				data[prop] = item.dataset[prop];
			});

			return data;
		}

		return null;
	}

	hasValidStateData(stateData) {
		if (this.stateDataProps) {
			if (!this.stateDataProps.length) {
				return true;
			}
			return stateData && this.stateDataProps.every((prop) => prop in stateData);
		}
		return true;
	}

	getValidateInstructions() {
		const instructions = super.getValidateInstructions();

		const options = Picker.normalizeOptions(this._instructions.options);

		if (this._instructions.multiple) {
			instructions.multiple = true;
		}

		if (
			!this._instructions.dynamic &&
			this._instructions.validate !== false &&
			Array.isArray(options.store) &&
			options.store.length
		) {
			instructions.validate = {
				enum: options.store.flatMap((option) =>
					this._instructions.grouped ? option.options.map((o) => o.value) : option.value,
				),
			};
		}

		return instructions;
	}

	getTeamId(instructions) {
		return instructions.options?.team != null ? instructions.options.team : this.form.meta.teamId;
	}

	getPreview() {
		const value = this.value;
		const valueLabel = this._control.valueLabel;

		if (!value && !valueLabel) {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		}

		return this.mode === 'map' ? this._coder.value : this._control.valueLabel;
	}

	_toCoderValue(value) {
		return Array.isArray(value) ? value.join(',') : value;
	}

	_fromCoderValue(value) {
		return this._control.hasAttribute('multiple') ? value.split(',') : value;
	}

	_setDisabled(disabled) {
		if (this._control) {
			this._control.disabled = disabled;
		}

		if (this._instructions.multiple && !this._instructions.dropdown) {
			for (const option of this._control.querySelectorAll('imt-option')) {
				if (disabled) {
					option.setAttribute('disabled', '');
				} else {
					option.removeAttribute('disabled');
				}
			}
		}
	}

	/**
	 * Defines whether to force load data from the API evn though it is cached.
	 * Intended for native inputs like accounts, hooks, etc. that should not been cached.
	 * @returns {boolean}
	 * @private
	 */

	_shouldLoadOnBuild(options, value) {
		return false;
	}
}

registerCustomElement('imt-input-select', SelectInput);
