/**
 * This file is used by both font-end and back-end.
 */

import * as text from './text.mjs';
import { ValidationError } from '../helpers/errors.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	value = text.cast(value);
	if (value === null) return value;
	return value.toLowerCase();
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	value = text.validate(value, instructions, options, more);
	if (value == null || value === '') return null;

	const patterns = {
		HEX: /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
		RGB: /rgb\((1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),\s*(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),\s*(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\)/i,
		RGBA: /rgba\((1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),\s*(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),\s*(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),\s*(0?\.[0-9]+|[0-1](\.0)?)\)/i,
		HSL: /hsl\(([1-2]?[0-9]{1,2}|3[0-5][0-9]|360),\s*([0-9]{1,2}|100)%,\s*([0-9]{1,2}|100)%\)/i,
		HSLA: /hsla\(([1-2]?[0-9]{1,2}|3[0-5][0-9]|360),\s*([0-9]{1,2}|100)%,\s*([0-9]{1,2}|100)%,\s*(0?\.[0-9]+|[0-1](\.0)?)\)/i,
	};

	const format = instructions.validate?.format.toUpperCase();

	if (patterns[format]) {
		if (!patterns[format].test(value)) {
			throw new ValidationError(`Value is not a valid color in format '${format}'.`, 'validate-color-invalid-format', {
				format,
			});
		}
	} else {
		if (
			Object.values(patterns)
				.map((p) => p.test(value))
				.every((p) => !p)
		) {
			throw new ValidationError('Value is not a valid color.', 'validate-color-invalid');
		}
	}

	return value.toLowerCase();
}
