/* global moment */

import { TextInput } from './text.mjs';
import { createDateTimePickerPanel, registerCustomElement } from '../utils.mjs';
import { I18n } from '../helpers/i18n.mjs';

export class TimeInput extends TextInput {
	get hasModeSwitch() {
		return false;
	}

	get value() {
		if (this._control.containsIML) {
			return this._control.value;
		}

		const m = moment(this._control.value, 'LT');

		if (!m.isValid()) {
			return this._control.value;
		}

		return m.locale('en').format('HH:mm');
	}

	set value(value) {
		super.value = value;
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		this._timezone = this.form?.meta?.timezone || this.form?.timezone || 'UTC';

		await super._build(instructions, value, metadata);

		if (!this.mappable) {
			let picker;

			this._control.type = 'text';
			this._control.addEventListener('focusin', () => {
				picker = createDateTimePickerPanel(this, this, instructions);
				picker.panel.open();
			});

			this._control.addEventListener('focusout', () => {
				if (picker?.panel) {
					picker?.abortController.abort();
					picker?.panel.close();
					picker = null;
				}
			});
		}

		this.value = value;
	}

	/**
	 * Builds the list of hints to be shown with the field.
	 *
	 * @param {function} hint Function that adds the hint. First argument is icon, second is text.
	 */

	_hints(hint) {
		hint('globe', I18n.l('hints.timezone', { value: this._timezone }, true));

		if (this.fieldset.mappable && this._instructions.mappable !== false) {
			hint('clock', I18n.l('hints.format', { value: 'HH:mm' }));
		} else {
			hint(
				'clock',
				I18n.l(
					'hints.format',
					{
						value: moment.localeData().longDateFormat('LT'),
					},
					true,
				),
			);
		}
	}

	getPreview() {
		const value = this._control.value;

		if (!value) {
			return `<${I18n.l('common.empty').toLowerCase()}>`;
		}

		return value;
	}
}

registerCustomElement('imt-input-time', TimeInput);
