/**
 * This file is used by both font-end and back-end.
 */

import { validate as validateNested } from '../types.mjs';
import { ValidationError } from '../helpers/errors.mjs';

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null || value === '') return null;

	let spec;
	let found;
	const store = (instructions.options && instructions.options.store) || instructions.options;

	function getOptions() {
		const valueField = instructions.options.value || 'value';

		if (instructions.grouped) {
			return store.reduce((s, group) => {
				if (Array.isArray(group.options)) {
					s.push(...group.options.map((o) => o[valueField]));
				}

				return s;
			}, []);
		} else {
			return store.map((s) => s[valueField]);
		}
	}

	if ('object' === typeof instructions.spec) {
		spec = Object.assign({}, instructions.spec);
		spec.name = instructions.name; // because of error messages
		spec.type = spec.type || 'text';
	}

	if (instructions.multiple) {
		if (!Array.isArray(value)) {
			value = [value];
		}

		if (instructions.required && value.length === 0) {
			throw new ValidationError(
				`Missing value of required parameter '${instructions.label || instructions.name}'.`,
				'validate-select-invalid',
				{ label: instructions.label || instructions.name },
			);
		}

		for (let index = 0; index < value.length; index++) {
			const item = value[index];

			if (spec) {
				value[index] = validateNested(item, spec.type, spec);
			} else if ('string' === typeof item) {
				value[index] = validateNested(item, 'text', instructions);
			}
		}

		if (
			instructions.validate &&
			instructions.validate.minItems != null &&
			value.length < instructions.validate.minItems
		) {
			throw new ValidationError(
				`Selected less than ${instructions.validate.minItems} items in parameter '${
					instructions.label || instructions.name
				}'.`,
				'validate-select-has-less',
				{
					minItems: instructions.validate.minItems,
					label: instructions.label || instructions.name,
				},
			);
		}

		if (
			instructions.validate &&
			instructions.validate.maxItems != null &&
			value.length > instructions.validate.maxItems
		) {
			throw new ValidationError(
				`Selected more than ${instructions.validate.maxItems} items in parameter '${
					instructions.label || instructions.name
				}'.`,
				'validate-select-has-more',
				{
					maxItems: instructions.validate.maxItems,
					label: instructions.label || instructions.name,
				},
			);
		}

		if (Array.isArray(store) && !instructions.dynamic) {
			for (const val of value) {
				if (!getOptions(store).includes(val)) {
					throw new ValidationError(
						`Value not found in options in parameter '${instructions.label || instructions.name}'.`,
						'validate-select-value-not-found',
						{ label: instructions.label || instructions.name },
					);
				}
			}
		}
	} else {
		if (spec) {
			value = validateNested(value, spec.type, spec, {}, more);
		} else {
			if (typeof value === 'string') {
				value = validateNested(value, 'text', instructions);
			}
		}

		if (Array.isArray(store) && !instructions.dynamic) {
			found = getOptions(store).find((val) => (typeof val === 'number' ? val === parseInt(value) : val === value));

			// falsy values can be valid values - if null/undefined/false is found the field is valid
			if (!found && value !== found) {
				throw new ValidationError(
					`Value not found in options in parameter '${instructions.label || instructions.name}'.`,
					'validate-select-value-not-found',
					{ label: instructions.label || instructions.name },
				);
			}
		}
	}

	if (found && found.nested) {
		more(found.nested);
	} else if (instructions.options && instructions.options.nested && instructions.options.nested.store) {
		more(instructions.options.nested.store);
	} else if (instructions.options && instructions.options.nested) {
		more(instructions.options.nested);
	}

	return value;
}
