/**
 * This file is used by both font-end and back-end.
 */

import { validate as validateNested } from '../types.mjs';
import { ValidationError } from '../helpers/errors.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	if (value == null) return null;

	if (!Array.isArray(value)) {
		value = [value];
	}

	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value == null || value === '') return null;

	if (!Array.isArray(value)) {
		value = [value];
	}

	// Custom validations
	const validate = instructions.validate || {};

	if (validate.minItems != null && value.length < validate.minItems) {
		throw new ValidationError(`Array has less than ${validate.minItems} items.`, 'validate-array-has-less', {
			minItems: validate.minItems,
		});
	}

	if (validate.maxItems != null && value.length > validate.maxItems) {
		throw new ValidationError(`Array has more than ${validate.maxItems} items.`, 'validate-array-has-more', {
			maxItems: validate.maxItems,
		});
	}

	if (value.length === 0) {
		// Empty array, no need to continue with the rest of validation
		return value;
	}

	if (options.depth !== 0) {
		// If depth is set to zero, nested structures are not validated. Usefull for front-end, where each field is validated separately.

		if (Array.isArray(instructions.spec)) {
			// Complex array
			if (instructions.spec.length === 0) {
				// Array with empty spec means we can convert primitive array into array of objects
				for (let i = 0, l = value.length; i < l; i++) {
					if (value == null || typeof value[i] !== 'object') {
						value[i] = { value: value[i] };
						continue;
					}

					// Objects created with `Object.create(null)` have no constructor.
					if (value[i].constructor != null && value[i].constructor.prototype !== Object.prototype) {
						value[i] = { value: value[i] }; // Date, Buffer, RegExp, ...
					}
				}
			} else {
				for (let i = 0, l = value.length; i < l; i++) {
					if (typeof value[i] !== 'object') {
						throw new ValidationError('Array of objects expected.', 'validate-array-not-object');
					}

					try {
						value[i] = validateNested(value[i], 'collection', instructions.spec, options);
					} catch (ex) {
						if (ex instanceof ValidationError) {
							ex.location = ex.location ? ` ${ex.location}` : '';
							ex.location = `imt-input-array-item:nth-of-type(${i + 1})` + ex.location;
						}
						throw ex;
					}
				}
			}
		} else {
			let spec = instructions.spec;

			if (typeof spec !== 'object') {
				// Array without spec = array of strings.
				spec = { type: 'text' };
			}

			for (let i = 0, l = value.length; i < l; i++) {
				try {
					value[i] = validateNested(value[i], spec.type || 'text', spec, options);
				} catch (ex) {
					if (ex instanceof ValidationError) {
						ex.location = ex.location ? ` ${ex.location}` : '';
						ex.location = `imt-input-array-item:nth-of-type(${i + 1})` + ex.location;
					}
					throw ex;
				}
			}
		}
	}

	return value;
}
