import { create, registerCustomElement } from './utils.mjs';

export class Alerts extends HTMLElement { }

registerCustomElement('imt-alerts', Alerts);

export class Alert extends HTMLElement {
	connectedCallback() {
		this.classList.add('alert');
		this.classList.add(`alert-${this.level}`);
		this.classList.add('alert-dismissible');
		this.classList.add('fade');
		this.classList.add('show');
	}

	get message() {
		return this.textContent;
	}

	set message(value) {
		if (!this._messageNode) {
			this._messageNode = this.appendChild(create('h6'));
		}

		this._messageNode.textContent = value;

		this._close = create('button.close[type="button"][data-dismiss="alert"]');

		this._close.innerHTML = '&times;';
		this.appendChild(this._close);
	}

	set detail(value) {
		if (!this._detailNode) {
			this._detailNode = this.appendChild(create('span'));
		}

		this._detailNode.textContent = value;
	}

	get level() {
		return this.getAttribute('level') || 'danger';
	}

	set level(value) {
		if (typeof value !== 'string') return;
		// This ensures that nobody can set any different classes using the level, as it will always go to alert-${value}
		if (!value.match(/^[a-z]+$/)) return;
		this.setAttribute('level', value);
	}

	removeCloseButton() {
		this.removeChild(this._close);
		this._close = null;
	}
}

registerCustomElement('imt-alert', Alert);
