/**
 * This file is used by both font-end and back-end.
 */

import * as number from './number.mjs';
import { ValidationError } from '../helpers/errors.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	value = number.cast(value);
	if (value === null) return value;
	return Math.floor(value);
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	value = number.validate(value, instructions, options, more);
	if (value === null) return value;

	if (!Number.isInteger(value)) {
		throw new ValidationError('Invalid integer.', 'validate-integer-invalid');
	}
	return value;
}
