import { SelectInput } from './select.mjs';
import { composedInputBuilder, create, registerCustomElement } from '../utils.mjs';
import EVENTS from '../events.mjs';
import configs from '../configs/config.mjs';
import RemoteForm from '../controls/remoteFormPanel.mjs';
import { I18n } from '../helpers/i18n.mjs';
import { DEFAULT_THEME } from '../helpers/color.mjs';

const config = configs.fields.udt;

export class UdtInput extends SelectInput {
	constructor() {
		super();
		this.panel = null;
	}

	get hasModeSwitch() {
		return false;
	}

	get stateDataProps() {
		return [];
	}

	extendInstructions(instructions) {
		instructions.options = instructions.options || {};
		instructions.options.label = instructions.options.label || config.api.list.label;
		instructions.options.value = instructions.options.value || config.api.list.value;
		instructions.options.wrapper = instructions.options.wrapper || config.api.list.wrapper;
		instructions.options.placeholder = instructions.options.placeholder || I18n.l('udt.placeholder');
		instructions.options.team = this.getTeamId(instructions);

		if (!instructions.organization) {
			instructions.organization = this.form.meta.organizationId;
		}

		instructions.theme = instructions.theme || this.form.meta.module.package?.theme || DEFAULT_THEME;

		const storeQuery = new URLSearchParams();

		storeQuery.append('teamId', instructions.options.team);

		instructions.options.store = `${config.api.list.path}?${storeQuery}`;

		return instructions;
	}

	_shouldLoadOnBuild(options, value) {
		return typeof value !== 'undefined' && value !== '';
	}

	/**
	 * Builds the input dom.
	 *
	 * @param instructions {object} Collection of directives.
	 * @param value {any} Initial value.
	 * @param metadata {object} Metadata.
	 */

	async _build(instructions, value, metadata) {
		// Picker

		const picker = create('imt-picker');

		// Build the component

		metadata.build = Object.assign(metadata.build || {}, { picker });
		await super._build(instructions, value, metadata);

		// Build composed input

		this.append(
			composedInputBuilder({
				picker,
				config,
				name: this.form.meta.module?.package?.name,
				theme: instructions.theme,
				buttons: [
					{
						label: I18n.l('common.edit'),
						action: this._updateHandler(picker, instructions, metadata),
						enabled: (option) => option?.value,
						collapsible: true,
					},
					{
						label: I18n.l('common.clone'),
						action: this._cloneHandler(picker, instructions),
						enabled: (option) => option?.value,
						collapsible: true,
					},
					{
						label: I18n.l('common.add'),
						action: this._createHandler(instructions),
					},
				],
				readonly: this.readonly,
			}),
		);
	}

	_updateHandler(picker, instructions, metadata) {
		return async (event, dropdownBtn) => {
			if (this.panel) return;

			this.disabled = true;

			const selected = picker.querySelector('imt-option[selected]');

			this.panel = new RemoteForm(dropdownBtn);

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			metadata.context.datastructureId = selected.value;

			await this.panel.build(
				{
					name: selected.textContent,
					src: config.api.instructions.update.path,
					theme: instructions.theme,
				},
				metadata,
			);

			this.panel.open();
		};
	}

	_cloneHandler(picker, instructions) {
		return async (event, dropdownBtn) => {
			if (this.panel) return;

			this.disabled = true;

			const selected = picker.querySelector('imt-option[selected]');

			this.panel = new RemoteForm(dropdownBtn);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				await this.addOption(event.detail.data.dataStructure);
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const storeQuery = new URLSearchParams();

			storeQuery.append('organizationId', instructions.organization);

			await this.panel.build(
				{
					name: selected.textContent,
					src: `${config.api.instructions.clone.path}?${storeQuery}`,
					theme: instructions.theme,
				},
				{ context: { datastructureId: selected.value } },
				storeQuery,
			);

			this.panel.open();
		};
	}

	_createHandler(instructions) {
		return async (event) => {
			if (this.panel) return;

			this.disabled = true;

			this.panel = new RemoteForm(event.target);

			this.panel.addEventListener(EVENTS.FORM.SUBMITTED, async (event) => {
				this.disabled = false;
				await this.addOption(event.detail.data.dataStructure);
			});

			this.panel.addEventListener(EVENTS.PANEL.CLOSE, (event) => {
				this.disabled = false;
				this.panel = null;
			});

			const instructionsQuery = new URLSearchParams();

			instructionsQuery.append('teamId', instructions.options.team);
			await this.panel.build({
				name: I18n.l('panels.udt.add'),
				src: `${config.api.instructions.create.path}?${instructionsQuery}`,
				theme: instructions.theme,
			});

			this.panel.open();
		};
	}
}

registerCustomElement('imt-input-udt', UdtInput);
