export class EventEmitter {
	constructor() {
		this._events = {};
	}

	on(name, handler) {
		if (!this._events[name]) this._events[name] = new Set();

		this._events[name].add(handler);
	}

	once(name, handler) {
		const temp = (...args) => {
			this.removeListener(name, temp);
			handler.apply(this, args);
		};

		this.on(name, temp);
	}

	emit(name, ...args) {
		if (!this._events[name]) return;

		for (const handler of this._events[name]) {
			handler.apply(this, args);
		}
	}

	removeListener(name, handler) {
		if (!this._events[name]) return;

		this._events[name].delete(handler);
	}
}
