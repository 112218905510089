import { NumberInput } from './number.mjs';
import { registerCustomElement } from '../utils.mjs';
import { cast } from '../types/integer.mjs';

export class IntegerInput extends NumberInput {
	castValue(value) {
		return cast(value);
	}
}

registerCustomElement('imt-input-integer', IntegerInput);
