/* eslint-disable max-len */

export default {
	common: {
		add: 'Přidat',
		close: 'Zavřít',
		save: 'Uložit',
		popup: 'Vyskakovací okno bylo zablokováno. Povolte prosím vyskakovací okna a zkuste to znovu.',
		error: 'Chyba',
		continue: 'Pokračovat',
		edit: 'Editovat',
		clone: 'Naklonovat',
		showAdvanced: 'Zobrazit pokročilé nastavení',
		note: 'Note',
		name: 'Název',
		empty: 'Prázdné',
		emptyString: 'Prázdný string',
		whitespace: 'Bílý znak',
		value: 'Hodnota: ',
	},
	buttons: {
		edititem: 'Upravit položku',
		extract: 'Extrahovat',
		newitem: 'Nová položka',
		addor: 'Přidat OR pravidlo',
		addand: 'Přidat AND pravidlo',
		browse: 'Procházet',
		map: 'Map',
		refresh: 'Obnovit',
		expandAll: 'Rozbalit vše',
		collapseAll: 'Sbalit vše',
	},
	panels: {
		keychain: {
			add: 'Přidat nový klíč',
		},
		udt: {
			add: 'Přidat datovou strukturu',
		},
		datastores: {
			create: 'Vytvořit datový sklad',
		},
		device: {
			add: 'Přidat nové zařízení',
		},
		account: {
			create: 'Vytvořit spojení',
			extend: 'Rozšířit oprávnění',
			waiting: 'Čekám na autorizaci...',
			creating: 'Vytvářím spojení...',
			authorize: 'Kliknutím zde spustíte ověřovací proces.',
			extending: 'Rozšiřuji oprávnění...',
			verifying: 'Ověřuji spojení...',
			extendhelp: 'Abychom mohli vybrané spojení použít, bude třeba u něj rozšířit rozsah udělených oprávnění.',
			extendfailed: 'Oprávnění se nepodařilo rozšířit.',
			verifyfailed: 'Spojení se nepodařilo ověřit.',
		},
		hook: {
			create: 'Vytvořit webhook',
		},
		rpc: {
			ok: 'OK',
		},
		datetimepicker: {
			dateTitle: 'Vyberte datum',
			timeTitle: 'Vyberte čas',
		},
	},
	datastore: {
		chooseDatastore: 'Vyberte datový sklad',
	},
	udtspec: {
		generate: 'Generovat',
		specification: {
			label: 'Typ',
		},
		label: {
			label: 'Popisek',
			help: 'Zobrazený název pro lepší čitelnost.',
		},
		name: {
			label: 'Název',
			help: 'Název proměnné.',
		},
	},
	udt: {
		placeholder: 'Vyberte datovou strukturu',
	},
	tokens: {
		token: 'Token',
		safariDenied: 'Přístup odepřen, prosím zkontrolujte nastavení prohlížeče Safari.',
		safariDisabled: 'Push notifikace jsou zakázané v nastavení prohlížeče Safari.',
		chromeExtNotInstalled: 'Chrome rozšíření není nainstalováno.',
		chromeActivateExtension: 'Nejdříve je potřeba nainstalovat rozšíření prohlížeče Chrome.',
	},
	hooks: {
		copy: 'Zkopírovat do schránky',
		gone: 'Webhook byl příliš dlouho neaktivní a proto byl odstraněn.',
		show: 'Zobrazit adresu',
		copied: 'Adresa byla zkopírována.',
		create: 'Přidat hook',
		attached: 'Webhook jsme do {{service}} automaticky zaregistrovali. O nic víc se už nemusíte starat.',
		listening:
			'{{integromat}} nyní očekává, že na výše uvedenou adresu webhooku pošlete nějaká data. Na základě přiatých dat pak odvodí datovou strukturu.',
		determined: 'Datová struktura získána.',
		redetermine: 'Získat datovou strukturu',
	},
	accounts: {
		failedAdd: 'Nepodařilo se přidat spojení.',
	},
	hints: {
		enum: 'Povolené hodnoty: {{value}}.',
		format: 'Formát: {{value}}',
		filetypes: 'Povolené typy souborů: {{value}}.',
		selectmost: 'Můžete vybrat maximálně {{value}} položek.',
		containmost: 'Může obsahovat nejvíce {{value}} položek.',
		incomparray:
			'Namapované pole není s cílovou datovou strukturou kompatibilní. Kliknutím zde do scénáře přidáte moduly, díky kterým budete moci datovou strukturu zdrojového pole přemapovat.',
		selectleast: 'Můžete vybrat nejméně {{value}} položek.',
		containleast: 'Musí obsahovat nejméně {{value}} položek.',
		exactlength: 'Musí být přesně {{value}} znaků dlouhé.',
		lengthbetween: 'Musí být minimálně {{a}} a maximálně {{b}} znaků dlouhé.',
		lengthmost: 'Musí být maximálně {{value}} znaků dlouhé.',
		lengthleast: 'Musí být minimálně {{value}} znaků dlouhé.',
		pattern: 'Musí odpovídat vzoru /{{pattern}}/.',
		striptags: 'Všechny HTML tagy budou automaticky odebrány.',
		escapetags: 'Všechny HTML tagy budou automaticky upraveny.',
		numberequal: 'Musí se rovnat {{value}}.',
		numberbetween: 'Musí být číslo mezi {{min}} a {{max}}.',
		numberlower: 'Musí být menší nebo rovno {{value}}.',
		numberhigher: 'Musí být větší nebo rovno {{value}}.',
		port: 'Musí být mezi 1 a 65535.',
		timezone: 'Časová zóna: {{value}}',
		formats: 'Podporované formáty data najdete v naší [dokumentaci](kb://mapping/type-coercion).',
		account:
			'Více informací o vytváření spojení se službou {{service}} najdete v naší [dokumentaci](kb://app/{{name}}).',
		webhook:
			'Více informací o vytváření webhooků ve službě {{service}} najdete v naší [dokumentaci](kb://app/{{name}}).',
	},
	errors: {
		invalidResponse: 'Neplatná odpověď.',
		noMatches: 'Nenalezeny žádné shody.',
	},
	picker: {
		failedLoad: 'Nepodařilo se načíst data!',
		listEmpty: 'Nenalezeny žádné položky.',
	},
	file: {
		noFile: 'Nejsou vybrány žádné soubory',
		more: '+{{n}} souborů',
	},
	filter: {
		groups: {
			date: 'Operátory pro datum a čas',
			text: 'Textové operátory',
			time: 'Operátory pro čas',
			array: 'Operátory pro práci s poli',
			basic: 'Základní operátory',
			number: 'Číselné operátory',
			boolean: 'Booleanovské operátory',
		},
		operators: {
			exist: {
				label: 'Existuje',
			},
			notexist: {
				label: 'Neexistuje',
			},
			'date:less': {
				label: 'Starší než',
			},
			'time:less': {
				label: 'Méně než',
			},
			'array:less': {
				label: 'Počet záznamů v poli menší než',
			},
			'date:equal': {
				label: 'Rovno',
			},
			'text:equal': {
				label: 'Rovno',
			},
			'time:equal': {
				label: 'Rovno',
			},
			'array:equal': {
				label: 'Počet záznamů v poli rovno',
			},
			'number:less': {
				label: 'Méně než',
			},
			'date:greater': {
				label: 'Novější než',
			},
			'number:equal': {
				label: 'Rovno',
			},
			'text:contain': {
				label: 'Obsahuje',
			},
			'text:endwith': {
				label: 'Končí',
			},
			'text:pattern': {
				label: 'Odpovídá výrazu',
			},
			'time:greater': {
				label: 'Více než',
			},
			'array:contain': {
				label: 'Obsahuje',
			},
			'array:greater': {
				label: 'Počet záznamů v poli větší než',
			},
			'boolean:equal': {
				label: 'Rovno',
			},
			'date:notequal': {
				label: 'Nerovno',
			},
			'text:equal:ci': {
				label: 'Rovno (nerozlišuje malá a velká písmena)',
			},
			'text:notequal': {
				label: 'Není rovno',
			},
			'time:notequal': {
				label: 'Není rovno',
			},
			'array:notequal': {
				label: 'Počet záznamů v poli nerovno',
			},
			'number:greater': {
				label: 'Více než',
			},
			'text:startwith': {
				label: 'Začíná',
			},
			'number:notequal': {
				label: 'Nerovno',
			},
			'text:contain:ci': {
				label: 'Obsahuje (nerozlišuje malá a velká písmena)',
			},
			'text:endwith:ci': {
				label: 'Končí (nerozlišuje malá a velká písmena)',
			},
			'text:notcontain': {
				label: 'Neobsahuje',
			},
			'text:notendwith': {
				label: 'Nekončí',
			},
			'text:notpattern': {
				label: 'Neodpovídá výrazu',
			},
			'text:pattern:ci': {
				label: 'Odpovídá výrazu (nerozlišuje malá a velká písmena)',
			},
			'array:contain:ci': {
				label: 'Obsahuje (nerozlišuje malá a velká písmena)',
			},
			'array:notcontain': {
				label: 'Neobsahuje',
			},
			'boolean:notequal': {
				label: 'Není rovno',
			},
			'date:lessorequal': {
				label: 'Starší než nebo rovno',
			},
			'text:notequal:ci': {
				label: 'Není rovno (nerozlišuje malá a velká písmena)',
			},
			'time:lessorequal': {
				label: 'Méně nebo rovno',
			},
			'array:lessorequal': {
				label: 'Počet záznamů v poli menší nebo roven',
			},
			'text:notstartwith': {
				label: 'Nezačíná',
			},
			'text:startwith:ci': {
				label: 'Začíná (nerozlišuje malá a velká písmena)',
			},
			'number:lessorequal': {
				label: 'Méně nebo rovno',
			},
			'text:notcontain:ci': {
				label: 'Neobsahuje (nerozlišuje malá a velká písmena)',
			},
			'text:notendwith:ci': {
				label: 'Nekončí (nerozlišuje malá a velká písmena)',
			},
			'text:notpattern:ci': {
				label: 'Neodpovídá výrazu (nerozlišuje malá a velká písmena)',
			},
			'array:notcontain:ci': {
				label: 'Neobsahuje (nerozlišuje malá a velká písmena)',
			},
			'date:greaterorequal': {
				label: 'Novější než nebo rovno',
			},
			'time:greaterorequal': {
				label: 'Více nebo rovno',
			},
			'array:greaterorequal': {
				label: 'Počet záznamů v poli větši nebo roven',
			},
			'text:notstartwith:ci': {
				label: 'Nezačíná (nerozlišuje malá a velká písmena)',
			},
			'number:greaterorequal': {
				label: 'Více nebo rovno',
			},
		},
	},
	iml: {
		date: {
			label: 'Datum a čas',
			functions: {
				setDay: {
					help: 'Vrátí datum kde den v týdnu bude nastaven na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 1 (neděle) - 7 (sobota) nebo název dne anglicky. Pokud bude tento rozsah překročen, bude v datu odpovídajícím způsobem nastaven i měsíc.',
					syntax: 'setDay(datum; číslo/název dne anglicky)',
					example:
						'setDay("2018-06-27T11:36:39.138Z", "monday") {2018-06-25T11:36:39.138Z}\nsetDay("2018-06-27T11:36:39.138Z", 1) {2018-06-24T11:36:39.138Z}\nsetDay("2018-06-27T11:36:39.138Z", 7) {2018-06-30T11:36:39.138Z}',
				},
				addDays: {
					help: 'Vrátí datum posunuté o zadaný počet dní. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addDays(datum; počet)',
					example:
						'addDays("2016-12-08T15:55:57.536Z"; 2) {2016-12-10T15:55:57.536Z}\naddDays("2016-12-08T15:55:57.536Z"; -2) {2016-12-6T15:55:57.536Z}',
				},
				setDate: {
					help: 'Vrátí datum kde den v měsíci bude nastaven na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 1 - 31. Pokud bude tento rozsah překročen, bude v datu odpovídajícím způsobem nastaven i měsíc.',
					syntax: 'setDate(datum; číslo)',
					example:
						'setDate("2015-08-07T11:36:39.138Z", 5) {2015-08-05T11:36:39.138Z}\nsetDate("2015-08-07T11:36:39.138Z", 32) {2015-09-01T11:36:39.138Z}',
				},
				setHour: {
					help: 'Vrátí datum kde hodiny budou nastaveny na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 0 - 23. Pokud bude tento rozsah překročen, bude v datu odpovídajícím způsobem nastaven i den.',
					syntax: 'setHour(datum; číslo)',
					example:
						'setHour("2015-08-07T11:36:39.138Z", 6) {2015-08-07T06:36:39.138Z}\nsetHour("2015-08-07T11:36:39.138Z", -6) {2015-08-06T18:36:39.138Z}',
				},
				setYear: {
					help: 'Vrátí datum kde rok bude nastaven na hodnotu uvedenou v druhém parametru.',
					syntax: 'setYear(datum; čislo)',
					example: 'setYear("2015-08-07T11:36:39.138Z", 2017) {2017-08-07T11:36:39.138Z}',
				},
				addHours: {
					help: 'Vrátí datum posunuté o zadaný počet hodin. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addHours(datum; počet)',
					example:
						'addHours("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T17:55:57.536Z}\naddHours("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T13:55:57.536Z}',
				},
				addYears: {
					help: 'Vrátí datum posunuté o zadaný počet roků. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addYears(datum; počet)',
					example:
						'addYears("2016-08-08T15:55:57.536Z"; 2) {2018-08-08T15:55:57.536Z}\naddYears("2016-08-08T15:55:57.536Z"; -2) {2014-08-08T15:55:57.536Z}',
				},
				setMonth: {
					help: 'Vrátí datum kde měsíc bude nastaven na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 1 - 12 nebo název měsíce anglicky. Pokud bude tento rozsah překročen, bude v datu odpovídajícím způsobem nastaven i rok.',
					syntax: 'setMonth(datum; číslo/název měsíce anglicky)',
					example:
						'setMonth("2015-08-07T11:36:39.138Z", 5) {2015-05-07T11:36:39.138Z}\nsetMonth("2015-08-07T11:36:39.138Z", 17) {2016-05-07T11:36:39.138Z}\nsetMonth("2015-08-07T11:36:39.138Z", "january") {2015-01-07T12:36:39.138Z}',
				},
				addMonths: {
					help: 'Vrátí datum posunuté o zadaný počet měsíců. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addMonths(datum; počet)',
					example:
						'addMonths("2016-08-08T15:55:57.536Z"; 2) {2016-10-08T15:55:57.536Z}\naddMonths("2016-08-08T15:55:57.536Z"; -2) {2016-06-08T15:55:57.536Z}',
				},
				parseDate: {
					help: 'Zpracuje textový řetězec s datem a vrátí datum.',
					syntax: 'parseDate(datum; šablona; [zóna])',
					example:
						'parseDate("2016-12-28"; "YYYY-MM-DD") {2016-12-28T00:00:00.000Z}\nparseDate("2016-12-28 16:03"; "YYYY-MM-DD HH:mm") {2016-12-28T16:03:00.000Z}\nparseDate("2016-12-28 04:03 pm"; "YYYY-MM-DD hh:mm a") {2016-12-28T16:03:06.000Z}\nparseDate(1482940986; "X") {2016-12-28T16:03:06.000Z}',
				},
				setMinute: {
					help: 'Vrátí datum kde minuty budou nastaveny na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 0 - 59. Pokud bude tento rozsah překročen, budou v datu odpovídajícím způsobem nastaveny i hodiny.',
					syntax: 'setMinute(datum; číslo)',
					example:
						'setMinute("2015-10-07T11:36:39.138Z", 10) {2015-10-07T11:10:39.138Z}\nsetMinute("2015-10-07T11:36:39.138Z", 61) {2015-10-07T12:01:39.138Z}',
				},
				setSecond: {
					help: 'Vrátí datum kde vteřiny budou nastaveny na hodnotu uvedenou v druhém parametru. Povolené hodnoty jsou 0 - 59. Pokud bude tento rozsah překročen, budou v datu odpovídajícím způsobem nastaveny i minuty.',
					syntax: 'setSecond(datum; číslo)',
					example:
						'setSecond("2015-10-07T11:36:39.138Z", 10) {2015-10-07T11:36:10.138Z}\nsetSecond("2015-10-07T11:36:39.138Z", 61) {2015-10-07T11:37:01.138Z}',
				},
				addMinutes: {
					help: 'Vrátí datum posunuté o zadaný počet minut. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addMinutes(datum; počet)',
					example:
						'addMinutes("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T15:57:57.536Z}\naddMinutes("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T15:53:57.536Z}',
				},
				addSeconds: {
					help: 'Vrátí datum posunuté o zadaný počet sekund. Pro posunutí směrem dozadu zadejte záporné číslo.',
					syntax: 'addSeconds(datum; počet)',
					example:
						'addSeconds("2016-12-08T15:55:57.536Z"; 2) {2016-12-08T15:55:59.536Z}\naddSeconds("2016-12-08T15:55:57.536Z"; -2) {2016-12-08T15:55:55.536Z}',
				},
				formatDate: {
					help: 'Vrátí datum v požadovaném formátu a volitelně v dané časové zóně. Příklad formátu: DD.MM.YYYY HH:mm',
					syntax: 'formatDate(datum; formát; [zóna])',
					example:
						'formatDate("2016-12-28T16:03:06.372Z"; "MM/DD/YYYY")  {12/28/2016}\nformatDate("2016-12-28T16:03:06.372Z"; "YYYY-MM-DD hh:mm A")  {2016-12-28 4:03 PM}\nformatDate("2016-12-28T16:03:06.372Z"; "DD.MM.YYYY HH:mm"; "Europe/Prague")  {28.12.2016 17:03}\n',
				},
			},
			variables: {
				now: {
					help: 'Aktuální datum a čas.',
				},
				timestamp: {
					help: 'Unix timestamp. Počet sekund od půlnoci 1. 1. 1970 GMT.',
				},
			},
		},
		math: {
			label: 'Matematické funkce',
			functions: {
				max: {
					help: 'Vrátí největší číslo z čísel v zadaném poli, nebo z jednotlivě zadaných čísel.',
					syntax: 'max([pole hodnot]), max(hodnota1; hodnota2; ...)',
				},
				min: {
					help: 'Vrátí nejmenší číslo z čísel v zadaném poli, nebo z jednotlivě zadaných čísel.',
					syntax: 'min([pole hodnot]), min(hodnota1; hodnota2; ...)',
				},
				sum: {
					help: 'Vrátí součet čísel v zadaném poli, nebo součet jednotlivě zadaných hodnot.',
					syntax: 'sum([pole hodnot]), sum(hodnota1; hodnota2; ...)',
				},
				ceil: {
					help: 'Vrátí nejmenší celé číslo větší nebo rovno zadanému číslu. ',
					syntax: 'ceil(číslo)',
					example: 'ceil(1.2) {2}\nceil(4) {4}',
				},
				floor: {
					help: 'Vrátí největší celé číslo menší nebo rovno zadanému číslu. ',
					syntax: 'floor(číslo)',
					example: 'floor(1.2) {1}\nfloor(1.9) {1}\nfloor(4) {4}',
				},
				round: {
					help: 'Zaokrouhlí hodnotu na nejbližší celé číslo.',
					syntax: 'round(číslo)',
					example: 'round(1.2) {1}\nround(1.5) {2}\nround(1.7) {2}\nround(2) {2}',
				},
				average: {
					help: 'Vrátí průměrnou hodnotu čísel v zadaném poli, nebo jednotlivě zadaných hodnot.',
					syntax: 'average([pole hodnot]), average(hodnota1; hodnota2; ...)',
				},
				parseNumber: {
					help: 'Zpracuje textový řetězec s číslem a vrátí číslo. Př: parseNumber(1 756,456;,)',
					syntax: 'parseNumber(číslo; oddělovací znak desetinných míst)',
				},
				formatNumber: {
					help: 'Vrátí číslo v požadovaném formátu. Desetinný oddělovač je defaulntě `,`. Oddělovač tisíců je defaultně `.`.',
					syntax: 'formatNumber(number, desetin, [desetinnyOddelovac], [oddelovacTisicu])',
				},
			},
			operators: {
				'%': {
					help: 'Modulo.',
				},
				'*': {
					help: 'Násobení.',
				},
				'+': {
					help: 'Součet.',
				},
				'-': {
					help: 'Odečet.',
				},
				'/': {
					help: 'Dělení.',
				},
				'<': {
					help: 'Menší než.',
				},
				'>': {
					help: 'Větší než.',
				},
				'<=': {
					help: 'Menší než nebo rovno.',
				},
				'>=': {
					help: 'Větší než nebo rovno.',
				},
			},
			variables: {
				pi: {
					help: 'Konstanta π.',
				},
				random: {
					help: 'Náhodné desetinné číslo mezi 0 (včetně) a 1 (vyjma).',
				},
			},
		},
		array: {
			label: 'Funkce pro práci s polem',
			keywords: {
				emptyarray: {
					help: 'Vloží prázdné pole.',
					label: 'Prázdné pole',
				},
			},
			functions: {
				add: {
					help: 'Vrátí pole rozšířené o nové záznamy.',
					syntax: 'add(pole; hodnota1; hodnota2; ...)',
				},
				map: {
					help: 'Vrátí jednoduché pole hodnot z pole objektů, které může zároveň filtrovat. Klíče musejí být zadány jako "raw" názvy promměných.',
					syntax: 'map(pole objektů; klíč;[klíč pro filtrování];[možné hodnoty pro filtrování])',
					example:
						'map(Emaily[]; "email") {Vrátí jednoduché pole s emaily}\nmap(Emaily[]; "email"; "label"; "práce,domů") {vrátí jednoduché pole emailů, které mají label roven práce nebo domů.}',
				},
				join: {
					help: 'Převede pole na text, kde položky pole budou spojeny oddělovačem.',
					syntax: 'join(pole; oddělovač)',
				},
				keys: {
					help: 'Vrátí nové pole obsahující seznam všech vlastností (klíčů) zadaného objektu nebo pole.',
					syntax: 'keys(objekt)',
				},
				sort: {
					help: 'Seřadí hodnoty uvnitř pole. Směr je buď `asc` (vzestupně), `desc` (sestupně), `asc ci` (vzestupně bez rozdílu velikosti písmen) nebo `desc ci` (sestupně bez rozdílu velikosti písmen). Pro přístup k vlastnostem komplexních objektů zadejte argument `klíč`. Klíče musejí být zadány jako "raw" názvy promměných. Pro přístup k zanořeným hodnotám použijte tečkovou notaci. První položka v poli má index 1.',
					syntax: 'sort(pole; [směr]; [klíč])',
					example:
						'sort(Contacts[]; "name") {Seřadí pole kontaktů podle vlastnosti `name`.}\nsort(Emails[]; "sender.name") {Seřadí pole kontaktů podle vlastnosti `sender.name`.}',
				},
				merge: {
					help: 'Spojí dvě nebo více polí do jednoho pole.',
					syntax: 'merge(array1; array2; ...)',
				},
				slice: {
					help: 'Vrátí nové pole obsahující pouze vybrané záznamy.',
					syntax: 'slice(pole; začátek; [konec])',
				},
				length: {
					help: 'Vrátí počet záznamů v poli.',
					syntax: 'length(pole)',
				},
				remove: {
					help: 'Smaže z pole hodnoty definované v parametrech do funkce. Efektivní pouze na jednoduchých textových a číselných polích.',
					syntax: 'remove(pole; hodnota1; hodnota2; ...)',
				},
				contains: {
					help: 'Ověří zda v poli existuje hledaná hodnota.',
					syntax: 'contains(pole; hodnota)',
				},
				distinct: {
					help: 'Odstraní duplicity uvnitř pole. Pro přístup k vlastnostem komplexních objektů zadejte argument `klíč`. Pro přístup k zanořeným hodnotám použijte tečkovou notaci. První položka v poli má index 1.',
					syntax: 'distinct(pole; [klíč])',
					example: 'distinct(Contacts[]; "name") {Odstraní duplicity uvnitř pole porovnáním vlastnosti `name`.}',
				},
				toCollection: {
					help: 'Převede pole objektů s klíč-hodnota páry na kolekci.',
					syntax: 'toCollection(pole; klíč; hodnota)',
					example:
						"toCollection(array; \"name\", \"value\") {Převede pole jako je toto `[{name: 'firstName', value: 'John'}, {name: 'lastName', value: 'Mars'}] na kolekci `{firstName: 'John', lastName: 'Mars'}`}",
				},
				deduplicate: {
					help: 'Odstraní duplicity z pole.',
					syntax: 'deduplicate(pole)',
				},
				flatten: {
					help: 'Vrátí nové pole, které bude obsahovat položky všech zanořených polí dle zadané hloubky. Hloubka je defaultně `1`.',
					syntax: 'flatten(pole, [hloubka])',
				},
				reverse: {
					help: 'Otočí pořadí položek v poli.',
					syntax: 'reverse(pole)',
				},
				shuffle: {
					help: 'Zamíchá pořadí položek v poli.',
					syntax: 'shuffle(pole)',
				},
				first: {
					help: 'Vrátí první položku z pole.',
					syntax: 'first(pole)',
				},
				last: {
					help: 'Vrátí poslední položku z pole.',
					syntax: 'last(pole)',
				},
			},
		},
		string: {
			label: 'Textové a binární funkce',
			keywords: {
				tab: {
					help: 'Vloží na dané místo znak pro tabulátor.',
					label: 'Tabulátor',
				},
				nbsp: {
					help: 'Vloží na dané místo znak pro nedělitelnou mezeru (non-breaking space).',
					label: 'Nedělitelná mezera',
				},
				space: {
					help: 'Vloží na dané místo znak pro mezeru.',
					label: 'Mezera',
				},
				newline: {
					help: 'Vloží na dané místo znak pro nový řádek.',
					label: 'Nový řádek',
				},
				emptystring: {
					help: 'Vloží na dané místo prázdný text.',
					label: 'Prázdný text',
				},
				carriagereturn: {
					help: 'Vloží na dané místo znak pro carriage return',
					label: 'Carriage return',
				},
			},
			functions: {
				md5: {
					help: 'Vytvoří md5 hash ze zadaného textu.',
					syntax: 'md5(text)',
					example: 'md5("internet") {c3581516868fb3b71746931cac66390e}',
				},
				sha1: {
					help: 'Vytvoří sha1 hash ze zadaného textu. Pokud zadáte klíč, vytvoří sha1 HMAC hash. Výstupní kódování může nabývat hodnot `hex` (default), `base64` nebo `latin1`. Kódování klíče může nabývat hodnot `text` (default), `hex`, `base64` nebo `binary`.',
					syntax: 'sha1(text; [výstupní kodování]; [klíč]; [kodování klíče])',
					example: 'sha1("internet") {4d0fb475b242228032cbdf6d53924d2538df037b}',
				},
				trim: {
					help: 'Odstraní mezery na začátku a konci textu.',
					syntax: 'trim(text)',
				},
				ascii: {
					help: 'Odstraní z textu všechny ne-ascii znaky.',
					syntax: 'ascii(text; [odebrat diakritiku])',
					example: 'ascii("Hěešlčlřož") {Hello}\nascii("ěščřž"; true) {escrz}',
				},
				lower: {
					help: 'Převede všechny znaky abecedy v textu na malá písmena.',
					syntax: 'lower(text)',
					example: 'lower("Hello"){hello}',
				},
				split: {
					help: 'Rozdělí text na samostatné bloky textu dle zadaného oddělovače a vytvoří pole. Pokud je parametr zachovatPrázdnýText roven `true`, pak jsou prázdné položky zachovány.',
					syntax: 'split(text; oddělovač; [zachovatPrázdnýText])',
					example: 'split("John,George,Paul"; ",")',
				},
				upper: {
					help: 'Převede všechny znaky abecedy v textu na velká písmena.',
					syntax: 'upper(text)',
					example: 'upper("Hello") {HELLO}',
				},
				base64: {
					help: 'Převede text do binární podoby v base64 kódování.',
					syntax: 'base64(text)',
					example: 'base64("internet") {aW50ZXJuZXQ=}',
				},
				length: {
					help: 'Vrátí počet znaků v textu nebo délku binárního bufferu v bytech.',
					syntax: 'length(text nebo buffer)',
					example: 'length("Hello"){5}',
				},
				sha256: {
					help: 'Vytvoří sha256 hash ze zadaného textu. Pokud zadáte klíč, vytvoří sha256 HMAC hash. Výstupní kódování může nabývat hodnot `hex` (default), `base64` nebo `latin1`. Kódování klíče může nabývat hodnot `text` (default), `hex`, `base64` nebo `binary`.',
					syntax: 'sha256(text; [výstupní kodování]; [klíč]; [kodování klíče])',
					example: 'sha256("internet") {3b0fe0d342e9fa16a5c68dbba33f2e63c024f72a9d4c1ce1028570101d5229ff}',
				},
				sha512: {
					help: 'Vytvoří sha512 hash ze zadaného textu. Pokud zadáte klíč, vytvoří sha512 HMAC hash. Výstupní kódování může nabývat hodnot `hex` (default), `base64` nebo `latin1`. Kódování klíče může nabývat hodnot `text` (default), `hex`, `base64` nebo `binary`.',
					syntax: 'sha512(text; [výstupní kodování]; [klíč]; [kodování klíče])',
					example:
						'sha512("internet") {394ec188f58367d2b25af31a11a61435b2544c197b0baeb43cfbcb0d7b0116cd8d412e618272ca23561f787960844f86b8f775803405fd2abc358861c074c595}',
				},
				indexOf: {
					help: 'Vrátí pozici prvního výskytu zadané hodnoty uvnitř řetězce. Pokud není nalezen žádný výskyt, vrací funkce hodnotu `-1`.',
					syntax: 'indexOf(řetězec; hodnota; [start])',
					example: 'indexOf("Internet"; "t") {2}\nindexOf("Internet"; "x") {-1}\nindexOf("Internet"; "t"; 3) {7}',
				},
				replace: {
					help: 'Nahradí hledaný textový řetězec novým řetězcem.',
					syntax: 'replace(text; hledaný text; náhradní text)',
					example: 'replace("Hello World";"Hello";"Hi") {Hi World}',
				},
				contains: {
					help: 'Ověří zda v textu existuje hledaný řetězec.',
					syntax: 'contains(text; hledaný řetězec)',
					example: 'contains("Hello World"; "Hello")  {true}\ncontains("Hello World"; "Bye")  {false}',
				},
				toBinary: {
					help: 'Převede libovolnou hodnotu na binární data. Jako druhý argument můžete uvést kódování `hex` nebo `base64` pro převod textového řetězce na binární data.',
					syntax: 'toBinary(hodnota)',
					example: 'toBinary("internet") {696e7465726e6574}\ntoBinary("aW50ZXJuZXQ="; "base64") {696e7465726e6574}',
				},
				toString: {
					help: 'Převede libovolnou hodnotu na textový řetězec.',
					syntax: 'toString(hodnota)',
				},
				decodeURL: {
					help: 'Dekóduje speciální znaky v URL adrese na text.',
					syntax: 'decodeURL(text)',
					example: 'decodeURL("Automate%20your%20workflow") {Automate your workflow}',
				},
				encodeURL: {
					help: 'Překóduje speciální znaky v textu na platnou URL adresu.',
					syntax: 'encodeURL(text)',
				},
				startcase: {
					help: 'Upraví počáteční písmeno všech slov na velké, ostatní písmena budou převedena na malá.',
					syntax: 'startcase(text)',
					example: 'startcase("hello WORLD") {Hello World}',
				},
				stripHTML: {
					help: 'Odstraní z textu všechny HTML značky.',
					syntax: 'stripHTML(text)',
					example: 'stripHTML("<b>Hello</b>"){Hello}',
				},
				substring: {
					help: 'Vrátí znaky v textu od pozice start do pozice konec. ',
					syntax: 'substring(text; start; konec)',
					example: 'substring("Hello";0;3) {Hel}\nsubstring("Hello";1;3)  {el}',
				},
				capitalize: {
					help: 'Převede první znak v textu na velké písmeno.',
					syntax: 'capitalize(text)',
					example: 'capitalize("internet") {Internet}',
				},
				escapeHTML: {
					help: 'Překóduje všechny HTML značky na text.',
					syntax: 'escapeHTML(text)',
					example: 'escapeHTML("<b>Hello</b>") {&lt;b&gt;Hello&lt;/b&gt;}',
				},
				escapeMarkdown: {
					example: 'escapeMarkdown("# Header") {&#35; Header}',
					help: 'Překóduje všechny Markdown značky na text.',
					syntax: 'escapeMarkdown(text)',
				},
			},
			variables: {
				uuid: {
					help: 'Unikátní identifikátor dle RFC 4122 verze 4.',
				},
			},
		},
		general: {
			label: 'Obecné funkce',
			keywords: {
				null: {
					help: 'Vloží null (prázdnou) hodnotu.',
					label: 'Null',
				},
				true: {
					help: 'Logická hodnota (true).',
					label: 'Ano',
				},
				false: {
					help: 'Logická hodnota (false).',
					label: 'Ne',
				},
				ignore: {
					help: 'Instruuje jádro, aby se k poli chovalo, jako kdyby nebylo vyplněné. Užitečné ve složitějších IML výrazech.',
					label: 'Ignorovat',
				},
				erase: {
					help: 'Vymaže hodnotu. V případě pole nastaví prázdné pole.',
					label: 'Smazat',
				},
			},
			functions: {
				'': {
					help: '',
					syntax: '(výraz)',
				},
				if: {
					help: 'Vyhodnotí výraz a pokud je výraz pravdivý vrátí hodnotu1, pokud nepravdivý vrátí hodnotu2.',
					syntax: 'if(výraz; hodnota1; hodnota2)',
					example: 'if(1 = 1; "A"; "B") {A}\nif(1 = 2; "A"; "B") {B}',
				},
				get: {
					help: 'Vrátí hodnotu dle zadané cesty uvnitř pole nebo objektu. Pro přístup k zanořeným hodnotám použijte tečkovou notaci. První položka v poli má index 1.',
					syntax: 'get(objekt nebo pole; cesta)',
					example:
						'get(array; 1+1)\nget(array; "5.raw_name")\nget(object; "raw_name")\nget(object; "raw_name.sub_raw_name")',
				},
				equal: {
					help: 'Porovnává, zda jsou hodnoty shodné.',
					syntax: 'equal(hodnota; hodnota)',
				},
				switch: {
					help: 'Funkce porovnává hodnotu výrazu se seznamem hodnot a jako výsledek vrací první shodnou hodnotu.',
					syntax: 'switch(výraz; hodnota1; výsledek1; [hodnota2; výsledek2; ...]; [else])',
					example:
						'switch("B"; "A"; 1; "B"; 2; "C"; 3) {2}\nswitch("C"; "A"; 1; "B"; 2; "C"; 3) {3}\nswitch("X"; "A"; 1; "B"; 2; "C"; 3; 4) {4}',
				},
				ifempty: {
					help: 'Pokud není hodnota1 prázdná vrátí tuto hodnotu, v opačném případě vrátí hodnotu2.',
					syntax: 'ifempty(hodnota1; hodnota2)',
					example: 'ifempty("A";"B"){A}\nifempty(unknown;"B"){B}\nifempty("";"B"){B}',
				},
				omit: {
					help: 'Vynechá zadané klíče z objektu.',
					syntax: 'omit(objekt; klíč1; [klíč2; ...])',
					example: 'omit(User; "heslo")',
				},
				pick: {
					help: 'Vybere z objektu zadané klíče.',
					syntax: 'pick(objekt; klíč1; [klíč2; ...])',
					example: 'pick(User; "jmeno"; "email")',
				},
			},
			operators: {
				'&': {
					help: 'Provádí logické AND (a zároveň) spojení dvou výrazů.',
					label: 'and',
				},
				'=': {
					help: 'Je rovno.',
				},
				'|': {
					help: 'Provádí logické OR (nebo) spojení dvou výrazů.',
					label: 'or',
				},
				'!=': {
					help: 'Není rovno.',
				},
			},
			variables: {
				executionId: {
					help: 'Unikátní ID právě spuštěného běhu.',
				},
			},
		},
		integromat: {
			label: 'Vlastní a systémové proměnné',
			variables: {
				operationsLeft: {
					help: 'Počet zbývajících operací v rámci aktuálního předplatného.',
				},
				dataTransferLeft: {
					help: 'Počet zbývajícího datového přenosu v rámci aktuálního předplatného.',
				},
			},
		},
	},
};
