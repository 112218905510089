import { FolderInput } from './folder.mjs';
import { registerCustomElement } from '../utils.mjs';

export class FileInput extends FolderInput {
	get isFileSelect() {
		return true;
	}

	constructor() {
		super();
	}

	hasSelectedFile() {
		const selects = this.querySelectorAll('.wrapper > imt-picker');
		const lastSelect = selects && selects[selects.length - 1];
		const selectedElement = lastSelect.getSelectedElements();

		if (
			lastSelect &&
			selectedElement &&
			selectedElement.length === 1 &&
			selectedElement[0].getAttribute('data-type') === 'file'
		) {
			return true;
		}

		return false;
	}

	_filterOptions(options) {
		return options;
	}

	// TODO: refactor this (copy paste from input)
	_runValidators(init = false) {
		if (!this._built) return;

		if (this.skipValidation) {
			return true;
		}

		if (this.form) {
			this.form.debug('validation', `validating value '${this.name}' in domain '${this.fieldset?.domain}'`);
		}

		const value = this.value;
		const problems = [];
		let valid = true;

		this.empty = value == null || value === '';

		if (this.containsIML()) {
			// When value contains IML, use Coder validations instead
			problems.push(...this._coder.errors);
		} else {
			// Any _validation method can return false to indicate this field is no valid without providing a reason
			valid = this._validate(value, problems, init);
		}

		if (problems.length || valid === false) {
			if (problems.length) {
				const msgs = problems.map((err) => {
					if (typeof err === 'string') return err;
					return err.message;
				});

				this.errors = msgs;
				this._validationMessages.textContent = msgs.join('\n');
			}

			if (this.valid) {
				this.valid = false;
			}

			return false;
		} else if (this.mode !== 'map' && this.value && !this.hasSelectedFile()) {
			this.errors = ['Invalid file'];
			this._validationMessages.textContent = this.errors.join('\n');
			this.valid = false;

			return false;
		} else {
			this.errors = [];
			if (!this.valid) {
				this.valid = true;
			}
			return true;
		}
	}
}

registerCustomElement('imt-input-file', FileInput);
