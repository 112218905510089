'use strict';

import moment from '../deps/moment.mjs';

const defaultTimeZone = 'UTC';

export function parseDate(value, timezone) {
	let d;
	let day;
	let month;
	let time;
	let year;

	if (value instanceof Date) return value;
	if (typeof value === 'number') value = String(value);
	if (typeof value !== 'string') return null;

	value = value.trim();
	if (!timezone) timezone = defaultTimeZone;

	// console.log 'IN', value

	if (/^\d+$/.test(value)) return new Date(parseInt(value));

	let hour = 0;
	let minute = 0;
	let second = 0;
	let fraction = 0;
	let offset = null;

	const dd = (val, digs = 2) => `${'0'.repeat(digs - String(val).length)}${val}`;

	// 2016-06-09
	// 2016-06-09T15:42
	// 2016-06-09 15:42:46
	// 2016-6-9T15:42:46+0030
	// 2016/06/09T15:42:46.502+00:30

	if (/^(\d{4})[\-\/](\d{1,2})[\-\/](\d{1,2})[T\s]?(.+)?$/i.exec(value)) {
		year = parseInt(RegExp.$1);
		month = parseInt(RegExp.$2);
		day = parseInt(RegExp.$3);
		time = RegExp.$4 != null ? RegExp.$4.trim() : undefined;

		if (month > 12) {
			d = day;
			day = month;
			month = d;
		}
	}

	if (/^(\d{1,2})[\.\-]\s?(\d{1,2})[\.\-]\s?(\d{4})\s*(.+)?$/i.exec(value)) {
		year = parseInt(RegExp.$3);
		month = parseInt(RegExp.$2);
		day = parseInt(RegExp.$1);
		time = RegExp.$4 != null ? RegExp.$4.trim() : undefined;

		if (month > 12) {
			d = day;
			day = month;
			month = d;
		}
	}

	if (/^(\d{1,2})\/(\d{1,2})\/(\d{4})\s*(.+)?$/i.exec(value)) {
		year = parseInt(RegExp.$3);
		month = parseInt(RegExp.$1);
		day = parseInt(RegExp.$2);
		time = RegExp.$4 != null ? RegExp.$4.trim() : undefined;

		if (month > 12) {
			d = day;
			day = month;
			month = d;
		}
	}

	if (time && /^(.+)?([+\-])(\d\d):?(\d\d)$/i.exec(time)) {
		offset = (parseInt(RegExp.$3) * 60 * 60 + parseInt(RegExp.$4) * 60) * 1000;
		if (RegExp.$2 === '-') {
			offset *= -1;
		}
		time = RegExp.$1 != null ? RegExp.$1.trim() : undefined;
	}

	if (time && /^(.+)?(Z|UTC|GMT)$/i.exec(time)) {
		if (!offset) {
			offset = 0;
		}
		time = RegExp.$1 != null ? RegExp.$1.trim() : undefined;
	}

	if (time && /^(\d{1,2}):(\d{1,2})(?:\:(\d{1,2})(?:\.(\d+))?)?$/i.exec(time)) {
		hour = parseInt(RegExp.$1);
		minute = parseInt(RegExp.$2);
		second = parseInt(RegExp.$3 || 0);
		fraction = parseInt((RegExp.$4 || 0).toString().substr(0, 3));
	} else if (time && /^(\d{1,2})(?:\:(\d{1,2}))?\s*(AM|PM)$/i.exec(time)) {
		hour = parseInt(RegExp.$1);
		minute = parseInt(RegExp.$2 || 0);
		const ampm = RegExp.$3.toLowerCase();

		if (ampm === 'am' && hour === 12) {
			hour = 0;
		} else if (ampm === 'pm' && hour !== 12) {
			hour += 12;
		}
	} else if (time) {
		return null;
	}

	// console.log 'OUT', year, month, day, hour, minute, second, fraction

	if (year) {
		const timestamp = Date.UTC(year, month - 1, day, hour, minute, second, fraction);

		if (isNaN(timestamp)) return null;

		if (offset != null) {
			return new Date(timestamp - offset);
		} else if (timezone && timezone !== 'UTC') {
			return moment
				.tz(`${year}-${dd(month)}-${dd(day)}T${dd(hour)}:${dd(minute)}:${dd(second)}.${dd(fraction, 3)}`, timezone)
				.toDate();
		} else {
			return new Date(timestamp);
		}
	}

	return null;
}

export function parseTime(value, timezone) {
	if (!timezone) timezone = defaultTimeZone;
	if (value instanceof Date) value = moment(value).tz(timezone).format('HH:mm:ss.SSS');
	if (typeof value !== 'string') return null;
	value = value.trim();

	let hour = 0;
	let minute = 0;
	let second = 0;
	let fraction = 0;

	if (value && /^(\d{1,2}):(\d{1,2})(?:\:(\d{1,2})(?:\.(\d{1,3}))?)?$/i.exec(value)) {
		hour = parseInt(RegExp.$1);
		minute = parseInt(RegExp.$2);
		second = parseInt(RegExp.$3 || 0);
		fraction = parseInt(RegExp.$4 || 0);
	} else if (/^(\d{1,2})(?:\:(\d{1,2}))?\s*(AM|PM)$/i.exec(value)) {
		hour = parseInt(RegExp.$1);
		minute = parseInt(RegExp.$2 || 0);
		const ampm = RegExp.$3.toLowerCase();

		if ((ampm === 'am' || ampm === 'pm') && hour > 12) {
			return null;
		}

		if (ampm === 'am' && hour === 12) {
			hour = 0;
		} else if (ampm === 'pm' && hour !== 12) {
			hour += 12;
		}
	} else {
		// try to parse value as a date
		value = parseDate(value, timezone);
		if (value) {
			return parseTime(value, timezone);
		}
		return null;
	}

	if (hour >= 24) return null;
	if (minute >= 60) return null;
	if (second >= 60) return null;
	if (fraction >= 1000) return null;

	return fraction + second * 1000 + minute * 60 * 1000 + hour * 60 * 60 * 1000;
}

export function timeToString(number) {
	if ('number' !== typeof number) return null;
	if (number >= 86400000) return null;

	const dd = (val, digs = 2) => `${'0'.repeat(digs - String(val).length)}${val}`;

	const hour = Math.floor(number / 3600000);

	number = number % 3600000;

	const minute = Math.floor(number / 60000);

	number = number % 60000;

	const second = Math.floor(number / 1000);
	const fraction = number % 1000;

	let txt = `${dd(hour)}`;

	txt += `:${dd(minute)}`;
	if (second || fraction) txt += `:${dd(second)}`;
	if (fraction) txt += `.${dd(fraction, 3)}`;

	return txt;
}
