import { NumberInput } from './number.mjs';
import { registerCustomElement } from '../utils.mjs';
import { cast } from '../types/uinteger.mjs';

export class UintegerInput extends NumberInput {
	castValue(value) {
		return cast(value);
	}
}

registerCustomElement('imt-input-uinteger', UintegerInput);
