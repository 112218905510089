/* eslint-env node */

class FakeBuffer {
	constructor(value) {
		this._value = value;
	}
	static from(value) {
		return new FakeBuffer(value);
	}
	static isBuffer(value) {
		return value instanceof FakeBuffer;
	}
	toString(codepage) {
		return this._value;
	}
}

const browser = typeof window !== 'undefined';

export const Buffer = browser ? window.Buffer || FakeBuffer : global['Buffer']; // This hack prevents Browserify to add Buffer polyfill
