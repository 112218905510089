/**
 * This file is used by both font-end and back-end.
 */

import { ValidationError } from '../helpers/errors.mjs';
import * as uinteger from './uinteger.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	value = uinteger.cast(value);
	if (value === null) return value;
	if (value > 65535) return null;
	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	value = uinteger.validate(value, instructions, options, more);
	if (value === null) return value;

	if (value <= 0 || value > 65535) {
		throw new ValidationError('Invalid port.', 'validate-port-invalid');
	}

	return value;
}
