/**
 * This file is used by both font-end and back-end.
 */

import { ValidationError } from '../helpers/errors.mjs';
import ajvLib from '../deps/ajv.mjs';
export { cast } from './text.mjs';

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} [options] Global options.
 * @param {function} [more] Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}, more = undefined) {
	if (value === '') return null;

	value = String(value != null ? value : '');

	// No need to validate when text is empty
	if (value === '') return value;

	return value;

	// TODO: Ajv uses unsafe-eval we need a different validator.

	const ajv = new ajvLib.Ajv(); // options can be passed, e.g. {allErrors: true}
	const validateOptions = instructions.validate || {};
	const stringSchema = { type: 'string' };

	if (validateOptions.maxLength) {
		stringSchema.maxLength = validateOptions.maxLength;
	}

	if (validateOptions.minLength) {
		stringSchema.minLength = validateOptions.minLength;
	}

	const validate = ajv.compile(stringSchema);
	const valid = validate(value);

	if (!valid) {
		const err = new ValidationError(`Value ${validate.errors[0].message}.`);

		err.imtExceptionHash = 'validate-json-string-invalid';
		throw err;
	}

	// parsing object and validation against schema
	let objectData;

	try {
		objectData = JSON.parse(value);
	} catch (e) {
		const err = new ValidationError(`Value is not a valid JSON string.`);

		err.imtExceptionHash = 'validate-json-format-invalid';
		throw err;
	}

	if (instructions.schema) {
		const ajvObject = new ajvLib.Ajv();
		const schema = instructions.schema;
		const objectValidate = ajvObject.compile(schema);
		const objectValid = objectValidate(objectData);

		if (!objectValid) {
			const err = new ValidationError(
				`Value is not valid against schema: ${objectValidate.errors[0].instancePath} ${objectValidate.errors[0].message}.`,
			);

			err.imtExceptionHash = 'validate-json-invalid';
			throw err;
		}
	}
	return objectData;
}
