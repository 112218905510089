import { EventEmitter } from './helpers/events.mjs';
import { deepEqual } from './utils.mjs';

const DEFAULT_DOMAIN = 'default';

/**
 * Internal store of values across the whole form. Serves as a backbone for bi-directional data binding.
 */

export class Values extends EventEmitter {
	constructor(config) {
		super();

		this.debug = config?.debug;
		this._store = Object.create(null);
		this._storeInitial = Object.create(null);
	}

	get changed() {
		return !deepEqual(this._storeInitial, this._store);
	}

	/**
	 * Sets the value of a field.
	 *
	 * @param path Name of the field with path.
	 * @param value Value.
	 * @param domain Domain of the field
	 * @param {HTMLElement} [sender] Sender of the event.
	 * @param {boolean} initialValue Define if the provided value is initial.
	 */

	set(domain, path, value, sender = null, initialValue = false) {
		this._store[domain] = this._store[domain] || {};
		this._storeInitial[domain] = this._storeInitial[domain] || {};

		if (initialValue) {
			this._storeInitial[domain][path] = value;
		}

		if (this._store[domain][path] === value) return; // Nothing has changed

		this._store[domain][path] = value;

		if (this.debug) {
			if (sender && sender.nodeName === 'IMT-INPUT-PASSWORD') {
				// We must make sure we don't leak password here
				this.debug(
					'values',
					`value '${path}' in domain '${domain}' changed by`,
					sender,
					'to',
					'*'.repeat(String(value).length),
				);
			} else {
				this.debug('values', `value '${path}' in domain '${domain}' changed by`, sender, 'to', value);
			}
		}

		if (!initialValue) {
			this.emit('change', domain, path, value, sender);
		}
	}

	get(domain, path, initial = false) {
		const store = initial ? this._storeInitial : this._store;

		return store[domain] && store[domain][path] != null ? store[domain][path] : undefined;
	}

	delete(domain, path) {
		if (!(this._store[domain] && this._store[domain][path])) return;
		delete this._store[domain][path];
	}

	load(data, domain = DEFAULT_DOMAIN) {
		if (!data) return;

		const iterate = (entry, path = '') => {
			if (Array.isArray(entry)) {
				this._store[domain] = this._store[domain] || {};
				this._store[domain][path] = entry;

				entry.forEach((e, i) => {
					iterate(e, `${path}[${i}]`);
				});
			} else if (entry instanceof Object) {
				Object.entries(entry).forEach(([key, value]) => {
					iterate(value, `${[path]}${path ? '.' : ''}${key}`);
				});
			} else {
				this._store[domain] = this._store[domain] || {};

				this._store[domain][path] = entry;
			}
		};

		iterate(data);
	}

	commit(domain) {
		const store = Object.entries(this._store).reduce((store, [storeDomain, fields]) => {
			const changed = Object.entries(fields)
				.filter(([name, value]) => value !== this._storeInitial[storeDomain][name])
				.map(([name, value]) => {
					this._storeInitial[storeDomain][name] = value;
					return name;
				});

			if (changed.length) store[storeDomain] = changed;
			return store;
		}, {});

		return domain ? store[domain] : store;
	}

	rollback(domain) {
		Object.entries(this._store).forEach(([storeDomain, values]) => {
			if (domain && domain !== storeDomain) return;

			const initialPaths = Object.keys(this._storeInitial[storeDomain]);

			Object.keys(values).forEach((path) => {
				if (!initialPaths.includes(path)) {
					if (this.debug) {
						this.debug(
							'values',
							`value '${path}' in domain '${storeDomain}' rolled back from ${this._store[storeDomain][path]} to undefined`,
						);
					}

					this._store[storeDomain][path] = undefined;
					this.emit('change', storeDomain, path, undefined);
				}
			});
		});

		Object.entries(this._storeInitial).forEach(([storeDomain, values]) => {
			if (domain && domain !== storeDomain) return;

			Object.entries(values).forEach(([path, value]) => {
				if (this.debug) {
					this.debug(
						'values',
						`value '${path}' in domain '${storeDomain}' rolled back from ${this._store[storeDomain][path]} to ${value}`,
					);
				}

				this._store[storeDomain][path] = value;
				this.emit('change', storeDomain, path, value);
			});
		});
	}

	reset(domain) {
		if (domain) {
			this._store[domain] = {};
			this._storeInitial[domain] = {};
		} else {
			this._store = Object.create(null);
			this._storeInitial = Object.create(null);
		}
	}
}
