import Loader from '../loader.mjs';
import {
	create,
	registerCustomElement,
	isAttribute,
	animateElementHeight,
	parseAttributeValue,
	dispatchResizeDebounced,
	deepEqual,
} from '../utils.mjs';
import EVENTS from '../events.mjs';
import './picker.scss';
import { I18n } from '../helpers/i18n.mjs';

window.pickerSetValue = [];

export class Option extends HTMLElement {
	constructor() {
		super();
	}

	static get observedAttributes() {
		return ['selected'];
	}

	/**
	 * Called when any of observed attributes is changed.
	 *
	 * @param {String} name Attribute name
	 * @param {*} oldValue Old value
	 * @param {*} newValue New value
	 */

	attributeChangedCallback(name, oldValue, newValue) {
		// Remove temporary value once unselected
		if (this.temporary && name === 'selected' && newValue == null) {
			this.parentNode.removeChild(this);
		}
	}

	get selected() {
		return isAttribute(this, 'selected');
	}

	set selected(value) {
		if (value) {
			this.setAttribute('selected', '');
		} else {
			this.removeAttribute('selected');
		}
	}

	get temporary() {
		return isAttribute(this, 'temporary');
	}

	set temporary(value) {
		value ? this.setAttribute('temporary', '') : this.removeAttribute('temporary');
	}

	get value() {
		const value = this.getAttribute('value');

		if (value === null) return;
		// We use JSON by default but the value can be rendered into DOM and probably it wont be in JSON
		return parseAttributeValue(value, this.getAttribute('content-type'));
	}

	set value(value) {
		if (typeof value !== 'undefined') {
			this.setAttribute('content-type', 'application/json');
			this.setAttribute('value', JSON.stringify(value));
		} else {
			this.removeAttribute('content-type');
			this.removeAttribute('value');
		}
	}
}

export class OptionGroup extends HTMLElement {
	constructor() {
		super();
	}
}

export class Picker extends HTMLElement {
	constructor() {
		super();

		this._built = false;
		this._loaded = false;
		this._options = {};
		this._defaultClassList = ['form-control'];
		this._inputField = null; // Input the picker is attached to

		this.components = {}; // Custom components
		this.showSearcher = 10;

		// Watch for options being added to our DOM
		const observer = new MutationObserver((mutationList) => {
			const additions = [];

			for (const mutation of mutationList) {
				for (const node of mutation.addedNodes) {
					// Only consume imt-optgroup and imt-option nodes
					if (node.nodeName === 'IMT-OPTGROUP' || node.nodeName === 'IMT-OPTION') {
						additions.push(node);
					}
				}
			}

			// Ignore rest if there're no additions we're interested about
			if (!additions.length) return;

			// Make sure we have been built
			if (!this._built) this._build();

			const options = this.querySelector(':scope .options');
			const temporaries = this.querySelectorAll('imt-option[temporary]');

			for (const node of additions) {
				// Move node under dropdown > options
				options.appendChild(node);

				if (temporaries.length) {
					// Check for temporary items if we can remove them
					const opts = node.nodeName === 'IMT-OPTION' ? [node] : node.querySelectorAll('imt-option');

					for (const opt of opts) {
						for (const temporary of temporaries) {
							if (temporary.parentNode && temporary !== opt && temporary.value === opt.value) {
								// If temporary option was selected, select the new one instead
								opt.selected = temporary.selected;

								// Remove temporary option
								temporary.parentNode.removeChild(temporary);

								// Break out from the loop of temporary options
								break;
							}
						}
					}
				}
			}

			// Update our display
			this.update();
		});

		observer.observe(this, {
			childList: true,
		});
	}

	static get observedAttributes() {
		return ['placeholder'];
	}

	/**
	 * Normalizes and merges options.
	 * @param {object|array|string} options - new options
	 * @param {object} currentOptions - old options, provide value if you want to merge new options with the existing options
	 * @return {object} - normalized options
	 */

	static normalizeOptions(options, currentOptions = {}) {
		if (!options) return { store: [] };

		try {
			options = JSON.parse(options);
		} catch (ex) {} // eslint-disable-line no-empty

		if ('object' === typeof options && !Array.isArray(options)) {
			Object.assign(currentOptions, options);
		}

		if (Array.isArray(options) || typeof options === 'string') {
			currentOptions.store = options;
		} else if (!options) {
			currentOptions.store = [];
		} else {
			currentOptions = options;
		}

		return currentOptions;
	}

	/**
	 * Called when any of observed attributes is changed.
	 *
	 * @param {String} name Attribute name
	 * @param {*} oldValue Old value
	 * @param {*} newValue New value
	 */

	attributeChangedCallback(name, oldValue, newValue) {
		this.update();
	}

	connectedCallback() {
		this._build();
	}

	get disabled() {
		return this.hasAttribute('disabled');
	}

	set disabled(value) {
		if (value) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}
	}

	get multiple() {
		return this.hasAttribute('multiple');
	}

	set multiple(value) {
		if (value) {
			this.setAttribute('multiple', '');
		} else {
			this.removeAttribute('multiple');
		}
	}

	get dropdown() {
		return this.hasAttribute('dropdown');
	}

	set dropdown(value) {
		if (value) {
			this.setAttribute('dropdown', '');
		} else {
			this.removeAttribute('dropdown');
		}
	}

	get list() {
		return this.hasAttribute('list');
	}

	set list(value) {
		if (value) {
			this.setAttribute('list', '');
		} else {
			this.removeAttribute('list');
		}
	}

	get readonly() {
		return this.hasAttribute('readonly');
	}

	set readonly(value) {
		if (value) {
			this.setAttribute('readonly', '');
		} else {
			this.removeAttribute('readonly');
		}
	}

	get value() {
		if (this.multiple) {
			return Array.prototype.map.call(this.querySelectorAll('imt-option[selected]'), (node) => node.value);
		} else {
			const selected = this.querySelector('imt-option[selected]');

			if (!selected) {
				const emptyOption = Array.isArray(this.options?.store) ? this.options.store.find((x) => x.empty) : null;

				return emptyOption ? emptyOption.value : undefined;
			}
			return selected.value;
		}
	}

	set value(value) {
		const preventDefault = !this.dispatchEvent(new CustomEvent(EVENTS.PICKER.CHANGE, { cancelable: true }));

		if (preventDefault) return;

		for (const node of this.querySelectorAll('imt-option')) {
			if (this.multiple && Array.isArray(value)) {
				if (value.includes(node.value)) {
					const preventDefault = !this.dispatchEvent(
						new CustomEvent(EVENTS.PICKER.BEFORE_SELECT_OPTION, { detail: { option: node }, cancelable: true }),
					);

					if (!preventDefault) node.selected = true;
				} else if (isAttribute(node, 'selected')) {
					node.selected = false;
				}
			} else {
				if (node.value === value) {
					const preventDefault = !this.dispatchEvent(
						new CustomEvent(EVENTS.PICKER.BEFORE_SELECT_OPTION, { detail: { option: node }, cancelable: true }),
					);

					if (!preventDefault) node.selected = true;
				} else if (isAttribute(node, 'selected')) {
					node.selected = false;
				}
			}
		}

		this.update();

		this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		this.dispatchEvent(new CustomEvent(EVENTS.PICKER.CHANGED));
	}

	get valueLabel() {
		if (this.multiple) {
			return Array.prototype.map
				.call(this.querySelectorAll('imt-option[selected]'), (node) => node.textContent)
				.join(', ');
		}

		return (this.querySelector('imt-option[selected]') || this.querySelector('imt-option.empty'))?.textContent;
	}

	get valueType() {
		if (this.multiple) {
			return Array.prototype.map.call(this.querySelectorAll('imt-option[selected]'), (node) =>
				node.getAttribute('data-type'),
			);
		} else {
			const selected = this.querySelector('imt-option[selected]');

			if (!selected) return null;
			return selected.getAttribute('data-type');
		}
	}

	get options() {
		return this._options;
	}

	set options(options) {
		this._options = options || [];
	}

	get staticOptions() {
		const options = this.getAttribute('static-options');

		return options ? JSON.parse(options) : [];
	}

	set staticOptions(options) {
		this.setAttribute('static-options', JSON.stringify(options));
	}

	set inputField(element) {
		this._inputField = element;
	}

	set defaultClassList(list) {
		this._defaultClassList = list;
	}

	/**
	 * Registers a new component for custom behavior of the picker
	 * @param {Picker.COMPONENTS} type Name of the component
	 * @param {function} component Component fuctions
	 */

	register(type, component) {
		this.components[type] = component;
	}

	_build() {
		if (this._built) return;
		this._built = true;

		let focusOutTimer;

		// Make switch focusable
		this.setAttribute('tabindex', 0);

		this.classList.add(...this._defaultClassList);

		this.appendChild(create('span.display'));
		this.appendChild(create('span.messages'));
		const options = create('div.options');

		options.addEventListener('click', (event) => {
			const option = event.composedPath().find((node) => node.nodeName === 'IMT-OPTION');

			if (option) this.selectOptionElement(option);
		});

		if ((this.multiple || this.list) && !this.dropdown) {
			this.appendChild(options);
		} else {
			const dropdown = create('imt-picker-dropdown');

			this.appendChild(dropdown);

			dropdown.addEventListener('click', (event) => {
				event.stopPropagation();
			});

			const searcher = create('imt-searcher');

			searcher.setAttribute('item', 'imt-option');
			searcher.setAttribute('group', 'imt-optgroup');
			searcher.container = options;

			dropdown.appendChild(searcher);
			dropdown.appendChild(options);
		}

		const reload = create('button.btn.btn-sm.reload[type="button"]');

		reload.appendChild(create('i.fa.fa-fw.fa-sync'));
		this.appendChild(reload);
		reload.addEventListener('click', (event) => {
			this.reload(this.value);
			event.stopPropagation();
		});

		this.update();

		// Keyboard handlers
		this.addEventListener('keydown', (event) => {
			const hover = this.querySelector('imt-option.hover') || this.querySelector('imt-option[selected]');
			let next;

			switch (event.code) {
				case 'Space':
				case 'ArrowDown':
					if (this.classList.contains('expanded')) {
						if (event.code === 'ArrowDown') {
							if (hover) {
								if (hover.nextElementSibling) {
									next = hover.nextElementSibling;
								} else if (
									// Jump to next group in grouped select
									hover.parentNode.nodeName === 'IMT-OPTGROUP' &&
									hover.parentNode.nextElementSibling &&
									hover.parentNode.nextElementSibling.firstChild
								) {
									next = hover.parentNode.nextElementSibling.firstChild;
								} else {
									next = hover;
								}

								next.classList.add('hover');
							} else {
								const first = this.querySelector('imt-option');

								if (first) first.classList.add('hover');
							}

							event.stopPropagation();
							event.preventDefault();
						}
					} else {
						this.open();

						event.stopPropagation();
						event.preventDefault();
					}

					break;

				case 'ArrowUp':
					if (hover) {
						if (hover.previousElementSibling) {
							next = hover.previousElementSibling;
						} else if (
							// Jump to previous group in grouped select
							hover.parentNode.nodeName === 'IMT-OPTGROUP' &&
							hover.parentNode.previousElementSibling &&
							hover.parentNode.previousElementSibling.lastChild
						) {
							next = hover.parentNode.previousElementSibling.lastChild;
						} else {
							next = hover;
						}

						next.classList.add('hover');
					}

					event.stopPropagation();
					event.preventDefault();
					break;

				case 'Enter':
					if (hover) this.selectOptionElement(hover);
					this.focus();

					event.stopPropagation();
					event.preventDefault();
					break;

				case 'Escape':
					this.close();
					this.focus();

					event.stopPropagation();
					event.preventDefault();
					break;
			}
		});

		// Mouse/touch handlers
		this.addEventListener('click', (event) => {
			if (this.classList.contains('expanded')) {
				this.close();
			} else {
				this.open();
			}
			event.stopPropagation();
			event.preventDefault();
		});

		// Focus out handlers - handles possibility of wtiching between search and picker
		this.addEventListener('focusin', () => {
			clearTimeout(focusOutTimer);
		});

		this.addEventListener('focusout', () => {
			focusOutTimer = setTimeout(this.close.bind(this), 100);
		});
	}

	update() {
		const display = this.querySelector(':scope > .display');

		if (!display) return;

		let value;
		let group;

		// Resolve selected value
		if (this.multiple) {
			value = Array.prototype.map
				.call(this.querySelectorAll('imt-option[selected]'), (node) => node.textContent || node.value)
				.join(', ');
		} else {
			const selected = this.querySelector('imt-option[selected]');

			if (selected) {
				group = selected.closest('imt-optgroup')?.getAttribute('label');
				value = selected.classList.contains('empty') ? selected.value : selected.textContent || selected.value;
			}
		}

		if (!value) {
			display.classList.add('text-muted');
			display.textContent = this.getAttribute('placeholder') || '';
		} else {
			display.classList.remove('text-muted');
			display.textContent = group ? `${group}: ${value}` : value;
		}
	}

	async open() {
		this.displaySearcher();

		if ((this.multiple || this.list) && !this.dropdown) return;
		if (this.readonly || this.disabled) return;
		if (this.classList.contains('expanded')) return;

		const dropdown = this.querySelector('imt-picker-dropdown');
		const rect = this.getBoundingClientRect();
		const maxHeight = window.innerHeight - rect.bottom - 50;

		dropdown.querySelector('.options').style.maxHeight = `${maxHeight < 165 ? 165 : maxHeight}px`;

		if (this.hasAttribute('provider')) {
			const rpcData = this.components[Picker.COMPONENTS.RPC_DATA_GETTER]() || {};

			if (this.hasAttribute('provider') && (!this._loaded || !deepEqual(rpcData, this._loadedRpcData))) {
				this.reload(undefined, false);
			}
		}

		dropdown.open();
		this.classList.add('expanded');

		animateElementHeight(dropdown, { timing: 150 }).then(() => {
			setTimeout(() => {
				const searcher = this.querySelector(':scope > imt-picker-dropdown > imt-searcher:not([hidden])');

				if (searcher) {
					searcher.focus();
				}
			}, 1);
		});
	}

	async reload(value, resolveDefaultValue) {
		if (this.classList.contains('loading')) return;
		if (!this.hasAttribute('provider')) return;
		this.classList.add('loading');
		this._inputField && this._inputField.form.loadings++;

		try {
			// Create abort controller so we can abort the request if needed
			this._abortController = new AbortController();

			const form = this.closest('imt-forman');
			const context = form ? form.meta.imlContext : {};

			this._loadedRpcData = this.components[Picker.COMPONENTS.RPC_DATA_GETTER]() || {};
			let response = await Loader.load(this.getAttribute('provider'), {
				signal: this._abortController.signal,
				data: this._loadedRpcData,
				context,
			});

			const wrapper = this.getAttribute('wrapper-field');

			if (wrapper && response[wrapper]) response = response[wrapper];

			// Fetch done, remove abort controller
			this._abortController = undefined;
			this._loaded = true;

			// Remove old options
			this.querySelectorAll('imt-option').forEach((option) => {
				// Don't remove temporary options
				if (!option.temporary) {
					if (option.selected) {
						// Convert selected option to temporary so the value is preserved
						option.temporary = true;
					} else {
						option.parentNode.removeChild(option);
					}
				}
			});

			if (!Array.isArray(response)) return;

			const options = Picker.normalizeOptions(response, this.options);

			this.addOptions(options, value, {}, resolveDefaultValue);

			this.update();

			if (this.list) {
				dispatchResizeDebounced(this);
			}

			this.dispatchEvent(new CustomEvent(EVENTS.PICKER.LOAD_DONE, { detail: options }));
			const dropdown = this.querySelector('imt-picker-dropdown');

			if (dropdown?._opened) {
				this.displaySearcher();
				dropdown.updatePosition();
			}

			this.showError(false);
		} catch (err) {
			if (err.name === 'AbortError') return;

			this.dispatchEvent(new CustomEvent(EVENTS.PICKER.LOAD_ERROR, { detail: err }));
			this.showError(err);

			throw err;
		} finally {
			this.classList.remove('loading');
			this._inputField && this._inputField.form.loadings--;
		}
	}

	close() {
		if ((this.multiple || this.list) && !this.dropdown) return;
		this.classList.remove('expanded');
		this.querySelector('imt-picker-dropdown').close();

		const searcher = this.querySelector('imt-searcher');

		if (searcher) {
			searcher.clear();
		}

		// Abort loading of external provider
		if (this._abortController) this._abortController.abort();
	}

	renderOption(item, temporary = false, parent, restoreData = {}) {
		if (!parent) {
			parent = this.querySelector('div.options') || this;
		}

		const labelField = this.getAttribute('label-field') || 'label';
		const valueField = this.getAttribute('value-field') || 'value';

		if ((this.multiple || this.list) && this.options.nested && item.nested) {
			throw new Error(
				'Invalid configuration. It is not possible to define common nested and option nested together for multiple select or list.',
			);
		}

		// if there is a temporary option with current value, convert it to non temporary and update it's text content
		const temporaryOption = parent.querySelector(`imt-option[temporary]`);

		if (temporaryOption && temporaryOption.value === item[valueField]) {
			temporaryOption.temporary = false;
			temporaryOption.textContent = item[labelField] || item[valueField];
			return temporaryOption;
		}

		const option = create('imt-option');

		if (item.empty) {
			option.classList.add('empty');
			option.value = item[this.options.value || valueField];
			option.textContent = item[labelField] || item[valueField];
		} else {
			// Store option metadata as attributes
			if (this.options.data) {
				Object.entries(this.options.data).forEach(([key, variable]) => {
					option.setAttribute(`data-${key}`, item[variable]);
				});
			}

			option.value = item[this.options.value || valueField];
			option.textContent = item[labelField] || item[valueField];

			if (!temporary && this.components[Picker.COMPONENTS.OPTION_RENDERER]) {
				this.components[Picker.COMPONENTS.OPTION_RENDERER](option, item, this.querySelector('div.options'));
			}
		}

		if (restoreData) {
			Object.entries(restoreData).forEach(([key, variable]) => {
				option.setAttribute(`data-${key}`, variable);
			});
		}

		// Prepare container for nested fieldsets if there are any
		const nestedInstructions = item.nested || this.options.nested;

		if (nestedInstructions) {
			const wrapper = this._inputField || this.parentNode;

			if (!wrapper.querySelector('imt-nested')) {
				wrapper.appendChild(create('imt-nested'));
			}
		}

		if (temporary) option.temporary = true;
		parent.appendChild(option);

		return option;
	}

	async addOptions(options, defaultValue, metadata = {}, resolveDefaultValue = true) {
		// Store options for further rendering of nested fieldsets
		this.options = options;

		const valueField = this.getAttribute('value-field') || 'value';
		const labelField = this.getAttribute('label-field') || 'label';

		if (resolveDefaultValue && typeof defaultValue === 'undefined') {
			defaultValue = this.resolveDefaultValue(options, valueField);
		}

		// Choose value if static options and defaultValue matches one of option value
		// Default value for multiple select can be simple value or array
		const shouldAddTemporary =
			typeof defaultValue !== 'undefined' &&
			'string' === typeof options.store &&
			!(this.staticOptions || []).find((o) => o.value === defaultValue);

		if (
			shouldAddTemporary &&
			(!Array.isArray(options.store) ||
				(this.hasAttribute('grouped') ? options.store.map((g) => g.options).flat() : options.store).some(
					(o) =>
						o &&
						((Array.isArray(defaultValue) && defaultValue.includes(o[valueField])) || o[valueField] === defaultValue),
				))
		) {
			/**
			 * If the value is set, we need to add temporary option so the value is visible
			 * until options are fetched (both synchronously and asynchronously). That also prevents
			 * value clearing when user doesn't open a dropdown so options are actually fetched.
			 */

			if (this.multiple && Array.isArray(defaultValue)) {
				// We have an array of values for multi-select
				defaultValue.map((v) => this.renderOption({ value: v, label: v }, true));
			} else {
				// Just a single value is single-select
				this.renderOption(
					{
						[valueField || 'value']: defaultValue,
						[labelField || 'label']: metadata.restore?.label || defaultValue,
					},
					true,
					undefined,
					metadata.restore?.data,
				);
			}
		}

		if (Array.isArray(options.store)) {
			options.store = this.staticOptions.concat(options.store);

			for (const item of options.store) {
				if (this.hasAttribute('grouped') && item.options) {
					const group = create('imt-optgroup');

					group.setAttribute('label', item.label);
					this.appendChild(group);

					if (Array.isArray(item.options)) {
						for (const subitem of item.options) {
							if (item.default) defaultValue = item.value;
							this.renderOption(subitem, false, group);
						}
					}
				} else {
					if (item.default && (typeof defaultValue === 'undefined' || defaultValue === '')) defaultValue = item.value;
					this.renderOption(item);
				}
			}

			if (options.store.length === 0 && this.list) {
				const empty = create('div.empty-list.list-group-item');

				console.log(I18n.l('picker.list-empty'));
				empty.textContent = I18n.l('picker.listEmpty');
				this.querySelector('div.options').append(empty);
			}
		}

		if (typeof defaultValue !== 'undefined') {
			this.value = defaultValue;
		}
	}

	loadCustomOptions(options, defaultValue) {
		if (!this._loaded && this.hasAttribute('provider')) {
			this._loaded = true;
		}

		this.addOptions(options, defaultValue);
	}

	resolveDefaultValue(options, valueField) {
		const value = this.multiple ? [] : undefined;
		const resolver = this.components[Picker.COMPONENTS.DEFAULT_OPTION_RESOLVER];

		if (!resolver || !(Array.isArray(options.store) && options.store.length)) return value;

		for (const option of options.store) {
			if (resolver(option)) {
				if (this.multiple) {
					value.push(option[valueField]);
				} else {
					return option[valueField];
				}
			}
		}
		return value;
	}

	selectOptionElement(option, close = true) {
		if (isAttribute(option, 'disabled')) return;

		const preventDefault = !this.dispatchEvent(
			new CustomEvent(EVENTS.PICKER.CHANGE, {
				detail: { element: option },
				cancelable: true,
			}),
		);

		if (preventDefault) return;

		const selected = option.hasAttribute('selected');

		if (!(this.multiple || this.list) && selected) {
			this.close();
			return;
		}

		if (this.multiple) {
			if (selected) {
				option.removeAttribute('selected');
			} else {
				option.setAttribute('selected', '');
			}
		} else {
			// Not a multiselect - unselect other options
			for (const child of this.querySelectorAll('imt-option')) {
				if (child !== option) child.removeAttribute('selected');
			}
			option.setAttribute('selected', '');
		}

		this.dispatchEvent(new CustomEvent('input', { bubbles: true }));
		if (close && !this.multiple && !this.list) this.close();
		this.update();

		this.dispatchEvent(new CustomEvent(EVENTS.PICKER.CHANGED, { detail: { element: option } }));
	}

	getSelectedElements() {
		return Array.from(this.querySelectorAll(':scope imt-option[selected]'));
	}

	showError(error) {
		if (!error) {
			this.removeAttribute('error');
			return;
		}

		const messages = this.querySelector(':scope > .messages');

		if (messages) messages.textContent = I18n.l('picker.failedLoad');
		this.setAttribute('error', '');
	}

	displaySearcher() {
		const searcher = this.querySelector('imt-searcher');

		if (searcher) {
			if (this.showSearcher === true) {
				searcher.hidden = false;
			} else if (this.showSearcher === false) {
				searcher.hidden = true;
			} else if (!isNaN(this.showSearcher)) {
				const minLength = +this.showSearcher;

				searcher.hidden =
					Array.isArray(this.options?.store) &&
					this.options.store.flatMap((item) => item.options || item).length < minLength;
			}
		}
	}
}

Picker.COMPONENTS = {
	RPC_DATA_GETTER: 'rpcData',
	OPTION_RENDERER: 'optionRenderer',
	DEFAULT_OPTION_RESOLVER: 'defaultOptionResolver',
};

export class PickerDropdown extends HTMLElement {
	constructor() {
		super();

		this.updatePosition = (dropdownRect = this.getBoundingClientRect()) => {
			const rect = this.parentNode.getBoundingClientRect();
			const yDifference = window.innerHeight - (rect.y + rect.height);

			if (yDifference < dropdownRect.height) {
				this.style.left = `${rect.x}px`;
				this.style.top = `${rect.top - 1 - dropdownRect.height}px`;
				this.style.width = `${Math.max(200, this.parentNode.offsetWidth)}px`;
				this.classList.add('top-aligned');
			} else {
				this.style.left = `${rect.x}px`;
				this.style.top = `${rect.bottom - 1}px`;
				this.style.width = `${Math.max(200, this.parentNode.offsetWidth)}px`;
				this.classList.remove('top-aligned');
			}
		};

		// Remove .hover class used for focus of elements while navigation via keyboard
		this._observer = new MutationObserver((mutationList) => {
			const hoverMutation = mutationList.find(
				(mutation) => mutation.target.classList.contains('hover') && mutation.attributeName === 'class',
			);
			const styleMutation = mutationList.find(
				(mutation) => mutation.attributeName === 'style' && mutation.target !== this,
			);

			if (hoverMutation) {
				this.querySelectorAll('imt-option.hover').forEach((elm) => {
					if (elm !== hoverMutation.target) elm.classList.remove('hover');
				});
			}

			if (styleMutation) {
				this.updatePosition();
			}
		});

		this._observer.observe(this, { childList: true, subtree: true, attributes: true, attributeFilter: ['class'] });
	}

	get opened() {
		return this._opened;
	}

	open() {
		const dropdownRect = this.getBoundingClientRect();

		this._opened = true;
		this._observer.observe(this, { childList: true, subtree: true, attributes: true, attributeFilter: ['class'] });
		this._observer.observe(document.body, { attributes: true, attributeFilter: ['style'], subtree: true });

		window.addEventListener('resize', () => this.updatePosition());
		window.addEventListener('scroll', () => this.updatePosition());

		// Detect all scrollable elements up the dom tree and attach scroll event to them
		this._scrollables = [];
		let node = this.parentNode;

		while (node && node !== window.document) {
			if (['auto', 'scroll'].includes(window.getComputedStyle(node).overflowY)) {
				this._scrollables.push(node);
				node.addEventListener('scroll', () => this.updatePosition());
			}
			node = node.parentNode;
		}

		// Remove hover class used for keyboard navigation
		this.querySelectorAll('imt-option').forEach((e) => e.classList.remove('hover'));

		// update position and height in requestAnimationFrame to ensure that the requeired values (like scrollHeight)
		// are properly calculated and to avoid flickering issues
		// setTimeout is used to schedule the style change to the next event loop cycle
		setTimeout(() => {
			requestAnimationFrame(() => {
				const rect = this.getBoundingClientRect(dropdownRect);

				if (rect.height < 200 && this.querySelector('.options').scrollHeight > 200) {
					this.style.minHeight = '200px';
				}
				this.updatePosition();
			});
		});
	}

	close() {
		this._opened = false;
		window.removeEventListener('resize', () => this.updatePosition());
		window.removeEventListener('scroll', () => this.updatePosition());
		this._observer.disconnect();
		this.style.minHeight = '';

		if (Array.isArray(this._scrollables)) {
			for (const node of this._scrollables) {
				node.removeEventListener('scroll', () => this.updatePosition());
			}
		}
	}
}

registerCustomElement('imt-picker', Picker);
registerCustomElement('imt-picker-dropdown', PickerDropdown);
registerCustomElement('imt-option', Option);
registerCustomElement('imt-optgroup', OptionGroup);
