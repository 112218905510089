/* eslint-env node */

const wrapper = {
	_library: typeof window !== 'undefined' ? { IML: window.IML, SIML: window.SIML } : null,
};

const IML = {
	execute(...args) {
		return wrapper._library.IML.execute(...args);
	},
	parse(...args) {
		return wrapper._library.IML.parse(...args);
	},
	stringify(...args) {
		return wrapper._library.IML.stringify(...args);
	},
	replace(...args) {
		return wrapper._library.IML.replace(...args);
	},
	_mapVariable(...args) {
		return wrapper._library.IML._mapVariable(...args);
	},
	get VARIABLES() {
		return wrapper._library.IML.VARIABLES;
	},
	get FUNCTIONS() {
		return wrapper._library.IML.FUNCTIONS;
	},
	get OPERATORS() {
		return wrapper._library.IML.OPERATORS;
	},
	get KEYWORDS() {
		return wrapper._library.IML.KEYWORDS;
	},
	get TEAM_VARIABLES() {
		return wrapper._library.IML.TEAM_VARIABLES;
	},
	get ORGANIZATION_VARIABLES() {
		return wrapper._library.IML.ORGANIZATION_VARIABLES;
	},
	get SCENARIO_VARIABLES() {
		return wrapper._library.IML.SCENARIO_VARIABLES;
	},
	get errors() {
		return wrapper._library.IML.errors;
	},
};

const SIML = {
	execute(...args) {
		return wrapper._library.SIML.execute(...args);
	},
};

export default wrapper;
export { IML, SIML };
