/**
 * This file is used by both font-end and back-end.
 */

import { validate as validateNested } from '../types.mjs';
import { ValidationError } from '../helpers/errors.mjs';

/**
 * Casts provded value. If value is not valid, returns null. Front-end uses this function to
 * evaluate final value from the input field.
 *
 * @param {*} value Value to cast.
 * @returns {*} Casted value.
 */

export function cast(value) {
	if (value == null) return null;

	return value;
}

/**
 * Validates and casts value.
 *
 * @param {*} value Value to validate.
 * @param {object} instructions Type instructions.
 * @param {object} options Global options.
 * @param {function} more Validate another array of instructions on the same bundle level.
 * @returns {*} Value casted to the type.
 */

export function validate(value, instructions, options = {}) {
	if (value == null || value === '') return null;

	if (typeof value !== 'object') {
		throw new ValidationError('Invalid collection.', 'validate-collection-invalid');
	}

	// Objects created with `Object.create(null)` have no constructor.
	if (value.constructor != null && value.constructor.prototype !== Object.prototype) {
		throw new ValidationError('Invalid collection.', 'validate-collection-invalid');
	}

	if (options.depth !== 0) {
		// If depth is set to zero, nested structures are not validated. Usefull for front-end, where each field is validated separately.

		const shadow = Object.create(null);
		const validatedFields = Object.create(null);
		const more = (instructions) => {
			if (Array.isArray(instructions) && instructions.length) {
				for (const item of instructions) {
					try {
						value[item.name] = validateNested(value[item.name], item.type, item, options, more);
					} catch (ex) {
						if (ex instanceof ValidationError) {
							ex.location = ex.location ? ` ${ex.location}` : '';
							ex.location = `imt-input-${item.type}[name="${item.name}"]` + ex.location;
						}
						throw ex;
					}

					if (options.strict) {
						validatedFields[item.name] = true;
					}

					if (instructions.sequence) {
						// Move value to shadow collection.
						shadow[item.name] = value[item.name];
						delete value[item.name];
					}
				}
			}
		};

		more(instructions.spec);

		if (options.strict) {
			for (const key of Object.keys(value)) {
				if (!validatedFields[key]) {
					throw new ValidationError(`Unexpected parameter '${key}' found.`, 'validate-collection-unexpected', { key });
				}
			}
		}

		if (instructions.sequence) {
			for (const key in value) {
				if (Object.prototype.hasOwnProperty.call(value, key)) {
					// Move value to shadow collection.
					shadow[key] = value[key];
					delete value[key];
				}
			}

			for (const key in shadow) {
				if (Object.prototype.hasOwnProperty.call(value, key)) {
					// Move value from shadow collection back to original collection.
					value[key] = shadow[key];
				}
			}
		}
	}

	return value;
}
