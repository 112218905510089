import { getStateObject } from './utils.mjs';

const DEFAULT_DOMAIN = 'default';

export default class Domains {
	constructor(form) {
		this._store = new Map();
		this.form = form;
	}

	get list() {
		return this._store.values();
	}

	get length() {
		return this._store.size;
	}

	setDefaults(name, defaults, instructions, values, metadata = {}, priority) {
		this._store.set(name, { name, defaults, instructions, values, metadata, priority });
	}

	getDefaults(name) {
		return this._store.get(name);
	}

	value(domain = DEFAULT_DOMAIN) {
		// Call fieldset.value instead of this.fields -> value to use value resolver from fieldset that
		// takes in account version of the manifest and excludes unexpected empty values if requested
		return this.fieldsets(domain).reduce((out, fieldset) => {
			Object.assign(out, fieldset.value);
			return out;
		}, {});
	}

	fieldsets(domain = DEFAULT_DOMAIN) {
		const fieldsets = [];

		function processFs(fs) {
			const fsDomain = fs.getAttribute('domain');

			if (fsDomain === domain) {
				fieldsets.push(fs);
				fieldsets.push(...fs.nestedFieldsets);
			} else {
				getNested(fs);
			}
		}

		// TODO: improve logic - structure could be different
		function getNested(fs) {
			fs.querySelectorAll(':scope > [field] > imt-nested > imt-fieldset').forEach(processFs);
		}

		function isRootFieldset(element) {
			const fieldsetTag = 'IMT-FIELDSET';

			if (!element.parentElement) {
				return true;
			}

			if (element.parentElement.tagName !== fieldsetTag) {
				return !element.parentElement.closest(fieldsetTag);
			}
			return false;
		}

		Array.from(this.form.querySelectorAll('imt-fieldset')).filter(isRootFieldset).forEach(processFs);

		return fieldsets;
	}

	fields(domain = DEFAULT_DOMAIN) {
		return this.fieldsets(domain).flatMap((fieldset) => fieldset.fields);
	}

	getValidateInstructions(domain = DEFAULT_DOMAIN) {
		return this.fields(domain).map((field) => field.getValidateInstructions());
	}

	getState(domain = DEFAULT_DOMAIN) {
		return getStateObject(this.fields(domain));
	}
}
